import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, FormField, Tag, Text } from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons'

const defaultState = {
  tags: [],
  tagValue: '',
}

const FilterByTags = (props) => {
  const { setJobState, jobState } = props
  const { filterTags } = jobState

  const [state, setState] = useSetState(defaultState)
  const { tags, tagValue } = state

  const toggleTag = (tag, type) => {
    if (type === 'add' && tags.find(x => x === tag)){
      setState({ tagValue: '' })
    } else {
      const updatedTags = type === 'remove' ? tags.filter(x => x !== tag) : [...tags, tag]
      setState({
        tags: updatedTags,
        tagValue: type === 'add' ? '' : tagValue,
      })

      setJobState({ filterTags: updatedTags })
    }
  }

  useEffect(() => {
    setState({ tags: filterTags })
  }, [])

  return (
    <FormField direction="column" label="Filter By Tags">
      <Box flexDirection="column" position="relative">
        <input
          onChange={e => setState({ tagValue: e.target.value })}
          onKeyDown={(e) => {
            if (e.key === 'Enter'){
              e.preventDefault()
              if (tagValue.trim().length){
                toggleTag(tagValue.trim(), 'add')
              }
            }
          }}
          style={{ paddingRight: '15px' }}
          type="text"
          value={tagValue}
          placeholder="Enter to add tag"
        />
        <Box
          fontSize="xsmall"
          onClick={() => (tagValue.trim().length ? toggleTag(tagValue.trim(), 'add') : null)}
          position="absolute"
          right={6}
          top={12}
          width="auto"
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Box>
      </Box>
      <Box flexWrap="wrap" gridGap="small" marginTop={tags.length ? 'medium' : null}>
        {tags.map(tag => (
          <Tag
            key={tag}
            boxProps={{
              width: 'fit-content',
              fontSize: 'xsmall',
              onClick: () => toggleTag(tag, 'remove'),
            }}
          >
            <Text color="bodyFontLightColor" style={{ cursor: 'pointer' }}>
              {tag} &nbsp;<FontAwesomeIcon icon={faTimes} />
            </Text>
          </Tag>
        ))}
      </Box>
    </FormField>
  )
}

FilterByTags.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default FilterByTags
