import { useThunkDispatch } from '@campaignhub/react-hooks'
import * as emailReplacementActions from '@redux/modules/emailReplacement'

const createEmailReplacement = (emailReplacementParams, dispatch) => {
  const { createEmailReplacement: createFn } = emailReplacementActions
  return dispatch(createFn(emailReplacementParams))
}

const deleteEmailReplacement = (emailReplacementParams, dispatch) => {
  const { deleteEmailReplacement: deleteFn } = emailReplacementActions
  return dispatch(deleteFn(emailReplacementParams))
}

const updateEmailReplacement = (emailReplacementParams, dispatch) => {
  const { updateEmailReplacement: updateFn } = emailReplacementActions
  return dispatch(updateFn(emailReplacementParams))
}

const useEmailReplacement = () => {
  const dispatch = useThunkDispatch()

  return {
      callbacks: {
        createEmailReplacement: emailReplacementParams => createEmailReplacement(emailReplacementParams, dispatch),
        deleteEmailReplacement: emailReplacementParams => deleteEmailReplacement(emailReplacementParams, dispatch),
        updateEmailReplacement: emailReplacementParams => updateEmailReplacement(emailReplacementParams, dispatch),
      },
    }
}

export default useEmailReplacement
