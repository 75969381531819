const chunkArray = (arr, maxItems, maxGroups) => {
  const chunked = []
  const chunkSize = Math.ceil(arr.length / maxGroups) < maxItems
    ? maxItems : Math.ceil(arr.length / maxGroups)

  for (let i = 0; i < arr.length; i += chunkSize){
      const chunk = arr.slice(i, i + chunkSize)
      chunked.push(chunk)
  }

  return chunked
}

export default chunkArray
