import React, { useContext } from 'react'

import PageContext from '@contexts/pageContext'
import { Box, Text } from '@campaignhub/suit-theme'

const ZoneList = () => {
  const { categories, zones } = useContext(PageContext)

  return (
    <Box
      backgroundColor="white"
      gridArea="zones-container"
      left="0"
      position="sticky"
      zIndex="4"
    >
      <Box flexDirection="column" alignItems="center">
        {zones.sort((a, b) => (a.name > b.name ? 1 : -1)).map(zone => (
          <Box flexDirection="column" key={zone.id}>
            <Box
              alignItems="center"
              backgroundColor="#f8f8f8"
              height="40px"
              justifyContent="center"
              textAlign="center"
            >
              <Text fontSize="xsmall">{zone.name.toUpperCase()}</Text>
            </Box>
            {categories.sort((a, b) => (a.description > b.description ? 1 : -1)).map(serviceCategory => (
              <Box
                key={serviceCategory.id}
                alignItems="center"
                height="40px"
                justifyContent="center"
                style={{ boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
                textAlign="center"
              >
                <Text fontSize="xsmall" color="bodyFontLightColor">
                  {serviceCategory.description}
                </Text>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

ZoneList.propTypes = {}

export default ZoneList
