import { DateTime } from 'luxon'
import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState, { requiredFields } from '@models/blockout'

import PageContext from '@contexts/pageContext'

import * as blockoutActions from '@redux/modules/blockout'

const filterBlockoutParams = (blockoutParams) => {
  let filteredParam = blockoutParams

  if (filteredParam.id === null){
    const { id, ...newUserParam } = filteredParam
    filteredParam = newUserParam
  }

  filteredParam.start = DateTime.fromISO(filteredParam.start, { zone: 'utc' }).toISO()
  filteredParam.finish = DateTime.fromISO(filteredParam.finish, { zone: 'utc' }).toISO()

  return filteredParam
}

const createBlockout = (blockoutParams, dispatch, requestOptions) => {
  const { createBlockout: createFn } = blockoutActions

  return dispatch(createFn(filterBlockoutParams(blockoutParams), requestOptions))
}

const createCurrentBlockout = (blockoutParams, dispatch, requestOptions) => {
  const { createCurrentBlockout: createFn } = blockoutActions

  return dispatch(createFn(filterBlockoutParams(blockoutParams), requestOptions))
}

const updateBlockout = (blockoutParams, dispatch, requestOptions) => {
  const { updateBlockout: updateFn } = blockoutActions

  return dispatch(updateFn(filterBlockoutParams(blockoutParams)), requestOptions)
}

const updateCurrentBlockout = (blockoutParams, dispatch, requestOptions) => {
  const { updateCurrentBlockout: updateFn } = blockoutActions

  return dispatch(updateFn(filterBlockoutParams(blockoutParams)), requestOptions)
}

const deleteBlockout = (blockout, dispatch) => {
  const { deleteBlockout: deleteFn } = blockoutActions

  return dispatch(deleteFn(blockout))
}

const deleteCurrentBlockout = (blockout, dispatch) => {
  const { deleteCurrentBlockout: deleteFn } = blockoutActions

  return dispatch(deleteFn(blockout))
}

const validateCurrentBlockout = (blockoutParams, dispatch, requestOptions) => {
  const { validateCurrentBlockout: validateFn } = blockoutActions

  const { id, ...payload } = filterBlockoutParams(blockoutParams)

  return dispatch(validateFn(payload, requestOptions))
}

const createOrEditCurrentBlockout = (blockout, dispatch, showCurrentBlockoutModal) => new Promise((resolve, reject) => {
  if (showCurrentBlockoutModal){
    const payload = {
      callbacks: {
        createCurrentBlockout: blockoutParams => createCurrentBlockout(blockoutParams, dispatch),
        deleteCurrentBlockout: () => deleteCurrentBlockout(blockout, dispatch),
        updateCurrentBlockout: blockoutParams => updateCurrentBlockout(blockoutParams, dispatch),
        validateCurrentBlockout: blockoutParams => validateCurrentBlockout(blockoutParams, dispatch),
      },
      blockout,
    }

    showCurrentBlockoutModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showCurrentBlockoutModal not defined in PageContext callbacks'))
})

// For User Management
const createOrEditBlockout = (blockout, dispatch, showBlockoutModal) => new Promise((resolve, reject) => {
  if (showBlockoutModal){
    const payload = {
      callbacks: {
        createBlockout: blockoutParams => createBlockout(blockoutParams, dispatch),
        deleteBlockout: () => deleteBlockout(blockout, dispatch),
        updateBlockout: blockoutParams => updateBlockout(blockoutParams, dispatch),
        // Add validate if needed
      },
      blockout,
    }

    showBlockoutModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showBlockoutModal not defined in PageContext callbacks'))
})

export function useBlockoutForm(blockout = {}){
  const userForm = useForm(defaultState, { entity: blockout, requiredFields }, [blockout.id])

  userForm.entityState.start = DateTime.fromISO(userForm.entityState.start, { zone: 'utc' }).toISO()
  userForm.entityState.finish = DateTime.fromISO(userForm.entityState.finish, { zone: 'utc' }).toISO()

  return {
    ...userForm,
  }
}

const useBlockout = (initBlockout = {}) => {
  const { entity: blockout } = useLatestEntity(initBlockout, 'blockouts')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showBlockoutModal } = callbacks || {}

  const { creating, deleting, loading, updating } = useSelector(reduxState => reduxState.blockouts)

  return {
    blockout,
    callbacks: {
      createBlockout: (blockoutParams, requestOptions) => createBlockout(blockoutParams, dispatch, requestOptions),
      createCurrentBlockout: (blockoutParams, requestOptions) => createCurrentBlockout(blockoutParams, dispatch, requestOptions),
      createOrEditBlockout: () => createOrEditBlockout(blockout, dispatch, showBlockoutModal),
      createOrEditCurrentBlockout: () => createOrEditCurrentBlockout(blockout, dispatch, showBlockoutModal),
      deleteBlockout: () => deleteBlockout(blockout, dispatch),
      deleteCurrentBlockout: () => deleteCurrentBlockout(blockout, dispatch),
      updateBlockout: (blockoutParams, requestOptions) => updateBlockout(blockoutParams, dispatch, requestOptions),
      updateCurrentBlockout: (blockoutParams, requestOptions) => updateCurrentBlockout(blockoutParams, dispatch, requestOptions),
      validateCurrentBlockout: (blockoutParams, requestOptions) => validateCurrentBlockout(blockoutParams, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useBlockout
