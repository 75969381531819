const state = {
  id: null,
  flags: [],
  name: '',
  reference: '',
  dusk_times: [],
  default_travel_time: 0,
}

export const requiredFields = [
  { key: 'name' },
]

export default state
