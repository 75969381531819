import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/serviceJobTimetracker/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/serviceJobTimetracker/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/serviceJobTimetracker/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/serviceJobTimetracker/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/serviceJobTimetracker/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/serviceJobTimetracker/CREATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
  isActive: false,
  isPaused: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(active = false, paused = false){
  return {
    type: CREATE_SUCCESS,
    isActive: active,
    isPaused: paused,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function getServiceJobUserTime(serviceJob){
  const options = serviceJob.payload

  return (dispatch) => {
    dispatch(fetchRequest())
    const promise = api(`/v1/service-jobs/${serviceJob.id}/time`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function getServiceJobUserTimes(serviceJob){
  const includes = ['serviceJobUser', 'serviceJob', 'user']
  const options = serviceJob.payload
  options.includes = includes.join(',')

  return (dispatch) => {
    dispatch(fetchRequest())
    const promise = api('/v1/service-jobs/list/time', options)
      .then(({ data }) => {
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function serviceJobTimeStart(serviceJobId, requestData, options){
  const config = {
    method: 'POST',
    data: requestData,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/v1/service-jobs/${serviceJobId}/job-in`, options, config)
    .then(({ data }) => {
      dispatch(createSuccess(true, false))

      return { success: true, result: data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(createFailure(errors))

      return { success: false, errors }
    })

    return promise
  }
}

export function serviceJobTimeStop(serviceJobId, requestData, options){
  const config = {
    method: 'POST',
    data: requestData,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/v1/service-jobs/${serviceJobId}/job-out`, options, config)
    .then(({ data }) => {
      dispatch(createSuccess(false, true))

      return { success: true, data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(createFailure(errors))

      return { success: false, errors }
    })

    return promise
  }
}

export default function reducer(state = initialState, action = {}){
  const { errors, type, payload } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    default:
      return state
  }
}
