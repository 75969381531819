import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, Text, ListItem,
} from '@campaignhub/suit-theme'

const ClientProductionNotes = (props) => {
  const { clientProductionNotes, hidden } = props

  if (hidden) return null

  return (
    <DashboardModule title="Production Notes">
      <Box flexDirection="column" lineHeight="1.3">
        {!clientProductionNotes ? (
          <ListItem
            boxProps={{
            borderBottom: '1px solid',
            padding: 'large',
          }}
            flexDirection="column"
            disableHover
          >
            <Text fontSize="xsmall" color="bodyFontLightColor">
              No Available Notes
            </Text>
          </ListItem>
        ) : (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Text fontSize="xsmall" color="bodyFontLightColor">
              {clientProductionNotes}
            </Text>
          </ListItem>
        )}
      </Box>
    </DashboardModule>
  )
}

ClientProductionNotes.propTypes = {
  clientProductionNotes: PropTypes.string,
  hidden: PropTypes.bool,
}

export default ClientProductionNotes
