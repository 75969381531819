import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/blockedServiceJob/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/blockedServiceJob/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/blockedServiceJob/FETCH_FAILURE'

const UPDATE_REQUEST = 'contenthouse/blockedServiceJob/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/blockedServiceJob/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/blockedServiceJob/UPDATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  results: {},
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    results: payload.data,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function loadServiceJobsServiceDate(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().serviceJobs.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/v1/service-jobs/list/servicedate', options)
      .then((data) => {
        const payload = {
          data: data.data,
        }

        const normalizedJson = normalize(data.data, Schemas.BLOCKED_SERVICE_JOB_TYPE_ARRAY)

        const updateEntityOptions = {
            mergeOptions: {
              customMerge: () => (_, source) => source,
            },
          }

        dispatch(updateEntities(normalizedJson, updateEntityOptions))
        dispatch(fetchSuccess(payload))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export default function reducer(state = initialState, action = {}){
  const { type, errors, loadedForKeys, loadedIds, results } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: loadedForKeys || state.loadedForKeys,
        loadedIds: loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        results: results || state.results,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    default:
      return state
  }
}
