import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'

const watchEntityKeys = ['usersV3']

function useUsersV3(options = {}){
  const {
    searchFilter,
    selectedService = [],
    selectedRole = [],
    selectedEmployment = [],
    searchFlags = [],
    reset,
    listCount,
  } = options

  const {
    updatedEntities: { usersV3: usersV3UpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { usersV3 } = useSelector(reduxState => reduxState.entities)

  let filteredUsersV3 = useMemo(() => {
    const filtered = Object.values(usersV3)
    .filter(user => ((user.name?.toLowerCase().includes(searchFilter.toLowerCase()))
    && (selectedService.length === 0 || user.provider?.services.some(x => selectedService.includes(x))
    || user.editor?.services.some(x => selectedService.includes(x))
    || user.qc?.services.some(x => selectedService.includes(x)))
    && (selectedRole.length === 0 || user.roles?.some(x => selectedRole.includes(x)))
    && (selectedEmployment.length === 0 || selectedEmployment.some(x => user.employment?.includes(x)))
    && (searchFlags.length === 0 || user.flags?.some(x => searchFlags.includes(x)))))

    return sortArrayBy(filtered, 'asc', 'name')
  }, [usersV3UpdatedAt, options])

  filteredUsersV3 = reset || selectedService.length === 0 || searchFlags.length === 0
  || selectedRole.length === 0 || selectedEmployment === 0 ? filteredUsersV3.slice(0, listCount) : filteredUsersV3

  const entityReducer = useSelector(reduxState => reduxState.usersV3)
  const { loading } = entityReducer

  return {
    filteredUsersV3,
    loading,
    usersV3,
  }
}

export default useUsersV3
