import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import {
  Box, Button, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import ServiceCategoriesList from '@components/ServiceCategoriesList'

const updateServiceCategory = (entityParams, updateFn, count, total, setState, closeModal) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })

  if (total === count){
    setState({ saving: false })
    closeModal()
  }
}

const defaultState = {
  disableSave: false,
  saving: false,
}

const compoCallbacks = (component, setState) => {
  const componentCallbacks = {
    ServiceCategoriesList: {
      disableSave: val => setState({ disableSave: val }),
    },
  }

  return componentCallbacks[component] || {}
}

const EditServiceCategoriesModal = (props) => {
  const [state, setState] = useSetState(defaultState)
  const { disableSave, saving } = state
  const { callbacks, modalKey, showModal } = props

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: { updateServiceCategory: updateFn },
    serviceCategories,
  } = modalPayload

  const save = async () => {
    setState({ saving: true })

    let count = 1
    const categories = Object.values(serviceCategories).filter(x => x.id >= 0)
    categories.forEach((serviceCategory) => {
      updateServiceCategory(serviceCategory, updateFn, count, categories.length, setState, closeModal)
      count += 1
    })
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="large">
      <SidebarModal.Header callbacks={callbacks} title="Edit" titleSecondLine="Service Categories" />
      <SidebarModal.Content>
        <Box flexDirection="column" border="1px solid" borderColor="lineColor" borderRadius={5}>
          <ServiceCategoriesList
            callbacks={compoCallbacks('ServiceCategoriesList', setState)}
            serviceCategories={Object.values(serviceCategories).filter(x => x.id >= 0)}
          />
        </Box>
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          loading={saving}
          disabled={disableSave}
          onClick={() => save()}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Update
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditServiceCategoriesModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditServiceCategoriesModal.defaultProps = {
  modalKey: 'EditServiceCategoriesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditServiceCategoriesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
