/* eslint-disable array-callback-return */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import useUsers from '@hooks/useUsers'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, FormField, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

import { getFilteredUserRole } from '@functions/getFilteredUserRole'
import { groupBy } from '@functions/groupBy'
import { getEntityByName } from '@functions/getEntityByName'
import userTypes from '@functions/userTypes'

const defaultState = {
  filteredUsers: [],
  showFilter: false,
  userState: [],
  filterLabel: 'Studio User',
  groupState: [{ name: 'All', isChecked: false }],
}

const StudioUserFilter = (props) => {
  const { setJobState, jobState } = props
  const { userFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { filteredUsers, showFilter, userState, filterLabel, groupState } = state

  const { users } = useUsers()

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  const entities = useSelector(reduxState => reduxState.entities)
  const { userRoles } = entities

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    const filteredUserRole = getFilteredUserRole(users, {
      user_type: userTypes.find(x => x.name === 'Studio').id,
      contract_base: getEntityByName(userRoles, 'ContractualEditor')?.id,
    })

    setState({
      filteredUsers: filteredUserRole.map(user => ({
        ...user,
        isChecked: userFilter.includes(user.id),
      })),
    })
  }, [users, userFilter])

  useEffect(() => {
    const groupedUserState = groupBy(filteredUsers, 'type')
    if (groupedUserState && Object.keys(groupedUserState).length !== 0){
      Object.keys(groupedUserState).forEach((group) => {
        groupedUserState[group] = groupedUserState[group]
        .sort((a, b) => ((`${a.firstName} ${a.lastName}`).toLowerCase() > (`${b.firstName} ${b.lastName}`).toLowerCase() ? 1 : -1))
        if (!groupState.some(x => x.name === group)){ setState({ groupState: [...groupState, { name: group, isChecked: false }] }) }
      })
    }
    setState({ userState: groupedUserState })
  }, [filteredUsers])

  const updateUserState = (checked, userId) => {
    const updatedUserFilter = checked ? [...userFilter, userId] : userFilter.filter(i => i !== userId)
    setJobState({ userFilter: updatedUserFilter })
    const initialUser = updatedUserFilter.length ? updatedUserFilter
      .map((id) => {
        const user = [].concat(...Object.values(userState)).find(obj => obj.id === id)
        return user ? `${user.firstName} ${user.lastName}` : null
      })
      .find(fullName => fullName !== null) : 'Studio User'
    setState({ filterLabel: `${initialUser} ${updatedUserFilter.length > 1 ? '...' : ''}` })
  }

  const selectAll = (checked, type) => {
    const filterList = type === 'All' ? filteredUsers : userState[type]
    setState({ filterLabel: (checked ? `All ${(type === 'All' ? 'Studio Users' : type)}` : 'Studio User') })
    setState({ groupState: groupState.map(group => (group.name === type ? { ...group, isChecked: checked } : group)) })
    let checklist = []

    if (checked){
      filterList.map((user) => {
        checklist = [...checklist, ...userFilter, user.id]
      })
    } else {
      checklist = [...userFilter]
      filterList.map((user) => {
        checklist = checklist.filter(i => i === user.id)
      })
    }

    setJobState({ userFilter: [...new Set(checklist)] })
  }

  if (Object.keys(userState).length === 0) return null

  return (
    <FormField direction="column" boxProps={{ paddingBottom: 'medium' }}>
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          defaultValue="Studio User"
        >
          <option>{ filterLabel }</option>
        </select>

        {showFilter && (
          <Box className={custom.popup}>
            <Box flexDirection="column" maxHeight="280px" overflowY="auto">
              <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                <input
                  type="checkbox"
                  className={custom.checkbox}
                  onChange={e => selectAll(e.target.checked, 'All')}
                  checked={groupState.find(group => group.name === 'All').isChecked}
                />
                <Text fontSize="small" marginLeft="medium">Select All</Text>
              </Box>
              {Object.keys(userState).map(type => (
                <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                  <input
                    type="checkbox"
                    className={custom.checkbox}
                    onChange={e => selectAll(e.target.checked, type)}
                    checked={groupState.find(group => group.name === type).isChecked}
                  />
                  <Text fontSize="small" marginLeft="medium">Select All {type}</Text>
                </Box>
              ))}
              {Object.keys(userState).map(userGroup => (
                <>
                  <Text fontSize="small" fontWeight="medium" paddingBottom="medium" paddingTop="large">{userGroup}</Text>
                  {userState[userGroup].map(user => (
                    <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                      <input
                        className={custom.checkbox}
                        onChange={e => updateUserState(e.target.checked, user.id)}
                        type="checkbox"
                        checked={user.isChecked}
                      />
                      <Text fontSize="small" marginLeft="medium">{`${user.firstName } ${ user.lastName}`}</Text>
                    </Box>
                  ))}
                </>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </FormField>
  )
}

StudioUserFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default StudioUserFilter
