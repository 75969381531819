import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

const useNotifications = (options) => {
  const { notifications } = options
  const unreadNotifications = Object.values(notifications).filter(notification => !notification.readAt)

  const filteredNotifications = useMemo(() => {
    const filtered = Object.values(notifications)

    return sortArrayBy(filtered, 'desc', 'id')
  }, [notifications])

  const entityReducer = useSelector(reduxState => reduxState.notifications)
  const { loading } = entityReducer

  const hasNotifications = !!filteredNotifications.length

  return {
    filteredNotifications,
    hasNotifications,
    loading,
    unreadNotifications,
  }
}

export default useNotifications
