import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Image, Text, Link, ListItem,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-light-svg-icons'

import Icons from '@components/Icons'
import PageContext from '@contexts/pageContext'

const defaultState = {
  versions: [],
}

const VersionList = () => {
  const [state, setState] = useSetState(defaultState)
  const { versions } = state

  const { callbacks, serviceJob, selectedAsset } = useContext(PageContext)
  const { toggleUpdateSelectedAsset } = callbacks || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const selectedImage = (version, assetUrl, previewUrl, premiumUrl, filePreviewRequest) => {
    const currentAsset = selectedAsset
    currentAsset.assetUrl = assetUrl
    currentAsset.previewUrl = previewUrl
    currentAsset.premiumUrl = premiumUrl
    currentAsset.version = version
    currentAsset.file.filePreviewRequest = filePreviewRequest

    toggleUpdateSelectedAsset(currentAsset)
  }

  useEffect(() => {
    if (selectedAsset?.histories.length){
      setState({ versions: [...new Map(selectedAsset.histories.map(item => [item.version, item])).values()] })
    }
  }, [selectedAsset])

  const latestVersion = selectedAsset?.histories.reduce((x, y) => ((x.version > y.version) ? x : y))

  return (
    versions.map((content) => {
      const previewFile = content?.filePreviewRequests?.find(x => x.imageType === 'Preview')

      return (
        <ListItem
          boxProps={{ paddingBottom: 'large' }}
          flexDirection="column"
          disableHover
          key={content.id}
        >
          <Box
            key={content.id}
            onClick={() => selectedImage(content.version, content.assetUrl, content.previewUrl, content.premiumUrl, content.filePreviewRequest)}
          >
            <Image
              boxProps={{
                border: selectedAsset.version === content.version ? '2px solid' : '1px solid',
                borderColor: selectedAsset.version === content.version ? 'blue' : '#ececec',
                borderRadius: 5,
                backgroundSize: 'contain',
              }}
              url={previewFile?.generatedAt ? content.previewUrl : ''}
              width="100%"
              height={182}
            >
              {!previewFile?.generatedAt && (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  display="grid"
                  justifyContent="center"
                  width="100%"
                >
                  <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
                </Box>
              )}
            </Image>
          </Box>
          <Box flexDirection="row" paddingY="medium" alignItems="center" justifyContent="center">
            <Text color="bodyFontLightColor" fontSize="small" marginRight="small">
              Version {content.version} {(latestVersion?.version === content.version) ? '(Current)' : ''}
            </Text>
            <Link href={content.assetUrl}>
              <FontAwesomeIcon icon={faDownload} size="sm" />
            </Link>
          </Box>
        </ListItem>
      )
    }).reverse()
  )
}

VersionList.propTypes = {}

export default VersionList
