import cloneDeep from 'lodash/cloneDeep'

const removeKeyWithEmptyValue = (entityState) => {
  const obj = cloneDeep(entityState)
  Object.keys(obj).forEach((key) => {
    if (obj[key] === '' || obj[key] === null){
      delete obj[key]
    } else if (Object.prototype.toString.call(obj[key]) === '[object Object]'){
      Object.keys(obj[key]).forEach((i) => {
        if (obj[key][i] === '' || obj[key][i] === null){
          delete obj[key][i]
        }
        if (Object.values(obj[key]).length === 0) delete obj[key]
      })
    } else if (Array.isArray(obj[key])){
        obj[key].some((i, index) => {
          if (Object.prototype.toString.call(i) === '[object Object]'){
            Object.entries(i).some((value) => {
              if (obj[key] != null && (value[1] === '' || value[1] === null || value[1] === 'Invalid DateTime')){
                delete obj[key][index] // Remove the array
                obj[key].splice(0, 1) // Update length of the array
              }
              if (!obj[key].length){
                obj[key] = null // Set the array to null
                return true // breaks loop
              }
              return false
            })
          }
        return true
      })
    }
  })

  return obj
}

export default removeKeyWithEmptyValue
