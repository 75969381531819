/* eslint-disable no-param-reassign */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { useFilterForm } from '@hooks/useFilter'

const createServiceJobView = (entityState, props, createFn) => {
  const { callbacks: { createFilter }, dateFilter, clientFilter, serviceFilter, statusFilter,
    userFilter, serviceUserFilter, classificationFilter, accountManagerFilter, serviceDateFilter } = props

  entityState.filterEnd = dateFilter.filterEnd
  entityState.filterStart = dateFilter.filterStart
  entityState.clients = clientFilter
  entityState.services = serviceFilter
  entityState.serviceJobStatuses = statusFilter
  entityState.users = userFilter
  entityState.serviceUsers = serviceUserFilter
  entityState.classifications = classificationFilter
  entityState.accountManagers = accountManagerFilter
  entityState.serviceDateFilterStart = serviceDateFilter.filterStart
  entityState.serviceDateFilterEnd = serviceDateFilter.filterEnd

  createFilter(entityState, createFn)
}

const CreateServiceJobViewModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const filterPayload = digObject(modalData, modalKey, {})

  const {
    filter,
    callbacks: { createFilter: createFn },
  } = filterPayload

  const filterFormPayload = useFilterForm(filter)

  const { creating } = useSelector(reduxState => reduxState.filters)

  const {
    entityState,
    entityState: {
      name,
    },
    errors,
    handlers,
    saveEnabled,
  } = filterFormPayload

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Add View" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0} lineHeight="1.3">
          <FormField direction="column" marginTop="large" errorMessage={errors.name} label="* Name">
            <input name="name" type="text" value={name} {...handlers} data-validate-field-on="change" />
          </FormField>
          <Text color="bodyFontLightColor" fontSize="small" marginTop="large">
            Note: Selected services and statuses shall be saved as a permanent view which you can access anytime.
          </Text>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={() => createServiceJobView(entityState, props, createFn)}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateServiceJobViewModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateServiceJobViewModal.defaultProps = {
  modalKey: 'CreateServiceJobViewModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateServiceJobViewModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
