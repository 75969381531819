import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useDebounce, useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, FormField, InputSearch, ListItem,
} from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import ServiceFilter from './components/ServiceFilter'
import RoleFilter from './components/RoleFilter'
import EmploymentFilter from './components/EmploymentFilter'

const defaultState = {
  searchFilter: '',
}

const Tools = () => {
  const { callbacks: { searchNameFilter, toggleResetFilter } } = useContext(PageContext)

  const [state, setState] = useSetState(defaultState)
  const { searchFilter } = state
  const debouncedSearchFilter = useDebounce(searchFilter, 300)

  useEffect(() => {
    searchNameFilter(debouncedSearchFilter)
  }, [debouncedSearchFilter])

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="column"
      disableHover
    >
      <Box flexDirection="column" paddingBottom="medium">
        <InputSearch
          onChange={e => setState({ searchFilter: e.target.value })}
          value={searchFilter}
        />
      </Box>
      <FormField direction="column" boxProps={{ paddingBottom: 'medium' }}>
        <Box flexDirection="column">
          <ServiceFilter />
          <RoleFilter />
          <EmploymentFilter />
        </Box>
      </FormField>
      <Box flexDirection="row" paddingBottom="medium">
        <Box flexDirection="column">
          <Button buttonStyle="secondary" size="medium" onClick={() => toggleResetFilter()}>
            Clear Filter
          </Button>
        </Box>
      </Box>
      <Box flexDirection="row">
        <Box flexDirection="column">
          <Link to="new" style={{ textDecoration: 'none' }}>
            <Button buttonStyle="secondary" size="medium">
              Create New User
            </Button>
          </Link>
        </Box>
      </Box>
    </ListItem>
  )
}

export default Tools
