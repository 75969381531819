/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useCurrentUser from '@hooks/useCurrentUser'

import { Box, LoadingBubbles, Text } from '@campaignhub/suit-theme'

import ServiceGroup from './components/ServiceGroup'

const defaultState = {
  filteredServiceGroups: [],
}

const ServiceTypeFilter = (props) => {
  const { setJobState, jobState } = props
  const { clientFilter, dateFilter, serviceFilter, statusFilter, userFilter, customFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { filteredServiceGroups } = state

  const { currentUser, currentUser: { services } } = useCurrentUser()

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, users } = entities

  const entityReducer = useSelector(reduxState => reduxState.serviceGroups)
  const { loading } = entityReducer

  const options = {
    ...clientFilter?.length && { clients: clientFilter.join(',') },
    ...dateFilter?.filterStart && { filterStart: dateFilter.filterStart },
    ...dateFilter?.filterEnd && { filterEnd: dateFilter.filterEnd },
    ...statusFilter?.length && { serviceJobStatuses: statusFilter.join(',') },
    ...userFilter?.length && { users: userFilter.join(',') },
    ...customFilter?.length && { customFilters: customFilter.join(',') },
  }

  useReduxAction('serviceJobFilter', 'loadServiceJobCounts', options, [clientFilter, dateFilter, statusFilter, userFilter, customFilter])

  useEffect(() => {
    let temp = []

    if (['BackOffice'].includes(users[currentUser.id]?.userRole.name)){
      temp = Object.values(serviceGroups)
      setState({ filteredServiceGroups: temp })
      return
    }

    if (Object.values(serviceGroups).length){
      services.map((serviceId) => {
        const group = Object.values(serviceGroups).find(serviceGroup => serviceGroup.services.find(service => service === serviceId))
        if (group) temp.push(group)
      })

      temp = [...new Set(temp)]
    }

    temp.map((serviceGroup) => {
      const group = serviceGroup
      const filteredServices = serviceGroup.services.filter(service => services.includes(service))
      group.services = filteredServices

      return group
    })

    setState({ filteredServiceGroups: temp })
  }, [services, serviceGroups])

  return (
    <Box flexDirection="column">
      <Text
        color="bodyFontLightColor"
        fontAlign="center"
        fontSize="small"
        marginBottom="large"
      >
        Service Types
      </Text>
      {loading && <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ flexShrink: 0 }} />}
      {!filteredServiceGroups.length && !loading && (
        <Box flexDirection="column">
          <Text color="bodyFontLightColor" fontAlign="center" fontSize="xsmall">
            No Available Services
          </Text>
        </Box>
      )}
      {filteredServiceGroups.map(serviceGroup => (
        <ServiceGroup key={serviceGroup.id} serviceGroup={serviceGroup} setJobState={setJobState} serviceFilter={serviceFilter} />
      ))}
    </Box>
  )
}

ServiceTypeFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default ServiceTypeFilter
