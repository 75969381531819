import { useSelector } from 'react-redux'

const useResourceTypes = () => {
  const entityReducer = useSelector(reduxState => reduxState.resourceTypes)
  const {
    loaded,
    loading,
  } = entityReducer

  const entities = useSelector(reduxState => reduxState.entities)
  const { resourceTypes } = entities

  return {
    resourceTypes,
    loaded,
    loading,
  }
}

export default useResourceTypes
