import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  FormField,
  DashboardModule,
 } from '@campaignhub/suit-theme'

 import { useSetState } from '@campaignhub/react-hooks'

 import validateEntityStateCustomFields from '@functions/validateEntityStateCustomFields'
 import { customManagementRequiredFields } from '@models/User/management'

 const defaultState = {
  noteOnChange: '',
  validRatings: [1, 2],
}

const ManagementDetail = (props) => {
  const [state, setState] = useSetState(defaultState)
  const { noteOnChange, validRatings } = state

  const { userForm, customErrors, callbacks } = props
  const { setCustomErrors } = callbacks
  const {
    entityState,
    entityState: {
      management,
    },
    handlers,
    setEntityState,
  } = userForm

  useEffect(() => {
    setState({ noteOnChange: management.note })
  }, [management.note])

  useEffect(() => {
    management.note = noteOnChange
    setEntityState({ management })
  }, [noteOnChange])

  useEffect(() => {
    setCustomErrors(validateEntityStateCustomFields(entityState, customManagementRequiredFields))
  }, [entityState.management])

  return (
    <DashboardModule title="Management Information">
      <Box flexDirection="column" padding="large">
        <Box
          flexDirection={['column', 'row']}
          marginBottom={[0, 'large']}
        >
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Office Note"
          >
            <textarea
              placeholder="Enter office notes"
              onChange={e => setState({ noteOnChange: e.target.value })}
              style={{ fontSize: '13px', height: 80, resize: 'none', width: '100%' }}
              value={noteOnChange}
              type="text"
            />
          </FormField>
        </Box>
        <Box
          flexDirection={['column', 'row']}
          marginBottom={[0, 'large']}
          width={['100%', 'calc(50% - 4px)']}
        >
          <FormField
            boxProps={{ flex: 1 }}
            label="User Rating"
            errorMessage={customErrors['management.rating']}
          >
            <select
              data-validate-field-on="change"
              value={management.rating}
              onChange={(event) => {
                management.rating = event.target.value
                setEntityState({ management })
              }}
              name="management.rating"
              {...handlers}
            >
              <option value="">Please Select...</option>
              {Object.values(validRatings).map(validRating => (
                <option value={validRating} key={validRating}>
                  {validRating > 1 ? 'Qualified' : 'Beginner'}
                </option>
              ))}
            </select>
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
    )
 }
 ManagementDetail.propTypes = {
  userForm: PropTypes.object,
  customErrors: PropTypes.array,
  callbacks: PropTypes.object,
}

  export default ManagementDetail
