import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import validateEntityStateCustomFields from '@functions/validateEntityStateCustomFields'
import { requiredFields } from '@models/Client/client'

import GeneralDetails from './components/GeneralDetails'
import Preferences from './components/Preferences'
import ZoneDetails from './components/ZoneDetails'
import ProductionNotes from './components/ProductionNotes'
import IntegrationDetails from './components/IntegrationDetails'
import AccountManagerDetails from './components/AccountManagerDetails'

const ClientDetails = (props) => {
  const { clientForm, customErrors, callback } = props
  const { setCustomErrors } = callback

  const { entityState } = clientForm

  useEffect(() => {
    setCustomErrors(validateEntityStateCustomFields(entityState, requiredFields))
  }, [entityState])

  return (
    <Box flexDirection="column">
      <GeneralDetails
        clientForm={clientForm}
        customErrors={customErrors}
        callbacks={{ setCustomErrors }}
      />
      <Preferences clientForm={clientForm} />
      <AccountManagerDetails clientForm={clientForm} />
      <ZoneDetails clientForm={clientForm} />
      <ProductionNotes clientForm={clientForm} />
      <IntegrationDetails clientForm={clientForm} />
    </Box>
  )
}

ClientDetails.propTypes = {
  clientForm: PropTypes.object.isRequired,
  customErrors: PropTypes.array,
  callback: PropTypes.object,
}

export default ClientDetails
