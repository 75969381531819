import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'

import Icons from '@components/Icons'

const BlockedServiceJobList = (props) => {
  const { serviceJobs } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const { currentUser: { timeZone } } = useCurrentUser()

  if (!serviceJobs.length){
    return (
      <Box flexDirection="column" variant="white">
        <ListItem
          boxProps={{
            borderBottom: '1px solid',
            padding: 'large',
          }}
          flexDirection="column"
          disableHover
        >
          <Text fontSize="xsmall" color="bodyFontLightColor">
            No Available Jobs
          </Text>
        </ListItem>
      </Box>
    )
  }

  return (
    <Box flexDirection="column" variant="white">
      {serviceJobs.map((serviceJob) => {
        const deadline = DateTime.fromISO(serviceJob.dateService, { zone: timeZone })

        return (
          <Link to={`/service-jobs/${serviceJob.id}`} target="_blank" style={{ textDecoration: 'none' }}>
            <ListItem
              boxProps={{
                as: 'a',
                borderBottom: '1px solid',
                gridGap: 'large',
                padding: 'large',
              }}
              flexDirection={['column', 'row']}
              justifyContent="center"
              key={serviceJob.id}
              showAngleIcon
            >
              <Box flexDirection="row" width={['100%', '80%']}>
                <Box
                  alignItems="center"
                  color="bodyFontLightColor"
                  display={['none', 'grid']}
                  justifyContent="center"
                  marginRight="large"
                  width="auto"
                >
                  <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={23} />
                </Box>
                <Box flexDirection="column">
                  <Text fontSize="small" marginBottom="medium">
                    {serviceJob.campaign?.name}
                  </Text>
                  <Text fontSize="xsmall" marginBottom="medium" color="bodyFontLightColor">
                    {serviceJob.client?.name}
                  </Text>
                  <Box display="block" width="auto">
                    <StatusBadge boxProps={{ marginRight: 'small' }} color="#282B42" ghost text={serviceJob.service?.name} />
                  </Box>
                </Box>
              </Box>
              <Box flexDirection="column" justifyContent="center" width={['100%', '20%']} textAlign={['left', 'right']}>
                <Text fontSize="small" color="bodyFontColor">{deadline.invalid ? '' : deadline.toFormat('y-LL-dd')}</Text>
                <Text fontSize="small" color="bodyFontColor">{deadline.invalid ? '' : deadline.toFormat('hh:mm a ccc')}</Text>
                <Text fontSize="small" color="bodyFontColor">{deadline.invalid ? '' : deadline.toRelativeCalendar()} </Text>
              </Box>
            </ListItem>
          </Link>
        )
      })}
    </Box>
  )
}

BlockedServiceJobList.propTypes = {
  serviceJobs: PropTypes.array.isRequired,
}

export default BlockedServiceJobList
