import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'

import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import { LoadingBubbles } from '@campaignhub/suit-theme'

import styles from './styles.module.scss'

const search = (searchFn, string, mapResults, setState) => {
  setState({ loading: true, results: null })

  const options = {
    string,
  }

  searchFn(options).then(({ success, data }) => {
    const processedData = mapResults ? mapResults(data) : data

    if (success) {
      setState({
        results: processedData,
        loading: false,
      })
      return
    }

    setState({
      loading: false,
      results: null,
    })
  })
}

const renderResults = (results, renderResult) => {
  // { groups: [{ title: 'Campaigns', items: [{ title: '123 Street', id: 12 }] }] }
  if (results.groups && results.groups.length) {
    return results.groups.map((group) => (
      <>
        <span className={styles.label}>{group.title}</span>
        {group.items.map((item) => renderResult(item, group))}
      </>
    ))
  }

  // [{ title: '123 Street', id: 12 }]
  return (
    <>
      <span className={styles.label}>Results</span>
      {results.map((item) => renderResult(item))}
    </>
  )
}

const defaultState = {
  loading: false,
  string: '',
  results: null,
}

const Search = (props) => {
  const { callbacks: { search: searchFn }, mapResults, renderResult } = props

  const [state, setState] = useSetState(defaultState)
  const { loading, results, string } = state

  const debouncedString = useDebounce(string, 300)

  useEffect(() => {
    if (searchFn && debouncedString.length > 0) {
      search(searchFn, debouncedString, mapResults, setState)
    }

    // Clearing input
    if (!debouncedString.length) {
      setState({ results: null })
    }
  }, [debouncedString])

  return (
    <div className={styles.root}>
      <div className={styles.searchBar}>
        <FontAwesomeIcon icon={faSearch} />
        <input type="text" value={string} onChange={(e) => setState({ string: e.target.value })} placeholder="Search" />
        {loading && <LoadingBubbles color="#02b7e2" style={{ flexShrink: 0, marginLeft: 'auto', width: 40 }} />}
      </div>

      {results && (
        <ul className={styles.results}>
          {renderResults(results, renderResult)}
        </ul>
      )}
    </div>
  )
}

export default Search
