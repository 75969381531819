import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  DashboardModule,
  ListItem,
  Text,
} from '@campaignhub/suit-theme'

import IconProfile from '@components/IconProfile'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh } from '@fortawesome/pro-light-svg-icons'

const listingDetails = {
  agencyDescription: 'Agency description goes here',
  agentDetails: [
    {
      name: 'Glennis Schindler',
      contactNo: '0412 737 324',
      email: 'glennisschindler@mcgrath.com.au',
    },
    {
      name: 'Peter Motalli',
      contactNo: '0412 737 324',
      email: 'glennisschindler@mcgrath.com.au',
    },
    {
      name: 'Yannes Yung',
      contactNo: '0412 737 324',
      email: 'glennisschindler@mcgrath.com.au',
    },
  ],
}

const ListingDetails = (props) => {
  const { client, showAgents, showStyleGuidelines, hidden } = props

  if (hidden) return null

  return (
    <DashboardModule title="Listing Details">
      <Box flexDirection="column" lineHeight="1.3">
        <ListItem
          boxProps={{ padding: 'large' }}
          flexDirection="column"
          disableHover
        >
          <Box flexDirection="row">
            <Box flexDirection="column" paddingRight="medium" justifyContent="center">
              <Text fontSize="small">
                {client?.name}
              </Text>
            </Box>
            {showStyleGuidelines && (
              <Box flexShrink="0" alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
                <Button
                  size="medium"
                  buttonStyle="primaryEdit"
                  icon={<FontAwesomeIcon icon={faTh} />}
                >
                  Style Guidelines
                </Button>
              </Box>
            )}
          </Box>
        </ListItem>
        {showAgents && listingDetails.agentDetails.map(agent => (
          <ListItem
            boxProps={{
              padding: 'large',
              paddingTop: 'medium',
            }}
            flexDirection="column"
            key={agent.name} // replace with id
            disableHover
          >
            <IconProfile
              name={agent.name}
              contactNo={agent.contactNo}
              email={agent.email}
            />
          </ListItem>
        ))}
      </Box>
    </DashboardModule>
  )
}

ListingDetails.propTypes = {
  client: PropTypes.object,
  showAgents: PropTypes.bool,
  showStyleGuidelines: PropTypes.bool,
  hidden: PropTypes.bool,
}

export default ListingDetails
