import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

import styles from './styles.module.scss'

const DropdownLink = (props) => {
  const {
    href, icon, onClick, text,
  } = props

  return (
    <a
      className={styles.root}
      href={href}
      onClick={onClick}
      tabIndex="0"
    >
      <FontAwesomeIcon icon={icon} style={{ margin: '0 15px' }} />
      {text}
      <FontAwesomeIcon icon={faAngleRight} style={{ margin: '0 15px 0 auto' }} />
    </a>
  )
}

DropdownLink.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
}

export default DropdownLink
