import React from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom'

import ErrorPage from '@screens/ErrorPage'
import FullAssetScreen from '@screens/FullAssetScreen'
import ServiceJob from '@screens/ServiceJob'
import ServiceJobs from '@screens/ServiceJobs'

import Studio from '.'
import Dashboard from './packs/Dashboard'
import Reports from './packs/Reports'
import ReportList from './packs/Reports/screens/ReportList'

const StudioRoutes = () => {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<ServiceJobs />} />
      </Route>
      <Route path="/service-jobs" element={<Dashboard />}>
        <Route index element={<ServiceJobs />} />
        <Route path=":id" element={<ServiceJob userType="Studio" />} />
      </Route>
      <Route path="/reports" element={<Reports />}>
        <Route index element={<ReportList />} />
      </Route>
      <Route path="/service-jobs/:id/assets" element={<FullAssetScreen />} />
    </Route>,
  ))

  return (
    <Studio>
      <RouterProvider router={router} />
    </Studio>
  )
}

export default StudioRoutes
