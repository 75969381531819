import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'
import { Button, ModalContext, SidebarModal } from '@campaignhub/suit-theme'

import { useUserForm } from '@hooks/useUserV3'

import UserDetail from '@components/UserDetail'

const defaultState = {
  customErrors: [],
}

const confirmDelete = (props, deleteFn) => {
  const { callbacks: { deleteUser } } = props
  swal
    .fire({
      title: 'Delete User?',
      text: 'Are you sure? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteUser(deleteFn)
      }
    })
}

const update = (props, userParams, updateFn) => {
  const { callbacks: { updateUser } } = props
  updateUser(userParams, updateFn)
}

const EditUserModal = (props) => {
  const [state, setState] = useSetState(defaultState)
  const { customErrors } = state
  const { callbacks, modalKey, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: { deleteUser: deleteFn, updateUser: updateFn },
    user,
  } = modalPayload

  const { creating, updating } = useSelector(reduxState => reduxState.usersV3)

  const userFormPayload = useUserForm(user)
  const { entityState, errors } = userFormPayload

  const save = () => {
    if (Object.keys(errors).length > 0 || Object.keys(customErrors).length > 0){
      toast.warn('One or more fields did not match the validation.')
      return
    }
    update(props, entityState, updateFn)
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="large">
      <SidebarModal.Header callbacks={callbacks} title="Edit" titleSecondLine="User" />
      <SidebarModal.Content>
        <UserDetail
          userForm={userFormPayload}
          customErrors={customErrors}
          callback={{ setCustomErrors: val => setState({ customErrors: val }) }}
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          onClick={() => confirmDelete(props, deleteFn)}
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Delete User
        </Button>
        <Button
          buttonStyle="primaryCreate"
          loading={creating || updating}
          onClick={() => save()}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditUserModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditUserModal.defaultProps = {
  modalKey: 'EditUserModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditUserModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
