import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import styles from './styles.module.scss'

const TopBarButton = (props) => {
  const {
    icon, onClick, href, text,
  } = props

  return (
    <a
      className={styles.root}
      href={href}
      onClick={onClick}
    >
      {icon}
      <Box display={['none', 'block']}>
        {text && <span className={styles.text}>{text}</span>}
      </Box>
    </a>
  )
}

TopBarButton.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

export default TopBarButton
