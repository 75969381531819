import React, { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Box, StatusBadge, Text,
} from '@campaignhub/suit-theme'
import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'

import custom from '@styles/custom.module.scss'
import InputComponent from './components/InputComponent'
import TextAreaComponent from './components/TextAreaComponent'

const defaultState = {
  showFilter: false,
}

const CopyTemplate = (props) => {
  const { filteredContent } = props

  const {
    callbacks: { toggleUpdateCopyDetails },
    copyFieldDisabled,
  } = useContext(PageContext)

  const [state, setState] = useSetState(defaultState)
  const { showFilter } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { assetComments } = entities

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  const componentMapping = {
    input: InputComponent,
    textarea: TextAreaComponent,
    DefaultView: () => (
      <Text fontSize="small" color="bodyFontLightColor" marginBottom="small">
        Input type does not exist.
      </Text>
    ),
  }

  const filteredCopyComments = copyDetailId => Object.values(assetComments)?.reduce((filtered, comment) => {
    if (comment.details?.some(x => x.entityFieldType?.name === 'CopyDetailId' && JSON.parse(x.value).value === copyDetailId)){
      filtered.push(comment)
    }
    return filtered
  }, [])

  return (
    <Box flexDirection="column" className={custom.scroll}>
      <Box flexDirection="column" alignSelf="center" height="90vh" maxWidth="1000px" overflowY="auto" padding="large">
        {filteredContent.filter(x => x.copy.selected)
          .sort((a, b) => (a.copy.copyTemplate.displayName > b.copy.copyTemplate.displayName ? 1 : -1))
          .map((content) => {
          const { copy } = content

          return (
            <Box flexDirection="column" key={copy.id}>
              <Box flexDirection="row" alignItems="center" marginBottom="large">
                <Text fontSize="medium" fontWeight="500" marginRight="small">
                  {copy.copyTemplate.displayName}
                </Text>
                <StatusBadge color="green" ghost text="Selected" />
              </Box>
              {copy.copyDetails.map((detail) => {
                const Component = componentMapping[detail.copyTemplateField.bulletPoint || (detail.copyTemplateField?.length < 100 && detail.copyTemplateField?.length > 0) ? 'input' : 'textarea'] || componentMapping.DefaultView

                return (
                  <Component
                    key={detail.id}
                    assetId={content.id}
                    callbacks={{ toggleUpdateCopyDetailValue: value => toggleUpdateCopyDetails(content, detail.id, value) }}
                    disableCopyField={copyFieldDisabled}
                    copyProps={detail}
                    comments={filteredCopyComments(detail.id) || []}
                  />
                )
              })}
            </Box>
          )
        })}
        {filteredContent.filter(x => !x.copy.selected)
          .sort((a, b) => (a.copy.copyTemplate.displayName > b.copy.copyTemplate.displayName ? 1 : -1))
          .map((content) => {
          const { copy } = content

          return (
            <Box flexDirection="column" key={copy.id}>
              <Text fontSize="medium" fontWeight="500" marginBottom="large">
                {copy.copyTemplate.displayName}
              </Text>
              {copy.copyDetails.map((detail) => {
                const Component = componentMapping[detail.copyTemplateField.bulletPoint || (detail.copyTemplateField?.length < 100 && detail.copyTemplateField?.length > 0) ? 'input' : 'textarea'] || componentMapping.DefaultView

                return (
                  <Component
                    key={detail.id}
                    assetId={content.id}
                    callbacks={{ toggleUpdateCopyDetailValue: value => toggleUpdateCopyDetails(content, detail.id, value) }}
                    disableCopyField={copyFieldDisabled}
                    copyProps={detail}
                    comments={filteredCopyComments(detail.id) || []}
                  />
                )
              })}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

CopyTemplate.propTypes = {
  filteredContent: PropTypes.array,
}

export default CopyTemplate
