import { useThunkDispatch } from '@campaignhub/react-hooks'
import * as wordReplacementActions from '@redux/modules/wordReplacement'

const createWordReplacement = (wordReplacementParams, dispatch) => {
  const { createWordReplacement: createFn } = wordReplacementActions
  return dispatch(createFn(wordReplacementParams))
}

const deleteWordReplacement = (wordReplacementParams, dispatch) => {
  const { deleteWordReplacement: deleteFn } = wordReplacementActions
  return dispatch(deleteFn(wordReplacementParams))
}

const updateWordReplacement = (wordReplacementParams, dispatch) => {
  const { updateWordReplacement: updateFn } = wordReplacementActions
  return dispatch(updateFn(wordReplacementParams))
}

const useWordReplacement = () => {
  const dispatch = useThunkDispatch()

  return {
      callbacks: {
        createWordReplacement: wordReplacementParams => createWordReplacement(wordReplacementParams, dispatch),
        deleteWordReplacement: wordReplacementParams => deleteWordReplacement(wordReplacementParams, dispatch),
        updateWordReplacement: wordReplacementParams => updateWordReplacement(wordReplacementParams, dispatch),
      },
    }
}

export default useWordReplacement
