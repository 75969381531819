import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'

import {
  Box, FormField, Tag, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons'

import useServiceJobTags from '@hooks/useServiceJobTags'

const createServiceJobTag = (serviceJobTag, createFn, setState) => {
  createFn(serviceJobTag).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    setState({ tagValue: '' })
  })
}

const deleteServiceJobTag = (serviceJobTag, deleteFn) => {
  deleteFn(serviceJobTag).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })
}

const defaultState = {
  tags: [],
  tagValue: '',
}

const ServiceJobTags = (props) => {
  const { boxProps } = props

  const [state, setState] = useSetState(defaultState)
  const { tags, tagValue } = state

  const { serviceJob } = useContext(PageContext)

  const {
    callbacks: {
      createServiceJobTag: createTagFn,
      deleteServiceJobTag: deleteTagFn,
    },
    serviceJobTags,
  } = useServiceJobTags()

  useEffect(() => {
    setState({ tags: Object.values(serviceJobTags).filter(x => x.serviceJobId === serviceJob.id) || [] })
  }, [serviceJob, serviceJobTags])

  const toggleTag = (tag, type) => {
    if (type === 'add' && tags.find(x => x.tag === tag)){
      setState({ tagValue: '' })
      return
    }

    if (type === 'add'){
      createServiceJobTag({ serviceJobId: serviceJob.id, tag }, createTagFn, setState)
    } else {
      deleteServiceJobTag(tag, deleteTagFn)
    }
  }

  return (
    <Box flexDirection="column" {...boxProps}>
      <FormField direction="column" label="Tags" boxProps={{ paddingBottom: 'medium' }}>
        <Box flexDirection="column" position="relative">
          <input
            onChange={e => setState({ tagValue: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === 'Enter'){
                e.preventDefault()
                if (tagValue.trim().length){
                  toggleTag(tagValue.trim(), 'add')
                }
              }
            }}
            style={{ paddingRight: '22px' }}
            type="text"
            value={tagValue}
            placeholder="Enter to add tag"
          />
          <Box
            fontSize="xsmall"
            onClick={() => (tagValue.trim().length ? toggleTag(tagValue.trim(), 'add') : null)}
            position="absolute"
            right={6}
            top={12}
            width="auto"
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Box>
        </Box>
        <Box flexWrap="wrap" gridGap="small" marginTop={tags.length ? 'medium' : null}>
          {tags.map(x => (
            <Tag
              key={x.tag}
              boxProps={{
                width: 'fit-content',
                fontSize: 'xsmall',
                onClick: () => toggleTag(x, 'remove'),
              }}
            >
              <Text color="bodyFontLightColor" style={{ cursor: 'pointer' }}>
                {x.tag} &nbsp;<FontAwesomeIcon icon={faTimes} />
              </Text>
            </Tag>
          ))}
        </Box>
      </FormField>
    </Box>
  )
}

ServiceJobTags.propTypes = {
  boxProps: PropTypes.object,
}

export default ServiceJobTags
