import { useSelector } from "react-redux"

import useReduxAction from '@hooks/useReduxAction'

const useUserRoles = (options = {}) => {
  const entities = useSelector(reduxState => reduxState.entities)
  const { userRoles } = entities

  useReduxAction('userRoles', 'loadUserRoles', {}, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    }
  })

  return {
    userRoles
  }
}

export default useUserRoles
