import api from '@functions/api'

import { handleError } from '../utils'

const CREATE_REQUEST = 'contenthouse/emailRequest/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/emailRequest/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/emailRequest/CREATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  results: {},
}

// Actions
export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function createEmailRequest(emailRequest, options){
  const config = {
    method: 'POST',
    data: emailRequest,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v1/email-requests', options, config)
      .then(() => {
        dispatch(createSuccess())
        return { success: true, data: {} }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export default function reducer(state = initialState, action = {}){
  const { type, errors } = action

  switch (type){
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    default:
      return state
  }
}
