import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, StatusBadge, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import Icons from '@components/Icons'
import styles from '@styles/custom.module.scss'

const defaultState = {
  expanded: false,
  statusState: [],
}

const ServiceStatusFilter = (props) => {
  const { setJobState, jobState } = props
  const { statusFilter } = jobState

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const [state, setState] = useSetState(defaultState)
  const { expanded, statusState } = state

  useEffect(() => {
    setState({
      statusState: Object.values(serviceJobStatuses).map(status => ({
        ...status,
        isChecked: statusFilter.includes(status.id),
      })),
    })
  }, [serviceJobStatuses, statusFilter])

  const updateFilterState = (checked, statusId) => {
    setState({
      statusState:
        statusState.map((data) => {
          const temp = data
          if (statusId === temp.id){
            temp.isChecked = checked
            if (checked){ setJobState({ statusFilter: [...statusFilter, statusId] }) } else {
              setJobState({ statusFilter: statusFilter.filter(i => i !== temp.id) })
            }
          }
          return temp
        }),
    })
  }

  return (
    <Box flexDirection="column">
      <Box
        flexDirection="row"
        marginBottom={expanded ? 'large' : null}
        onClick={() => setState({ expanded: !expanded })}
        style={{ cursor: 'pointer' }}
      >
        <Box color="bodyFontLightColor" marginLeft="auto" marginRight="medium" width="auto">
          <Icons name="Status" />
        </Box>
        <Box flexDirection="column">
          <Text color="bodyFontLightColor" fontSize="small" fontAlign="center">
            Status
          </Text>
        </Box>
        <Box color="bodyFontLightColor" marginLeft="medium" marginRight="auto" width="auto">
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </Box>
      </Box>
      {expanded && (
        <Box flexDirection="column" alignItems="center" lineHeight="1.5">
          {statusState.map(status => (
            <Box key={status.id} flexDirection="column" alignItems="center" lineHeight="1.4" marginBottom="medium">
              <Box flexDirection="row">
                <Box color="bodyFontLightColor" marginLeft="auto" marginRight="medium" width="auto">
                  <input
                    onChange={e => updateFilterState(e.target.checked, status.id)}
                    type="checkbox"
                    checked={status.isChecked}
                    className={styles.checkbox}
                  />
                </Box>
                <Box flexDirection="column">
                  <Text color="bodyFontLightColor" fontSize="small" marginRight="medium">
                    {status.description}
                  </Text>
                </Box>
                <Box flexDirection="column" width="auto">
                  <StatusBadge color="gray" text={status.jobCount ?? 0} />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

ServiceStatusFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default ServiceStatusFilter
