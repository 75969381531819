import { useContext } from 'react'
import { useSelector } from 'react-redux'
import useReduxAction from '@hooks/useReduxAction'
import PageContext from '@contexts/pageContext'
import useWordReplacement from '@hooks/useWordReplacement'

const createOrEditWordReplacements = (wordReplacements, showWordReplacementsModal, createFn, deleteFn, updateFn) => new Promise((resolve, reject) => {
  if (showWordReplacementsModal){
    const payload = {
      callbacks: {
        createWordReplacement: wordReplacementParam => createFn(wordReplacementParam),
        deleteWordReplacement: wordReplacementParam => deleteFn(wordReplacementParam),
        updateWordReplacement: wordReplacementParam => updateFn(wordReplacementParam),
      },
      wordReplacements,
    }

    showWordReplacementsModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showWordReplacementsModal not defined in PageContext callbacks'))
})

const useWordReplacements = (options = {}) => {
  const { performHttpRequests } = options || {}
  useReduxAction('serviceCategories', 'loadServiceCategories', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { callbacks: {
    createWordReplacement: createFn,
    deleteWordReplacement: deleteFn,
    updateWordReplacement: updateFn,
  } } = useWordReplacement()

  const { callbacks } = useContext(PageContext)
  const { showWordReplacementsModal } = callbacks || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { wordReplacements } = entities

  const { updating, loading } = useSelector(reduxState => reduxState.wordReplacements)

  return {
    callbacks: {
      createOrEditWordReplacements: () => createOrEditWordReplacements(wordReplacements, showWordReplacementsModal, createFn, deleteFn, updateFn),
    },
    loading,
    updating,
    wordReplacements,
  }
}

export default useWordReplacements
