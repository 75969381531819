import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'

const watchEntityKeys = ['zonesV3']

function useZonesV3(options = {}){
  const {
    searchFilter,
    reset,
  } = options

  const {
    updatedEntities: { zonesV3: zonesV3UpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { zonesV3 } = useSelector(reduxState => reduxState.entities)

  let filteredZonesV3 = useMemo(() => {
    const filtered = Object.values(zonesV3).filter(zone => zone.name.toLowerCase().includes(searchFilter.toLowerCase()))

    return sortArrayBy(filtered, 'asc', 'name')
  }, [zonesV3UpdatedAt, options])

  filteredZonesV3 = reset ? filteredZonesV3.slice(0, 50) : filteredZonesV3

  const hasfilteredZonesV3 = !!filteredZonesV3.length

  const entityReducer = useSelector(reduxState => reduxState.zonesV3)
  const { loading } = entityReducer

  return {
    filteredZonesV3,
    zonesV3,
    hasfilteredZonesV3,
    loading,
  }
}

export default useZonesV3
