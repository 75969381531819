import LogoMark from './components/LogoMark'
import mainNavigationStyle from './styles/mainNavigation.module.scss'

const brand = {
  key: 'default',
  logoMark: {
    component: LogoMark,
    fillColor: 'white',
  },
  styles: {
    mainNavigation: mainNavigationStyle,
  },
  title: 'Default',
}

export default brand
