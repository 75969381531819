import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'

import useReduxAction from '@hooks/useReduxAction'
import useClientsV3 from '@hooks/useClientsV3'

import {
  Box, Columns, DashboardModule, ModalContext, PageHeader, LoadingModule,
} from '@campaignhub/suit-theme'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'
import EditClientModal from '@modals/EditClientModal'

import blankState from '@components/assets/blankState.svg'
import custom from '@styles/custom.module.scss'
import Client from './components/Client'
import Tools from './components/Tools'

const defaultState = {
  searchFilter: '',
  showEditClientModal: false,
  refresh: false,
  reset: false,
  defaultCount: 50,
  importDone: true,
  selectedClassification: [],
  listCount: 50,
}

const deleteClient = (deleteFn, setState) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    setState({ showEditClientModal: false })
  })
}

const updateClient = (clientParams, updateFn, setState) => {
  updateFn(clientParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditClientModal: false })
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    EditClientModal: {
      closeModal: () => setState({ showEditClientModal: false }),
      deleteClient: deleteFn => deleteClient(deleteFn, setState),
      updateClient: (clientParams, updateFn) => updateClient(clientParams, updateFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const RefreshClients = (props) => {
  const { continuationToken, setState, count } = props

  const options = {
    size: count,
    orderBy: '{ \'name\': \'asc\' }',
    ...continuationToken?.length && { continuationToken },
  }

  useReduxAction('clientsV3', 'loadClientsV3', options, [continuationToken], {
    dispatchAction: (action, requestOptions) => action(requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loading } = entityReducer

      return !loading && continuationToken !== null && continuationToken !== undefined
    },
  })

  setState({ refresh: false, reset: false })
  return null
}

const importClients = (importFn, setState) => {
  setState({ importDone: false })
  importFn().then(({ success, request, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }
    if (request.data){
      swal.fire({
        title: 'Import Client Details',
        html: `<p><b>Remaining Imports:</b> ${request.data.remainingImports}</p>`
              + `<p><b>Successful Imports:</b> ${request.data.successfulImports}</p>`
              + `<p><b>Failed Imports:</b> ${request.data.failedImports}</p>`,
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: '#e2001a',
      })
      setState({ importDone: true })
    }
  })
}

const ClientList = () => {
  const [state, setState] = useSetState(defaultState)
  const { searchFilter, showEditClientModal, defaultCount, reset, refresh,
          importDone, selectedClassification, listCount } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const updateSearchFilter = (val) => {
    setState({ searchFilter: val, reset: false })
  }

  const updateSelectedClassification = (checked, val) => {
    if (checked){
      setState({ selectedClassification: [...selectedClassification, val], reset: false })
    } else {
      setState({ selectedClassification: selectedClassification.filter(i => i !== val) })
    }
  }

  const resetFilter = () => {
    setState({ searchFilter: '', selectedClassification: [], reset: true, listCount: defaultCount })
  }

  const entityReducer = useSelector(reduxState => reduxState.clientsV3)
  const { result: { continuationToken }, loading } = entityReducer

  const { filteredClientsV3, hasfilteredClients, callbacks: { importClients: importFn } } = useClientsV3({
    searchFilter,
    reset,
    selectedClassification,
    listCount,
  })

  const searchFilterString = `"name":"%${searchFilter}%"`
  const classificationString = `"classification": ["${selectedClassification.join('" , "')}"]`

  const urlComponent = (`{${searchFilter.length > 0 ? `${searchFilterString},` : ''}
                          ${selectedClassification.length > 0 ? `${classificationString},` : ''}}`).replace(/\s+/g, '')

  const options = {
    filter: (urlComponent !== '{}') ? urlComponent : '',
    size: defaultCount,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('clientsV3', 'loadClientsV3', options, [searchFilter, selectedClassification])

  const pageContext = {
    callbacks: {
      showEditClientModal: (payload) => {
        setModalData('EditClientModal', payload)
        setState({ showEditClientModal: true })
      },
      searchClientFilter: val => updateSearchFilter(val),
      importClients: () => importClients(importFn, setState),
      toggleSelectedClassification: (checked, val) => updateSelectedClassification(checked, val),
      toggleResetFilter: () => resetFilter(),
    },
    importDone,
    selectedClassification,
    reset,
  }

  const loadMoreClient = () => {
    setState({ refresh: true, listCount: listCount + defaultCount })
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          activeTabBarItemKey="clients"
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          title="Client Management"
        />
        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns
            boxProps={{ marginTop: 'large' }}
            flexDirection={['column', 'column', 'row']}
          >
            <Columns.Main>
              <DashboardModule title="Clients">
                <Box flexDirection="column" className={custom.scroll}>
                  <Box flexDirection="column" maxHeight="75vh" overflowY="auto">
                    {hasfilteredClients && filteredClientsV3.map(client => (
                      <Client key={client.id} client={client} />
                    ))}
                    <LoadingModule loading={loading} boxProps={{ border: 0 }} />
                  </Box>
                  {hasfilteredClients && (
                    <DashboardModule.LoadMoreFooter
                      callbacks={{ loadMore: () => loadMoreClient() }}
                      canLoadMore={continuationToken !== null && continuationToken !== undefined}
                      entityCount={filteredClientsV3?.length}
                    />
                  )}
                  {!loading && !hasfilteredClients && (
                    <DashboardModule.BlankState imageUrl={blankState}>
                      <DashboardModule.BlankState.Title>No Clients Available</DashboardModule.BlankState.Title>
                      <DashboardModule.BlankState.Paragraph>
                        Try adjusting your filters.
                      </DashboardModule.BlankState.Paragraph>
                    </DashboardModule.BlankState>
                  )}
                  {refresh && (
                    <RefreshClients continuationToken={continuationToken} setState={setState} count={defaultCount} />
                  )}
                </Box>
              </DashboardModule>
            </Columns.Main>
            <Columns.Sidebar>
              <DashboardModule title="Tools">
                <Tools />
              </DashboardModule>
            </Columns.Sidebar>
          </Columns>
        </Box>
        <EditClientModal
          callbacks={callbacks('EditClientModal', setState)}
          showModal={showEditClientModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default ClientList
