import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'
import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as setAvailableActions from '@redux/modules/serviceJobPushRaw'

const postServiceJobPushRaw = (id, options, dispatch) => {
  const { postServiceJobPushRaw: createFn } = setAvailableActions

  return dispatch(createFn(id, options))
}

const useServiceJobPushRaw = () => {
    
  const entityReducer = useSelector(reduxState => reduxState.serviceJobPushRaw)
  const {
    errors,
    loaded,
    loading,
    result: serviceJobPushRaw,
  } = entityReducer

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      postServiceJobPushRaw: (serviceJobId, options) => postServiceJobPushRaw(serviceJobId, options, dispatch),
    },
    serviceJobPushRaw,
    errors,
    loaded,
    loading,
  }
}

export default useServiceJobPushRaw
