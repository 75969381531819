/* eslint-disable import/prefer-default-export */
/* eslint-disable array-callback-return */
import { redirectURI } from '@functions/api'

const api_key = process.env.REACT_APP_GOOGLE_API_KEY
const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID
const client_secret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET

export function oauth2SignIn(emailAddress){
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

  const form = document.createElement('form')
  form.setAttribute('method', 'GET')
  form.setAttribute('action', oauth2Endpoint)

  const thisparams = {
    client_id,
    redirect_uri: redirectURI,
    access_type: 'offline',
    scope: 'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.upload',
    state: emailAddress,
    include_granted_scopes: 'true',
    response_type: 'code',
    login_hint: emailAddress,
    // prompt: 'none',
  }

  Object.entries(thisparams).map((x) => {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', x[0])
    input.setAttribute('value', x[1])
    form.appendChild(input)
  })

  document.body.appendChild(form)
  form.submit()
}

export function requestTokens(authCode, callback){
  const { toggleUpdateTokenState } = callback || {}

  const oauth2Endpoint = 'https://oauth2.googleapis.com/token'
    + `?code=${authCode}`
    + `&client_id=${client_id}`
    + `&client_secret=${client_secret}`
    + `&redirect_uri=${redirectURI}`
    + '&grant_type=authorization_code'

  const xhr = new XMLHttpRequest()
  xhr.open('POST', oauth2Endpoint)
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200){
      const response = JSON.parse(xhr.response)
      toggleUpdateTokenState(response.access_token || '', response.refresh_token || '')
    } else if (xhr.readyState === 4 && xhr.status === 401){
      console.log(xhr.response)
    }
  }
  xhr.send(null)
}

export function requestChannel(accessToken, callback){
  const { toggleUpdateChannelState } = callback || {}

  const oauth2Endpoint = 'https://youtube.googleapis.com/youtube/v3/channels'
    + '?part=id&mine=true'
    + `&key=${api_key}`

  const xhr = new XMLHttpRequest()
  xhr.open('GET', oauth2Endpoint)
  xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`)
  xhr.setRequestHeader('Accept', 'application/json')
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200){
      const response = JSON.parse(xhr.response)
      if (response.items){
        toggleUpdateChannelState(response.items[0].id, response.items)
      }
    } else if (xhr.readyState === 4 && xhr.status === 401){
      console.log(xhr.response)
    }
  }
  xhr.send(null)
}

export function updateTokens(type, token, callback){
  const { toggleRefreshState, toggleUpdateAccessToken } = callback || {}

  let oauth2Endpoint = 'https://oauth2.googleapis.com/'
  if (type === 'revoke'){
    oauth2Endpoint += `revoke?token=${token}` // access_token
  }
  if (type === 'refresh'){
    oauth2Endpoint = `${oauth2Endpoint}token`
    + `?client_id=${client_id}`
    + `&client_secret=${client_secret}`
    + `&refresh_token=${token}`
    + '&grant_type=refresh_token'
  }

  const xhr = new XMLHttpRequest()
  xhr.open('POST', oauth2Endpoint)
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200){
      const response = JSON.parse(xhr.response)
      if (type === 'refresh') toggleUpdateAccessToken(response.access_token)
      else toggleRefreshState()
    } else if (xhr.readyState === 4 && xhr.status === 401){
      console.log(xhr.response)
    } else if (xhr.status === 400 && type === 'revoke'){
      toggleRefreshState()
    }
  }
  xhr.send(null)
}
