import React, { useEffect, useState } from 'react'
import { useIdleTimerContext } from 'react-idle-timer'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'
import { Notifications as NotificationsBase } from '@campaignhub/suit-theme'

import useNotification from '@hooks/useNotification'
import useNotifications from '@hooks/useNotifications'
import useReduxAction from '@hooks/useReduxAction'
import useCurrentUser from '@hooks/useCurrentUser'

import Notification from './Notification'

const readAllNotification = (readFn) => {
  readFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })
}

const defaultState = {
  unreadNotifCount: 0,
}

const Notifications = () => {
  const [state, setState] = useSetState(defaultState)
  const [seconds, setSeconds] = useState(0)
  const { unreadNotifCount } = state

  const { currentUser } = useCurrentUser()

  const idleTimer = useIdleTimerContext()
  if (idleTimer.isIdle() && seconds !== 0){
    setSeconds(0)
  }

  const options = {
    filterStart: '-30d',
    filterEnd: '1d',
    includes: 'createdBy',
    users: currentUser.id,
    orderBy: 'createdAt desc',
  }

  useReduxAction('notifications', 'loadNotifications', options, [seconds], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loading } = entityReducer
      return !loading && !errors.length && [0, 30].includes(seconds) && !idleTimer.isIdle()
    },
  })

  const entityReducer = useSelector(reduxState => reduxState.notifications)
  const { result: notifications } = entityReducer

  const {
    filteredNotifications,
    hasNotifications,
    unreadNotifications,
  } = useNotifications({ notifications })

  const { callbacks: { readAllNotification: readFn } } = useNotification()

  const entities = useSelector(reduxState => reduxState.entities)
  const { notificationTypes } = entities

  useEffect(() => {
    setSeconds(1)
    setState({ unreadNotifCount: unreadNotifications.length })
  }, [notifications])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!idleTimer.isIdle()){
        setSeconds(prevSecond => prevSecond + 1)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const performReadAllNotification = () => {
    setState({ unreadNotifCount: 0 })
    if (unreadNotifCount > 0) readAllNotification(readFn)
  }

  return (
    <NotificationsBase
      callbacks={{ performToggleNotifications: () => performReadAllNotification() }}
      unseenNotificationsCount={unreadNotifCount}
    >
      {hasNotifications && filteredNotifications.map(notification => (
        <Notification key={notification.id} notification={notification} notificationTypes={notificationTypes} />
      ))}
    </NotificationsBase>
  )
}

export default Notifications
