import React from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom'

import ErrorPage from '@screens/ErrorPage'
import FullAssetScreen from '@screens/FullAssetScreen'
import ServiceJob from '@screens/ServiceJob'

import OnSite from '.'
import Availability from './packs/ProviderDiary/screens/Availability'
import Dashboard from './packs/Dashboard'
import JobList from './packs/ServiceJobs/screens/JobList'
import Overview from './packs/Dashboard/screens/Overview'
import ProviderDiary from './packs/ProviderDiary'
import ServiceJobs from './packs/ServiceJobs'

const OnSiteRoutes = () => {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Overview />} />
      </Route>
      <Route path="/service-jobs" element={<ServiceJobs />}>
        <Route index element={<JobList />} />
        <Route path=":id" element={<ServiceJob userType="Onsite" />} />
      </Route>
      <Route path="/service-jobs/:id/assets" element={<FullAssetScreen />} />
      <Route path="/provider-diary" element={<ProviderDiary />}>
        <Route index element={<Availability />} />
        <Route path="availability" element={<Availability />} />
      </Route>
    </Route>,
  ))

  return (
    <OnSite>
      <RouterProvider router={router} />
    </OnSite>
  )
}

export default OnSiteRoutes
