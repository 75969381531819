/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable import/prefer-default-export */
export function filterToDate(filterDate, filterType, userTimeZone){
  const today = new Date()
  const baseDate = new Date(today)

  const todayTimezone = today.toLocaleString('en-US', { timeZone: userTimeZone })
  const dateTime = todayTimezone.split(',')
  const date = dateTime[0].split('/')

  const dateFilters = filterDate.split(' ')

  dateFilters.map((dateFilter) => {
    const offset = parseInt(dateFilter.slice(0, -1))
    const type = dateFilter.slice(dateFilter.length - 1)

    if (type === 'd'){ baseDate.setDate(parseInt(date[1], 10) + (filterType === 'start' ? offset : offset - 1)) }

    if (type === 'm'){
      baseDate.setMonth(today.getMonth() + (filterType === 'start' ? offset : offset - 1))

      const lastDate = new Date(baseDate.getYear(), baseDate.getMonth() + 1, 0)
      baseDate.setDate(filterType === 'start' ? 1 : lastDate.getDate())
    }
  })

  return baseDate
}
