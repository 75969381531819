import React from 'react'
import { Outlet } from 'react-router-dom'

import { MainContent, TopBar } from '@campaignhub/suit-theme'

import useMainNavigation from '@hooks/useMainNavigation'

import LoggedInUser from '@suiteThemeCustom/TopBar/components/LoggedInUser'
import MainNavigation from '@sections/Default/components/MainNavigation'

const Dashboard = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <Outlet />
      </MainContent>
    </>
  )
}

export default Dashboard
