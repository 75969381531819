import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'

const watchEntityKeys = ['clientYoutubeChannels']

const useClientYoutubeChannels = (options = {}) => {
  const {
    youtubeChannelId,
  } = options

  const {
    updatedEntities: { clientYoutubeChannels: clientYoutubeChannelsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { clientYoutubeChannels } = useSelector(reduxState => reduxState.entities)

  const filteredClientYoutubeChannels = useMemo(() => {
    const filtered = Object.values(clientYoutubeChannels).filter(channel => (
      channel.youtubeChannelId === youtubeChannelId
    ))

    return sortArrayBy(filtered, 'asc', 'name')
  }, [clientYoutubeChannelsUpdatedAt, options])

  const hasfilteredClientYoutubeChannels = !!filteredClientYoutubeChannels.length

  const entityReducer = useSelector(reduxState => reduxState.clientYoutubeChannels)
  const { loading } = entityReducer

  return {
    filteredClientYoutubeChannels,
    hasfilteredClientYoutubeChannels,
    loading,
    clientYoutubeChannels,
  }
}

export default useClientYoutubeChannels
