import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'

import AssetComment from '../AssetComment'

const defaultState = {
  showComments: false,
}

const AssetCommentGroup = (props) => {
  const { comments, groupName } = props

  const [state, setState] = useSetState(defaultState)
  const { showComments } = state

  useEffect(() => {
    if (groupName === 'Unresolved') setState({ showComments: true })
  }, [])

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="column"
      disableHover
    >
      <Box
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        onClick={() => setState({ showComments: !showComments })}
        style={{ cursor: 'pointer' }}
      >
        <Text fontSize="small" fontWeight="500">{groupName}</Text>
        <Box color="bodyFontLightColor" width="auto">
          <FontAwesomeIcon icon={showComments ? faChevronUp : faChevronDown} size="sm" />
        </Box>
      </Box>
      {showComments && (
      <>
        {comments?.map(comment => (
          <AssetComment key={comment.id} comment={comment} />
        ))}
        {!comments?.length && (
          <Text fontSize="xsmall" color="bodyFontLightColor">No {groupName.toLowerCase()} comments</Text>
        )}
      </>
      )}
    </ListItem>
  )
}

AssetCommentGroup.propTypes = {
  comments: PropTypes.array,
  groupName: PropTypes.string,
}

export default AssetCommentGroup
