const userTypes = [
  { id: 1, name: 'Default', description: 'Default' },
  { id: 2, name: 'Integration', description: 'Integration' },
  { id: 3, name: 'BackOffice', description: 'Back Office' },
  { id: 4, name: 'OnSite', description: 'On Site' },
  { id: 5, name: 'Studio', description: 'Studio' },
  { id: 6, name: 'Client', description: 'Client' },
]

export default userTypes
