import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useUsers = (userOptions = {}) => {
  const { userTypes } = userOptions || {}
  const entities = useSelector(reduxState => reduxState.entities)
  const { users } = entities

  const includes = ['userRole', 'userServices', 'userIdentity']
  const options = {
    includes: includes.join(','),
    ...userTypes && { userTypes: userTypes.join(',') },
  }

  useReduxAction('users', 'loadUsers', options, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    },
  })

  const entityReducer = useSelector(reduxState => reduxState.users)
  const { loading } = entityReducer

  return {
    users,
    loading,
  }
}

export default useUsers
