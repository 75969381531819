import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowFromRight, faCompress, faExpand } from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'
import { Button, PageHeader } from '@campaignhub/suit-theme'

const Header = () => {
  const {
    callbacks: { toggleFullScreen },
    fullscreen,
    isMobileDevice,
  } = useContext(PageContext)

  const navigate = useNavigate()

  return (
    <PageHeader
      boxProps={{ justifyContent: 'flex-start' }}
      offset={{ left: 0, top: 0 }}
      width={!isMobileDevice && fullscreen ? '100%' : 'calc(100% - 375px)'}
      title="Live Capacity Dashboard"
      actionContent={(
        <>
          <Button
            buttonStyle={fullscreen ? 'primaryEdit' : 'ghostEdit'}
            icon={<FontAwesomeIcon icon={fullscreen ? faCompress : faExpand} />}
            marginLeft="medium"
            onClick={() => toggleFullScreen()}
            size="medium"
            title={fullscreen ? 'Compress' : 'Expand'}
            width="auto"
          />
          <Button
            buttonStyle="primaryCreate"
            icon={<FontAwesomeIcon icon={faArrowFromRight} />}
            marginLeft="medium"
            onClick={() => navigate('/')}
            size="medium"
            title="Go Back"
            width="auto"
          />
        </>
      )}
    />
  )
}

export default Header
