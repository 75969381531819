import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, DashboardModule,
} from '@campaignhub/suit-theme'

const ProductionNotes = (props) => {
  const { clientForm } = props

  const {
    entityState: {
      production_note,
    },
    handlers,
  } = clientForm

return (
  <DashboardModule title="Production Notes" boxProps={{ marginBottom: 'large' }}>
    <Box padding="large">
      <Box marginBottom="large" width={['100%', 'calc(50% - 4px)']}>
        <FormField
          boxProps={{ flex: 1 }}
          label="Production Notes"
        >
          <textarea
            name="production_note"
            value={production_note}
            style={{ height: 80, resize: 'none', width: '100%' }}
            {...handlers}
          />
        </FormField>
      </Box>
    </Box>
  </DashboardModule>
  )
}

ProductionNotes.propTypes = {
  clientForm: PropTypes.object,
}

export default ProductionNotes
