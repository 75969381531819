const state = {
  services: [],
  services_selected: [],
}

export const customSpecialistRequiredFields = [
  { key: 'specialist.services' },
]

export default state
