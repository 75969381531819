import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import months from '@functions/months'

const defaultState = {
  endDay: 0,
  endMonth: 0,
  startDay: 0,
  startMonth: 0,
  time: '',
}

const DuskTime = (props) => {
  const { callbacks: { removeDuskTime }, duskTime } = props

  const timeUtc = DateTime.fromISO(duskTime.time, { zone: 'utc' })

  const [state, setState] = useSetState(defaultState)
  const { endDay, endMonth, startDay, startMonth, time } = state

  useEffect(() => {
    setState({
      endDay: duskTime.day_end,
      endMonth: duskTime.month_end,
      startDay: duskTime.day_start,
      startMonth: duskTime.month_start,
      time: timeUtc.toFormat('HH:mm'),
    })
  }, [duskTime])

  useEffect(() => {
      duskTime.month_start = startMonth
      duskTime.day_start = startDay
      duskTime.month_end = endMonth
      duskTime.day_end = endDay
      duskTime.time = DateTime.fromISO(time, { zone: 'utc' }).toFormat('HH:mm:ss')
  }, [endDay, endMonth, startDay, startMonth, time])

  return (
    <ListItem
      boxProps={{
          borderBottom: '1px solid',
          padding: 'large',
        }}
      flexDirection="column"
    >
      <Box flexDirection={['column', 'row']} marginBottom={[0, 'medium']}>
        <FormField boxProps={{ flex: 2, marginRight: [0, 'medium'] }}>
          <select
            onChange={(e) => { setState({ startMonth: parseInt(e.target.value, 10) }) }}
            value={startMonth}
          >
            <option value={0} disabled selected hidden>Please Select...</option>
            {months.map(month => (
              <option value={months.indexOf(month) + 1}>
                {month}
              </option>
              ))}
          </select>
        </FormField>
        <FormField boxProps={{ flex: 1, marginRight: [0, 'medium'] }}>
          <input
            max={31}
            min={1}
            onChange={(e) => { setState({ startDay: parseInt(e.target.value, 10) }) }}
            type="number"
            value={startDay}
          />
        </FormField>
        <Text fontSize="small" marginRight={[0, 'medium']} padding="medium">to</Text>
        <FormField boxProps={{ flex: 2, marginRight: [0, 'medium'] }}>
          <select
            onChange={(e) => { setState({ endMonth: parseInt(e.target.value, 10) }) }}
            value={endMonth}
          >
            <option value={0} disabled selected hidden>Please Select...</option>
            {months.map(month => (
              <option value={months.indexOf(month) + 1}>
                {month}
              </option>
              ))}
          </select>
        </FormField>
        <FormField boxProps={{ flex: 1, marginRight: [0, 'medium'] }}>
          <input
            max={31}
            min={1}
            onChange={(e) => { setState({ endDay: parseInt(e.target.value, 10) }) }}
            type="number"
            value={endDay}
          />
        </FormField>
        <Text fontSize="small" marginRight={[0, 'medium']} padding="medium">:</Text>
        <FormField boxProps={{ flex: 2 }}>
          <input
            onChange={e => setState({ time: e.target.value })}
            type="time"
            value={time}
          />
        </FormField>
        <Box marginLeft="auto" width="auto">
          <Link
            greyLink
            onClick={() => removeDuskTime()}
            textProps={{ marginLeft: 'medium', padding: 'medium' }}
            width="fit-content"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        </Box>
      </Box>
    </ListItem>
  )
}

DuskTime.propTypes = {
  callbacks: PropTypes.shape({
    removeDuskTime: PropTypes.func,
  }),
  duskTime: PropTypes.shape({
    day_end: PropTypes.number,
    day_start: PropTypes.number,
    month_start: PropTypes.number,
    month_end: PropTypes.number,
    time: PropTypes.number,
  }),
}

export default DuskTime
