import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, ListItem,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useServiceJobUser from '@hooks/useServiceJobUser'
import useServiceJobUsers from '@hooks/useServiceJobUsers'
import useCurrentUser from '@hooks/useCurrentUser'

import AssignedUser from './components/AssignedUser'

const AssignedUsers = (props) => {
  const { hidden } = props

  const { serviceJob } = useContext(PageContext)
  const { filteredServiceJobUsers } = useServiceJobUsers(serviceJob.id)

  const { currentUser } = useCurrentUser()

  const initServiceJobUser = { id: null, serviceJobId: serviceJob.id }
  const serviceJobUserPayload = useServiceJobUser(initServiceJobUser)
  const {
    callbacks: {
      launchChangeServiceJobUserModal,
    },
  } = serviceJobUserPayload

  if (hidden) return null

  return (
    <DashboardModule title="Assigned Users">
      <Box flexDirection="column">
        {filteredServiceJobUsers.map(serviceJobUser => (
          <AssignedUser
            key={serviceJobUser.id}
            serviceJobUser={serviceJobUser}
            showDetails={serviceJobUser.user.id === currentUser.id}
            serviceJob={serviceJob}
          />
        ))}
        <ListItem
          boxProps={{
            borderBottom: '1px solid',
            padding: 'large',
          }}
          flexDirection="column"
          disableHover
        >
          <Button buttonStyle="secondary" size="medium" onClick={launchChangeServiceJobUserModal}>
            Assign User
          </Button>
        </ListItem>
      </Box>
    </DashboardModule>
  )
}

AssignedUsers.propTypes = {
  hidden: PropTypes.bool,
}

export default AssignedUsers
