import slotState from '@models/slot'
import { isPositiveInteger } from '@functions/validations'

const state = {
  address: {
    address: '',
    postcode: '',
    state: '',
    suburb: '',
  },
  bookable_services: [],
  contact_number: '',
  flags: [],
  minimum_slots: '',
  primary_category: '',
  secondary_categories: [],
  services: [],
  services_selected: [],
  slots: slotState,
  virtual_contact_number: '',
  zones: [],
}

export const customProviderRequiredFields = [
  { key: 'provider.services' },
  { key: 'provider.minimum_slots', validation: isPositiveInteger, invalidMessage: 'Positive integer only', allowNullOrEmpty: false },
]

export default state
