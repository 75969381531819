import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box, Button, Heading, Image,
} from '@campaignhub/suit-theme'

import LogoMarkUrl from '@components/assets/logo-mark.svg'

const ErrorPage = () => {
  const navigate = useNavigate()

  return (
    <Box
      alignItems="center"
      backgroundColor="whiteGrey"
      height="100vh"
      justifyContent="center"
      padding={['large', 'xxlarge']}
      width="100%"
    >
      <Box alignItems="center" flexDirection="column" justifyContent="center" width={['100%', '400px']}>
        <Image backgroundSize="contain" url={LogoMarkUrl} width={50} height={50} />
        <Heading textProps={{ color: 'bodyFontLightColor', marginTop: 'xlarge' }}>
          Page Not Found
        </Heading>
        <Box alignItems="center" fontSize="xsmall" justifyContent="center" marginTop="xlarge">
          <Button
            buttonStyle="primaryUtility"
            size="medium"
            margin="medium"
            onClick={() => navigate('/')}
          >
            Back to Dashboard
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorPage
