import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { Box, MainContent, ModalContext } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'
import weeks from '@functions/weeks'
import useCurrentDate from '@hooks/useCurrentDate'
import useFreshDesk from '@hooks/useFreshDesk'
import useReduxAction from '@hooks/useReduxAction'

import CapacityModal from '@modals/CapacityModal'
import CapacitySettingsModal from '@modals/CapacitySettingsModal'
import Header from './components/Header'
import MainPanel from './components/MainPanel'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CapacityModal: {
      closeModal: () => setState({ showCapacityModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const Refresh = (props) => {
  const { endOffset, startOffset } = props

  useReduxAction('capacities', 'loadCapacities', {
    filterStart: `${startOffset}d`,
    filterEnd: `${endOffset}d`,
  }, [endOffset, startOffset])

  return null
}

const defaultState = {
  calendarDates: [],
  endOffset: null,
  fullscreen: true,
  isMobileDevice: false,
  refresh: false,
  selectedCategories: [],
  selectedWeek: { id: 0, name: 'This Week' },
  selectedZones: [],
  showCapacityModal: false,
  showCapacitySettingsModal: false,
  startOffset: null,
}

const LiveCapacity = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    calendarDates, endOffset, fullscreen, isMobileDevice, refresh, selectedWeek, selectedCategories, selectedZones, showCapacityModal, showCapacitySettingsModal, startOffset,
  } = state

  const { serviceCategories, zones } = useSelector(reduxState => reduxState.entities)

  const { defaultOffset, firstCalendarDay } = useCurrentDate()

  const getCalendarDates = useCallback(({ currentPage = 0 }) => {
    const days = 5
    const newCalendarDates = []

    let day = firstCalendarDay().plus({ days: currentPage * 7 })
    const start = defaultOffset + (currentPage * 7)
    const end = start + 7
    while (newCalendarDates.length < days) {
      if (day.weekday < 6) newCalendarDates.push(day)
      day = day.plus({ days: 1 })
    }

    setState({
      calendarDates: newCalendarDates,
      endOffset: end,
      refresh: true,
      startOffset: start,
    })
  }, [])

  useEffect(() => {
    getCalendarDates({})
  }, [])

  const updateSelectedCategories = (checked, id) => {
    if (checked) {
      setState({ selectedCategories: [...selectedCategories, Object.values(serviceCategories).find(x => x.id === id)] })
    } else {
      setState({ selectedCategories: selectedCategories.filter(x => x.id !== id) })
    }
  }

  const updateSelectedWeek = (currentPage) => {
    getCalendarDates({ currentPage })

    const selected = weeks.find(x => x.id === parseInt(currentPage, 10))
    setState({ selectedWeek: selected })
  }

  const updateSelectedZones = (checked, id) => {
    if (checked) {
      setState({ selectedZones: [...selectedZones, Object.values(zones).find(x => x.id === id)] })
    } else {
      setState({ selectedZones: selectedZones.filter(x => x.id !== id) })
    }
  }

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    calendarDates,
    callbacks: {
      showCapacityModal: (payload) => {
        setModalData('CapacityModal', payload)
        setState({ showCapacityModal: true })
      },
      showCapacitySettingsModal: (payload) => {
        setModalData('CapacitySettingsModal', payload)
        setState({ showCapacitySettingsModal: true })
      },
      toggleFullScreen: () => setState({ fullscreen: !fullscreen, showCapacitySettingsModal: !showCapacitySettingsModal }),
      toggleUpdateSelectedCategories: (checked, val) => updateSelectedCategories(checked, val),
      toggleUpdateSelectedWeek: currentPage => updateSelectedWeek(currentPage),
      toggleUpdateSelectedZones: (checked, val) => updateSelectedZones(checked, val),
    },
    categories: selectedCategories.length > 0 ? selectedCategories : Object.values(serviceCategories),
    fullscreen,
    isMobileDevice,
    selectedCategories,
    selectedWeek,
    selectedZones,
    weeks,
    zones: selectedZones.length > 0 ? selectedZones : Object.values(zones),
  }

  useFreshDesk()

  useEffect(() => {
    setState({ fullscreen: !fullscreen, showCapacitySettingsModal: !showCapacitySettingsModal })
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <MainContent
          offset={{ left: 0, top: 71 }}
          width={!isMobileDevice && fullscreen ? '100%' : 'calc(100% - 375px)'}
          style={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}
        >
          <Header />
          <Box padding="large">
            <MainPanel />
          </Box>
        </MainContent>
        {refresh && (
          <Refresh endOffset={endOffset} startOffset={startOffset} />
        )}
        <CapacityModal
          callbacks={callbacks('CapacityModal', setState)}
          showModal={showCapacityModal}
        />
        <CapacitySettingsModal
          callbacks={callbacks('CapacitySettingsModal', setState, isMobileDevice)}
          disableAnimation
          disableOverlay
          showModal={showCapacitySettingsModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default LiveCapacity
