import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/serviceJobFilter/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/serviceJobFilter/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/serviceJobFilter/FETCH_FAILURE'

const UPDATE_REQUEST = 'contenthouse/serviceJobFilter/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/serviceJobFilter/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/serviceJobFilter/UPDATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  headers: {},
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function loadServiceJobCounts(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v1/service-jobs/counts', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.serviceGroups, Schemas.SERVICE_GROUP_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        const normalizedJson2 = normalize(data.serviceJobStatuses, Schemas.SERVICE_JOB_STATUS_ARRAY)
        dispatch(updateEntities(normalizedJson2))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export default function reducer(state = initialState, action = {}){
  const { type, errors, loadedForKeys, loadedIds } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: loadedForKeys || state.loadedForKeys,
        loadedIds: loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    default:
      return state
  }
}
