import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'
import * as assetActions from '@redux/modules/asset'

const createAsset = (assetParam, options, dispatch) => {
  const { createAsset: createFn } = assetActions
  return dispatch(createFn(assetParam, options))
}

const createAssetDirect = (assetParam, dispatch) => {
  const { createAssetDirect: createDirectFn } = assetActions
  return dispatch(createDirectFn(assetParam))
}

const deleteAsset = (assetParam, dispatch) => {
  const { deleteAsset: deleteFn } = assetActions

  return dispatch(deleteFn(assetParam))
}

const updateAsset = (assetParam, options, dispatch) => {
  const { updateAsset: updateFn } = assetActions
  return dispatch(updateFn(assetParam, options))
}

const launchAssetUploadModal = (dispatch, showAssetUploadModal) => new Promise((resolve, reject) => {
  if (showAssetUploadModal){
    const payload = {
      callbacks: {
      },
    }

    showAssetUploadModal(payload)
    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showAssetUploadModal not defined in PageContext callbacks'))
})

const useUploadAsset = () => {
  const { callbacks } = useContext(PageContext)
  const { showAssetUploadModal } = callbacks || {}
  const dispatch = useThunkDispatch()

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.assets)

  return {
    callbacks: {
      createAsset: (assetParam, options) => createAsset(assetParam, options, dispatch),
      createAssetDirect: assetParam => createAssetDirect(assetParam, dispatch),
      deleteAsset: assetParam => deleteAsset(assetParam, dispatch),
      launchAssetUploadModal: () => launchAssetUploadModal(dispatch, showAssetUploadModal),
      updateAsset: (assetParam, options) => updateAsset(assetParam, options, dispatch),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useUploadAsset
