const serviceJobNoteTypes = {
  studio: {
     id: 1,
     name: 'Studio',
     description: 'Studio',
  },
  provider: {
    id: 2,
    name: 'Provider',
    description: 'Provider',
  },
  totalAssetNote: {
    id: 3,
    name: 'TotalAssetNote',
    description: 'TotalAssetNote',
  },
}

export default serviceJobNoteTypes
