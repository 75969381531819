import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/pro-light-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'
import Icons from '@components/Icons'

const Result = (props) => {
  const { icon, result: { id, customIcon, faIcon, href, priority, subtitle, title } } = props

  return (
    <li key={id}>
      <Link to={href} style={{ textDecoration: 'none' }}>
        <Box
          alignItems="center"
          color="blue"
          fontSize="medium"
          justifyContent="center"
          marginRight="large"
          width={20}
        >
          {customIcon ? <Icons name={customIcon} size={20} />
          : <FontAwesomeIcon icon={faIcon || icon || faHome} />}
        </Box>

        <Box
          alignItems="flexStart"
          flexDirection="column"
          justifyContent="center"
          opacity={priority === 0 ? '0.5' : '1'}
        >
          <Text color="bodyFontColor" fontSize="small">
            {title}
          </Text>
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {subtitle}
          </Text>
        </Box>
      </Link>
    </li>
  )
}

Result.propTypes = {
  icon: PropTypes.object,
  result: PropTypes.shape({
    customIcon: PropTypes.string,
    faIcon: PropTypes.string,
    href: PropTypes.string,
    id: PropTypes.number.isRequired,
    priority: PropTypes.number,
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default Result
