import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import PageContext from '@contexts/pageContext'
import { useSetState } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import '@styles/swal-custom.css'

import BlockedServiceJobList from '@components/BlockedServiceJobList'

import useBlockout, { useBlockoutForm } from '@hooks/useBlockout'
import useReduxAction from '@hooks/useReduxAction'

const createOrUpdateBlockout = (blockout, props, createFn, updateFn) => {
  const updatedBlockout = blockout
  updatedBlockout.reason = updatedBlockout.reason.trim() === '' ? '-' : updatedBlockout.reason
  const {
    callbacks: { createCurrentBlockout, updateCurrentBlockout },
  } = props

  if (blockout.id){
    return updateCurrentBlockout(updatedBlockout, updateFn)
  }
  return createCurrentBlockout(updatedBlockout, createFn)
}

const defaultState = {
  finishDate: '',
  finishTime: '',
  startDate: '',
  startTime: '',
  refreshBlockedServiceJobList: false,
}

const RefreshBlockedServiceJobs = (props) => {
  const { filterStart, filterEnd } = props
  useReduxAction('blockedServiceJobs', 'loadServiceJobsServiceDate', new URLSearchParams([
    ['filterStart', filterStart],
    ['filterEnd', filterEnd],
  ]), [filterStart, filterEnd])

  return null
}

const CreateOrEditCurrentBlockoutModal = (props) => {
  const {
    callbacks, modalKey, showModal,
  } = props

  const { closeModal, deleteCurrentBlockout, validateCurrentBlockout } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { blockoutSaveEnabled } = useContext(PageContext)

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const {
    blockout,
  } = modalPayload

  const blockoutFormPayload = useBlockoutForm(blockout)
  const {
    entityState,
    entityState: {
      id,
      start,
      finish,
      reason,
    },
    errors,
    handlers,
  } = blockoutFormPayload

  const {
    callbacks: {
      createCurrentBlockout: createFn,
      deleteCurrentBlockout: deleteFn,
      updateCurrentBlockout: updateFn,
      validateCurrentBlockout: validateFn,
    },
    creating,
    deleting,
    updating,
  } = useBlockout(blockout)

  const startUTC = DateTime.fromISO(start, { zone: 'utc' })
  const finishUTC = DateTime.fromISO(finish, { zone: 'utc' })

  const [state, setState] = useSetState(defaultState)
  const { finishDate, finishTime, startDate, startTime, refreshBlockedServiceJobList } = state

  // Only id to avaoid infinite loop
  useEffect(() => {
    setState({
      finishDate: finishUTC.startOf('day').toFormat('y-MM-dd'),
      finishTime: finishUTC.toFormat('HH:mm'),
      startDate: startUTC.startOf('day').toFormat('y-MM-dd'),
      startTime: startUTC.toFormat('HH:mm'),
    })
  }, [id])

  useEffect(() => {
    entityState.finish = DateTime.fromISO(`${finishDate}T${finishTime}`, { zone: 'utc' }).toISO()
    entityState.start = DateTime.fromISO(`${startDate}T${startTime}`, { zone: 'utc' }).toISO()
    if (entityState.start < entityState.finish && entityState.start !== null && entityState.finish !== null){
      setState({ refreshBlockedServiceJobList: true })
      validateCurrentBlockout(entityState, validateFn)
    } else {
      setState({ refreshBlockedServiceJobList: false })
    }
  }, [finishDate, finishTime, startDate, startTime])

  function deleteBlocked(){
    swal.fire({
      title: 'Delete Blockout',
      html: 'This action will delete this blockout.'
      + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
    }).then(({ value }) => {
      if (value){
        deleteCurrentBlockout(deleteFn)
      }
    })
  }

  function createOrUpdateBlockoutConfirm(){
    swal.fire({
      title: 'Blockout Reminder',
      html: 'Blockouts do not cancel or nullify existing appointments.'
      + '<br /> If a job has already been assigned to you prior to adding the blockout, it will remain on your job list.'
      + '<br /> If you need it removed, please contact the Operations Team.',
      icon: 'warning',
      confirmButtonText: 'Okay',
      confirmButtonColor: '#e2001a',
      customClass: {
        content: 'swal-content-blockout',
      },
    }).then(({ value }) => {
      if (value){
        createOrUpdateBlockout(entityState, props, createFn, updateFn)
      }
    })
  }
  const {
    loading, results: filteredServiceJobs,
  } = useSelector(reduxState => reduxState.blockedServiceJobs)
  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="large">
      <SidebarModal.Header
        callbacks={callbacks}
        title={id ? 'Edit' : 'Add'}
        titleSecondLine="Blockout"
      />
      <SidebarModal.Content>
        <Box flexDirection="column" marginBottom="large">
          <FormField label="Start Date &#38; Time" errorMessage={errors.start}>
            <Box flexDirection="row">
              <input
                max={finishDate}
                onChange={e => setState({ startDate: e.target.value })}
                type="date"
                value={startDate}
              />
              <input
                max={finishTime}
                onChange={e => setState({ startTime: startDate === finishDate && e.target.value > finishTime ? finishTime : e.target.value })}
                type="time"
                value={startTime}
              />
            </Box>
          </FormField>
        </Box>
        <Box flexDirection="column" marginBottom="large">
          <FormField label="End Date &#38; Time" errorMessage={errors.finish}>
            <Box flexDirection="row">
              <input
                min={startDate}
                onChange={e => setState({ finishDate: e.target.value })}
                type="date"
                value={finishDate}
              />
              <input
                min={startTime}
                onChange={e => setState({ finishTime: startDate === finishDate && e.target.value < startTime ? startTime : e.target.value })}
                type="time"
                value={finishTime}
              />
            </Box>
          </FormField>
        </Box>
        <Box flexDirection="column" marginBottom="large">
          <FormField label="Reason">
            <textarea
              name="reason"
              type="text"
              style={{ height: 90, resize: 'vertical' }}
              value={reason}
              maxLength="300"
              {...handlers}
            />
          </FormField>
        </Box>
        {!loading && filteredServiceJobs.length > 0 && refreshBlockedServiceJobList && (
          <Box flexDirection="column" marginBottom="large">
            <SidebarModal.ExpandableSectionBox
              label="You cannot apply blockout on this period because of the job/s below. Please contact the Operations Team for assistance."
              defaultOpen
            >
              <BlockedServiceJobList serviceJobs={filteredServiceJobs} />
            </SidebarModal.ExpandableSectionBox>
          </Box>
        )}
        {refreshBlockedServiceJobList && (
          <RefreshBlockedServiceJobs
            filterStart={entityState.start}
            filterEnd={entityState.finish}
          />
        )}

        {id && (
          <FormField direction="column" label="More Options">
            <Button
              buttonStyle="secondaryUtility"
              loading={deleting}
              onClick={() => deleteBlocked()}
              size="medium"
              width="100%"
            >
              Delete Blockout
            </Button>
          </FormField>
        )}
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          disabled={!blockoutSaveEnabled || filteredServiceJobs.length > 0}
          loading={creating || updating}
          size="medium"
          onClick={() => createOrUpdateBlockoutConfirm()}
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          {id ? 'Update' : 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>

  )
}

CreateOrEditCurrentBlockoutModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditCurrentBlockoutModal.defaultProps = {
  modalKey: 'CreateOrEditCurrentBlockoutModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditCurrentBlockoutModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
