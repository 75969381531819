/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect, useMemo } from 'react'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import { storageAccountName } from '@functions/api'

const initializeUppy = (options) => {
  const {
    allowedFileTypes,
    callbacks,
    currentUserId,
    fileServiceContainer,
    maxFileSize,
    requestOptions = {},
    type,
    validateFileCount,
  } = options

  let errorList = []
  let selectedFiles = []
  const uploadedList = []

  const { sasToken, container, id: fileServiceContainerId } = fileServiceContainer || {}
  const { serviceJobId } = requestOptions

  const { addRecentlyUploadedBatch, createDirectFn, doneUpload, setFilesToUploadCount } = callbacks

  const createDirect = async (entityParams, newList) => {
    await createDirectFn(entityParams).then(({ success, errors, data }) => {
      if (!success && errors){
        toast.warn(errors[0])
      }

      newList.push(data)
    })
  }

  const onComplete = async () => {
    let counter = uploadedList.length
    const newList = []

    function updateList(){
      counter -= 1
      uploadedList.splice(0, uploadedList.length)
      if (counter === 0){
        addRecentlyUploadedBatch(newList, errorList)
        doneUpload(true)
      }
    }

    async function addToNewList(file, callback){
      await createDirect(file.meta, newList)
      callback()
    }

    uploadedList.forEach(async (file) => {
      addToNewList(file, updateList)
    })

    if (!uploadedList.length && errorList.length){
      addRecentlyUploadedBatch(newList, errorList)
      doneUpload(true)
    }
  }

  const validateItemCounts = async (files, uppy) => {
    if (Object.keys(files).length === parseInt(validateFileCount, 10)){
      uppy.upload()
    } else {
      swal.fire({
        title: 'Number of Resources',
        text: `Selected images do not match the number of images for upload. Would you like to proceed with ${Object.keys(files).length}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#e2001a',
      }).then(({ value }) => {
        if (value){
          uppy.upload().then(() => {
            setFilesToUploadCount(Object.keys(files).length)
          })
        } else {
          errorList = []
          uppy.reset()
        }
      })
    }
    selectedFiles = []
  }

  const uppy = new Uppy({
    id: 'uppyUpload',
    debug: false,
    autoProceed: false,
    restrictions: {
      allowedFileTypes,
      maxFileSize,
    },
    allowMultipleUploadBatches: !parseInt(validateFileCount, 10),
    onBeforeFileAdded: (currentFile) => {
      selectedFiles.push(currentFile)
    },
  })
    .use(XHRUpload, {
      method: 'PUT',
      formData: false,
      fieldName: 'FormFile',
      getResponseError(){ },
      timeout: 0,
    })
    .on('restriction-failed', (file, error) => {
      if (error.message === 'Cannot add more files'){
        toast.warning(error.message)
      } else {
        errorList.push({ ...file, errorType: 'restricted', errorMessage: error.message })
      }
    })
    .on('files-added', async (files) => {
      const filePath = type.toUpperCase() === 'ASSETS' ? `${serviceJobId}/assets/${Date.now()}` : `${serviceJobId}/resources`
      Object.values(files).map((file) => {
        const fileName = file.meta.name
        const blobUrl = encodeURI(`https://${storageAccountName}.blob.core.windows.net/${container}/${filePath}/${fileName}`)

        let tags = `userId=${currentUserId}&containerId=${fileServiceContainerId}`
        tags = type.toUpperCase() === 'ASSETS' ? tags : `${tags}&resourceTypeId=${requestOptions.resourceTypeId}`

        uppy.setFileMeta(file.id, {
          ...requestOptions,
          fileServiceContainerId,
          filePath,
          fileName,
          blobUrl,
        })

        uppy.setFileState(file.id, {
          xhrUpload: {
            ...file.xhrUpload,
            endpoint: `${blobUrl}?${sasToken}`,
            headers: {
              'x-ms-blob-type': 'BlockBlob',
              'x-ms-blob-content-type': file.meta.type,
              'x-ms-tags': tags,
            },
          },
        })
      })

      if (validateFileCount){
        await validateItemCounts(selectedFiles, uppy)
      } else {
        uppy.upload()
      }
    })
    .on('upload-success', (file) => {
      uploadedList.push(file)
    })
    .on('upload-error', (file) => {
      errorList.push({ ...file, errorType: 'failed', errorMessage: 'Failed to Upload' })
    })
    .on('complete', async () => {
      await onComplete()
      uppy.reset()
    })

  return uppy
}

const checkRequiredOptions = (options = {}) => {
  const requiredOptions = ['allowedFileTypes', 'requestOptions', 'fileServiceContainer']

  return requiredOptions.every(key => Object.keys(options).includes(key))
}

function useUppyUpload(options = {}){
  const uppy = useMemo(() => {
    if (checkRequiredOptions(options)){
      return initializeUppy(options)
    }
  })

  useEffect(() => () => (uppy ? uppy.close() : null))

  return uppy
}

export default useUppyUpload
