import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'

const watchEntityKeys = ['clients']

function useClients(options = {}){
  const {
    searchFilter,
    reset,
    selectedClassification,
    listCount,
  } = options

  const {
    updatedEntities: { clients: clientsV3UpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { clients } = useSelector(reduxState => reduxState.entities)

  let filteredClients = useMemo(() => {
    const filtered = Object.values(clients)
      .filter(client => ((client.name.toLowerCase().includes(searchFilter.toLowerCase()))
        && (!selectedClassification?.length || selectedClassification?.some(x => client.classification?.includes(x)))))

    return sortArrayBy(filtered, 'asc', 'name')
  }, [clientsV3UpdatedAt, options])

  filteredClients = reset ? filteredClients.slice(0, 50) : filteredClients.slice(0, listCount)

  const hasfilteredClients = !!filteredClients.length

  const entityReducer = useSelector(reduxState => reduxState.clientsV3)
  const { loading } = entityReducer

  return {
    clients,
    filteredClients,
    hasfilteredClients,
    loading,
  }
}

export default useClients
