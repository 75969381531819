import { DateTime } from 'luxon'

export function csvReport(data, fileName){
  const currentTime = DateTime.fromISO(DateTime.now()).toFormat('dd-MM-yyyy_HHmmss')
  const header = Object.keys(data[0]).join(',')
  const rows = data.map(item => Object.values(item).map(row => `"${row}"`).join(','))
  const csvData = `${header}\n${rows.join('\n')}`
  const blob = new Blob([csvData], { type: 'text/csv' })

  const url = window.URL.createObjectURL(blob)
  const objectLink = document.createElement('a')
  objectLink.href = url
  objectLink.download = `${fileName}_${currentTime}.csv`
  objectLink.click()
  window.URL.revokeObjectURL(url)
}

export default csvReport
