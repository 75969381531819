import { useSelector } from 'react-redux'
import { digObject } from '@campaignhub/javascript-utils'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as serviceJobSearchActions from '@redux/modules/serviceJobSearch'

const mapResults = (result, serviceGroups) => {
  const presentResults = []
  const emptyResults = []

  if (result){
    if (result.length){
      presentResults.push({
        items: result.map(serviceJob => ({
          id: serviceJob.id,
          key: serviceJob.id,
          customIcon: serviceGroups[serviceJob.serviceGroupId]?.name,
          href: `/service-jobs/${serviceJob.id}`,
          subtitle: serviceJob.name,
          title: serviceJob.campaign.name,
        })),
        title: 'Campaigns',
      })
    } else {
      emptyResults.push({
        items: [{
          id: -1,
          key: -1,
          subtitle: 'Try searching again',
          title: 'No Campaigns found',
          priority: 0,
        }],
        title: 'Campaigns',
      })
    }
  }

  return { groups: [...presentResults, ...emptyResults] }
}

const serviceJobSearch = (options, dispatch) => {
  const string = digObject(options, 'string', '')

  if (string.length > 2){
    const { searchServiceJobs: searchFn } = serviceJobSearchActions

    const includes = ['campaign']
    const params = {
      search: string,
      includes: includes.join(','),
    }

    return dispatch(searchFn(params))
  }
  return Promise.resolve({ success: false })
}

const useServiceJobSearch = () => {
  const dispatch = useThunkDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  return {
    callbacks: {
      serviceJobSearch: options => serviceJobSearch(options, dispatch),
      mapResults: data => mapResults(data, serviceGroups),
    },
  }
}

export default useServiceJobSearch
