import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MainNavigation as BaseNavigation } from '@campaignhub/suit-theme'

import useMainNavigation from '@hooks/useMainNavigation'
import useCurrentUserV3 from '@hooks/useCurrentUserV3'

import systemBrandPayload from '@components/systemBrands/default'

import generateMainNavigationItems from '@sections/OnSite/utils/generateMainNavigationItems'

const MainNavigation = (props) => {
  const { minimized, nestedNavigation } = props
  const navigate = useNavigate()

  const { currentUserV3 } = useCurrentUserV3()

  const navigationItems = useMemo(() => generateMainNavigationItems(
      { toggleNavigate: item => navigate(item, { replace: true }) },
      currentUserV3 && !currentUserV3?.provider?.flags?.includes('Disable_Provider_Diary'),
    ), [currentUserV3, navigate])

  const { callbacks: { toggleNavigation }, showNavigation } = useMainNavigation()

  return (
    <BaseNavigation
      callbacks={{ toggleSidebar: toggleNavigation }}
      minimized={minimized}
      navigationItems={navigationItems}
      nestedNavigation={nestedNavigation}
      nestedNavigationWidth={240}
      showNavigation={showNavigation}
      systemBrandPayload={systemBrandPayload}
    />
  )
}

MainNavigation.propTypes = {
  minimized: PropTypes.bool,
  nestedNavigation: PropTypes.node,
}

export default MainNavigation
