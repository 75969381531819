import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowFromRight, faComment, faCommentCheck, faCompress, faExpand,
  faEyeSlash, faEye, faFileSearch, faPencil, faSave, faDownload,
} from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'
import { Button, PageHeader } from '@campaignhub/suit-theme'

const Header = () => {
  const {
    callbacks: {
      toggleCopyFieldState, toggleFullScreen, toggleSaveCopy, toggleShowAllComments,
      toggleShowMarkers,
    },
    copyFieldDisabled,
    creatingAsset,
    fullscreen,
    isMobileDevice,
    selectedAsset,
    serviceJob,
    showAllComments,
    showMarkers,
    updatingAsset,
  } = useContext(PageContext)

  const navigate = useNavigate()

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const actionContent = () => {
    switch (serviceGroups[serviceJob?.serviceGroupId]?.name){
      case 'Copywriting': return (
        <>
          {(!copyFieldDisabled || (updatingAsset || creatingAsset)) && (
            <>
              <Button
                buttonStyle="ghostCreate"
                loading={creatingAsset || updatingAsset}
                icon={<FontAwesomeIcon icon={faSave} />}
                marginLeft="medium"
                onClick={toggleSaveCopy}
                size="medium"
                title="Save"
                width="auto"
              />
              <Button
                buttonStyle="ghostCreate"
                loading={creatingAsset || updatingAsset}
                icon={<FontAwesomeIcon icon={faFileSearch} />}
                marginLeft="medium"
                onClick={() => toggleSaveCopy('scan')}
                size="medium"
                title="Scan and Save"
                width="auto"
              />
            </>
          )}
          <Button
            buttonStyle={copyFieldDisabled ? 'primaryUtility' : 'ghostUtility'}
            icon={<FontAwesomeIcon icon={copyFieldDisabled ? faPencil : faEye} />}
            marginLeft="medium"
            onClick={toggleCopyFieldState}
            size="medium"
            title={copyFieldDisabled ? 'Enable Fields' : 'View Only'}
            width="auto"
          />
        </>
      )
      case 'Photography': case 'Floorplan': return (
        <>
          {showMarkers && (
            <Button
              buttonStyle={showAllComments ? 'primaryUtility' : 'ghostUtility'}
              icon={<FontAwesomeIcon icon={showAllComments ? faCommentCheck : faComment} />}
              marginLeft="medium"
              onClick={toggleShowAllComments}
              size="medium"
              title={showAllComments ? 'All Unresolved Only' : 'All Comments'}
              width="auto"
            />
          )}
          <Button
            buttonStyle={showMarkers ? 'primaryUtility' : 'ghostUtility'}
            icon={<FontAwesomeIcon icon={showMarkers ? faEyeSlash : faEye} />}
            marginLeft="medium"
            onClick={toggleShowMarkers}
            size="medium"
            title={`${showMarkers ? 'Hide' : 'Show'} Markers`}
            width="auto"
          />
          <Button
            as="a"
            buttonStyle="ghostEdit"
            icon={<FontAwesomeIcon icon={faDownload} />}
            marginLeft="medium"
            href={selectedAsset?.file?.downloadUrl || selectedAsset?.assetUrl}
            size="medium"
            title="Download"
            width="auto"
          />
        </>
      )
      default: return null
    }
  }

  return (
    <PageHeader
      boxProps={{ justifyContent: 'flex-start' }}
      offset={{ left: 0, top: 0 }}
      width={!isMobileDevice && fullscreen ? '100%' : 'calc(100% - 375px)'}
      title="Asset Page"
      actionContent={(
        <>
          {actionContent()}
          <Button
            buttonStyle={fullscreen ? 'primaryEdit' : 'ghostEdit'}
            icon={<FontAwesomeIcon icon={fullscreen ? faCompress : faExpand} />}
            marginLeft="medium"
            onClick={() => toggleFullScreen()}
            size="medium"
            title={fullscreen ? 'Compress' : 'Expand'}
            width="auto"
          />
          <Button
            buttonStyle="primaryCreate"
            icon={<FontAwesomeIcon icon={faArrowFromRight} />}
            marginLeft="medium"
            onClick={() => navigate(`/service-jobs/${serviceJob.id}`)}
            size="medium"
            title="Go Back"
            width="auto"
          />
        </>
      )}
    />
  )
}

export default Header
