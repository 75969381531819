import React from 'react'
import { useModals, useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'

import {
  Box, Columns, ModalContext, PageHeader,
} from '@campaignhub/suit-theme'

import useReduxAction from '@hooks/useReduxAction'
import useCurrentUserV3 from '@hooks/useCurrentUserV3'
import CreateOrEditCurrentBlockoutModal from '@modals/CreateOrEditCurrentBlockoutModal'

import PageContent from './components/PageContent'
import Tools from './components/Tools'

const createCurrentBlockout = (blockoutParams, createFn, setState) => {
  createFn(blockoutParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showBlockoutModal: false })
  })
}

const deleteCurrentBlockout = (deleteFn, setState) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    setState({ showBlockoutModal: false })
  })
}

const updateCurrentBlockout = (blockoutParams, updateFn, setState) => {
  updateFn(blockoutParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showBlockoutModal: false })
  })
}

const validateCurrentBlockout = (blockoutParams, validateFn, setState) => {
  validateFn(blockoutParams).then(({ success, errors }) => {
    if (!success && errors){
      swal.fire({
        title: 'Minimum Hours not met due to Blockout',
        html: 'Your Blockout exceeds your Minimum Required Hours.'
        + '<br /> Total Blockout hours will cause your submitted hours to go below your minimum weekly requirement.'
        + '<br /> Contact the Operations Team if you need further information.',
        icon: 'warning',
        confirmButtonText: 'Okay',
        confirmButtonColor: '#e2001a',
        customClass: {
          content: 'swal-content-blockout',
        },
      })
      setState({ blockoutSaveEnabled: false })
    }

    setState({ blockoutSaveEnabled: true })
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditCurrentBlockoutModal: {
      closeModal: () => setState({ showBlockoutModal: false }),
      createCurrentBlockout: (blockoutParams, createFn) => createCurrentBlockout(blockoutParams, createFn, setState),
      deleteCurrentBlockout: deleteFn => deleteCurrentBlockout(deleteFn, setState),
      updateCurrentBlockout: (blockoutParams, updateFn) => updateCurrentBlockout(blockoutParams, updateFn, setState),
      validateCurrentBlockout: (blockoutParams, createFn) => validateCurrentBlockout(blockoutParams, createFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showBlockoutModal: false,
  blockoutSaveEnabled: false,
}

const Availability = () => {
  const [state, setState] = useSetState(defaultState)
  const { showBlockoutModal, blockoutSaveEnabled } = state

  const { currentUserV3, loading: currentUserV3Loading } = useCurrentUserV3()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    callbacks: {
      showBlockoutModal: (payload) => {
        setModalData('CreateOrEditCurrentBlockoutModal', payload)
        setState({ showBlockoutModal: true, blockoutSaveEnabled: false })
      },
    },
    currentUserV3,
    currentUserV3Loading,
    blockoutSaveEnabled,
    loadAction: 'loadCurrentBlockouts',
  }

  useReduxAction('blockouts', pageContext.loadAction, {
    size: 100,
    orderBy: '{ \'finish\': \'desc\' }',
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          activeTabBarItemKey="availability"
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          title="Provider Availability"
        />
        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns
            boxProps={{ marginTop: 'large' }}
            flexDirection={['column', 'column', 'row']}
          >
            <Columns.Main>
              <PageContent slots={currentUserV3?.provider.slots} />
            </Columns.Main>
            <Columns.Sidebar>
              <Tools />
            </Columns.Sidebar>
          </Columns>
        </Box>
        <CreateOrEditCurrentBlockoutModal
          callbacks={callbacks('CreateOrEditCurrentBlockoutModal', setState)}
          showModal={showBlockoutModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Availability
