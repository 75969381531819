import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import defaultState, { requiredFields } from '@models/channel'

import PageContext from '@contexts/pageContext'

import * as youtubeChannelActions from '@redux/modules/youtubeChannel'
import * as clientYoutubeChannelActions from '@redux/modules/clientYoutubeChannel'

const createYoutubeChannel = (channelParams, dispatch, requestOptions) => {
  const { createYoutubeChannel: createFn } = youtubeChannelActions
  return dispatch(createFn(channelParams, requestOptions))
}

const deleteYoutubeChannel = (channelParams, dispatch) => {
  const { deleteYoutubeChannel: deleteFn } = youtubeChannelActions
  return dispatch(deleteFn(channelParams))
}

const updateYoutubeChannel = (channelParams, dispatch, requestOptions) => {
  const { updateYoutubeChannel: updateFn } = youtubeChannelActions
  return dispatch(updateFn(channelParams, requestOptions))
}

const linkClientYoutubeChannel = (channelParams, dispatch, requestOptions) => {
  const { createClientYoutubeChannel: createFn } = clientYoutubeChannelActions
  return dispatch(createFn(channelParams, requestOptions))
}

const removeClientYoutubeChannel = (channelParams, dispatch) => {
  const { deleteClientYoutubeChannel: deleteFn } = clientYoutubeChannelActions
  return dispatch(deleteFn(channelParams))
}

const editYoutubeChannel = (channel, linkedClients, dispatch, showEditYoutubeChannelModal) => new Promise((resolve, reject) => {
  if (showEditYoutubeChannelModal){
    const payload = {
      callbacks: {
        deleteYoutubeChannel: () => deleteYoutubeChannel(channel, dispatch),
        linkClientYoutubeChannel: (channelParams, requestOptions) => linkClientYoutubeChannel(channelParams, dispatch, requestOptions),
        updateYoutubeChannel: (channelParams, requestOptions) => updateYoutubeChannel(channelParams, dispatch, requestOptions),
        removeClientYoutubeChannel: channelParams => removeClientYoutubeChannel(channelParams, dispatch),
      },
      channel,
      linkedClients,
    }

    showEditYoutubeChannelModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditYoutubeChannelModal not defined in PageContext callbacks'))
})

export function useChannelForm(channel = {}){
  const channelForm = useForm(defaultState, { entity: channel, requiredFields }, [channel.id])

  return {
    ...channelForm,
  }
}

const useYoutubeChannel = (initYoutubeChannel = {}) => {
  const { entity: youtubeChannel } = useLatestEntity(initYoutubeChannel, 'youtubeChannels')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showEditYoutubeChannelModal } = callbacks || {}

  const { creating, deleting, loading, updating } = useSelector(reduxState => reduxState.youtubeChannels)

  return {
    callbacks: {
      createYoutubeChannel: (channelParams, requestOptions) => createYoutubeChannel(channelParams, dispatch, requestOptions),
      deleteYoutubeChannel: channelParams => deleteYoutubeChannel(channelParams, dispatch),
      editYoutubeChannel: (linkedClients = []) => editYoutubeChannel(youtubeChannel, linkedClients, dispatch, showEditYoutubeChannelModal),
      updateYoutubeChannel: (channelParams, requestOptions) => updateYoutubeChannel(channelParams, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    updating,
    youtubeChannel,
  }
}

export default useYoutubeChannel
