export function getFilteredUserRole(users, options) {
  const { user_type, contract_base } = options
  const filteredUserRole = []

  Object.values(users).map(user => {
    const { userRole: { userTypeId }} = user

    if (userTypeId === user_type)
      filteredUserRole.push({
        ...user,
        type: user.userRole.id === contract_base ? 'Contract-base' : 'Full-time'
      })
  })

  return filteredUserRole 
}