import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import {
  Box,
  Button,
  Checkbox,
  DashboardModule,
  ListItem,
  Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faTh } from '@fortawesome/pro-light-svg-icons'

const defaultState = {
  clientProofNote: '',
  productionNote: '',
  supplierNote: '',
  studioNote: '',
}

const ProductDetails = (props) => {
  const { details, service, showOrderDetails, showRelatedServices, hidden } = props

  const [state, setState] = useSetState(defaultState)
  const { clientProofNote, productionNote, supplierNote, studioNote } = state

  useEffect(() => {
    setState({
      clientProofNote: '',
      productionNote: '',
      supplierNote: '',
      studioNote: '',
    })

    if (details?.length) {
      setState({
        clientProofNote: details.find(x => x.entityFieldType.name === 'ClientProofNote')?.value || '',
        productionNote: details.find(x => x.entityFieldType.name === 'ProductionNote')?.value || '',
        supplierNote: details.find(x => x.entityFieldType.name === 'SupplierNote')?.value || '',
        studioNote: details.find(x => x.entityFieldType.name === 'StudioNote')?.value || '',
      })
    }
  }, [details])

  if (hidden) return null

  return (
    <DashboardModule title="Product Details" flexDirection="column">
      <Box flexDirection="column" lineHeight="1.3">
        <ListItem
          boxProps={{
            borderBottom: '1px solid',
            padding: 'large',
          }}
          flexDirection="row"
          disableHover
        >
          <Box flexDirection="column" paddingRight="medium" justifyContent="center">
            <Text fontSize="small">{service?.description}</Text>
          </Box>
          {showRelatedServices && (
            <Box flexShrink="0" alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
              <Button
                size="medium"
                buttonStyle="primaryEdit"
                icon={<FontAwesomeIcon icon={faTh} />}
              >
                Related Services
              </Button>
            </Box>
          )}
        </ListItem>
        {showOrderDetails && (
          <>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
                alignItems: 'center',
              }}
              flexDirection="row"
              disableHover
            >
              <Box flexDirection="row">
                <Checkbox />
                <Text fontSize="small">5 Standard Images</Text>
              </Box>
              <Box flexDirection="row" width="auto" alignItems="center">
                <Text fontSize="small">$150.00</Text>
                <Box flexDirection="column" paddingLeft="medium">
                  <FontAwesomeIcon icon={faAngleRight} />
                </Box>
              </Box>
            </ListItem>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="row"
              disableHover
            >
              <Box flexDirection="row">
                <Checkbox />
                <Text fontSize="small">1 Day to Dusk Images</Text>
              </Box>
              <Box flexDirection="row" width="auto" alignItems="center">
                <Text fontSize="small">$50.00</Text>
                <Box flexDirection="column" paddingLeft="medium">
                  <FontAwesomeIcon icon={faAngleRight} />
                </Box>
              </Box>
            </ListItem>
          </>
        )}
        {clientProofNote && JSON.parse(clientProofNote).value.trim() !== '' && (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Text fontSize="small">Client Proofing Notes</Text>
            <Text color="bodyFontLightColor" fontSize="xsmall">
              {JSON.parse(clientProofNote).value}
            </Text>
          </ListItem>
        )}
        {productionNote && JSON.parse(productionNote).value.trim() !== '' && (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Text fontSize="small">Production Notes</Text>
            <Text color="bodyFontLightColor" fontSize="xsmall">
              {JSON.parse(productionNote).value}
            </Text>
          </ListItem>
        )}
        {supplierNote && JSON.parse(supplierNote).value.trim() !== '' && (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Text fontSize="small">Supplier Notes</Text>
            <Text color="bodyFontLightColor" fontSize="xsmall">
              {JSON.parse(supplierNote).value}
            </Text>
          </ListItem>
        )}
        {studioNote && JSON.parse(studioNote).value.trim() !== '' && (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Text fontSize="small">Studio Notes</Text>
            <Text color="bodyFontLightColor" fontSize="xsmall">
              {JSON.parse(studioNote).value}
            </Text>
          </ListItem>
        )}
      </Box>
    </DashboardModule>
  )
}

ProductDetails.propTypes = {
  details: PropTypes.array,
  service: PropTypes.object,
  showOrderDetails: PropTypes.bool,
  showRelatedServices: PropTypes.bool,
  hidden: PropTypes.bool,
}

export default ProductDetails
