import React from 'react'
import PropTypes from 'prop-types'
import { useModals, useSetState } from '@campaignhub/react-hooks'

import {
  Box, ModalContext,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import EditFlagsModal from '@modals/EditFlagsModal'

import GeneralDetails from './components/GeneralDetails'
import LinkingDetails from './components/LinkingDetails'
import DuskTimeDetails from './components/DuskTimeDetails'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    EditFlagsModal: {
      closeModal: () => setState({ showEditFlagsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showEditFlagsModal: false,
}

const ZoneDetails = (props) => {
  const { zoneForm, customErrors, callbacks: zoneCallbacks, linkedClients, linkedProviders } = props
  const { setCustomErrors, toggleUpdateLinkedClients, toggleUpdateLinkedProviders } = zoneCallbacks

  const [state, setState] = useSetState(defaultState)
  const { showEditFlagsModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    callbacks: {
      showEditFlagsModal: (payload) => {
        setModalData('EditFlagsModal', payload)
        setState({ showEditFlagsModal: true })
      },
    },
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <Box flexDirection="column">
          <GeneralDetails
            zoneForm={zoneForm}
            customErrors={customErrors}
            callbacks={{ setCustomErrors }}
          />
          <LinkingDetails
            zoneForm={zoneForm}
            callbacks={{ toggleUpdateLinkedClients, toggleUpdateLinkedProviders }}
            linkedClients={linkedClients}
            linkedProviders={linkedProviders}
          />
          <DuskTimeDetails
            zoneForm={zoneForm}
          />
        </Box>
        <EditFlagsModal
          callbacks={callbacks('EditFlagsModal', setState)}
          showModal={showEditFlagsModal}
          form={zoneForm}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

ZoneDetails.propTypes = {
  zoneForm: PropTypes.object.isRequired,
  customErrors: PropTypes.array,
  callbacks: PropTypes.object,
  linkedClients: PropTypes.array,
  linkedProviders: PropTypes.array,
}

export default ZoneDetails
