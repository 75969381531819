import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/clientV3/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/clientV3/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/clientV3/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/clientV3/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/clientV3/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/clientV3/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/clientV3/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/clientV3/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/clientV3/UPDATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/clientV3/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/clientV3/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/clientV3/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadClientsV3(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v3/clients', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.results, Schemas.CLIENTV3_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadClientV3(id, dispatch){
  dispatch(fetchRequest())

  const promise = api(`/v3/clients/${id}`, {})
    .then(({ data }) => {
      const normalizedJson = normalize(data, Schemas.CLIENTV3)
      dispatch(updateEntities(normalizedJson))
      dispatch(fetchSuccess())

      return { success: true, data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(fetchFailure(errors))

      return { success: false, errors }
    })

  return promise
}

export function createClientV3(client, options = {}){
  const config = {
    method: 'POST',
    data: client,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v3/clients', options, config)
      .then((request) => {
        dispatch(createSuccess())
        return loadClientV3(request.data.id, dispatch)
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function importClientsV3(options = {}){
  const config = {
    method: 'POST',
    data: {},
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v3/clients/import/50', options, config)
      .then((request) => {
        dispatch(createSuccess())
        return ({ success: true, request })
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateClientV3(client, options = {}){
  const config = {
    method: 'PUT',
    data: client,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/v3/clients/${client.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CLIENTV3)

        const updateEntityOptions = {
          mergeOptions: {
            customMerge: (key) => {
              if (!['classification'].includes(key)){
                return (_, source) => source
              }

              return undefined
            },
          },
        }

        dispatch(updateEntities(normalizedJson, updateEntityOptions))
        dispatch(updateSuccess())
        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteClientV3(client){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/v3/clients/${client.id}`, { permanent: true }, config)
      .then(({ data }) => {
        const normalizedJson = normalize(client, Schemas.CLIENTV3)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

export function loadZoneClientsV3(zoneId, options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/v3/zones/${zoneId}/clients`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.results, Schemas.CLIENTV3_ARRAY)
        dispatch(updateEntities(normalizedJson))

        const zoneClients = {
          linkedClients: data.results,
        }
        dispatch(fetchSuccess(zoneClients))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type, payload } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
