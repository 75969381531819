let userInfo = null

export const setUserInfo = async (getUserInfo) => {
  try {
    userInfo = await getUserInfo()
  } catch (error){
    console.log(error)
  }
}

export const getUserInfo = () => userInfo
