import { isValidRating } from '@functions/validations'

const state = {
  rating: '',
  note: '',
}

export const customManagementRequiredFields = [
  { key: 'management.rating', validation: isValidRating, invalidMessage: 'Rating is Beginner and Qualified only.', allowNullOrEmpty: true },
]

export default state
