/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import { DateTime, Duration } from 'luxon'
import PropTypes from 'prop-types'
import { snakeToTitleCase } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import {
 Box, Grid, Text, Button,
} from '@campaignhub/suit-theme'

import slotDefaultState from '@models/slot'
import RangeSlider from './components/RangeSlider'

const defaultState = {
  currentSlots: {},
  selectedHours: 0,
  submittedHours: 0,
}

const Scheduler = (props) => {
  const { boxProps, slots, callbacks, minimum_slots, showSave } = props
  const { updateSlots, saveSlotUpdates } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { currentSlots, selectedHours, submittedHours } = state

  useEffect(() => {
    const combinedSlots = { ...slotDefaultState, ...slots }
    const filteredSlots = combinedSlots
    const { saturday, sunday, ...weekdaySlots } = filteredSlots
    setState({ currentSlots: weekdaySlots })
  }, [slots])

  function computeHours(xSlots){
    let currentHours = 0
    const slotEntries = Object.entries(xSlots || {}).filter(([key]) => !key.includes('saturday', 'sunday'))
    slotEntries.map((slot) => {
      slot[1].map((x) => {
        const start = DateTime.fromISO(x.start)
        const end = DateTime.fromISO(x.end)
        const diff = end.diff(start).toObject()

        const dur = Duration.fromObject(diff)
        currentHours += (dur.as('minutes') / 60)
      })
    })
    return currentHours
  }

  function update(weekday, schedule){
    const tempSlots = currentSlots
    tempSlots[`${weekday}`] = schedule

    setState({
      currentSlots: tempSlots,
      selectedHours: computeHours(currentSlots),
    })
    updateSlots(tempSlots)
  }

  useEffect(() => {
    setState({ submittedHours: computeHours(slots) })
  }, [slots])

  return (
    <Box flexDirection="column" {...boxProps}>
      <Box flexDirection="column" marginBottom="large">
        <Box flexDirection="row" justifyContent="end">
          <Text fontSize="small" marginBottom="small" color="bodyFontLightColor">
            Minimum Hours Required:
          </Text>
          <Text fontSize="small" fontWeight="600" marginLeft="small">
            {minimum_slots}
          </Text>
        </Box>
        <Box flexDirection="row" justifyContent="end">
          <Text fontSize="small" marginBottom="small" color="bodyFontLightColor">
            Hours Submitted:
          </Text>
          <Text fontSize="small" fontWeight="600" marginLeft="small">
            {submittedHours}
          </Text>
        </Box>
        <Box flexDirection="row" justifyContent="end">
          <Text fontSize="small" color="bodyFontLightColor">
            Hours Selected:
          </Text>
          <Text
            color={selectedHours >= minimum_slots ? 'green' : 'red'}
            fontSize="small"
            fontWeight="600"
            marginLeft="small"
          >
            {selectedHours}
          </Text>
        </Box>
      </Box>
      <Grid gridGap="medium">
        {Object.keys(currentSlots).map(weekday => (
          <Grid gridTemplateColumns="40px 1fr" key={weekday}>
            <Text
              color="bodyFontLightColor"
              fontSize="small"
              paddingRight="medium"
              paddingTop="medium"
              textAlign="end"
            >
              {snakeToTitleCase(weekday.substring(0, 3))}
            </Text>
            <RangeSlider
              slots={currentSlots[weekday] !== undefined ? currentSlots[weekday] : []}
              callbacks={{ toggleUpdateSlot: schedule => update(weekday, schedule) }}
              showTimeRange={weekday === 'friday'}
            />
          </Grid>
          ))}
      </Grid>
      {showSave && (
        <Box flexDirection="row" justifyContent="end" marginTop="large">
          <Button
            buttonStyle="primaryCreate"
            size="medium"
            width="auto"
            onClick={() => setState({ submittedHours: saveSlotUpdates(selectedHours) || submittedHours })}
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
    )
}

Scheduler.propTypes = {
  boxProps: PropTypes.object,
  slots: PropTypes.object.isRequired,
  callbacks: PropTypes.object.isRequired,
  showSave: PropTypes.bool,
  minimum_slots: PropTypes.number,
}

export default Scheduler
