import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/notification/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/notification/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/notification/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/notification/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/notification/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/notification/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/notification/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/notification/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/notification/UPDATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/notification/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/notification/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/notification/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadNotifications(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v1/notifications', options)
      .then(({ data }) => {
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadNotification(id, dispatch){
  dispatch(fetchRequest())

  const promise = api(`/v1/notifications/${id}`, {})
    .then(({ data }) => {
      dispatch(fetchSuccess(data))

      return { success: true, data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(fetchFailure(errors))

      return { success: false, errors }
    })

  return promise
}

export function createNotification(notification, options = {}){
  const config = {
    method: 'POST',
    data: notification,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v1/notifications', options, config)
      .then((request) => {
        dispatch(createSuccess())
        return loadNotification(request.data.id, dispatch)
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateNotification(notification, options = {}){
  const config = {
    method: 'PUT',
    data: notification,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/v1/notifications/${notification.id}`, options, config)
      .then(() => {
        dispatch(updateSuccess())

        return loadNotification(notification.id, dispatch)
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteNotification(notification){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/v1/notifications/${notification.id}`, { permanent: true }, config)
      .then(({ data }) => {
        dispatch(deleteSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

export function readAllNotification(includes, options = {}){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v1/notifications/read-all', options, config)
      .then(() => {
        dispatch(createSuccess())
        return { success: true }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type, payload } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
