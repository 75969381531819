import React, { useEffect } from 'react'
import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, FormField, DashboardModule,
} from '@campaignhub/suit-theme'

import { oauth2SignIn } from '@functions/accessYoutube'
import { isValidEmail } from '@functions/validations'

const defaultState = {
  customError: '',
  emailAddress: '',
}

const YoutubeAuthentication = () => {
  const [state, setState] = useSetState(defaultState)
  const { customError, emailAddress } = state

  useEffect(() => {
    if (emailAddress === '' && customError !== '') setState({ customError: '' })
  }, [emailAddress])

  const authenticate = () => {
    if (!isValidEmail(emailAddress)){
      setState({ customError: 'Invalid Email Format' })
      return
    }

    oauth2SignIn(emailAddress)
  }

  return (
    <DashboardModule title="Youtube Details" boxProps={{ marginBottom: 'large' }}>
      <Box flexDirection="column" padding="large">
        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Email Address"
            errorMessage={customError}
          >
            <input name="email" type="text" onChange={e => setState({ emailAddress: e.target.value })} />
          </FormField>
          <Box flex="1" maxHeight="37px" alignSelf={['start', 'end']}>
            <Button
              buttonStyle="primaryUtility"
              size="medium"
              onClick={() => authenticate()}
              disabled={emailAddress === ''}
            >
              Authenticate
            </Button>
          </Box>
        </Box>
      </Box>
    </DashboardModule>
  )
}

export default YoutubeAuthentication
