import React from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import { Box, TabBar } from '@campaignhub/suit-theme'

import ClientLinking from './ClientLinking'
import ProviderLinking from './ProviderLinking'

const defaultState = {
  activeTabBarItemKey: 'clients',
}

const LinkingDetails = (props) => {
  const { zoneForm, callbacks, linkedClients, linkedProviders } = props
  const { toggleUpdateLinkedClients, toggleUpdateLinkedProviders } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { activeTabBarItemKey } = state

  const tabs = [
    {
      key: 'clients',
      title: 'Clients',
      onClick: () => setState({ activeTabBarItemKey: 'clients' }),
    },
    {
      key: 'providers',
      title: 'Providers',
      onClick: () => setState({ activeTabBarItemKey: 'providers' }),
    },
  ]

  return (
    <Box flexDirection="column" marginBottom="large">
      <TabBar
        items={tabs}
        selectedItemKey={activeTabBarItemKey}
        invertColors
      />
      <Box flexDirection="column" padding="large" variant="white">
        {activeTabBarItemKey === 'clients' && (
          <ClientLinking
            callbacks={{ toggleUpdateLinkedClients }}
            linkedClients={linkedClients}
            zoneForm={zoneForm}
          />
        )}
        {activeTabBarItemKey === 'providers' && (
          <ProviderLinking
            callbacks={{ toggleUpdateLinkedProviders }}
            linkedProviders={linkedProviders}
            zoneForm={zoneForm}
          />
        )}
      </Box>
    </Box>
  )
}

LinkingDetails.propTypes = {
  zoneForm: PropTypes.object,
  callbacks: PropTypes.object,
  linkedClients: PropTypes.array,
  linkedProviders: PropTypes.array,
}

export default LinkingDetails
