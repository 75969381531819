import React from 'react'
import PropTypes from 'prop-types'
import {
  Box, Button, Image, Text,
} from '@campaignhub/suit-theme'

import LogoMarkUrl from '@components/assets/logo-mark.svg'

const Login = (props) => {
  const { showLoginErrorMessage } = props

  const loginProvider = (provider) => {
    window.location.href = `/login/${provider}`
  }

  const signOutLoginAADB2C = () => {
    const url = new URL(process.env.REACT_APP_AADB2C_END_SESSION_ENDPOINT)
    const params = new URLSearchParams(url.search)

    params.set('post_logout_redirect_uri', `${window.location.href}login/aadb2c`)
    url.search = `${params}`

    window.location.href = url
  }

  return (
    <Box
      alignItems="center"
      backgroundColor="whiteGrey"
      height="100vh"
      justifyContent="center"
      padding={['large', 'xxlarge']}
      width="100%"
    >
      <Box alignItems="center" flexDirection="column" justifyContent="center" width={['100%', '400px']}>
        <Image backgroundSize="contain" url={LogoMarkUrl} width={50} height={50} />
        <>
          <Text marginTop="large" marginBottom="large">
            Log in to <strong>Content House</strong>
          </Text>
          <Button
            buttonStyle="primaryUtility"
            size="medium"
            marginBottom="medium"
            onClick={signOutLoginAADB2C}
          >
            Login with Email
          </Button>
          <Button
            buttonStyle="primaryUtility"
            size="medium"
            onClick={() => loginProvider('google')}
          >
            Continue with Google
          </Button>
          {showLoginErrorMessage && (
          <Text marginTop="large" marginBottom="large" color="red">
            Login failed. Please try again
          </Text>
          )}
        </>
      </Box>
    </Box>
  )
}

Login.propTypes = {
  showLoginErrorMessage: PropTypes.bool,
}

export default Login
