import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Grid, Text,
} from '@campaignhub/suit-theme'

import MultiRangeSlider from './multirangeslider'
import './multirangeslider.css'
import styles from './styles.module.scss'

const RangeSlider = (props) => {
  const { callbacks: { toggleUpdateSlot }, slots, showTimeRange } = props

  const slider = useRef()

  const range = new MultiRangeSlider({
    min: 8,
    max: 22,
    minWidth: 1,
    step: 0.5,
    values: slots,
    ghostLabel(value){
      const start = value[0] / 60
      const startTime = `${Math.floor(start)}:${start % 1 ? '30' : '00'}`

      return startTime
    },
    valueParse(value){
      return value * 60
    },
    valueFormat(value){
      return value / 60
    },
    label(value){
      const start = value[0] / 60
      const end = value[1] / 60

      const startTime = `${Math.floor(start)}:${start % 1 ? '30' : '00'}`
      const endTime = `${Math.floor(end)}:${end % 1 ? '30' : '00'}`

      const result = `${startTime}-${endTime}`

      return result
    },
  })

  window.addEventListener('resize', () => {
    range.render()
  })

  useEffect(() => {
    if (slider.current){
      slider.current.appendChild(range.el)
      range.addRanges()

      toggleUpdateSlot(slots)

      range.on('change', (data) => {
        toggleUpdateSlot(data.data)
      })
    }
  }, [slider])

  return (
    <Box flexDirection="column">
      <div ref={slider} />
      {showTimeRange && (
        <Grid className={styles.timeRange}>
          <Text color="bodyFontLightColor" fontSize="xsmall">8:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">9:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">10:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">11:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">12:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">13:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">14:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">15:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">16:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">17:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">18:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">19:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">20:00</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">21:00</Text>
        </Grid>
      )}
    </Box>
  )
}

RangeSlider.propTypes = {
  callbacks: PropTypes.object,
  slots: PropTypes.array,
  showTimeRange: PropTypes.bool,
}

export default RangeSlider
