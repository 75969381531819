import { useSelector } from 'react-redux'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as navigationActions from '@redux/modules/navigation'

import useCurrentUser from '@hooks/useCurrentUser'

const toggleNavigation = (showNavigation, dispatch) => {
  const { changeParam } = navigationActions
  return dispatch(changeParam('showNavigation', !showNavigation))
}

function useMainNavigation(){
  const dispatch = useThunkDispatch()
  const { currentUser } = useCurrentUser()

  const showNavigation = useSelector(reduxState => reduxState.navigation.showNavigation)

  const showReports = ['ProductionManager'].includes(currentUser?.userRole.name)

  return {
    callbacks: {
      toggleNavigation: () => toggleNavigation(showNavigation, dispatch),
    },
    showNavigation,
    showReports,
  }
}

export default useMainNavigation
