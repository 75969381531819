import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/slot/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/slot/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/slot/FETCH_FAILURE'

const UPDATE_REQUEST = 'contenthouse/slot/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/slot/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/slot/UPDATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(payload = {}){
  return {
    type: UPDATE_SUCCESS,
    payload,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadSlots(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v3/users/current/slots', options)
      .then(({ data }) => {
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function updateSlots(slot, options = {}){
  const config = {
    method: 'PUT',
    data: slot,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api('/v3/users/current/slots', options, config)
      .then(({ data }) => {
        dispatch(updateSuccess(data))
        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type, payload, errors } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
        result: payload,
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    default:
      return state
  }
}
