import { traverseObject } from '@functions/traverseObject'

const validateEntityStateCustomFields = (entityState, customRequiredFields) => {
  const c = []

  customRequiredFields.forEach((entry) => {
    const fn = entry.validation
    const { invalidMessage, requiredMessage } = entry

    const val = traverseObject(entry.key, entityState)
    const { allowNullOrEmpty } = entry

    if ((val === '' || val === null) && allowNullOrEmpty){
      delete c[entry.key]
    } else if (fn && fn.constructor && fn.call && fn.apply){
      if (fn(val)){
        delete c[entry.key]
      } else {
        c[entry.key] = invalidMessage || 'Failed to pass validation'
      }
    } else if (val == null || val.length === 0){
        c[entry.key] = requiredMessage || 'Required'
      } else {
        delete c[entry.key]
      }
  })

  return c
}

export default validateEntityStateCustomFields
