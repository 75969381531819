import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import PageContext from '@contexts/pageContext'
import { Box, Grid, Text } from '@campaignhub/suit-theme'
import useServiceCategory from '@hooks/useServiceCategory'

import styles from '@styles/custom.module.scss'

const ZoneCapacity = () => {
  const { calendarDates, categories, zones } = useContext(PageContext)

  const { capacities } = useSelector(reduxState => reduxState.entities)

  const { callbacks: { launchCapacityModal } } = useServiceCategory()

  return (
    <Grid backgroundColor="white" gridArea="zones-capacity">
      {zones.sort((a, b) => (a.name > b.name ? 1 : -1)).map(zone => (
        <Box key={zone.id} flexDirection="column">
          <Box backgroundColor="#f8f8f8" height="40px" />
          {categories.sort((a, b) => (a.description > b.description ? 1 : -1)).map(serviceCategory => (
            <Grid
              key={serviceCategory.id}
              gridColumnGap="large"
              gridTemplateColumns="repeat(5, minmax(160px, 1fr))"
            >
              {calendarDates.map((calendarDate) => {
                const key = `${calendarDate.c.year}`
                + `-${calendarDate.c.month > 9 ? calendarDate.c.month : `0${calendarDate.c.month}`}`
                + `-${calendarDate.c.day > 9 ? calendarDate.c.day : `0${calendarDate.c.day}`}`
                + `_${zone.id}_${serviceCategory.id}`

                const capacity = Object.values(capacities)?.find(x => x.id === key)

                return (
                  <Grid gridTemplateColumns="repeat(2, 1fr)" textAlign="center" key={key}>
                    <Grid
                      alignContent="center"
                      className={styles.highlightCell}
                      height="40px"
                      onClick={() => launchCapacityModal(capacity, 'Total')}
                      padding="medium"
                      style={{ cursor: 'pointer', boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
                    >
                      <Text fontSize="xsmall" color="bodyFontLightColor">
                        {Math.round(capacity?.total * 10) / 10 || 0}
                      </Text>
                    </Grid>
                    <Grid
                      alignContent="center"
                      backgroundColor={serviceCategory.threshold < capacity?.remaining ? null : 'rgba(251, 109, 112, 0.3)'}
                      className={styles.highlightCell}
                      height="40px"
                      onClick={() => launchCapacityModal(capacity, 'Remaining')}
                      padding="medium"
                      style={{ cursor: 'pointer', boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
                    >
                      <Text fontSize="xsmall" color="bodyFontLightColor">
                        {Math.round(capacity?.remaining * 10) / 10 || 0}
                      </Text>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          ))}
        </Box>
      ))}
    </Grid>
  )
}

export default ZoneCapacity
