import React from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'
import { getEntityByName } from '@functions/getEntityByName'
import useAssetComment from '@hooks/useAssetComment'

const defaultState = {
  commentOnChange: '',
}

const CopyCommentBox = (props) => {
  const { assetId, callbacks: { toggleShowComments }, copyProps } = props

  const [state, setState] = useSetState(defaultState)
  const { commentOnChange } = state

  const assetCommentPayload = useAssetComment()
  const {
    callbacks: {
      createAssetComment: createFn,
    },
    creating,
  } = assetCommentPayload

  const entities = useSelector(reduxState => reduxState.entities)
  const { assetCommentTypes } = entities

  const saveComment = () => {
    if (!commentOnChange.trim().length) return

    const newComment = {
      assetId,
      assetCommentTypeId: getEntityByName(assetCommentTypes, 'Studio')?.id,
      comment: commentOnChange,
      assetCommentDetails: [{
        fieldName: 'CopyDetailId',
        value: `{ "Value": ${copyProps.id} }`,
      }],
    }

    createFn(newComment).then(({ success, errors }) => {
      if (!success){
        toast.warning(errors[0])
      } else {
        setState({ commentOnChange: '' })
      }

      return success
    })
  }

  return (
    <Box flexDirection="column" disableHover>
      <FormField boxProps={{ marginBottom: 'medium', marginTop: 'medium' }}>
        <textarea
          onChange={e => setState({ commentOnChange: e.target.value })}
          placeholder="Enter message here..."
          style={{ height: 80, resize: 'vertical' }}
          type="text"
          value={commentOnChange}
        />
      </FormField>
      <Box alignItems="end" flexDirection="row">
        <Button
          buttonStyle="secondaryUtility"
          marginLeft="auto"
          size="medium"
          width="auto"
          onClick={() => toggleShowComments()}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          disabled={!commentOnChange.trim().length}
          loading={creating}
          marginLeft="medium"
          onClick={() => saveComment()}
          size="medium"
          width="auto"
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

CopyCommentBox.propTypes = {
  assetId: PropTypes.number.isRequired,
  callbacks: PropTypes.object,
  copyProps: PropTypes.object,
}

export default CopyCommentBox
