import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Checkbox, DashboardModule, ListItem, LoadingBubbles,
  LoadingModule, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import chunkArray from '@functions/chunkArray'

import useServiceJobDetail from '@hooks/useServiceJobDetail'

const updateServiceJobDetail = (serviceJobDetail, updateFn) => {
  updateFn(serviceJobDetail).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const defaultState = {
  checkedIds: [],
  chunkedList: [],
}

const ProviderChecklist = (props) => {
  const { providerChecklist, hidden } = props

  const [state, setState] = useSetState(defaultState)
  const { checkedIds, chunkedList } = state

  const {
    callbacks: {
      updateServiceJobDetail: updateFn,
    },
    loading,
    updating,
  } = useServiceJobDetail()

  useEffect(() => {
    const list = []
    let chunkedArray = []

    if (providerChecklist?.length > 0){
      const checkedListIds = []

      providerChecklist.map((x) => {
        const value = JSON.parse(x.value)
        if (value.checked) checkedListIds.push(x.id)

        return list.push({ ...value, id: x.id })
      })

      setState({ checkedIds: checkedListIds })
    }

    chunkedArray = chunkArray(list, 10, 3)

    setState({ chunkedList: chunkedArray })
  }, [providerChecklist])

  const updateChecklist = (id) => {
    let updatedChecklistIds = []
    const selectedChecklist = providerChecklist.find(x => x.id === id)
    const checklistValue = JSON.parse(selectedChecklist.value)

    if (checkedIds.includes(id)){
      checklistValue.checked = false
      updatedChecklistIds = checkedIds.filter(x => x !== id)
    } else {
      checklistValue.checked = true
      updatedChecklistIds = [...checkedIds, id]
    }

    setState({ checkedIds: updatedChecklistIds })

    const serviceJobDetailParams = {
      id,
      value: JSON.stringify(checklistValue),
    }

    updateServiceJobDetail(serviceJobDetailParams, updateFn)
  }

  if (!providerChecklist?.length || hidden) return null

  return (
    <DashboardModule
      title="Provider Checklist"
      headerRight={(
        <Box display="block" width="auto" textAlign="end" height={20}>
          {updating ? <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ flexShrink: 0 }} />
          : <StatusBadge color="#43ac6a" ghost text="Updated" />}
        </Box>
      )}
    >
      <LoadingModule loading={loading} />
      {!loading && (
        <Box
          padding="large"
          flexDirection={['column', 'row']}
          lineHeight="1.3"
          gridGap={[0, 'medium']}
        >
          {chunkedList.map(chunk => (
            <Box key={chunk.length} flexDirection="column">
              {chunk.map(checklist => (
                <ListItem
                  key={checklist.id}
                  boxProps={{
                    paddingBottom: 'small',
                  }}
                  flexDirection="row"
                  disableHover
                >
                  <Checkbox checked={checkedIds.includes(checklist.id)} onClick={() => updateChecklist(checklist.id)} />
                  <Text fontSize="small">{checklist.description}</Text>
                </ListItem>
                ))}
            </Box>
          ))}
        </Box>
      )}
    </DashboardModule>
  )
}

ProviderChecklist.propTypes = {
  providerChecklist: PropTypes.array,
  hidden: PropTypes.bool,
}

export default ProviderChecklist
