import React from 'react'
import PropTypes from 'prop-types'

import { Box, SidebarModal } from '@campaignhub/suit-theme'

import CategoryFilter from './components/CategoryFilter'
import WeekFilter from './components/WeekFilter'
import ZoneFilter from './components/ZoneFilter'

const CapacitySettingsModal = (props) => {
  const { callbacks, disableAnimation, disableOverlay, modalKey, showModal } = props

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Live Capacity" titleSecondLine="Settings" />
      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column" padding="large">
          <ZoneFilter />
          <CategoryFilter />
          <WeekFilter />
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

CapacitySettingsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CapacitySettingsModal.defaultProps = {
  modalKey: 'CapacitySettingsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CapacitySettingsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
