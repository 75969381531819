import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, FormField, Tag, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons'

import useFlags from '@hooks/useFlags'

import validateEntityStateCustomFields from '@functions/validateEntityStateCustomFields'
import { requiredFields } from '@models/Zone/zone'

const GeneralDetails = (props) => {
  const { zoneForm, callbacks } = props

  const { setCustomErrors } = callbacks

  const {
    entityState,
    entityState: {
      flags,
      name,
      default_travel_time,
    },
    errors,
    handlers,
    setEntityState,
  } = zoneForm

  useEffect(() => {
    setCustomErrors(validateEntityStateCustomFields(entityState, requiredFields))
  }, [entityState])

  const { result: zoneFlagsV3 } = useSelector(reduxState => reduxState.zoneFlagsV3)

  const { callbacks: { editFlags } } = useFlags()

  return (
    <DashboardModule title="General Information" boxProps={{ marginBottom: 'large' }}>
      <Box flexDirection="column" padding="large">
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']} width={['100%', 'calc(50% - 4px)']}>
          <FormField
            boxProps={{ flex: 1 }}
            label="Name"
            errorMessage={errors.name}
          >
            <input name="name" type="text" value={name} {...handlers} />
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']} width={['100%', 'calc(50% - 4px)']}>
          <FormField
            boxProps={{ flex: 1 }}
            label="Default Travel Time"
          >
            <input
              name="default_travel_time"
              type="number"
              value={default_travel_time}
              {...handlers}
            />
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
            label={(
              <Box flexDirection="row" alignItems="center">
                <Text
                  color="bodyFontLightColor"
                  fontSize="xsmall"
                  letterSpacing="1px"
                  marginRight="small"
                >
                  Flags
                </Text>
                <Tag
                  boxProps={{
                    backgroundColor: '#43ac6a!important',
                    fontSize: 'xsmall',
                    onClick: () => { editFlags('zone') },
                    width: 'fit-content',
                  }}
                >
                  <Text color="white" fontSize={9} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Text>
                </Tag>
              </Box>
            )}
          >
            <Box flexWrap="wrap" gridGap="small">
              {flags.map(flag => (
                <Tag
                  key={flag}
                  boxProps={{
                    width: 'fit-content',
                    fontSize: 'xsmall',
                    onClick: () => {
                      setEntityState({ flags: flags.filter(x => x !== flag) })
                    },
                  }}
                >
                  <Text color="bodyFontLightColor" style={{ cursor: 'pointer' }} title={zoneFlagsV3.find(x => x.value === flag)?.description}>
                    {zoneFlagsV3.find(x => x.value === flag)?.name} &nbsp;<FontAwesomeIcon icon={faTimes} />
                  </Text>
                </Tag>
              ))}
            </Box>
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
  )
}

GeneralDetails.propTypes = {
  zoneForm: PropTypes.object,
  customErrors: PropTypes.array,
  callbacks: PropTypes.object,
}

export default GeneralDetails
