import React from 'react'

import { Box, Button, DashboardModule } from '@campaignhub/suit-theme'

import useWordReplacements from '@hooks/useWordReplacements'
import useServiceCategories from '@hooks/useServiceCategories'
import useEmailReplacements from '@hooks/useEmailReplacements'

const Tools = () => {
  const {
    callbacks: { editServiceCategories },
    loading: serviceCategoriesLoading,
  } = useServiceCategories({ performHttpRequests: true })

  const {
    callbacks: { createOrEditWordReplacements },
    loading: wordReplacementsLoading,
  } = useWordReplacements()

  const {
    callbacks: { createOrEditEmailReplacements },
    loading: emailReplacementsLoading,
  } = useEmailReplacements()

  return (
    <DashboardModule title="Actions">
      <Box flexDirection="column" padding="large">
        <Button
          buttonStyle="secondary"
          loading={serviceCategoriesLoading}
          marginBottom="medium"
          onClick={editServiceCategories}
          size="medium"
        >
          Service Categories
        </Button>
        <Button
          buttonStyle="secondary"
          loading={wordReplacementsLoading}
          marginBottom="medium"
          onClick={createOrEditWordReplacements}
          size="medium"
        >
          Word Replacement
        </Button>
        <Button
          buttonStyle="secondary"
          loading={emailReplacementsLoading}
          marginBottom="medium"
          onClick={createOrEditEmailReplacements}
          size="medium"
        >
          Email Replacement
        </Button>
      </Box>
    </DashboardModule>
  )
}

export default Tools
