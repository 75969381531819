import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

const FailedItemList = (props) => {
  const { list } = props

  return (
    <Box flexDirection="column" variant="white">
      {list.map(listItem => (
        <ListItem
          boxProps={{
            borderBottom: '1px solid',
            padding: 'large',
          }}
          flexDirection="column"
          disableHover
          key={listItem.id}
        >
          <Text variant="ellipsis" marginRight="small" marginBottom="small" fontSize="small">
            {listItem.data?.name}
          </Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">
            {listItem.errorMessage}
          </Text>
        </ListItem>
      ))}
    </Box>
  )
}

FailedItemList.propTypes = {
  list: PropTypes.array,
}

export default FailedItemList
