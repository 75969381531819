import React from 'react'
import PropTypes from 'prop-types'

const LogoMark = (props) => {
  const {
    className, fillColor, width, height,
  } = props

  return (
    <svg
      className={className}
      viewBox="0 0 35 35"
      fill="none"
      style={{ width, height }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7794 0.769893C19.0033 -0.256631 16.8149 -0.256631 15.0387 0.769893L4.87035 6.64661C3.09417 7.67314 2 9.57023 2 11.6233V23.3767C2 25.4298 3.09417 27.3269 4.87035 28.3534L15.0387 34.2301C16.8149 35.2566 19.0033 35.2566 20.7794 34.2301L30.9478 28.3534C32.724 27.3269 33.8182 25.4298 33.8182 23.3767V11.6233C33.8182 9.57023 32.724 7.67314 30.9478 6.64661L20.7794 0.769893ZM21.0973 21.3496C21.4234 21.3496 21.6877 21.6094 21.6877 21.9299V23.7912C21.6877 24.1117 21.4234 24.3715 21.0973 24.3715H11.5539C10.9424 24.3715 10.4468 23.8844 10.4468 23.2835V21.9299C10.4468 21.6094 10.7111 21.3496 11.0372 21.3496H21.0973ZM23.5026 14.4087C23.1089 14.1831 22.6147 14.4622 22.6147 14.91V23.8156C22.6147 24.1361 22.8791 24.3959 23.2052 24.3959H24.8104C25.4218 24.3959 25.9175 23.9088 25.9175 23.3079V16.1255C25.9175 15.9191 25.8059 15.7282 25.6244 15.6242L23.5026 14.4087ZM13.1591 20.395C13.4852 20.395 13.7495 20.1353 13.7495 19.8148V14.8841C13.7495 14.4372 13.2571 14.158 12.8635 14.3817L10.7417 15.5874C10.5592 15.6911 10.4468 15.8826 10.4468 16.0897V19.8148C10.4468 20.1353 10.7111 20.395 11.0372 20.395H13.1591ZM10.9874 11.2796C10.6521 11.4758 10.4467 11.831 10.4467 12.2144V13.7103C10.4467 14.1564 10.9377 14.4357 11.3314 14.2134L17.9015 10.503C18.0841 10.3998 18.3089 10.4001 18.4913 10.5038L25.0605 14.2366C25.4541 14.4602 25.9465 14.1811 25.9465 13.7342V12.241C25.9465 11.8586 25.7422 11.5043 25.4084 11.3077L18.4991 7.23902C18.3128 7.12929 18.0802 7.12897 17.8935 7.2382L10.9874 11.2796Z"
        style={{ fill: fillColor }}
      />
    </svg>
  )
}

LogoMark.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default LogoMark
