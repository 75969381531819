import React, { useEffect } from 'react'
import PropTypes, { number } from 'prop-types'

import { ListItem, Text } from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'
import ServiceCategoryItem from './components/ServiceCategoryItem'

const defaultState = {
  serviceCategoryError: [number],
}

const InsertError = (val, serviceCategoryError, setState) => {
  setState({ serviceCategoryError: [...serviceCategoryError, val] })
}

const RemoveError = (val, serviceCategoryError, setState) => {
  setState({ serviceCategoryError: serviceCategoryError.filter(i => i !== val) })
}

const ServiceCategoriesList = (props) => {
  const [state, setState] = useSetState(defaultState)
  const { serviceCategoryError } = state
  const { serviceCategories, callbacks } = props
  const { disableSave } = callbacks

  useEffect(() => {
    disableSave(Object.values(serviceCategoryError).length > 1)
  }, [serviceCategoryError])

  if (!serviceCategories.length){
    return (
      <ListItem
        boxProps={{
          borderBottom: '1px solid',
          padding: 'large',
        }}
        flexDirection="column"
        disableHover
      >
        <Text fontSize="xsmall" color="bodyFontLightColor">
          No Service Categories found
        </Text>
      </ListItem>
    )
  }

  return (
    serviceCategories.sort((a, b) => (a.description > b.description ? 1 : -1)).map(serviceCategory => (
      <ServiceCategoryItem
        key={serviceCategory.id}
        serviceCategory={serviceCategory}
        callbacks={{ insertError: val => InsertError(val, serviceCategoryError, setState), removeError: val => RemoveError(val, serviceCategoryError, setState) }}
      />
    ))
  )
}

ServiceCategoriesList.propTypes = {
  serviceCategories: PropTypes.array.isRequired,
  callbacks: PropTypes.object,
}

export default ServiceCategoriesList
