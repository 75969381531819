import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import { Button, Box, FormField, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { dateToFilter } from '@functions/dateToFilter'
import { filterToDate } from '@functions/filterToDate'
import { DateTime } from 'luxon'
import useCurrentUser from '@hooks/useCurrentUser'

const defaultState = {
  dateEnd: new Date(),
  dateStart: new Date(),
  showFilter: false,
  filterLabel: 'Service Date Range',
}

const ServiceDateFilter = (props) => {
  const { setJobState, jobState } = props
  const { serviceDateFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { dateEnd, dateStart, showFilter, filterLabel } = state

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  const { currentUser: { timeZone } } = useCurrentUser()

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    setState({
      dateStart: filterToDate(serviceDateFilter.filterStart, 'start', timeZone),
      dateEnd: filterToDate(serviceDateFilter.filterEnd, 'end', timeZone),
    })
  }, [serviceDateFilter])

  const setCustomDateFilter = (startDate, endDate) => {
    const displayStart = DateTime.fromJSDate(startDate)
    const displayEnd = DateTime.fromJSDate(endDate)
    setState({ filterLabel: `${displayStart.toFormat('MM.dd.yyyy')} - ${displayEnd.toFormat('MM.dd.yyyy')}` })
    setJobState({
      serviceDateFilter: {
        filterStart: dateToFilter(startDate, 'start'),
        filterEnd: dateToFilter(endDate, 'end'),
      },
    })
  }

  const setDateFilter = (startDate, endDate, filterDateLabel) => {
    setState({ filterLabel: filterDateLabel })
    setJobState({
      serviceDateFilter: {
        filterStart: startDate,
        filterEnd: endDate,
      },
    })
  }

  return (
    <FormField direction="column">
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          defaultValue="Service Date Range"
        >
          <option>{ filterLabel }</option>
        </select>

        {showFilter && (
          <Box className={custom.popup}>
            <Box flexDirection="column" maxHeight="290px" overflowY="auto">
              <Box flexDirection="row" marginBottom="medium">
                <Box flexDirection="column" marginRight="medium">
                  <Text color="bodyFontLightColor" fontSize="xsmall" paddingBottom="small">From</Text>
                  <DatePicker selected={dateStart} onChange={date => setCustomDateFilter(date, dateEnd)} dateFormat="MM.dd.yyy" maxDate={dateEnd} />
                </Box>
                <Box flexDirection="column" marginRight="medium">
                  <Text color="bodyFontLightColor" fontSize="xsmall" paddingBottom="small">To</Text>
                  <DatePicker selected={dateEnd} onChange={date => setCustomDateFilter(dateStart, date)} dateFormat="MM.dd.yyy" minDate={dateStart} />
                </Box>
              </Box>
              <Button
                buttonStyle="secondary"
                size="medium"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('0d', '1d', 'Today')}
              >
                Today
              </Button>
              <Button
                buttonStyle="secondary"
                size="medium"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('-1d', '0d', 'Yesterday')}
              >
                Yesterday
              </Button>
              <Button
                buttonStyle="secondary"
                size="medium"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('1d', '2d', 'Tomorrow')}
              >
                Tomorrow
              </Button>
              <Button
                buttonStyle="secondary"
                size="medium"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('0m', '1m', 'This Month')}
              >
                This Month
              </Button>
              <Button
                buttonStyle="secondary"
                size="medium"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('-1m', '0m', 'Past Month')}
              >
                Past Month
              </Button>
              <Button
                buttonStyle="secondary"
                size="medium"
                marginBottom="medium"
                fontWeight="light"
                onClick={() => setDateFilter('', '', 'Service Date Range')}
              >
                Reset
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </FormField>
  )
}

ServiceDateFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default ServiceDateFilter
