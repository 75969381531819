import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, ListItem, StatusBadge, Tag, Text,
} from '@campaignhub/suit-theme'

import useUserV3 from '@hooks/useUserV3'

import { customColor } from '@functions/customColor'

const User = (props) => {
  const { user } = props

  const { callbacks: { editUser } } = useUserV3(user)

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection={['column', 'column', 'row']}
      justifyContent="center"
      onClick={editUser}
      showAngleIcon
      key={user.id}
    >
      <Box flexDirection="column" width={['100%', '100%', '35%']} flexShrink="0">
        <Text fontSize="small" marginBottom="small">
          {user.name}
        </Text>
        <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">
          {user.email}
        </Text>
        <Box flexWrap="wrap" gridGap="small">
          <StatusBadge
            color={customColor(user.roles[0].toLowerCase())}
            ghost
            text={user.roles[0]}
          />
          <StatusBadge
            color={customColor(user.employment?.toLowerCase())}
            ghost
            text={user.employment}
          />
        </Box>
      </Box>
      {user[user.roles[0].toLowerCase()]?.services && (
        <Box
          flexDirection="column"
          textAlign={['left', 'left', 'right']}
          marginTop={['large', 'large', 0]}
          marginLeft={[0, 0, 'large']}
          width={['100%', '100%', '65%']}
        >
          <Text fontSize="xsmall" marginBottom="small" marginRight="small" color="bodyFontLightColor">
            Services:
          </Text>
          <Box flexWrap="wrap" gridGap="small" justifyContent={['flex-start', 'flex-start', 'flex-end']}>
            {user[user.roles[0].toLowerCase()]?.services.sort((a, b) => (a > b ? 1 : -1)).map(service => (
              <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall' }} key={service}>
                {service}
              </Tag>
            ))}
          </Box>
        </Box>
      )}
    </ListItem>
  )
}

User.propTypes = {
  user: PropTypes.object,
}

export default User
