/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  DashboardModule,
  ListItem,
  Text,
  UserProfileImage,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-light-svg-icons'

const StudioFeedback = (props) => {
  const { hidden } = props

  if (hidden) return null

  return (
    <DashboardModule title="Studio Feedback">
      <ListItem
        boxProps={{
          borderBottom: '1px solid',
          padding: 'large',
          lineHeight: '1.3',
        }}
        flexDirection="column"
        style={{ cursor: 'pointer' }}
      >
        <Text fontSize="xsmall">
          Joan Viscaya, Quality Controller
        </Text>
        <Box flexDirection="row" marginTop="large">
          <UserProfileImage imageUrl="">
            <FontAwesomeIcon icon={faUser} />
          </UserProfileImage>
          <Box flexDirection="column" paddingLeft="large">
            <Text color="bodyFontLightColor" fontSize="xsmall">
              Hey guys thanks for sending that through - just some overall feedback on this job from Provider A and B. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur et augue dolor. Fusce quis luctus nisl, eget pellentesque elit. Donec sagittis vel lacus non pellentesque. Curabitur rutrum, est eget commodo luctus, enim dui faucibus elit, in efficitur purus dui ut lectus. Proin eu augue mattis, egestas eros a, maximus lacus. Ut fringilla egestas risus nec lacinia.
            </Text>
          </Box>
        </Box>
      </ListItem>
    </DashboardModule>
  )
}

StudioFeedback.propTypes = {
  hidden: PropTypes.bool,
}

export default StudioFeedback
