import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, ListItem, ModalContext, SidebarModal, Tag, Text, UserProfileImage,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faUser } from '@fortawesome/pro-light-svg-icons'

import useCurrentUser from '@hooks/useCurrentUser'

const defaultState = {
  availableUsers: [],
}

const CapacityModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const [state, setState] = useSetState(defaultState)
  const { availableUsers } = state

  const { currentUser } = useCurrentUser()

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { serviceCategories, zones } = useSelector(reduxState => reduxState.entities)

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { capacity, type } = modalPayload
  const { categoryId, date, users, zoneId } = capacity

  useEffect(() => {
    const filtered = users.filter((user) => {
      if (type === 'Total') return user
      if (type === 'Remaining' && user.availableSlots - user.bookedSlots > 0) return user
      return false
    })

    setState({ availableUsers: filtered })
  }, users)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title={type} titleSecondLine="Capacity" />
      <SidebarModal.Content>
        <Box flexDirection="column" paddingBottom="large">
          <Text fontSize="xsmall" color="bodyFontLightColor" marginBottom="small">
            {DateTime.fromISO(date, { zone: currentUser?.timeZone }).toFormat('ccc LLL dd, y')}
          </Text>
          <Text fontSize="xsmall" color="bodyFontLightColor">{zones[zoneId].name}</Text>
        </Box>
        <Text fontSize="small" marginBottom="small">{serviceCategories[categoryId].description}</Text>
        <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="large">
          {type} Capacity
        </Text>
        <Box flexDirection="column">
          {availableUsers.length > 0 ? availableUsers.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)).map((provider) => {
            const providerCategories = Object.values(serviceCategories).filter(x => x.id === provider.primaryCategoryId || provider.secondaryCategoryIds.includes(x.id))
            const remainingSlots = provider.availableSlots - provider.bookedSlots

            return (
              <ListItem
                boxProps={{ borderBottom: '1px dashed', marginBottom: 'medium' }}
                disableHover
                flexDirection="column"
                forceBottomBorder
                key={provider.id}
              >
                <Box flexDirection="row" marginBottom="large" alignItems="center" justifyContent="center">
                  <Box flexDirection="column" marginRight="large" marginTop="small" width="auto" alignSelf="flex-start">
                    <UserProfileImage>
                      <FontAwesomeIcon icon={faUser} />
                    </UserProfileImage>
                  </Box>
                  <Box flexDirection="column">
                    <Box flexDirection="row" justifyContent="space-between">
                      <Text fontSize="xsmall">{provider.firstName} {provider.lastName}</Text>
                      <Text fontSize="xsmall">{Math.round(remainingSlots * 10) / 10} / {Math.round(provider.availableSlots * 10) / 10}</Text>
                    </Box>
                    <Box flexDirection="row" flexWrap="wrap" gridGap="small" marginTop="medium">
                      {providerCategories.sort((a, b) => (a.description > b.description ? 1 : -1)).map(category => (
                        <Tag
                          key={category.id}
                          boxProps={{
                            backgroundColor: category.id === provider.primaryCategoryId ? '#43ac6a!important' : null,
                            width: 'fit-content',
                            fontSize: 'xsmall',
                          }}
                        >
                          <Text color={category.id === provider.primaryCategoryId ? 'white' : 'bodyFontLightColor'}>
                            {category.description} {category.id === provider.primaryCategoryId ? <FontAwesomeIcon icon={faStar} /> : null}
                          </Text>
                        </Tag>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </ListItem>
            )
          }) : (
            <ListItem
              boxProps={{ borderBottom: '1px dashed', marginBottom: 'medium' }}
              disableHover
              flexDirection="column"
              forceBottomBorder
            >
              <Box flexDirection="column" marginBottom="large" alignItems="center" justifyContent="center">
                <Text fontSize="xsmall">No Available Providers</Text>
              </Box>
            </ListItem>
          )}
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

CapacityModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CapacityModal.defaultProps = {
  modalKey: 'CapacityModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CapacityModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
