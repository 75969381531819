import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, ListItem, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import BlockoutItem from './components/BlockoutItem'

const Blockouts = (props) => {
  const { blockouts, loading } = props

  return (
    <Box flexDirection="column">
      <ListItem
        boxProps={{
          borderBottom: '1px solid',
          padding: 'large',
        }}
        flexDirection="column"
        disableHover
      >
        <Text fontSize="xsmall" color="bodyFontLightColor">
          Note: You will still need to attend appointments that have already been confirmed.
        </Text>
      </ListItem>
      {blockouts.sort((a, b) => Date.parse(b.start) - Date.parse(a.start)).map(blockout => (
        <BlockoutItem key={blockout.id} blockout={blockout} />
      ))}
      <LoadingModule loading={loading} boxProps={{ border: 0 }} />
      {blockouts.length === 0 && !loading && (
        <ListItem
          boxProps={{
            borderBottom: '1px solid',
            padding: 'large',
          }}
          flexDirection="column"
          disableHover
        >
          <Text fontSize="xsmall" color="bodyFontLightColor">
            No Blocked Dates
          </Text>
        </ListItem>
      )}
    </Box>
  )
}

Blockouts.propTypes = {
  blockouts: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}

export default Blockouts
