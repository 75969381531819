/* eslint-disable space-before-blocks */
import api from '@functions/api'
import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/serviceJobDetail/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/serviceJobDetail/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/serviceJobDetail/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/serviceJobDetail/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/serviceJobDetail/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/serviceJobDetail/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/serviceJobDetail/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/serviceJobDetail/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/serviceJobDetail/UPDATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadServiceJobDetails(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v1/service-job-details', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SERVICE_JOB_DETAIL_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadServiceJobDetail(serviceJobDetailId, dispatch){
  dispatch(fetchRequest())

  const promise = api(`/v1/service-job-details/${serviceJobDetailId}`, { includes: 'entityFieldType' })
    .then(({ data }) => {
      const normalizedJson = normalize(data, Schemas.SERVICE_JOB_DETAIL)
      dispatch(updateEntities(normalizedJson))
      dispatch(fetchSuccess())

      return { success: true, data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(fetchFailure(errors))

      return { success: false, errors }
    })

  return promise
}

export function updateServiceJobDetail(serviceJobDetail, options) {
  const config = {
    method: 'PUT',
    data: serviceJobDetail,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/v1/service-job-details/${serviceJobDetail.id}`, options, config)
      .then(({ data }) => {
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function assignServiceJobDetailDusk(serviceJobDetail, options = {}){
  const config = {
    method: 'POST',
    data: serviceJobDetail,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v1/service-job-details/dusk-detail', options, config)
      .then((data) => {
        dispatch(createSuccess())
        return loadServiceJobDetail(data.data, dispatch)
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
