import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState, { requiredFields } from '@models/filter'

import * as filterActions from '@redux/modules/filter'

import PageContext from '@contexts/pageContext'

const createFilter = (filterParams, dispatch, requestOptions) => {
  const { createFilter: createFn } = filterActions
  return dispatch(createFn(filterParams, requestOptions))
}

const updateFilter = (filterParams, dispatch, requestOptions) => {
  const { updateFilter: updateFn } = filterActions
  return dispatch(updateFn(filterParams, requestOptions))
}

const deleteFilter = (filterParams, dispatch, requestOptions) => {
  const { deleteFilter: deleteFn } = filterActions
  return dispatch(deleteFn(filterParams, requestOptions))
}

const launchCreateServiceJobViewModal = (filter, dispatch, showCreateServiceJobViewModal) => new Promise((resolve, reject) => {
  if (showCreateServiceJobViewModal) {
    const payload = {
      callbacks: {
        createFilter: (filterParams, requestOptions) => createFilter(filterParams, dispatch, requestOptions),
      },
      filter,
    }

    showCreateServiceJobViewModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showCreateServiceJobViewModal not defined in PageContext callbacks'))
})

const launchEditServiceJobViewModal = (filter, dispatch, showEditServiceJobViewModal) => new Promise((resolve, reject) => {
  if (showEditServiceJobViewModal) {
    const payload = {
      callbacks: {
        deleteFilter: (deletedParams, requestOptions) => deleteFilter(deletedParams, dispatch, requestOptions),
        updateFilter: (updatedParams, requestOptions) => updateFilter(updatedParams, dispatch, requestOptions),
      },
      filter,
    }

    showEditServiceJobViewModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditServiceJobViewModal not defined in PageContext callbacks'))
})

export function useFilterForm(filter) {
  const filterForm = useForm(defaultState, { entity: filter, requiredFields }, [filter.id])

  return {
    ...filterForm,
  }
}

function useFilter(initFilter) {
  const { entity: filter } = useLatestEntity(initFilter, 'filters')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showCreateServiceJobViewModal, showEditServiceJobViewModal } = callbacks || {}

  const { creating, deleting, loading, updating } = useSelector(reduxState => reduxState.filters)

  return {
    filter,
    callbacks: {
      createFilter: (filterParams, requestOptions) => createFilter(filterParams, dispatch, requestOptions),
      deleteFilter: (filterParams, requestOptions) => deleteFilter(filterParams, dispatch, requestOptions),
      launchCreateServiceJobViewModal: () => launchCreateServiceJobViewModal(filter, dispatch, showCreateServiceJobViewModal),
      launchEditServiceJobViewModal: () => launchEditServiceJobViewModal(filter, dispatch, showEditServiceJobViewModal),
      updateFilter: (filterParams, requestOptions) => updateFilter(filterParams, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useFilter