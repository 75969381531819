import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, FormField, Text,
} from '@campaignhub/suit-theme'

import useServiceJob from '@hooks/useServiceJob'

const updateServiceJob = (serviceJob, updateFn) => {
  updateFn(serviceJob).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const defaultState = { qcScore: 0 }

const QCScore = (props) => {
  const { serviceJob } = props

  const [state, setState] = useSetState(defaultState)
  const { qcScore } = state

  const {
    callbacks: {
      updateServiceJob: updateFn,
    },
    updating,
  } = useServiceJob()

  useEffect(() => {
    setState({ qcScore: serviceJob?.qcScore })
  }, [serviceJob])

  const saveQCScore = () => {
    const payload = {
      id: serviceJob.id,
      name: serviceJob.name,
      studioDeadline: serviceJob.studioDeadline,
      clientDeadline: serviceJob.clientDeadline,
      qcScore: qcScore || null,
    }

    updateServiceJob(payload, updateFn)
  }

  return (
    <Box flexDirection="column" padding={0} lineHeight="1.3">
      <Box flexDirection={['column', 'row']}>
        <Text fontSize="small" paddingBottom="small">
          QC Score
        </Text>
      </Box>
      <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
        <FormField
          boxProps={{ flex: 3, paddingBottom: ['medium', 0] }}
        >
          <input
            name="qcScore"
            type="number"
            value={qcScore}
            onChange={e => setState({ qcScore: e.target.value })}
          />
        </FormField>
        <FormField
          boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
        >
          <Button
            buttonStyle="primaryCreate"
            loading={updating}
            onClick={() => saveQCScore(qcScore)}
            size="medium"
            marginLeft="auto"
            width="auto"
          >
            {serviceJob?.qcScore ? 'Update' : 'Save'}
          </Button>
        </FormField>
      </Box>
    </Box>
  )
}

QCScore.propTypes = {
  serviceJob: PropTypes.object,
}

export default QCScore
