import { useSelector } from 'react-redux'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as notificationActions from '@redux/modules/notification'

const createNotification = (notificationParams, dispatch, requestOptions) => {
  const { createNotification: createNotifFn } = notificationActions
  return dispatch(createNotifFn(notificationParams, requestOptions))
}

const updateNotification = (notificationParams, dispatch, requestOptions) => {
  const { updateNotification: updateFn } = notificationActions
  return dispatch(updateFn(notificationParams, requestOptions))
}

const deleteNotification = (notification, dispatch) => {
  const { deleteNotification: deleteFn } = notificationActions
  return dispatch(deleteFn(notification))
}

const readAllNotification = (dispatch) => {
  const { readAllNotification: readFn } = notificationActions
  return dispatch(readFn())
}

const useNotification = () => {
  const dispatch = useThunkDispatch()

  const { creating, deleting, loading, updating } = useSelector(reduxState => reduxState.notifications)

  return {
    callbacks: {
      createNotification: (notificationParams, requestOptions) => createNotification(notificationParams, dispatch, requestOptions),
      updateNotification: (notificationParams, requestOptions) => updateNotification(notificationParams, dispatch, requestOptions),
      deleteNotification: notificationParams => deleteNotification(notificationParams, dispatch),
      readAllNotification: () => readAllNotification(dispatch),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useNotification
