// http://stackoverflow.com/a/850995/752397
function findUltimateAncestor(node){
  let ancestor = node
  while (ancestor.parentNode){
    ancestor = ancestor.parentNode
  }
  return ancestor
}

function isInDOMTree(node){
  return !!(findUltimateAncestor(node).body)
}

class Base {
  // This class contains methods to calculate different measurements for Bar class
  pixelToUnit(value){
    if (!isInDOMTree(this.el)){
      throw new Error('element is not in dom!')
    }
    const rect = this.el.getBoundingClientRect()
    const { width } = rect
    if (width === 0){
      throw new Error('element width is 0 or element is not attached to dom')
    }
    return value / width
  }

  unitToPixel(value){
    const rect = this.el.getBoundingClientRect()
    const { width } = rect
    return value * width
  }

  unitToUser(value){
    return (this.options.max - this.options.min) * value + this.options.min
  }

  userToUnit(value){
    return (value - this.options.min) / (this.options.max - this.options.min)
  }

  roundUserValue(value){
    return this.options.min + Math.floor((value - this.options.min) / this.options.step) * this.options.step
  }

  getCursor(event){
    const rect = this.el.getBoundingClientRect()
    let position = event.clientX

    if (event.type === 'touchstart' || event.type === 'touchmove'){
      position = event.touches[0].clientX
    }

    const x = position - rect.left
    return this.unitToUser(this.pixelToUnit(x))
  }
}

export default Base
