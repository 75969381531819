import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/clientYoutubeChannel/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/clientYoutubeChannel/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/clientYoutubeChannel/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/clientYoutubeChannel/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/clientYoutubeChannel/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/clientYoutubeChannel/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/clientYoutubeChannel/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/clientYoutubeChannel/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/clientYoutubeChannel/UPDATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/clientYoutubeChannel/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/clientYoutubeChannel/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/clientYoutubeChannel/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadClientYoutubeChannels(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v1/client-youtube-channels', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.CLIENT_YOUTUBE_CHANNEL_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createClientYoutubeChannel(channel, options = {}){
  const config = {
    method: 'POST',
    data: channel,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v1/client-youtube-channels', options, config)
      .then((data) => {
        const normalizedJson = normalize(data.data, Schemas.CLIENT_YOUTUBE_CHANNEL)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteClientYoutubeChannel(channel){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/v1/client-youtube-channels/${channel.id}`, { permanent: true }, config)
      .then(({ data }) => {
        const normalizedJson = normalize(channel, Schemas.CLIENT_YOUTUBE_CHANNEL)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type, errors } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors,
      }
    default:
      return state
  }
}
