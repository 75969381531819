import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'

import { DashboardModule, LoadingModule } from '@campaignhub/suit-theme'

import useReduxAction from '@hooks/useReduxAction'
import useSlot from '@hooks/useSlot'

import Blockouts from '@components/Blockouts'
import Scheduler from '@components/Scheduler'

const RefreshBlockouts = (props) => {
  const { loadAction, setState } = props

  const options = {
    size: 100,
    orderBy: '{ \'finish\': \'desc\' }',
  }

  useReduxAction('blockouts', loadAction, options, [])

  setState({ refresh: false })
  return null
}

const defaultState = {
  currentSlots: {},
  refresh: false,
}

const PageContent = (props) => {
  const { slots } = props

  const [state, setState] = useSetState(defaultState)
  const { currentSlots, refresh } = state

  const { currentUserV3, currentUserV3Loading, loadAction } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { blockouts } = entities

  const blockoutEntityReducer = useSelector(reduxState => reduxState.blockouts)
  const { loading: blockoutsLoading } = blockoutEntityReducer

  useEffect(() => {
    setState({ currentSlots: slots })
  }, [slots, currentUserV3])

  const { callbacks: { updateSlots: updateFn } } = useSlot()

  const updateSlots = (updatedSlots) => {
    setState({ currentSlots: updatedSlots })
  }

  function saveSlotUpdates(currentHours){
    if (currentUserV3.provider.minimum_slots > currentHours){
      swal.fire({
        title: 'Required Hours not Met',
        text: 'Your weekly hours are lower than the Minimum Hours Required. Please add more slots to proceed.',
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: '#e2001a',
      })
      return null
    }

    updateFn(currentSlots).then(({ success, errors: updateErrors }) => {
      if (!success && updateErrors){
        swal.fire({
          icon: 'warning',
          title: 'Unexpected error occurred',
          html: '<p>Please screenshot the error message below and send to support@contenthouse.io so that the team can resolve the issue.</p>'
            + `<pre style="background-color:#ececec;margin-top:5px;font-size:14px">${updateErrors[0]}</pre>`,
          confirmButtonText: 'OK',
          confirmButtonColor: '#e2001a',
        })
      }
    })

    swal.fire({
      icon: 'success',
      title: 'Successfully saved',
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      currentUserV3.provider.slots = currentSlots
    })

    return currentHours
  }

  return (
    <>
      <DashboardModule title="Work Hours">
        <LoadingModule loading={currentUserV3Loading} boxProps={{ border: 0 }} />
        {currentUserV3 && (
          <Scheduler
            slots={slots}
            callbacks={{ updateSlots, saveSlotUpdates }}
            showSave
            minimum_slots={currentUserV3.provider.minimum_slots}
            boxProps={{ padding: 'large' }}
          />
        )}
      </DashboardModule>

      <DashboardModule title="Upcoming Blockouts">
        {currentUserV3 && (
          <Blockouts
            blockouts={Object.values(blockouts).filter(x => x.user === `User/${currentUserV3.id}`)}
            loading={blockoutsLoading}
          />
        )}
        {refresh && (
          <RefreshBlockouts loadAction={loadAction} setState={setState} />
        )}
      </DashboardModule>
    </>
  )
}

PageContent.propTypes = {
  slots: PropTypes.object.isRequired,
}

export default PageContent
