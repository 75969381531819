import React, { useEffect, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'
import PageContext from '@contexts/pageContext'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, FormField, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

const defaultState = {
  showFilter: false,
}

const EmploymentFilter = () => {
  const [state, setState] = useSetState(defaultState)
  const { showFilter } = state

  const { result: employmentTypesV3 } = useSelector(reduxState => reduxState.employmentTypesV3)
  const { callbacks: { toggleSelectedEmployment }, selectedEmployment } = useContext(PageContext)

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  return (
    <FormField direction="column">
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          value="Employment"
        >
          <option>Employment</option>
        </select>

        {showFilter && (
        <Box className={custom.popup}>
          <Box flexDirection="column" maxHeight="280px" overflowY="auto">
            {Object.values(employmentTypesV3).map(employment => (
              <>
                <Box flexDirection="row" paddingTop="small" paddingBottom="medium" alignItems="center">
                  <input
                    className={custom.checkbox}
                    onChange={e => toggleSelectedEmployment(e.target.checked, employment.value)}
                    type="checkbox"
                    checked={selectedEmployment.includes(employment.value)}
                  />
                  <Text fontSize="small" marginLeft="medium">{employment.name}</Text>
                </Box>
              </>
                ))}
          </Box>
        </Box>
            )}
      </Box>
    </FormField>
  )
}

export default EmploymentFilter
