import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, InputSearch, ListItem, FormField,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import ClassificationFilter from './components/ClassificationFilter'

const defaultState = {
  searchFilter: '',
}

const Tools = () => {
  const { callbacks: { searchClientFilter, importClients, toggleResetFilter }, importDone, reset } = useContext(PageContext)

  const [state, setState] = useSetState(defaultState)
  const { searchFilter } = state
  const debouncedSearchFilter = useDebounce(searchFilter, 300)

  useEffect(() => {
    searchClientFilter(debouncedSearchFilter)
  }, [debouncedSearchFilter])

  useEffect(() => {
    setState({ searchFilter: searchFilter !== '' ? '' : '' })
  }, [importDone])

  useEffect(() => {
    if (reset) setState({ searchFilter: '' })
  }, [reset])

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="column"
      disableHover
    >
      <Box flexDirection="column" paddingBottom="medium">
        <InputSearch
          onChange={e => setState({ searchFilter: e.target.value })}
          placeholder="Search Client"
          value={searchFilter}
        />
      </Box>
      <FormField direction="column" boxProps={{ paddingBottom: 'medium' }}>
        <Box flexDirection="column">
          <ClassificationFilter />
        </Box>
      </FormField>
      <Box flexDirection="row" paddingBottom="medium">
        <Box flexDirection="column">
          <Button buttonStyle="secondary" size="medium" onClick={() => toggleResetFilter()}>
            Clear Filter
          </Button>
        </Box>
      </Box>
      <Box flexDirection="row">
        <Box flexDirection="column">
          <Link to="new" style={{ textDecoration: 'none' }}>
            <Button buttonStyle="secondary" size="medium">
              Create New Client
            </Button>
          </Link>
        </Box>
      </Box>
      <Box flexDirection="row">
        <Box flexDirection="column">
          <Button buttonStyle="secondary" size="medium" marginTop="medium" onClick={() => importClients()} loading={!importDone}>
            Import Clients
          </Button>
        </Box>
      </Box>
    </ListItem>
  )
}

Tools.propTypes = {}

export default Tools
