import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, ListItem, Text,
} from '@campaignhub/suit-theme'

const ProductionNotes = (props) => {
  const { productionNotes } = props

  return (
    <DashboardModule title="Post-Production Notes">
      <Box flexDirection="column" lineHeight="1.3">
        {productionNotes.length > 0 && productionNotes.map(note => (
          <ListItem
            key={note.id}
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Text fontSize="xsmall" color="bodyFontLightColor">
              {JSON.parse(note.value).value}
            </Text>
          </ListItem>
        ))}
        {!productionNotes.length > 0 && (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Text fontSize="xsmall" color="bodyFontLightColor">
              No Available Notes
            </Text>
          </ListItem>
        )}
      </Box>
    </DashboardModule>
  )
}

ProductionNotes.propTypes = {
  productionNotes: PropTypes.array,
}

export default ProductionNotes
