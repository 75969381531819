import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

const defaultState = {
  input: '',
  output: '',
}

const WordReplacement = (props) => {
  const { callbacks: { toggleRemoveWordReplacement, toggleUpdateWordReplacement }, replacementDetails } = props

  const [state, setState] = useSetState(defaultState)
  const { input, output } = state

  useEffect(() => {
    setState({
      input: replacementDetails.input,
      output: replacementDetails.output,
    })
  }, [replacementDetails])

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="row"
    >
      <Box flexDirection={['column', 'row']}>
        <FormField boxProps={{ flex: 1, marginRight: [0, 'medium'] }}>
          <input
            onBlur={() => toggleUpdateWordReplacement({ input, output })}
            onChange={(e) => { setState({ input: e.target.value }) }}
            type="text"
            value={input}
          />
        </FormField>
        <Text fontSize="small" marginRight={[0, 'medium']} padding="medium">to</Text>
        <FormField boxProps={{ flex: 1, marginRight: [0, 'medium'] }}>
          <input
            onBlur={() => toggleUpdateWordReplacement({ input, output })}
            onChange={(e) => { setState({ output: e.target.value }) }}
            type="text"
            value={output}
          />
        </FormField>
      </Box>
      <Box marginLeft="auto" width="auto" alignItems="center">
        <Link
          greyLink
          onClick={() => toggleRemoveWordReplacement()}
          textProps={{ marginLeft: 'medium' }}
          width="fit-content"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Link>
      </Box>
    </ListItem>
  )
}

WordReplacement.propTypes = {
  callbacks: PropTypes.object,
  replacementDetails: PropTypes.object,
}

export default WordReplacement
