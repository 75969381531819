import {
  faAddressBook,
  faCog,
  faGlobe,
  faHome,
  faList,
  faTachometer,
  faUser,
  faVideo,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = ({ toggleNavigate }) => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          icon: faHome,
          key: 'dashboard',
          onClick: () => toggleNavigate('/'),
          title: 'Dashboard',
          visible: true,
        },
      ],
    },
    {
      key: 'backOffice',
      title: 'Back Office',
      visible: true,
      items: [
        {
          icon: faList,
          key: 'jobs',
          onClick: () => toggleNavigate('/service-jobs'),
          title: 'Jobs',
          visible: true,
        },
        {
          icon: faCog,
          key: 'system-manager',
          title: 'System Manager',
          visible: true,
          items: [
            {
              icon: faAddressBook,
              key: 'clients',
              onClick: () => toggleNavigate('/systemManager/clients'),
              title: 'Clients',
              visible: true,
            },
            {
              icon: faUser,
              key: 'users',
              onClick: () => toggleNavigate('/systemManager/users'),
              title: 'Users',
              visible: true,
            },
            {
              icon: faVideo,
              key: 'youtube',
              onClick: () => toggleNavigate('/systemManager/youtube'),
              title: 'Youtube',
              visible: true,
            },
            {
              icon: faGlobe,
              key: 'zones',
              onClick: () => toggleNavigate('/systemManager/zones'),
              title: 'Zones',
              visible: true,
            },
          ],
        },
        {
          onClick: () => toggleNavigate('/liveCapacity'),
          icon: faTachometer,
          key: 'liveCapacity',
          title: 'Live Capacity',
          visible: true,
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
