/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext } from 'react'
import { Duration } from 'luxon'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'
import { Box, Button, FormField, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import { faComment } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useAssetComment from '@hooks/useAssetComment'
import { getEntityByName } from '@functions/getEntityByName'

const defaultState = {
  comment: '',
  currentTime: 0,
  showCommentBox: false,
}

const VideoPlayer = () => {
  const [state, setState] = useSetState(defaultState)
  const { comment, currentTime, showCommentBox } = state

  const { assetVideo, selectedAsset } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { assetCommentTypes } = entities

  const assetCommentPayload = useAssetComment()
  const {
    callbacks: {
      createAssetComment: createFn,
    },
    creating,
  } = assetCommentPayload

  const displayCommentBox = (type) => {
    if (type === 'enable'){
      assetVideo.current.pause()
    } else {
      assetVideo.current.play()
    }

    setState({
      comment: '',
      currentTime: assetVideo.current.currentTime || 0,
      showCommentBox: type === 'enable',
    })
  }

  const addComment = () => {
    const newComment = {
      assetId: selectedAsset?.id,
      assetCommentTypeId: getEntityByName(assetCommentTypes, 'Studio')?.id,
      comment,
      serviceJobHistoryId: null,
      assetCommentDetails: [{
        fieldName: 'Timestamp',
        value: `{ "value": ${parseInt(currentTime * 1000, 10)} }`,
      }],
    }

    return createFn(newComment).then(({ success, errors }) => {
      if (!success){
        toast.warning(errors[0])
      }
      displayCommentBox('disable')
      return success
    })
  }

  return (
    <Box alignItems="center" height="90vh" flexDirection="column" justifyContent="center" lineHeight="1.5">
      <Box
        onMouseOver={() => (showCommentBox ? null : assetVideo.current.setAttribute('controls', 'controls'))}
        onMouseOut={() => assetVideo.current.removeAttribute('controls')}
        maxHeight="87vh"
        position="relative"
        overflow="hidden"
        width="auto"
        justifyContent="center"
      >
        <video
          height="auto"
          ref={assetVideo}
          src={selectedAsset?.file?.downloadUrl || selectedAsset?.assetUrl}
          width="100%"
        >
          Your browser does not support the video tag.
        </video>
        {showCommentBox && (
          <Box
            position="absolute"
            width="85%"
            flexDirection={['column', 'row']}
            padding="medium"
            bottom={0}
            background="rgba(248, 248, 248, 0.5)"
            borderRadius={5}
            marginBottom="large"
          >
            <FormField boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}>
              <Box flexDirection="column" position="relative">
                <input
                  type="text"
                  onChange={e => setState({ comment: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter'){
                      e.preventDefault()
                      addComment()
                    }
                  }}
                  placeholder="Add a comment..."
                  defaultValue={comment}
                  style={{ borderRadius: 5, paddingLeft: '50px' }}
                />
                <Box position="absolute" top={9} left={9} width="auto" borderRight="1px solid" borderColor="lineColor" paddingRight="small">
                  <Text color="bodyFontLightColor" fontSize="xsmall">
                    {Duration.fromMillis(currentTime * 1000).toFormat('mm:ss')}
                  </Text>
                </Box>
              </Box>
            </FormField>
            <Box flexDirection="row" width="auto" justifyContent="end">
              <Button
                buttonStyle="primaryCreate"
                disabled={!comment.length}
                loading={creating}
                marginRight="medium"
                onClick={() => addComment()}
                size="medium"
                width="auto"
              >
                Comment
              </Button>
              <Button
                buttonStyle="primaryUtility"
                onClick={() => displayCommentBox('disable')}
                size="medium"
                width="auto"
              >
                x
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Button
        buttonStyle="primaryCreate"
        icon={<FontAwesomeIcon icon={faComment} />}
        onClick={() => displayCommentBox('enable')}
        size="medium"
        style={{ marginTop: '10px' }}
        width="auto"
      >
        Comment
      </Button>
    </Box>
  )
}

export default VideoPlayer
