import { useThunkDispatch } from '@campaignhub/react-hooks'
import * as serviceJobTimeTrackerActions from '@redux/modules/serviceJobTimetracker'

const jobTimeStart = (serviceJobId, requestData, dispatch, requestOptions) => {
  const { serviceJobTimeStart: serviceJobTimeStartFn } = serviceJobTimeTrackerActions
  return dispatch(serviceJobTimeStartFn(serviceJobId, requestData, requestOptions))
}

const jobTimeStop = (serviceJobId, requestData, dispatch, requestOptions) => {
  const { serviceJobTimeStop: serviceJobTimeStopFn } = serviceJobTimeTrackerActions
  return dispatch(serviceJobTimeStopFn(serviceJobId, requestData, requestOptions))
}

const getCurrentServiceJobTime = (serviceJob, dispatch) => {
  const { getServiceJobUserTime: loadFn } = serviceJobTimeTrackerActions

  return dispatch(loadFn(serviceJob))
}

const useServiceJobTimeTracker = () => {
  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      jobTimeStart: (serviceJobId, requestData, requestOptions) => jobTimeStart(serviceJobId, requestData, dispatch, requestOptions),
      jobTimeStop: (serviceJobId, requestData, requestOptions) => jobTimeStop(serviceJobId, requestData, dispatch, requestOptions),
      getCurrentServiceJobTime: serviceJob => getCurrentServiceJobTime(serviceJob, dispatch),
    },
  }
}

export default useServiceJobTimeTracker
