import React from 'react'
import PropTypes from 'prop-types'

import { Box, StatusBadge, Text, Link } from '@campaignhub/suit-theme'

import useFilter from '@hooks/useFilter'

import styles from '@styles/custom.module.scss'

const View = (props) => {
  const { view, updateCustomView } = props
  const { id, name, count, isChecked } = view

  const initFilter = { id: id }
  const { callbacks: { launchEditServiceJobViewModal } } = useFilter(initFilter)

  return (
    <Box flexDirection="row">
      <Box
        flexDirection="column"
        marginLeft="auto"
        marginRight="medium"
        width="auto"
      >
        <input
          type="checkbox"
          onChange={e => updateCustomView(e.target.checked, view)}
          checked={isChecked}
          className={styles.checkbox}
        />
      </Box>
      <Box flexDirection="column">
        <Link onClick={launchEditServiceJobViewModal}>
          <Text
            color="bodyFontLightColor"
            fontSize="small"
            marginBottom="medium"
            marginRight="medium"
          >
            {name}
          </Text>
        </Link>
      </Box>
      <Box flexDirection="column" width="auto" >
        <StatusBadge color='gray' text={count} />
      </Box>
    </Box>
  )
}

View.propTypes = {
  view: PropTypes.object,
  updateCustomView: PropTypes.func,
}

export default View
