import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, DashboardModule, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import { updateTokens } from '@functions/accessYoutube'
import videoServiceTypes from '@functions/videoServiceTypes'
import { useChannelForm } from '@hooks/useYoutubeChannel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import ChannelClientLinking from './components/ChannelClientLinking'

const confirmDelete = (props, accessToken, deleteFn) => {
  const { callbacks: { deleteYoutubeChannel } } = props

  swal.fire({
    title: 'Delete Channel?',
    text: 'Are you sure? ',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it.',
    confirmButtonColor: '#DD6B55',
  })
  .then(({ value }) => {
    if (value){
      updateTokens('revoke', accessToken, { toggleRefreshState: () => deleteYoutubeChannel(deleteFn) })
    }
  })
}

const defaultState = {
  customErrors: [],
  linkedClients: [],
}

const EditYoutubeChannelModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { updateYoutubeChannel } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { linkedClients } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: {
      deleteYoutubeChannel: deleteFn,
      updateYoutubeChannel: updateFn,
      linkClientYoutubeChannel: createLinkFn,
      removeClientYoutubeChannel: deleteLinkFn,
    },
    channel,
    linkedClients: currentlyLinkedClients,
  } = modalPayload

  const { deleting, updating } = useSelector(reduxState => reduxState.youtubeChannels)

  const channelPayload = useChannelForm(channel)
  const {
    entityState,
    entityState: {
      id,
      name,
      accessToken,
      emailAddress,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = channelPayload

  useEffect(() => {
    if (currentlyLinkedClients){
      setState({ linkedClients: currentlyLinkedClients })
    }
  }, [id])

  useEffect(() => {
    if (accessToken !== channel.accessToken && accessToken !== ''){
      updateYoutubeChannel(entityState, updateFn)
    }
  }, [accessToken])

  const linkClientYoutubeChannel = (clientYoutubeDetails) => {
    createLinkFn(clientYoutubeDetails).then(({ success, data, errors: linkErrors }) => {
      if (!success && linkErrors){
        toast.warn(linkErrors[0])
      }

      const clientYoutube = {
        id: data.id,
        ...clientYoutubeDetails,
      }

      const newList = [...linkedClients, clientYoutube]
      setState({ linkedClients: newList })
    })
  }

  const removeClientYoutubeChannel = (clientYoutubeDetails) => {
    deleteLinkFn(clientYoutubeDetails).then(({ success, errors: linkErrors }) => {
      if (!success && linkErrors){
        toast.warn(linkErrors[0])
      }

      const newList = linkedClients.filter(x => x.id !== clientYoutubeDetails.id)
      setState({ linkedClients: newList })
    })
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="large">
      <SidebarModal.Header callbacks={callbacks} title="Youtube" titleSecondLine="Channel Details" />
      <SidebarModal.Content>
        <Box flexDirection="column">
          <DashboardModule title="Details" boxProps={{ marginBottom: 'large' }}>
            <Box flexDirection="column" padding="large">
              <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
                <FormField
                  boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
                  label="Name"
                  errorMessage={errors.name}
                >
                  <input name="name" type="text" value={name} {...handlers} />
                </FormField>
                <FormField
                  boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
                  label="Email"
                  errorMessage={errors.emailAddress}
                >
                  <input name="emailAddress" type="text" value={emailAddress} {...handlers} />
                </FormField>
              </Box>
              <Box flexDirection={['column', 'row']} marginBottom="large">
                <FormField
                  boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
                  label="Channel ID"
                >
                  <input name="channelId" type="text" value={channel.channelId} disabled />
                </FormField>
                <FormField
                  boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
                  label="Type"
                >
                  <input name="email" type="text" value={videoServiceTypes.find(x => x.id === channel.videoServiceTypeId).name} disabled />
                </FormField>
              </Box>
              <Box flexDirection={['column', 'row']} marginBottom="large">
                <FormField
                  boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
                  label="Access Token"
                  errorMessage={errors.accessToken}
                >
                  <textarea
                    disabled
                    name="accessToken"
                    style={{ color: '#8c8c8e', height: 82, resize: 'vertical' }}
                    type="text"
                    value={accessToken}
                    {...handlers}
                  />
                </FormField>
              </Box>
              <Box flexDirection={['column', 'row']} marginBottom="large">
                <FormField
                  boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
                  label="Refresh Token"
                >
                  <textarea
                    disabled
                    name="refreshToken"
                    style={{ color: '#8c8c8e', height: 50, resize: 'vertical' }}
                    type="text"
                    value={channel.refreshToken}
                  />
                </FormField>
              </Box>
              <FormField direction="column" label="More Options">
                <Button
                  as="a"
                  buttonStyle="secondaryUtility"
                  href={`https://www.youtube.com/channel/${channel.channelId}`}
                  marginBottom="medium"
                  size="medium"
                  target="_blank"
                >
                  View Channel
                </Button>
                <Button
                  buttonStyle="secondaryUtility"
                  loading={updating}
                  onClick={() => updateTokens('refresh', channel.refreshToken, { toggleUpdateAccessToken: token => setEntityState({ accessToken: token }) })}
                  size="medium"
                >
                  Refresh Access Token
                </Button>
              </FormField>
            </Box>
          </DashboardModule>
          <ChannelClientLinking
            callbacks={{
              toggleLinkClientYoutubeChannel: clientDetails => linkClientYoutubeChannel(clientDetails),
              toggleRemoveClientYoutubeChannel: clientDetails => removeClientYoutubeChannel(clientDetails),
            }}
            linkedClients={linkedClients}
            channelForm={channelPayload}
          />
        </Box>
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          loading={deleting}
          onClick={() => confirmDelete(props, accessToken, deleteFn)}
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Delete Channel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateYoutubeChannel(entityState, updateFn)}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditYoutubeChannelModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditYoutubeChannelModal.defaultProps = {
  modalKey: 'EditYoutubeChannelModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditYoutubeChannelModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
