import React, { useEffect } from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import { Box, Text } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import { groupBy } from '@functions/groupBy'

const defaultState = {
  groupedTimeTrackers: [],
}

const Logs = (props) => {
  const { timetrackers } = props

  const [state, setState] = useSetState(defaultState)
  const { groupedTimeTrackers } = state

    const { timeZone } = useCurrentUser()

  useEffect(() => {
    if (timetrackers){
      setState({ groupedTimeTrackers: groupBy(timetrackers, 'entryDate') })
    }
  }, [timetrackers])

  return (
    <Box flexDirection="column" borderTop="1px solid" borderColor="lineColor" padding="large">
      {Object.keys(groupedTimeTrackers).map(date => (
        <Box key={date} flexDirection="column" alignItems="center">
          <Text fontSize="xsmall" color="bodyFontLightColor" marginBottom="medium">
            {DateTime.fromISO(date, { zone: timeZone }).toFormat('dd LLLL y').toUpperCase()}
          </Text>
          {groupedTimeTrackers[date].map(log => (
            <Text key={log.id} fontSize="xsmall" marginBottom="medium">
              {DateTime.fromISO(log.clockStart, { zone: timeZone }).toFormat('hh:mm a')}
              {` - ${log.clockEnd ? DateTime.fromISO(log.clockEnd, { zone: timeZone }).toFormat('hh:mm a') : 'On Going'}`}
            </Text>
          ))}
        </Box>
      ))}
      {Object.keys(groupedTimeTrackers).length === 0 && (
        <Text fontSize="xsmall" textAlign="center">No Available Logs</Text>
      )}
    </Box>
  )
}

Logs.propTypes = {
  timetrackers: PropTypes.array,
}

export default Logs
