import { useContext } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState, { requiredFields } from '@models/Zone/zone'

import PageContext from '@contexts/pageContext'

import * as zoneActions from '@redux/modules/zoneV3'

const createZone = (zoneParams, dispatch, requestOptions) => {
  const { createZoneV3: createFn } = zoneActions
  return dispatch(createFn(zoneParams, requestOptions))
}

const updateZone = (zoneParams, dispatch, requestOptions) => {
  const { updateZoneV3: updateFn } = zoneActions
  return dispatch(updateFn(zoneParams, requestOptions))
}

const deleteZone = (zone, dispatch) => {
  const { deleteZoneV3: deleteFn } = zoneActions
  return dispatch(deleteFn(zone))
}

const updateZoneClients = (zoneParams, dispatch, requestOptions) => {
  const { updateZoneClientsV3: updateFn } = zoneActions
  return dispatch(updateFn(zoneParams, requestOptions))
}

const updateZoneProviders = (zoneParams, dispatch, requestOptions) => {
  const { updateZoneProvidersV3: updateFn } = zoneActions
  return dispatch(updateFn(zoneParams, requestOptions))
}

const editZone = (zone, dispatch, showEditZoneModal) => new Promise((resolve, reject) => {
  if (showEditZoneModal){
    const payload = {
      callbacks: {
        deleteZone: () => deleteZone(zone, dispatch),
        updateZone: zoneParams => updateZone(zoneParams, dispatch),
        updateZoneClients: zoneParams => updateZoneClients(zoneParams, dispatch),
        updateZoneProviders: zoneParams => updateZoneProviders(zoneParams, dispatch),
      },
      zone,
    }

    showEditZoneModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditZoneModal not defined in PageContext callbacks'))
})

export function useZoneForm(zone = {}){
  const zoneForm = useForm(defaultState, { entity: { ...defaultState, ...zone }, requiredFields, validateOn: 'change' }, [zone.id, zone.cache_key])
  const initialValue = Object.keys(zone).length === 0 ? defaultState : cloneDeep(zoneForm.entityState)
  return {
    ...zoneForm,
    initialValue,
  }
}

const useZoneV3 = (initZone = {}) => {
  const { entity: zoneV3 } = useLatestEntity(initZone, 'zonesV3')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showEditZoneModal } = callbacks || {}

  const { creating, deleting, loading, updating } = useSelector(reduxState => reduxState.zonesV3)

  return {
    zoneV3,
    callbacks: {
      editZone: () => editZone(zoneV3, dispatch, showEditZoneModal),
      updateZone: (zoneParams, requestOptions) => updateZone(zoneParams, dispatch, requestOptions),
      createZone: (zoneParams, requestOptions) => createZone(zoneParams, dispatch, requestOptions),
      deleteZone: zoneParams => deleteZone(zoneParams, dispatch),
      updateZoneClients: (zoneParams, requestOptions) => updateZoneClients(zoneParams, dispatch, requestOptions),
      updateZoneProviders: (zoneParams, requestOptions) => updateZoneProviders(zoneParams, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useZoneV3
