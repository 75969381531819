import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, Text,
} from '@campaignhub/suit-theme'

import {
  useOutsideClick, useSetState,
} from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAltLines } from '@fortawesome/pro-light-svg-icons'

import custom from '@styles/custom.module.scss'
import CopyComment from '../CopyComment'
import CopyCommentBox from '../CopyCommentBox'

const defaultState = {
  contentOnChange: '',
  showComments: false,
}

const InputComponent = (props) => {
  const { assetId, callbacks: { toggleUpdateCopyDetailValue }, comments, copyProps, disableCopyField } = props

  const [state, setState] = useSetState(defaultState)
  const { contentOnChange, showComments } = state

  useEffect(() => {
    setState({ contentOnChange: copyProps?.value })
    if (copyProps?.value !== ''){
      toggleUpdateCopyDetailValue(copyProps?.value)
    }
  }, [copyProps?.value, copyProps])

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showComments })

  useEffect(() => {
    if (isClickedOutside && showComments){
      setState({ showComments: !showComments })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  return (
    <FormField label={copyProps.copyTemplateField?.name} boxProps={{ marginBottom: 'medium' }}>
      <Box flexDirection="column" position="relative" marginBottom="small">
        <input
          onBlur={() => toggleUpdateCopyDetailValue(contentOnChange)}
          onChange={e => setState({ contentOnChange: e.target.value })}
          style={{ paddingRight: '33px' }}
          type="text"
          value={contentOnChange}
          disabled={disableCopyField}
        />
        <Box className={custom.root} ref={contentEl} position="absolute" top="3px" right="3px" width="auto">
          <Box padding="medium" marginBottom="auto" onClick={() => setState({ showComments: !showComments })}>
            {comments && comments?.filter(comment => comment.status === null).length > 0 && (
              <Text marginRight="small" color="red" fontSize="small" width="auto">
                {comments?.filter(comment => comment.status === null).length}
              </Text>
            )}
            <Box position="relative" style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faCommentAltLines} />
              {comments && comments?.filter(comment => comment.status === null).length > 0 && (
                <Box
                  alignItems="center"
                  backgroundColor="red"
                  borderRadius="1rem"
                  justifyContent="center"
                  padding="4px"
                  position="absolute"
                  width="auto"
                  right={-1}
                  top={-1}
                />
              )}
            </Box>
          </Box>
          {showComments && (
            <Box className={custom.popup} width="350px" right="0" zIndex="4!important">
              <Box flexDirection="column" lineHeight="1.5" maxHeight="280px" overflowY="auto">
                {comments?.map(comment => (
                  <CopyComment comment={comment} />
                ))}
              </Box>
              <CopyCommentBox
                assetId={assetId}
                callbacks={{ toggleShowComments: () => setState({ showComments: !showComments }) }}
                copyProps={copyProps}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box flexDirection="row" alignItems="flexEnd">
        <Text
          color={contentOnChange.length > copyProps.copyTemplateField.length ? 'red' : 'bodyFontLightColor'}
          fontSize="xsmall"
          marginBottom="medium"
          marginLeft="auto"
        >
          Actual {contentOnChange.length},&nbsp;
        </Text>
        <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="medium">
          Suggested {copyProps.copyTemplateField.length}
        </Text>
      </Box>
    </FormField>
  )
}

InputComponent.propTypes = {
  assetId: PropTypes.number.isRequired,
  callbacks: PropTypes.object,
  comments: PropTypes.array,
  copyProps: PropTypes.object,
  disableCopyField: PropTypes.bool,
}

export default InputComponent
