const weeks = [
  { id: -1, name: 'Last Week' },
  { id: 0, name: 'This Week' },
  { id: 1, name: 'Next Week' },
  { id: 2, name: 'In Two Weeks' },
  { id: 3, name: 'In Three Weeks' },
  { id: 4, name: 'In Four Weeks' },
  { id: 5, name: 'In Five Weeks' },
  { id: 6, name: 'In Six Weeks' },
  { id: 7, name: 'In Seven Weeks' },
  { id: 8, name: 'In Eight Weeks' },
]

export default weeks
