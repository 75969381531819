const customFilters = {
  studioNotDone: {
    id: 1,
    name: 'StudioNotDone',
    description: 'StudioNotDone',
  },
  onsiteNotDone: {
    id: 2,
    name: 'OnsiteNotDone',
    description: 'OnsiteNotDone',
  },
  notArchived: {
    id: 3,
    name: 'NotArchived',
    description: 'NotArchived',
  },
}

export default customFilters