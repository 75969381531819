/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { toggleArray } from '@campaignhub/javascript-utils'

import JSZip from 'jszip'
import axios from 'axios'
import { saveAs } from 'file-saver'

import {
  Box, Image, Text, ListItemWithImage, StatusBadge, Checkbox, Button, Tag,
} from '@campaignhub/suit-theme'

import Icons from '@components/Icons'

import { faSlidersH, faTrash, faFolderDownload, faFilePdf } from '@fortawesome/pro-light-svg-icons'
import { faCaretDown, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import custom from '@styles/custom.module.scss'

const defaultState = {
  resourceTypeFilter: [],
  selectedResourceTypes: [],
  showFilter: false,
  zipLoading: false,
  selectAll: false,
  selectedIds: [],
  zipCount: 0
}

const ClientResources = (props) => {
  const { resources, serviceJob, isPortrait } = props

  const [state, setState] = useSetState(defaultState)
  const { resourceTypeFilter, selectedResourceTypes, showFilter, zipLoading, selectAll, selectedIds, zipCount, } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    setState({ selectedIds : selectAll ? resources.clientPhotos.files.map(content => content.resourceId) : [] })
  }, [selectAll])

  useEffect(() => {
    const resourceTypes = Object.keys(resources).filter((x) => {
      if (resources[x].files.length > 0) return x
    })
    setState({ resourceTypeFilter: resourceTypes })
  }, [])

  const updateFilterState = (checked, type) => {
    let selected = selectedResourceTypes
    if (checked){
      selected = type === 'All' ? resourceTypeFilter : [...selectedResourceTypes, type]
    } else {
      selected = type === 'All' ? [] : selected.filter(i => i !== type)
    }

    setState({ selectedResourceTypes: selected })
  }

  const selectPortraitContent = (selectedContent) => {
    const currentlySelected = toggleArray(
      selectedIds.includes(selectedContent.id) ? selectedIds.filter(id => id !== selectedContent.resourceId) : selectedIds,
      selectedContent.resourceId,
    ) || []

    setState({ selectedIds: currentlySelected})
    setState({ selectAll: resources.clientPhotos.files.length == currentlySelected.length})
  }

  const zip = new JSZip()
  const countRef = useRef()
  const totalRef = useRef()

  const download = item => axios.get(item.fileOrig || item.fileLink, { responseType: 'blob',
  onDownloadProgress: (progressEvent) => {
    // indication if the zip has unexpectedly stopped
    console.info(progressEvent.loaded)
  } }).then((resp) => {
      countRef.current.textContent = Object.values(zip.files).length
      zip.file(item.name, resp.data)
  })
  .catch((err) => {
    console.error(err)
    console.error(item)
  })

const downloadPortraitPhotos = () => {
  setState({ zipLoading: true, zipCount: selectedIds.length })

  const selectedResources = resources.clientPhotos.files.filter(x => selectedIds.includes(x.resourceId))
  const arrOfFiles = selectedResources.map(item => download(item))

  Promise.all(arrOfFiles)
    .then(() => {
      zip.generateAsync({ type: 'blob' }, (metadata) => {
        countRef.current.textContent = metadata.percent.toFixed(0)
        totalRef.current.textContent = '% Processing Zip'
        }).then((blob) => {
            saveAs(blob, `${serviceJob.campaign.name}.zip`)
            setState({ zipLoading: false })
        })
    })
    .catch((err) => {
      console.error(err)
      setState({ zipLoading: false })
    })
}

  return (
    <>
      {resourceTypeFilter.length > 0 && (
        <Box flexDirection="column" fontSize="small" className={custom.scroll}>
          <Box
            alignItems="center"
            borderBottom="1px solid"
            borderColor="lineColor"
            flexDirection="row"
            padding="large"
            className={custom.root}
            ref={contentEl}
          >
            <Box
              color="blue"
              flexDirection="row"
              onClick={() => setState({ showFilter: !showFilter })}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={faSlidersH} />
              <Text paddingX="medium" color="blue">Filter</Text>
              <FontAwesomeIcon icon={faCaretDown} />
            </Box>
            {showFilter && (
              <Box className={custom.popup} width="300px">
                <Box flexDirection="column" maxHeight="280px" overflowY="auto">
                  <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                    <input
                      type="checkbox"
                      className={custom.checkbox}
                      onChange={e => updateFilterState(e.target.checked, 'All')}
                    />
                    <Text fontSize="small" marginLeft="medium">Select All</Text>
                  </Box>
                  {resourceTypeFilter.map(type => (
                    <Box flexDirection="row" key={type} paddingBottom="medium" alignItems="center">
                      <input
                        type="checkbox"
                        className={custom.checkbox}
                        onChange={e => updateFilterState(e.target.checked, type)}
                        checked={selectedResourceTypes.includes(type)}
                      />
                      <Text fontSize="small" marginLeft="medium">{resources[type].description}</Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {isPortrait && (
              <Box
                alignItems="center"
                flexShrink="0"
                width="auto"
                style={{ marginRight: 6 }}
              >
                <Button
                  buttonStyle="secondary"
                  disabled={selectedIds.length < 1}
                  onClick={() => downloadPortraitPhotos()}
                  marginRight="medium"
                  size="small"
                  title="Download"
                  width="auto"
                >
                   {zipLoading && (
                    <>
                        <Text ref={countRef} marginRight="small"> 0 </Text>
                        <Text ref={totalRef} marginRight="large"> / {zipCount} </Text>
                    </>
                  )}
                  <FontAwesomeIcon icon={zipLoading ? faSpinner : faFolderDownload} className={zipLoading ? 'fa-spin' : ''} />
                </Button>
                <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall', marginRight: 'large' }}>{selectedIds.length} Selected</Tag>
                <Checkbox checked={selectAll} onClick={() => setState({ selectAll: !selectAll })} />
              </Box>
            )}
          </Box>
          <Box flexDirection="column" padding="large" maxHeight="586px" overflowY="auto">
            {(selectedResourceTypes.length > 0 ? selectedResourceTypes : resourceTypeFilter).map(resouceType => (
              <Box flexDirection="column" key={resources[resouceType].description} paddingBottom="large">
                <Text fontSize="small" fontWeight={600} marginBottom="large">
                  {resources[resouceType].description}
                </Text>
                {resources[resouceType].files.map(content => (
                  <ListItemWithImage key={content.resourceId} imageComponent={(
                    <Box
                      alignItems="center"
                      as="a"
                      borderRight={['none', '1px solid']}
                      borderBottom={['1px solid', 'none']}
                      borderColor={['lineColor', 'lineColor']}
                      href={content.fileLink}
                      justifyContent="center"
                      padding="medium"
                      style={{ textDecoration: 'none' }}
                      width={['100%', 'fit-content']}
                      target="_blank"
                    >
                      <Image
                        boxProps={{ backgroundColor: 'hoverLightGrey' }}
                        borderRadius={5}
                        height={[180, '100%']}
                        url={content.fileLink}
                        width={['100%', 200]}
                      >
                        {!content.fileLink && (
                          <Box
                            alignItems="center"
                            color="bodyFontColor"
                            display="grid"
                            justifyContent="center"
                            width="100%"
                          >
                            <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
                          </Box>
                        )}
                      </Image>
                    </Box>
                    )}
                  >
                    <ListItemWithImage.Header>
                      <ListItemWithImage.HeaderLeft>
                        <ListItemWithImage.HeaderText>
                          {content.name}
                        </ListItemWithImage.HeaderText>
                        {content.galleryCode && (
                          <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="small">
                            Uploaded By: {content.uploader}
                          </Text>
                        )}
                        {content.galleryCode && (
                        <Text color="bodyFontLightColor" fontSize="xsmall">
                          Gallery Code: {content.galleryCode}
                        </Text>
                        )}
                      </ListItemWithImage.HeaderLeft>
                      {isPortrait && (
                        <ListItemWithImage.HeaderRight>
                          <Checkbox checked={selectedIds.includes(content.resourceId)} marginRight={0} onClick={() => selectPortraitContent(content)} />
                        </ListItemWithImage.HeaderRight>
                      )}
                    </ListItemWithImage.Header>

                    <ListItemWithImage.Footer>
                      <ListItemWithImage.FooterLeft boxProps={{ maxWidth: '75%!important' }}>
                        <Box fontSize="small" marginBottom="small">
                          <StatusBadge
                            color="mysteryGrey"
                            text={content.fileExt}
                            boxProps={{ marginRight: 'small' }}
                          />
                        </Box>
                        {content.description && (
                          <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="small">
                            {content.description}
                          </Text>
                        )}
                        {content.comment && (
                          <Text color="bodyFontLightColor" fontSize="xsmall">
                            {content.comment}
                          </Text>
                        )}
                      </ListItemWithImage.FooterLeft>
                    </ListItemWithImage.Footer>
                  </ListItemWithImage>
                  ))}
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {!resourceTypeFilter.length && (
        <Box flexDirection="column" fontSize="small" padding="large">
          <Box
            alignItems="center"
            color="bodyFontColor"
            display="grid"
            height={[150, 250]}
            justifyContent="center"
          >
            <Text fontSize="small" fontWeight="600">
              No client resources available.
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}

ClientResources.propTypes = {
  resources: PropTypes.object,
  serviceJob: PropTypes.object,
}

export default ClientResources
