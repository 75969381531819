import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import useClientV3 from '@hooks/useClientV3'
import useCurrentUser from '@hooks/useCurrentUser'

import { customColor } from '@functions/customColor'

const Client = (props) => {
  const { client } = props

  const { callbacks: { editClient } } = useClientV3(client)

  const { currentUser: { timeZone } } = useCurrentUser()

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="row"
      justifyContent="center"
      onClick={editClient}
      showAngleIcon
      key={client.id}
    >
      <Box flexDirection="column">
        <Text fontSize="small" marginBottom="small">
          {client.name} ({client.number})
        </Text>
        <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">
          {client.business_name}
        </Text>
        <Text fontSize="xsmall" color="bodyFontLightColor">
          {client.brand}
        </Text>
        {client.classification && (
          <Box marginTop="small">
            <StatusBadge
              color={customColor(client.classification.toLowerCase())}
              ghost
              text={client.classification}
            />
          </Box>
        )}
      </Box>
      <Box flexDirection="column" textAlign="right">
        <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">
          Last Modified:
        </Text>
        <Text fontSize="xsmall" marginBottom="small">
          {client.modified_by}
        </Text>
        <Text fontSize="xsmall">
          {DateTime.fromISO(client.lastModified, { zone: timeZone }).toFormat('LLLL dd, y hh:mm a')}
        </Text>
      </Box>
    </ListItem>
  )
}

Client.propTypes = {
  client: PropTypes.object,
}

export default Client
