import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/statistic/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/statistic/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/statistic/FETCH_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

function getTotalJobs(services){
  let jobsToday = 0
    let incomplete = 0
    let manpower = 0
    let overdue = 0
    let urgent = 0

  Object.values(services).map((service) => {
    jobsToday += service.jobsToday
    incomplete += service.incomplete
    manpower += service.manpower
    overdue += service.overdue
    urgent += service.urgent
  })

  const total = {
    id: 0,
    name: 'Jobs',
    jobsToday,
    incomplete,
    percentComplete: incomplete / (jobsToday * 100),
    manpower,
    overdue,
    urgent,
  }

  return total
}

export function loadStatistics(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v1/service-jobs/statistics', options)
      .then(({ data }) => {
        const { serviceGroups } = data
        serviceGroups.unshift(getTotalJobs(serviceGroups))

        const normalizedJson = normalize(serviceGroups, Schemas.STATISTIC_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}
