import React, { useEffect, useState } from 'react'

import { getUserInfo } from '@functions/staticWebAppUserInfo'

import Login from '@auth/Login'
import AuthorizationController from '@auth/AuthorizationController'
// import SWAController from '@auth/SWAController'
// When logging in the SWA user info is not instantly available
// We wait and check 0.1 second later for it to be available

const waitForUserInfo = (setSwaUserInfo, setLoginErrorMessage) => {
  const intervalId = setInterval(() => {
    const userInfo = getUserInfo()
    if (userInfo && (userInfo.error === undefined && !userInfo.error)){
      clearInterval(intervalId)
      setSwaUserInfo(userInfo)
    }
    setLoginErrorMessage(userInfo && userInfo.error)
  }, 100)
}

const AuthenticationController = () => {
  const [swaUserInfo, setSwaUserInfo] = useState(null)
  const [showLoginErrorMessage, setLoginErrorMessage] = useState(null)
  useEffect(() => {
    waitForUserInfo(setSwaUserInfo, setLoginErrorMessage)
  }, [])

  if (swaUserInfo){
    return <AuthorizationController />
  }

  return <Login showLoginErrorMessage={showLoginErrorMessage} />
}

export default AuthenticationController
