import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { Notification as NotificationBase } from '@campaignhub/suit-theme'

const Notification = (props) => {
  const {
    notification,
    notificationTypes,
  } = props

  const newNotifEntity = {
    body: notification.description,
    link: notification.link,
    title: notificationTypes[notification.notificationTypeId]?.description,
  }

  const baseTime = DateTime.fromISO(notification.createdAt).toFormat('y-LL-dd hh:mm a ccc')
  const diffInToday = DateTime.fromISO(notification.createdAt).diffNow('days').days <= -1
  const diffInWeek = DateTime.fromISO(notification.createdAt).diffNow('weeks').weeks <= -1
  const diffInHours = DateTime.fromISO(notification.createdAt).diffNow('hours').hours <= -1

  const relativeTime = (`${diffInWeek ? `${baseTime} - ` : ''}
                        ${!diffInToday ? DateTime.fromISO(notification.createdAt).toRelative({ unit: diffInHours ? 'hours' : 'minutes' }) //= > "hrs ago"
                          : DateTime.fromISO(notification.createdAt).toRelativeCalendar()}`)

  return (
    <NotificationBase
      notification={newNotifEntity}
      relativeTime={relativeTime}
    />
  )
}

Notification.propTypes = {
  notification: PropTypes.object,
  notificationTypes: PropTypes.object,
}

export default Notification
