/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import {
  Box, Columns, LoadingModule, ModalContext,
} from '@campaignhub/suit-theme'

import useReduxAction from '@hooks/useReduxAction'
import useCurrentUser from '@hooks/useCurrentUser'

import PageContext from '@contexts/pageContext'
import CreateServiceJobViewModal from '@modals/CreateServiceJobViewModal'
import EditServiceJobViewModal from '@modals/EditServiceJobViewModal'

import customFilters from '@functions/customFilters'

import custom from '@styles/custom.module.scss'
import JobGroup from '../JobGroup'
import JobListFilter from './components/JobListFilter'

const createFilter = (filterParams, createFn, setState) => {
  createFn(filterParams).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateServiceJobViewModal: false })
  })
}

const updateFilter = (filter, updateFn, setState) => {
  updateFn(filter).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditServiceJobViewModal: false })
  })
}

const deleteFilter = (filter, deleteFn, setState) => {
  deleteFn(filter).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditServiceJobViewModal: false })
    toast.success('View Deleted')
  })
}

const callbacks = (component, props, setState) => {
  const componentCallbacks = {
    CreateServiceJobViewModal: {
      closeModal: () => setState({ showCreateServiceJobViewModal: false }),
      createFilter: (filterParams, createFn) => createFilter(filterParams, createFn, setState),
    },
    EditServiceJobViewModal: {
      closeModal: () => setState({ showEditServiceJobViewModal: false }),
      deleteFilter: (filter, deleteFn) => deleteFilter(filter, deleteFn, setState),
      updateFilter: (filter, updateFn) => updateFilter(filter, updateFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  accountManagerFilter: [],
  classificationFilter: [],
  clientFilter: [],
  customFilter: [
    customFilters.onsiteNotDone.id,
    customFilters.notArchived.id,
  ],
  dateFilter: {
    filterEnd: '',
    filterStart: '',
  },
  filterLimit: 50,
  filterTags: [],
  hideArchivedJobs: true,
  hideDoneJobs: true,
  serviceFilter: [],
  serviceJobGroupByDates: [],
  showCreateServiceJobViewModal: false,
  showEditServiceJobViewModal: false,
  statusFilter: [],
  userFilter: [],
}

const JobList = (props) => {
  const [state, setState] = useSetState(defaultState)
  const {
    clientFilter, customFilter, dateFilter, filterLimit, filterTags,
    hideArchivedJobs, hideDoneJobs, serviceFilter, showCreateServiceJobViewModal,
    showEditServiceJobViewModal, statusFilter, userFilter, groupByServiceJobDates,
    classificationFilter, accountManagerFilter,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = {
    callbacks: {
      showCreateServiceJobViewModal: (payload) => {
        setModalData('CreateServiceJobViewModal', payload)
        setState({ showCreateServiceJobViewModal: true })
      },
      showEditServiceJobViewModal: (payload) => {
        setModalData('EditServiceJobViewModal', payload)
        setState({ showEditServiceJobViewModal: true })
      },
    },
  }

  useEffect(() => {
    const filters = []
    if (hideDoneJobs) filters.push(customFilters.onsiteNotDone.id)
    if (hideArchivedJobs) filters.push(customFilters.notArchived.id)

    setState({ customFilter: filters })
  }, [hideDoneJobs, hideArchivedJobs])

  const includes = ['campaign', 'client', 'details', 'service', 'status', 'users', 'notes']
  const options = {
    ...accountManagerFilter?.length && { accountManagers: accountManagerFilter.join(',') },
    ...classificationFilter?.length && { classifications: classificationFilter.join(',') },
    ...clientFilter?.length && { clients: clientFilter.join(',') },
    ...customFilter?.length && { customFilters: customFilter.join(',') },
    ...dateFilter?.filterEnd && { filterEnd: dateFilter.filterEnd },
    ...dateFilter?.filterStart && { filterStart: dateFilter.filterStart },
    ...filterTags?.length && { tags: filterTags.join(',') },
    ...serviceFilter?.length && { services: serviceFilter.join(',') },
    ...statusFilter?.length && { serviceJobStatuses: statusFilter.join(',') },
    ...userFilter?.length && { users: userFilter.join(',') },
    includes: includes.join(','),
    pageSize: filterLimit,
    pageNumber: 1,
    orderBy: 'dateService',
  }

  useReduxAction('serviceJobs', 'loadServiceJobs', options, [
    clientFilter, customFilter, dateFilter, serviceFilter, statusFilter, userFilter, filterLimit, filterTags,
    classificationFilter, accountManagerFilter,
  ])

  const {
    loading, results: filteredServiceJobs,
  } = useSelector(reduxState => reduxState.serviceJobs)

  const { currentUser: { timeZone } } = useCurrentUser()

  useEffect(() => {
    if (Object.keys(filteredServiceJobs)?.length){
      const groupByDateFilter = filteredServiceJobs?.reduce((filtered, serviceJob) => {
        if (!filtered.find(x => x.dateService === DateTime.fromISO(serviceJob.dateService, { zone: timeZone }).toFormat('LLL dd, yyyy'))){
          filtered.push({
            dateService: DateTime.fromISO(serviceJob.dateService, { zone: timeZone }).toFormat('LLL dd, yyyy'),
          })
        }
        return filtered
      }, [])
      setState({
        groupByServiceJobDates: groupByDateFilter ? [...groupByDateFilter] : [],
      })
    } else {
      setState({
        groupByServiceJobDates: [],
      })
    }
  }, [filteredServiceJobs])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <Box paddingX="large" paddingTop="large">
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column-reverse', 'column-reverse', 'row']}>
            <Columns.Main>
              <LoadingModule loading={loading} />
              {!loading && (
                <Box flexDirection="column" className={custom.scroll} variant="white">
                  <Box flexDirection="column" overflowY="auto" maxHeight="82vh">
                    {groupByServiceJobDates?.map(item => (
                      <JobGroup
                        serviceJobs={Object.values(filteredServiceJobs)?.filter(x => DateTime.fromISO(x.dateService, { zone: timeZone }).toFormat('LLL dd, yyyy') === item.dateService)}
                        serviceJobGroupDate={item.dateService}
                      />
                    ))}
                  </Box>
                  {!loading && !groupByServiceJobDates?.length && (
                    <JobGroup serviceJobs={[]} />
                  )}
                </Box>
              )}
            </Columns.Main>
            <Columns.Sidebar>
              <JobListFilter setJobState={setState} jobState={state} />
            </Columns.Sidebar>
          </Columns>
        </Box>
        <CreateServiceJobViewModal
          callbacks={callbacks('CreateServiceJobViewModal', props, setState)}
          showModal={showCreateServiceJobViewModal}
          clientFilter={clientFilter}
          dateFilter={dateFilter}
          serviceFilter={serviceFilter}
          statusFilter={statusFilter}
          userFilter={userFilter}
          classificationFilter={classificationFilter}
          accountManagerFilter={accountManagerFilter}
        />

        <EditServiceJobViewModal
          callbacks={callbacks('EditServiceJobViewModal', props, setState)}
          showModal={showEditServiceJobViewModal}
          clientFilter={clientFilter}
          dateFilter={dateFilter}
          serviceFilter={serviceFilter}
          statusFilter={statusFilter}
          userFilter={userFilter}
          classificationFilter={classificationFilter}
          accountManagerFilter={accountManagerFilter}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default JobList
