import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, ListItem, StatusBadge, Tag, Text,
} from '@campaignhub/suit-theme'

import useClientYoutubeChannels from '@hooks/useClientYoutubeChannels'
import useYoutubeChannel from '@hooks/useYoutubeChannel'

import { customColor } from '@functions/customColor'

const defaultState = {
  linkedClients: [],
}

const Channel = (props) => {
  const { channel } = props

  const [state, setState] = useSetState(defaultState)
  const { linkedClients } = state

  const { filteredClientYoutubeChannels, hasfilteredClientYoutubeChannels } = useClientYoutubeChannels({
    youtubeChannelId: channel.id,
  })

  useEffect(() => {
    if (hasfilteredClientYoutubeChannels){
      const newList = []
      filteredClientYoutubeChannels.map(x => newList.push({
        id: x.id,
        clientId: x.clientId,
        name: x.client.name,
        youtubeChannelId: x.youtubeChannelId,
      }))

      setState({ linkedClients: newList })
    }
  }, [channel])

  const { callbacks: { editYoutubeChannel } } = useYoutubeChannel(channel)

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection={['column', 'column', 'row']}
      justifyContent="center"
      onClick={() => editYoutubeChannel(linkedClients)}
      showAngleIcon
      key={channel.channelId}
    >
      <Box flexDirection="column" width={['100%', '100%', '35%']} flexShrink="0">
        <Text fontSize="small" marginBottom="small">
          {channel.name}
        </Text>
        <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">
          {channel.emailAddress}
        </Text>
        {channel.videoServiceTypeId !== 1 && (
          <Box flexWrap="wrap" gridGap="small">
            <StatusBadge
              color={customColor(channel.videoServiceTypeId)}
              ghost
              text={channel.videoServiceTypeId === 2 ? 'RES TV' : 'Proofing'}
            />
          </Box>
        )}
      </Box>
      {linkedClients.length > 0 ? (
        <Box
          flexDirection="column"
          textAlign={['left', 'left', 'right']}
          marginTop={['large', 'large', 0]}
          marginLeft={[0, 0, 'large']}
          width={['100%', '100%', '65%']}
        >
          <Text fontSize="xsmall" marginBottom="small" marginRight="small" color="bodyFontLightColor">
            Clients:
          </Text>
          <Box flexWrap="wrap" gridGap="small" justifyContent={['flex-start', 'flex-start', 'flex-end']}>
            {linkedClients.map(x => (
              <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall' }}>
                {x.name}
              </Tag>
            ))}
          </Box>
        </Box>
      ) : null}
    </ListItem>
  )
}

Channel.propTypes = {
  channel: PropTypes.object,
}

export default Channel
