import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'
import * as assetActions from '@redux/modules/asset'

const loadAsset = (assetParam, options, dispatch) => {
  const { loadAsset: loadFn } = assetActions
  return dispatch(loadFn(assetParam, options))
}

const updateAsset = (assetParam, options, dispatch) => {
  const { updateAsset: updateFn } = assetActions
  return dispatch(updateFn(assetParam, options))
}

const updateAssetDirect = (assetParam, options, dispatch) => {
  const { updateAssetDirect: updateDirectFn } = assetActions
  return dispatch(updateDirectFn(assetParam, options))
}

const launchAssetUploadVersionModal = (dispatch, showAssetUploadVersionModal) => new Promise((resolve, reject) => {
  if (showAssetUploadVersionModal){
    const payload = {
      callbacks: {},
    }

    showAssetUploadVersionModal(payload)
    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showAssetUploadVersionModal not defined in PageContext callbacks'))
})

const useUploadAssetVersion = () => {
  const { callbacks } = useContext(PageContext)
  const { showAssetUploadVersionModal } = callbacks || {}
  const dispatch = useThunkDispatch()

  const { creating, updating } = useSelector(reduxState => reduxState.assets)

  return {
    callbacks: {
      launchAssetUploadVersionModal: () => launchAssetUploadVersionModal(dispatch, showAssetUploadVersionModal),
      loadAsset: (assetParam, options) => loadAsset(assetParam, options, dispatch),
      updateAsset: (assetParam, options) => updateAsset(assetParam, options, dispatch),
      updateAssetDirect: (assetParam, options) => updateAssetDirect(assetParam, options, dispatch),
    },
    creating,
    updating,
  }
}

export default useUploadAssetVersion
