import React, { useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/pro-light-svg-icons'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import { Box, LoadingBubbles, Button } from '@campaignhub/suit-theme'

import styles from './styles.module.scss'

const defaultState = {
  // Temporary as True or false
  startStopTime: false, // True: play time; false:pause time
}

const PlayPauseTime = (props) => {
  const {
    loading,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { startStopTime } = state

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: startStopTime })

  useEffect(() => {
    if (startStopTime && isClickedOutside){
      setState({ startStopTime: !startStopTime })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  return (
    <Box className={styles.root} alignItems="center" px="large">
      {loading && <LoadingBubbles />}

      {!loading && (
        <>
          <Box onClick={() => setState({ startStopTime: !startStopTime })}>
            <Box>
              <Button size="small" buttonStyle="primaryCreate">
                <span><FontAwesomeIcon icon={faPlay} /></span>
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default PlayPauseTime
