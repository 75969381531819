import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  Box, FormField, DashboardModule,
} from '@campaignhub/suit-theme'

const ZoneDetails = (props) => {
  const { clientForm } = props

  const { zonesV3 } = useSelector(reduxState => reduxState.entities)

  const {
    entityState: {
      zone,
    },
    handlers,
    setEntityState,
  } = clientForm

  return (
    <DashboardModule title="Zone Information" boxProps={{ marginBottom: 'large' }}>
      <Box flexDirection="column" padding="large">
        <Box flexDirection={['column', 'row']} marginBottom="large" width={['100%', 'calc(50% - 4px)']}>
          <FormField
            boxProps={{ flex: 1 }}
            label="Zone"
          >
            <select
              data-validate-field-on="change"
              name="zone.entity"
              value={zone.entity}
              onChange={(event) => {
                zone.entity = event.target.value
                setEntityState({ zone })
              }}
              {...handlers}
            >
              <option value="">Please Select...</option>
              {Object.values(zonesV3).map(z => <option value={z.reference} key={z.id}>{z.name}</option>)}
            </select>
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
  )
}

ZoneDetails.propTypes = {
  clientForm: PropTypes.object,
}

export default ZoneDetails
