import React, { useContext, useEffect, useRef } from 'react'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import PageContext from '@contexts/pageContext'
import PropTypes from 'prop-types'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, FormField,
} from '@campaignhub/suit-theme'

import { DateTime } from 'luxon'
import useUsers from '@hooks/useUsers'
import { getEntityByName } from '@functions/getEntityByName'
import Comment from '@suiteThemeCustom/Comment'
import useAssetComment from '@hooks/useAssetComment'
import custom from '@styles/custom.module.scss'

const defaultState = {
  commentOnChange: '',
  showComments: false,
  popupHeight: 0,
}

const CustomMarker = (props) => {
  const { markerProps, markers, markerSetState } = props
  const {
    left, top, id: commentId, commentIndex, comment, createdAt, createdById, status,
  } = markerProps

  const [state, setState] = useSetState(defaultState)
  const { commentOnChange, showComments, popupHeight } = state

  const { users } = useUsers()

  const entities = useSelector(reduxState => reduxState.entities)
  const { assetCommentTypes } = entities

  useEffect(() => {
    setState({ commentOnChange: commentId ? comment : '' })
  }, [commentId])

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showComments })

  useEffect(() => {
    if (isClickedOutside && showComments){
      setState({ showComments: !showComments })
      setIsClickedOutside(false)
      if (!commentId) markerSetState({ markers: markers.filter(x => !(x.top === top && x.left === left)) })
    }
  }, [isClickedOutside])

  const popupEl = useRef()
  useEffect(() => {
    if (popupEl.current){
      setState({ popupHeight: popupEl.current.offsetHeight })
    }
  }, [showComments])

  const assetCommentPayload = useAssetComment()
  const {
    callbacks: {
      createAssetComment: createFn,
      updateAssetComment: updateFn,
      deleteAssetComment: deleteFn,
     },
  } = assetCommentPayload

  const { assignedUserRole, currentUser, selectedAsset } = useContext(PageContext)
  const userRole = users[currentUser.id]?.userRole.name

  const confirm = (updateToStatus) => {
    const newComment = {
      assetId: selectedAsset?.id,
      assetCommentTypeId: getEntityByName(assetCommentTypes, 'Studio')?.id,
      comment: commentOnChange,
      serviceJobHistoryId: null,
      assetCommentDetails: [{
        fieldName: 'Position',
        value: `{ "X": "${ left }", "Y" : "${ top }" }`,
      }],
    }

    if (commentId){
      const updateComment = {
        id: commentId,
        comment: commentOnChange,
        status: updateToStatus || null,
      }

      return updateFn((updateComment)).then(({ success, errors }) => {
        if (!success){
          toast.warning(errors[0])
        }
        setState({ showComments: false })
        return success
      })
    }

    return createFn(newComment).then(({ success, errors }) => {
      if (!success){
        toast.warning(errors[0])
      }
      setState({ showComments: false })
      return success
    })
  }

  function deleteConfirmation(){
    swal.fire({
      title: 'Delete Comment',
      html: 'This action will delete the comment.'
      + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
    }).then(({ value }) => {
      if (value){
        deleteFn({ id: commentId }).then(({ success, errors }) => {
          if (!success){
            toast.warning(errors[0])
          }
          setState({ showComments: false })
          markerSetState({ markers: markers.filter(x => !(x.top === top && x.left === left)) })
        })
      }
    })
  }

  useEffect(() => {
    if (!commentId) setState({ showComments: !showComments })
  }, [markers])

  return (
    <Box className={custom.root} ref={contentEl} width="auto">
      <Button
        alignItems="center"
        backgroundColor={status === null || !status
          ? '#E95B35' : (status === 'Resolved' ? 'mysteryGrey' : 'green')}
        border={0}
        borderRadius={20}
        color="white"
        fontSize="small"
        fontWeight={500}
        height="34px"
        justifyContent="center"
        onClick={() => {
          setState({ showComments: !showComments })
          if (!commentId){
            markerSetState({ markers: markers.filter(x => !(x.top === top && x.left === left)) })
          }
        }}
        padding="medium"
        width="34px"
      >
        {commentIndex || markers.length}
      </Button>
      {showComments && (
        <Box
          borderRadius="5px"
          className={custom.popup}
          left={left >= 50 ? -295 : 38}
          top={`${top >= 50 ? -popupHeight + 34 : 0 }px!important`}
          width="290px"
          zIndex="4!important"
          ref={popupEl}
        >
          <Box flexDirection="column">
            {!comment ? (
              <FormField boxProps={{ marginBottom: 'medium', marginTop: 'medium' }}>
                <textarea
                  onChange={e => setState({ commentOnChange: e.target.value })}
                  placeholder="Enter comment here..."
                  style={{ height: 60, resize: 'vertical' }}
                  type="text"
                  value={commentOnChange}
                />
              </FormField>
              ) : (
                <Comment
                  commentBody={commentOnChange}
                  commentTime={DateTime.fromISO(createdAt, { zone: currentUser.timeZone }).toFormat('dd LLLL y, hh:mm a')}
                  fullName={markerProps.details?.find(x => x.entityFieldType.name === 'Commenter')
                    ? JSON.parse(markerProps.details.find(x => x.entityFieldType.name === 'Commenter')?.value).value
                    : `${users[createdById]?.firstName} ${users[createdById]?.lastName}`}
                  hideImage
                />
            )}
            <Box flexDirection="row">
              <Box alignItems="end" flexDirection="row">
                <Button
                  buttonStyle="secondaryUtility"
                  marginLeft="auto"
                  size="medium"
                  width="auto"
                  onClick={() => {
                    setState({ showComments: !showComments })
                    if (!commentId){
                      markerSetState({ markers: markers.filter(x => !(x.top === top && x.left === left)) })
                    }
                  }}
                >
                  Cancel
                </Button>
                {(['ProductionManager'].includes(userRole) || assignedUserRole?.find(x => ['QualityControl', 'Specialist'].includes(x.serviceJobUserRole?.name))) ? (
                  <>
                    {!commentId && (
                      <Button
                        buttonStyle="primaryCreate"
                        marginLeft="medium"
                        size="medium"
                        width="auto"
                        onClick={() => confirm()}
                      >
                        Comment
                      </Button>
                    )}
                    {commentId && status !== 'Resolved' && (
                    <>
                      <Button
                        buttonStyle="primaryDestroy"
                        marginLeft="medium"
                        size="medium"
                        width="auto"
                        onClick={() => deleteConfirmation()}
                      >
                        Delete
                      </Button>
                      <Button
                        buttonStyle="primaryCreate"
                        marginLeft="medium"
                        size="medium"
                        width="auto"
                        onClick={() => confirm('Resolved')}
                      >
                        Resolve
                      </Button>
                    </>
                    )}
                    {commentId && status === 'Resolved' && (
                      <Button
                        buttonStyle="primaryUtility"
                        marginLeft="medium"
                        size="medium"
                        width="auto"
                        onClick={() => confirm()}
                      >
                        Unresolve
                      </Button>
                    )}
                  </>
                ) : (assignedUserRole?.find(x => x.serviceJobUserRole?.name === 'EditorDrafterCopywriter') ? (
                  <>
                    {!status && (
                      <Button
                        buttonStyle="primaryCreate"
                        marginLeft="medium"
                        size="medium"
                        width="auto"
                        onClick={() => confirm('Revised')}
                      >
                        Revised
                      </Button>
                    )}
                    {status === 'Revised' && (
                      <Button
                        buttonStyle="primaryUtility"
                        marginLeft="medium"
                        size="medium"
                        width="auto"
                        onClick={() => confirm()}
                      >
                        Unresolve
                      </Button>
                    )}
                  </>
                ) : <></>)}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

CustomMarker.propTypes = {
  markerProps: PropTypes.object,
  markers: PropTypes.array,
  markerSetState: PropTypes.func,
}

export default CustomMarker
