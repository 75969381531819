/* eslint-disable import/prefer-default-export */
export function getDeadline(dueDate, pastDeadline, isLate){
  const absDate = {
    ...dueDate,
    years: Math.abs(dueDate.years),
    months: Math.abs(dueDate.months),
    days: Math.abs(dueDate.days),
    hours: Math.abs(dueDate.hours),
    minutes: Math.abs(dueDate.minutes),
  }
  const value = absDate.years > 0 ? absDate.years : absDate.months > 0 ? absDate.months : absDate.days > 0
  ? absDate.days : absDate.hours > 0 ? absDate.hours : absDate.minutes > 0 ? absDate.minutes : ''
  const unit = absDate.years > 0 ? 'year/s' : absDate.months > 0 ? 'month/s' : absDate.days > 0
  ? 'day/s' : absDate.hours > 0 ? 'hour/s' : absDate.minutes > 0 ? 'minute/s' : ''

  if (value && unit){
    if (pastDeadline){
      return `${Math.round(value)} ${unit} ${isLate ? 'Late' : 'Overdue'}`
    } return `Due in ${Math.round(value)} ${unit}`
  } return ''
}
