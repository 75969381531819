import React from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import {
  Box, Grid, ListItem, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

import useBlockout from '@hooks/useBlockout'

const BlockoutItem = (props) => {
  const { blockout } = props

  const {
    callbacks: {
      createOrEditCurrentBlockout,
    },
  } = useBlockout(blockout)

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="row"
      onClick={createOrEditCurrentBlockout}
      showAngleIcon
    >
      <Grid gridTemplateColumns="minmax(130px, 150px) minmax(30px, 75px) minmax(130px, 1fr)">
        <Box flexDirection="column">
          <Text fontSize="small" marginBottom="small">
            {DateTime.fromISO(blockout.start, { zone: 'utc' }).toFormat('LLLL dd, y')}
          </Text>
          <Text fontSize="xsmall" color="bodyFontLightColor">
            {DateTime.fromISO(blockout.start, { zone: 'utc' }).toFormat('hh:mm a')}
          </Text>
        </Box>
        <Box flexDirection="column" justifyContent="center">
          <FontAwesomeIcon icon={faArrowRight} size="sm" />
        </Box>
        <Box flexDirection="column">
          <Text fontSize="small" marginBottom="small">
            {DateTime.fromISO(blockout.finish, { zone: 'utc' }).toFormat('LLLL dd, y')}
          </Text>
          <Text fontSize="xsmall" color="bodyFontLightColor">
            {DateTime.fromISO(blockout.finish, { zone: 'utc' }).toFormat('hh:mm a')}
          </Text>
        </Box>
      </Grid>
    </ListItem>
  )
}

BlockoutItem.propTypes = {
  blockout: PropTypes.object.isRequired,
}

export default BlockoutItem
