import React from 'react'
import { Link } from 'react-router-dom'
import { faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  Columns,
  DashboardModule,
  ModuleBlankState,
  PageHeader,
} from '@campaignhub/suit-theme'

import TasksImageUrl from '@components/assets/tasks.svg'

const Overview = () => (
  <>
    <PageHeader
      activeTabBarItemKey="dashboard"
      boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
      tabBarItems={[
          {
            href: '/',
            icon: faHome,
            key: 'dashboard',
            title: 'Dashboard',
          },
        ]}
      title="Dashboard"
    />

    <Box paddingX="large" paddingTop={[112, 105]}>
      <Columns
        boxProps={{ marginTop: 'large' }}
        flexDirection={['column', 'column', 'row']}
      >
        <Columns.Main>
          <DashboardModule
            headerRight={(
              <DashboardModule.Link href="#/">
                Module Link
              </DashboardModule.Link>
              )}
            title="Module"
          >
            <ModuleBlankState imageUrl={TasksImageUrl}>
              <ModuleBlankState.Title>
                No Tasks to Display
              </ModuleBlankState.Title>

              <ModuleBlankState.Paragraph>
                Tasks are created when you assign a workflow to a campaign.
                Workflow Tasks can be located in the campaign menu.
              </ModuleBlankState.Paragraph>
            </ModuleBlankState>
          </DashboardModule>

          <DashboardModule
            headerRight={(
              <DashboardModule.Link href="#/">
                Module Link
              </DashboardModule.Link>
              )}
            title="Module"
          >
            <ModuleBlankState imageUrl={TasksImageUrl}>
              <ModuleBlankState.Title>
                No Tasks to Display
              </ModuleBlankState.Title>

              <ModuleBlankState.Paragraph>
                Tasks are created when you assign a workflow to a campaign.
                Workflow Tasks can be located in the campaign menu.
              </ModuleBlankState.Paragraph>
            </ModuleBlankState>
          </DashboardModule>
        </Columns.Main>

        <Columns.Sidebar>
          <DashboardModule title="Actions">
            <Box flexDirection="column" padding="large">
              <Link to="/logout" reloadDocument style={{ textDecoration: 'none' }}>
                <Button buttonStyle="secondary" size="medium">
                  Log Out
                </Button>
              </Link>
            </Box>
          </DashboardModule>
        </Columns.Sidebar>
      </Columns>
    </Box>
  </>
  )

export default Overview
