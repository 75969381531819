import React from 'react'
import PropTypes from 'prop-types'
import isMobileBrowser from 'is-mobile'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-light-svg-icons'

import { Box } from '@campaignhub/suit-theme'
import calculateContentOffset from '../../utils/calculateContentOffset'

import DropdownLink from './components/DropdownLink'
import LoggedInUser from './components/LoggedInUser'
import Notifications from './components/Notifications'
import PlayPauseTime from './components/PlayPauseTime'
import Result from './components/Result'
import Search from './components/Search'
import TimeTracker from './components/TimeTracker'
import TopBarButton from './components/TopBarButton'

const isMobile = isMobileBrowser()

const topBarHeight = 55

const TopBar = (props) => {
  const {
    callbacks: { toggleNavigation },
    loggedInUserComponent,
    nestedNavigation,
    offset,
    searchComponent,
    timeTrackerComponent,
    notificationComponent,
  } = props

  const contentOffset = calculateContentOffset(offset, { nestedNavigation })

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={11}
      backgroundColor="white"
      borderBottom="1px solid"
      borderBottomColor="lineColor"
      style={{
        left: contentOffset,
        width: `calc(100% - ${contentOffset || 0}px)`,
        height: topBarHeight,
      }}
    >
      {isMobile && (
        <TopBarButton
          icon={<FontAwesomeIcon icon={faBars} />}
          onClick={() => toggleNavigation()}
        />
      )}

      {searchComponent}

      <Box display="flex" width="auto">
        {timeTrackerComponent}

        {notificationComponent}

        {loggedInUserComponent}
      </Box>
    </Box>
  )
}

TopBar.propTypes = {
  callbacks: PropTypes.shape({
    toggleNavigation: PropTypes.func,
  }),
  loggedInUserComponent: PropTypes.node,
  nestedNavigation: PropTypes.bool,
  offset: PropTypes.object,
  searchComponent: PropTypes.node,
  timeTrackerComponent: PropTypes.node,
  notificationComponent: PropTypes.node,
}

TopBar.defaultProps = {
  callbacks: {},
  offset: {},
}

TopBar.DropdownLink = DropdownLink
TopBar.LoggedInUser = LoggedInUser
TopBar.Result = Result
TopBar.Search = Search
TopBar.topBarHeight = topBarHeight
TopBar.timeTracker = TimeTracker
TopBar.playPauseTimeComponent = PlayPauseTime
TopBar.Notifications = Notifications

export default TopBar
