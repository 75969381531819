import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates, useThunkDispatch } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'
import * as clientActions from '@redux/modules/clientV3'

const watchEntityKeys = ['clientsV3']

const importClients = (dispatch) => {
  const { importClientsV3: createFn } = clientActions
  return dispatch(createFn({}))
}

function useClientsV3(options = {}){
  const {
    searchFilter,
    reset,
    selectedClassification,
    listCount,
  } = options

  const {
    updatedEntities: { clientsV3: clientsV3UpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { clientsV3 } = useSelector(reduxState => reduxState.entities)

  let filteredClientsV3 = useMemo(() => {
    const filtered = Object.values(clientsV3)
      .filter(client => ((client.name.toLowerCase().includes(searchFilter.toLowerCase()))
        && (!selectedClassification?.length || selectedClassification?.some(x => client.classification?.includes(x)))))

    return sortArrayBy(filtered, 'asc', 'name')
  }, [clientsV3UpdatedAt, options])

  filteredClientsV3 = reset ? filteredClientsV3.slice(0, 50) : filteredClientsV3.slice(0, listCount)

  const hasfilteredClients = !!filteredClientsV3.length

  const entityReducer = useSelector(reduxState => reduxState.clientsV3)
  const { loading } = entityReducer

  const dispatch = useThunkDispatch()

  return {
    clientsV3,
    filteredClientsV3,
    hasfilteredClients,
    loading,
    callbacks: {
      importClients: () => importClients(dispatch),
    },
  }
}

export default useClientsV3
