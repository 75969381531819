import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/zoneV3/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/zoneV3/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/zoneV3/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/zoneV3/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/zoneV3/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/zoneV3/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/zoneV3/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/zoneV3/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/zoneV3/UPDATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/zoneV3/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/zoneV3/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/zoneV3/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadZonesV3(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v3/zones', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.results, Schemas.ZONEV3_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createZoneV3(zone, options = {}){
  const config = {
    method: 'POST',
    data: zone,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v3/zones', options, config)
      .then((data) => {
        const normalizedJson = normalize(data.data, Schemas.ZONEV3)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateZoneV3(zone, options = {}){
  const config = {
    method: 'PUT',
    data: zone,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/v3/zones/${zone.id}`, options, config)
      .then(({ data }) => {
        const updatedData = data
        if (!updatedData.flags){
          updatedData.flags = null
        }

        const normalizedJson = normalize(updatedData, Schemas.ZONEV3)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())
        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteZoneV3(zone){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/v3/zones/${zone.id}`, { permanent: true }, config)
      .then(({ data }) => {
        const normalizedJson = normalize(zone, Schemas.ZONEV3)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, result: errors }
      })
    return promise
  }
}

export function updateZoneClientsV3(zone, options){
  const config = {
    method: 'PUT',
    data: zone.linkedClients,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/v3/zones/${zone.id}/clients`, options, config)
      .then(({ data }) => {
        dispatch(updateSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateZoneProvidersV3(zone, options){
  const config = {
    method: 'PUT',
    data: zone.linkedProviders,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/v3/zones/${zone.id}/providers`, options, config)
      .then(({ data }) => {
        dispatch(updateSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type, payload, errors } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload.continuationToken,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors,
      }
    default:
      return state
  }
}
