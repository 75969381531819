/* eslint-disable consistent-return */
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import { storageAccountName } from '@functions/api'

const initializeUppy = (options) => {
  const {
    allowedFileTypes,
    callbacks,
    fileServiceContainer,
    maxFileSize,
    requestOptions = {},
    loadOptions,
  } = options

  const { sasToken, container, id: fileServiceContainerId } = fileServiceContainer || {}
  const { asset, asset: { file: { filePath }, version } } = requestOptions
  const { updateDirectFn } = callbacks

  const updateDirect = async (file) => {
    const { meta: { blobUrl, fileName } } = file

    const payload = {
      id: asset.id,
      description: 'new upload',
      fileServiceContainerId,
      filePath,
      fileName,
      blobUrl,
    }

    await updateDirectFn(payload, loadOptions).then(({ success, errors }) => {
      if (!success && errors){
        toast.warn(errors[0])
      }
    })
  }

  const uppy = new Uppy({
    id: 'uppyUpload',
    debug: false,
    autoProceed: true,
    restrictions: {
      allowedFileTypes,
      maxFileSize,
      maxNumberOfFiles: 1,
    },
  })
    .use(XHRUpload, {
      method: 'PUT',
      formData: false,
      fieldName: 'FormFile',
      getResponseError(){ },
      timeout: 0,
    })
    .on('restriction-failed', (file, error) => {
      toast.warning(`${error}.  File: ${file.name}`)
    })
    .on('file-added', (file) => {
      const fileName = file.meta.name.replace(/(\.[^.]+$)/, `_v${version + 1}$1`)
      const blobUrl = encodeURI(`https://${storageAccountName}.blob.core.windows.net/${container}/${filePath}/${fileName}`)
        uppy.setFileMeta(file.id, {
          ...requestOptions,
          fileServiceContainerId,
          filePath,
          fileName,
          blobUrl,
        })

        uppy.setFileState(file.id, {
          xhrUpload: {
            ...file.xhrUpload,
            endpoint: `${blobUrl}?${sasToken}`,
            headers: {
              'x-ms-blob-type': 'BlockBlob',
              'x-ms-blob-content-type': file.meta.type,
            },
          },
        })
    })
    .on('upload-error', (file) => {
      toast.warning(`Failed to upload ${file.name}`)
    })
    .on('complete', async (files) => {
      if (files.successful.length) await updateDirect(files.successful[0])
      uppy.reset()
    })

  return uppy
}

const checkRequiredOptions = (options = {}) => {
  const requiredOptions = ['allowedFileTypes', 'requestOptions', 'fileServiceContainer']

  return requiredOptions.every(key => Object.keys(options).includes(key))
}

function useUppyUploadVersion(options = {}){
  const uppy = useMemo(() => {
    if (checkRequiredOptions(options)){
      return initializeUppy(options)
    }
  })

  useEffect(() => () => (uppy ? uppy.close() : null))

  return uppy
}

export default useUppyUploadVersion
