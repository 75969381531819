import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Button, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import ZoneDetails from '@components/ZoneDetails'

import { useZoneForm } from '@hooks/useZoneV3'
import useReduxAction from '@hooks/useReduxAction'

import removeKeyWithEmptyValue from '@functions/removeKeyWithEmptyValue'

const defaultState = {
  customErrors: [],
  linkedClients: [],
  linkedProviders: [],
  refresh: false,
}

const Load = (props) => {
  const { zoneId } = props

  const options = {
    size: -1,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('usersV3', 'loadZoneProvidersV3', options, [zoneId], {
    dispatchAction: (action, requestOptions) => action(zoneId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { errors } = entityReducer
      return !errors.length
    },
  })

  return null
}

const confirmDelete = (props, deleteFn) => {
  const { callbacks: { deleteZone } } = props

  swal.fire({
    title: 'Delete Zone?',
    text: 'Are you sure? ',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it.',
    confirmButtonColor: '#DD6B55',
  })
  .then(({ value }) => {
    if (value){
      deleteZone(deleteFn)
    }
  })
}

const updateZoneDetails = (props, zoneParams, updateFn,
  linkedClients, linkedProviders, updateZoneClientsFn, updateZoneProvidersFn) => {
  const { callbacks: { updateZone } } = props
  updateZone(zoneParams, updateFn, linkedClients, linkedProviders, updateZoneClientsFn, updateZoneProvidersFn)
}

const EditZoneModal = (props) => {
  const [state, setState] = useSetState(defaultState)
  const { customErrors, linkedClients, linkedProviders, refresh } = state
  const { callbacks, modalKey, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: {
      deleteZone: deleteFn,
      updateZone: updateFn,
      updateZoneClients: updateZoneClientsFn,
      updateZoneProviders: updateZoneProvidersFn,
    },
    zone,
  } = modalPayload

  const { updating } = useSelector(reduxState => reduxState.zonesV3)

  const zoneFormPayload = useZoneForm(zone)
  const { entityState, errors } = zoneFormPayload

  const { result: { linkedProviders: updatedLinkedProviders } } = useSelector(reduxState => reduxState.usersV3)

  const save = () => {
    if (Object.keys(errors).length > 0 || Object.keys(customErrors).length > 0){
      toast.warn('One or more fields did not match the validation.')
      return
    }

    updateZoneDetails(props, removeKeyWithEmptyValue(entityState), updateFn,
      linkedClients, linkedProviders, updateZoneClientsFn, updateZoneProvidersFn)
  }

  useEffect(() => {
    setState({ refresh: true })
  }, [])

  useEffect(() => {
    if (updatedLinkedProviders && updatedLinkedProviders.length > 0){
      setState({ linkedProviders: updatedLinkedProviders })
    }
    setState({ refresh: true })
  }, [updatedLinkedProviders])

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="large">
      <SidebarModal.Header callbacks={callbacks} title="Edit" titleSecondLine="Zone" />
      <SidebarModal.Content>
        <ZoneDetails
          zoneForm={zoneFormPayload}
          customErrors={customErrors}
          callbacks={{
            setCustomErrors: val => setState({ customErrors: val }),
            toggleUpdateLinkedClients: clients => setState({ linkedClients: clients }),
            toggleUpdateLinkedProviders: providers => setState({ linkedProviders: providers }),
          }}
          linkedClients={linkedClients}
          linkedProviders={linkedProviders}
        />
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          onClick={() => confirmDelete(props, deleteFn)}
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Delete Zone
        </Button>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          onClick={() => save()}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Save
        </Button>
      </SidebarModal.Footer>
      {refresh && <Load zoneId={zone.id} />}
    </SidebarModal>
  )
}

EditZoneModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditZoneModal.defaultProps = {
  modalKey: 'EditZoneModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditZoneModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
