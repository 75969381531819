import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState from '@models/resource'

import * as resourceActions from '@redux/modules/resource'

import PageContext from '@contexts/pageContext'

const updateResource = (resourceParams, dispatch, requestOptions) => {
  const { updateResource: updateFn } = resourceActions

  return dispatch(updateFn(resourceParams, requestOptions))
}

const launchEditResourceDetailModal = (resource, dispatch, showEditResourceDetailModal) => new Promise((resolve, reject) => {
  if (showEditResourceDetailModal){
    const payload = {
      callbacks: {
        updateResource: (updatedParams, requestOptions) => updateResource(updatedParams, dispatch, requestOptions),
      },
      resource,
    }

    showEditResourceDetailModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditResourceDetailModal not defined in PageContext callbacks'))
})

export function useResourceForm(resource){
  const resourceEntity = { ...resource, ...{ displayName: resource.file.displayName } }
  const resourceForm = useForm(defaultState, { entity: resourceEntity }, [resource.id])

  return {
    ...resourceForm,
  }
}

function useResource(initResource){
  const { entity: resource } = useLatestEntity(initResource, 'resources')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showEditResourceDetailModal } = callbacks || {}

  const { loading, updating } = useSelector(reduxState => reduxState.resources)

  return {
    resource,
    callbacks: {
      launchEditResourceDetailModal: () => launchEditResourceDetailModal(resource, dispatch, showEditResourceDetailModal),
      updateResource: (resourceParams, requestOptions) => updateResource(resourceParams, dispatch, requestOptions),
    },
    loading,
    updating,
  }
}

export default useResource
