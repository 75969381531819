import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'
import {
  Box, Button, Columns, DashboardModule, PageHeader, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons'

import removeKeyWithEmptyValue from '@functions/removeKeyWithEmptyValue'
import ClientDetails from '@components/ClientDetails'
import useClientV3, { useClientForm } from '@hooks/useClientV3'

const defaultState = {
  customErrors: [],
}

const goBack = (navigate) => {
  navigate(-1)
}

const confirmRedirect = (navigate) => {
  swal
    .fire({
      title: 'Leave Client Management Details?',
      text: 'You have unsaved changes. Are you sure you want to go back? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        goBack(navigate)
      }
    })
}

const updateClient = (entityParams, updateFn, navigate) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Client has been updated!')
    goBack(navigate)
  })
}

const createClient = (entityParams, createFn, navigate) => {
  createFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Client has been created!')
    goBack(navigate)
  })
}

const confirmDelete = (user, deleteFn, navigate) => {
  swal
    .fire({
      title: 'Delete Client?',
      text: 'Are you sure? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn(user).then(({ success, errors }) => {
          if (!success && errors){
            toast.warn(errors[0])
            return
          }
          toast('Client has been deleted!')
          goBack(navigate)
        })
      }
    })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    ClientDetail: {
      setCustomErrors: val => setState({ customErrors: val }),
    },
  }

  return componentCallbacks[component] || {}
}
const ClientEdit = (props) => {
  const { isNew } = props
  const [state, setState] = useSetState(defaultState)
  const { customErrors } = state

  const { clientId } = useParams()
  const { clientsV3 } = useSelector(reduxState => reduxState.entities)
  const client = clientsV3[clientId] || {}

  const navigate = useNavigate()

  const clientForm = useClientForm(client)
  const {
    entityState,
    entityState: {
      id,
    },
    saveEnabled,
    initialValue,
  } = clientForm

  const clientPayload = useClientV3(client)
  const {
    callbacks: {
      updateClient: updateFn,
      createClient: createFn,
      deleteClient: deleteFn,
    },
    updating,
    creating,
  } = clientPayload

  const save = () => {
    if (Object.keys(customErrors).length > 0){
      toast.warn('One or more fields did not match the validation.')
      return
    }

    if (isNew){
      createClient(removeKeyWithEmptyValue(entityState), createFn, navigate)
    } else {
      updateClient(entityState, updateFn, navigate)
    }
  }

  const checkRedirect = () => {
    let existingChanges = false
    const fields = Object.keys(entityState)
    fields.forEach((key) => {
      if (entityState[key] !== initialValue[key] && typeof entityState[key] === 'string'){
        existingChanges = true
      } else if (typeof entityState[key] === 'object' && !JSON.stringify(entityState[key]) === JSON.stringify(initialValue[key])){
        existingChanges = true
      }
    })
    if (existingChanges){
      confirmRedirect(navigate)
    } else {
      goBack(navigate)
    }
  }

  return (
    <>
      <PageHeader
        activeTabBarItemKey="clients"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        title="Client Management"
      />
      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            {!isNew && (
              <Text marginTop="large" marginBottom="large">
                Client does not exists.
              </Text>
            )}
            {isNew && (
              <ClientDetails
                clientForm={clientForm}
                customErrors={customErrors}
                callback={callbacks('ClientDetail', setState)}
              />
            )}
          </Columns.Main>
          <Columns.Sidebar>
            <DashboardModule title="Actions">
              <Box flexDirection="column" padding="large">
                <Box padding="small" marginTop="small">
                  <Button
                    buttonStyle="secondaryUtility"
                    icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                    size="medium"
                    onClick={() => checkRedirect()}
                  >
                    Back
                  </Button>
                </Box>
                {!isNew && (
                  <Box padding="small">

                    <Button
                      buttonStyle="secondaryUtility"
                      icon={<FontAwesomeIcon icon={faTrashAlt} />}
                      size="medium"
                      onClick={() => confirmDelete(entityState, deleteFn, navigate)}
                      disabled={isNew || (!isNew && id === null)}
                    >
                      Delete Client
                    </Button>

                  </Box>
                )}
                <Box padding="small" marginBottom="small">
                  <Button
                    buttonStyle="primaryCreate"
                    size="medium"
                    disabled={!isNew && id === null && !saveEnabled}
                    loading={isNew ? creating : updating}
                    onClick={() => save()}
                  >
                    {isNew ? 'Create' : 'Save'}
                  </Button>
                </Box>
              </Box>
            </DashboardModule>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

ClientEdit.propTypes = {
  isNew: PropTypes.bool,
}

export default ClientEdit
