import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import { useSelector } from 'react-redux'

import * as serviceJobDetailActions from '@redux/modules/serviceJobDetail'

// ToDo: next refactor, use CRUD instead of this custom route
const assignServiceJobDetailDusk = (serviceJobDetailParam, dispatch, requestOptions) => {
  const { assignServiceJobDetailDusk: createFn } = serviceJobDetailActions

  return dispatch(createFn(serviceJobDetailParam, requestOptions))
}

const updateServiceJobDetail = (serviceJobDetailParam, dispatch, requestOptions) => {
  const { updateServiceJobDetail: updateFn } = serviceJobDetailActions

  return dispatch(updateFn(serviceJobDetailParam, requestOptions))
}

const useServiceJobDetail = (initServiceJobDetail = {}) => {
  const { entity: serviceJobDetail } = useLatestEntity(initServiceJobDetail, 'serviceJobDetails')

  const dispatch = useThunkDispatch()

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.serviceJobDetails)

  return {
    callbacks: {
      assignServiceJobDetailDusk: (serviceJobDetailParam, requestOptions) => assignServiceJobDetailDusk(serviceJobDetailParam, dispatch, requestOptions),
      updateServiceJobDetail: (serviceJobDetailParam, requestOptions) => updateServiceJobDetail(serviceJobDetailParam, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    serviceJobDetail,
    updating,
  }
}

export default useServiceJobDetail
