export function getRequestOptions(type, serviceJobOptions, selectedResourceType){
  switch (type.toUpperCase()){
    case 'RESOURCES':
      return {
        description: '',
        sort: 0,
        ...serviceJobOptions,
        resourceTypeId: selectedResourceType,
      }
    case 'ASSETS':
      return {
        description: 'new upload',
        ...serviceJobOptions,
      }
    default:
      return null
  }
}

export function getUppyOptions(type, allowedFileTypes, serviceJobOptions, callbacks, selectedResourceType, filesToUploadCount, fileServiceContainer, currentUserId){
  const requestOptions = getRequestOptions(type, serviceJobOptions, selectedResourceType)
  switch (type.toUpperCase()){
    case 'RESOURCES':
      return {
        allowedFileTypes,
        callbacks,
        currentUserId,
        fileServiceContainer,
        loadOptions: {
          includes: ['file'].join(','),
        },
        maxFileSize: 0,
        requestOptions,
        type,
        validateFileCount: filesToUploadCount,
      }
    case 'ASSETS':
      return {
        allowedFileTypes,
        callbacks,
        currentUserId,
        fileServiceContainer,
        loadOptions: {
          includes: ['comments', 'file', 'histories'].join(','),
        },
        maxFileSize: 0,
        requestOptions,
        type,
        validateFileCount: 0,
      }
    default:
      return {
        fileServiceContainer,
      }
  }
}

export function getComponentCallbacks(component, setState){
  const componentsCallbacks = {
    UPPY: {
      addRecentlyUploadedBatch: (uploadedList, failedList) => setState({ lastUploadedBatch: uploadedList, lastFailedBatch: failedList }),
      doneUpload: status => setState({ doneUpload: status }),
      setFilesToUploadCount: count => setState({ filesToUploadCount: count }),
    },
    RECENTLYUPLOADEDLIST: {
      removeRecentlyDeleted: id => setState({ lastDeletedId: id }),
    },
  }

  return componentsCallbacks[component.toUpperCase()] || {}
}
