import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Button, ListItem } from '@campaignhub/suit-theme'

import VideoServiceTypeFilter from './components/VideoServiceTypeFilter'

const Tools = () => (
  <ListItem
    boxProps={{
      borderBottom: '1px solid',
      padding: 'large',
    }}
    flexDirection="column"
    disableHover
  >
    <Box flexDirection="row">
      <Box flexDirection="column">
        <VideoServiceTypeFilter />
        <Link to="authorize" reloadDocument style={{ textDecoration: 'none' }}>
          <Button buttonStyle="secondary" size="medium">
            Add New Channel
          </Button>
        </Link>
      </Box>
    </Box>
  </ListItem>
  )

Tools.propTypes = {}

export default Tools
