import {
  faCalendar, faList, faTachometer, faUser,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = ({ toggleNavigate }, showProviderDiary) => ({
    sections: [
      {
        key: 'dashboard',
        visible: true,
        items: [
          {
            icon: faTachometer,
            key: 'dashboard',
            onClick: () => toggleNavigate('/'),
            title: 'Overview',
            visible: true,
          },
        ],
      },
      {
        key: 'provider',
        title: 'Provider',
        visible: true,
        items: [
          {
            icon: faList,
            key: 'jobs',
            onClick: () => toggleNavigate('/service-jobs'),
            title: 'Jobs',
            visible: true,
          },
          {
            ...showProviderDiary && {
              icon: faUser,
              key: 'provider-diary',
              title: 'Provider Diary',
              visible: true,
              items: [
                {
                  icon: faCalendar,
                  key: 'availability',
                  onClick: () => toggleNavigate('/provider-diary/availability'),
                  title: 'Availability',
                  visible: true,
                },
              ],
            },
          },
        ],
      },
    ],
  })

export default generateMainNavigationItems
