import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import {
  Box, Columns, DashboardModule, LoadingModule, ModalContext, PageHeader,
} from '@campaignhub/suit-theme'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'
import useUsersV3 from '@hooks/useUsersV3'

import EditUserModal from '@modals/EditUserModalV3'

import blankState from '@components/assets/blankState.svg'
import custom from '@styles/custom.module.scss'
import Tools from './components/Tools'
import User from './components/User'

const deleteUser = (deleteFn, setState) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    setState({ showEditUserModal: false })
  })
}

const updateUser = (userParams, updateFn, setState) => {
  updateFn(userParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditUserModal: false })
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    EditUserModal: {
      closeModal: () => setState({ showEditUserModal: false }),
      deleteUser: deleteFn => deleteUser(deleteFn, setState),
      updateUser: (userParams, updateFn) => updateUser(userParams, updateFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const RefreshUsers = (props) => {
  const { continuationToken, setState } = props

  useReduxAction('usersV3', 'loadUsersV3', new URLSearchParams([
    ['size', '50'],
    ['continuationToken', continuationToken],
    ['orderBy', '{ \'name\': \'asc\'}'],
  ]), [continuationToken])

  useEffect(() => {
    setState({ refresh: false, reset: false })
  }, [])

  return null
}

const defaultState = {
  searchFilter: '',
  selectedRole: [],
  selectedService: [],
  selectedEmployment: [],
  showEditUserModal: false,
  refresh: false,
  reset: false,
  listCount: 50,
  defaultCount: 50,
}

const UserList = () => {
  const [state, setState] = useSetState(defaultState)
  const { selectedRole, searchFilter, showEditUserModal,
    selectedService, selectedEmployment, reset, refresh, listCount, defaultCount } = state

  const searchFilterString = `"name":"${searchFilter}%"`
  // ToDo: cannot add services filter for other roles
  const serviceString = `"provider.services": ["${selectedService.join('" , "')}"]`
  const roleString = `"roles": ["${selectedRole.join('" , "')}"]`
  const employeeString = `"employment": ["${selectedEmployment.join('" , "')}"]`

  const urlComponent = (`{${searchFilter.length > 0 ? `${searchFilterString},` : ''}
                        ${selectedService.length > 0 ? `${serviceString},` : ''}
                        ${selectedRole.length > 0 ? `${roleString},` : ''}
                        ${selectedEmployment.length > 0 ? `${employeeString},` : ''}}`).replace(/\s+/g, '')

  const options = {
    filter: (urlComponent !== '{}') ? urlComponent : '',
    size: defaultCount,
    orderBy: '{ \'name\': \'asc\'}',
  }

  const { loading } = useReduxAction('usersV3', 'loadUsersV3', options, [searchFilter, selectedService, selectedRole, selectedEmployment])

  const entityReducer = useSelector(reduxState => reduxState.usersV3)
  const { result: { continuationToken } } = entityReducer

  const { filteredUsersV3 } = useUsersV3({
    searchFilter,
    selectedService,
    selectedRole,
    selectedEmployment,
    reset,
    listCount,
  })

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const updateSearchFilter = (val) => {
    setState({ searchFilter: val })
  }

  const updateSelectedService = (checked, val) => {
    if (checked){
      setState({ selectedService: [...selectedService, val] })
    } else {
      setState({ selectedService: selectedService.filter(i => i !== val) })
    }
  }

  const updateSelectedRole = (checked, val) => {
    if (checked){
      setState({ selectedRole: [...selectedRole, val] })
    } else {
      setState({ selectedRole: selectedRole.filter(i => i !== val) })
    }
  }

  const updateSelectedEmployment = (checked, val) => {
    if (checked){
      setState({ selectedEmployment: [...selectedEmployment, val] })
    } else {
      setState({ selectedEmployment: selectedEmployment.filter(i => i !== val) })
    }
  }

  const resetFilter = () => {
    setState({ searchFilter: '', selectedRole: [], selectedService: [], selectedEmployment: [], reset: true, listCount: defaultCount })
  }

  const loadMore = () => {
    setState({ refresh: true, listCount: listCount + defaultCount })
  }

  const pageContext = {
    callbacks: {
      showEditUserModal: (payload) => {
        setModalData('EditUserModal', payload)
        setState({ showEditUserModal: true })
      },
      toggleResetFilter: () => resetFilter(),
      toggleSelectedService: (checked, val) => updateSelectedService(checked, val),
      toggleSelectedRole: (checked, val) => updateSelectedRole(checked, val),
      toggleSelectedEmployment: (checked, val) => updateSelectedEmployment(checked, val),
      loadMore,
      searchNameFilter: val => updateSearchFilter(val),
    },
    selectedService,
    selectedRole,
    selectedEmployment,
    searchFilter,
    refresh,
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          activeTabBarItemKey="users"
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          title="User Management"
        />

        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns
            boxProps={{ marginTop: 'large' }}
            flexDirection={['column', 'column', 'row']}
          >
            <Columns.Main>
              <DashboardModule title="Users">
                <Box flexDirection="column" className={custom.scroll}>
                  <Box flexDirection="column" maxHeight="75vh" overflowY="auto">
                    {filteredUsersV3.length > 0 && filteredUsersV3.map(user => (
                      <User key={user.id} user={user} />
                    ))}
                    <LoadingModule loading={loading} boxProps={{ border: 0 }} />
                  </Box>
                  {filteredUsersV3.length > 0 && (
                    <DashboardModule.LoadMoreFooter
                      callbacks={{ loadMore: () => loadMore() }}
                      canLoadMore={continuationToken !== null && filteredUsersV3.length >= listCount}
                      entityCount={filteredUsersV3?.length}
                    />
                  )}
                  {!loading && !filteredUsersV3.length && (
                    <DashboardModule.BlankState imageUrl={blankState}>
                      <DashboardModule.BlankState.Title>No Users Available</DashboardModule.BlankState.Title>
                      <DashboardModule.BlankState.Paragraph>
                        Try adjusting your filters.
                      </DashboardModule.BlankState.Paragraph>
                    </DashboardModule.BlankState>
                  )}
                  {refresh && (
                    <RefreshUsers continuationToken={continuationToken} setState={setState} />
                  )}
                </Box>
              </DashboardModule>
            </Columns.Main>
            <Columns.Sidebar>
              <DashboardModule title="Tools">
                <Tools />
              </DashboardModule>
            </Columns.Sidebar>
          </Columns>
        </Box>
        <EditUserModal
          callbacks={callbacks('EditUserModal', setState)}
          showModal={showEditUserModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default UserList
