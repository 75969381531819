import React from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, DashboardModule, ListItem, Toggle,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import ClassificationFilter from '@components/ToolsFilter/components/ClassificationFilter'
import ClientFilter from '@components/ToolsFilter/components/ClientFilter'
import DateFilter from '@components/ToolsFilter/components/DateFilter'
import FilterByLimit from '@components/ToolsFilter/components/FilterByLimit'

const defaultState = {
  expanded: true,
}

const JobListFilter = (props) => {
  const {
    jobState, setJobState,
  } = props

  const { hideArchivedJobs, hideDoneJobs } = jobState

  const [state, setState] = useSetState(defaultState)
  const { expanded } = state

  const resetAllFilters = () => {
    setJobState({
      classificationFilter: [],
      clientFilter: [],
      dateFilter: {
        filterEnd: '',
        filterStart: '',
      },
      filterLimit: 50,
      statusFilter: [],
    })
  }

  return (
    <DashboardModule
      boxProps={{ marginBottom: 'large' }}
      title="Filters"
      headerRight={(
        <Box
          onClick={() => setState({ expanded: !expanded })}
          style={{ cursor: 'pointer' }}
          textAlign="end"
          width="auto"
        >
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </Box>
      )}
    >
      <Box flexDirection="column">
        {expanded && (
          <>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="column"
              disableHover
            >
              <Box marginBottom="medium">
                <Toggle
                  onClick={() => setJobState({ hideDoneJobs: !hideDoneJobs })}
                  toggleOffColor="bodyFontColor"
                  toggleOffText="Show Done Jobs"
                  toggleOn={!hideDoneJobs}
                  toggleOnText="Show Done Jobs"
                />
              </Box>
              <Box>
                <Toggle
                  onClick={() => setJobState({ hideArchivedJobs: !hideArchivedJobs })}
                  toggleOffColor="bodyFontColor"
                  toggleOffText="Show Archived Jobs"
                  toggleOn={!hideArchivedJobs}
                  toggleOnText="Show Archived Jobs"
                />
              </Box>
            </ListItem>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="column"
              disableHover
            >
              <DateFilter setJobState={setJobState} jobState={jobState} />
              <ClientFilter setJobState={setJobState} jobState={jobState} />
              <ClassificationFilter setJobState={setJobState} jobState={jobState} />
            </ListItem>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="column"
              disableHover
            >
              <FilterByLimit setJobState={setJobState} jobState={jobState} />
            </ListItem>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="column"
              disableHover
            >
              <Button
                buttonStyle="secondary"
                size="medium"
                onClick={() => resetAllFilters()}
              >
                Reset All Filters
              </Button>
            </ListItem>
          </>
        )}
      </Box>
    </DashboardModule>
  )
}

JobListFilter.propTypes = {
  jobState: PropTypes.object,
  setJobState: PropTypes.func,
}

export default JobListFilter
