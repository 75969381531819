import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const UPDATE_REQUEST = 'contenthouse/serviceJobChecklistItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/serviceJobChecklistItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/serviceJobChecklistItem/UPDATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
}

// Actions
export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function updateServiceJobChecklistItem(checklist, options){
  const config = {
    method: 'PUT',
    data: checklist,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/v1/service-job-checklist-items/${checklist.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.SERVICE_JOB_CHECKLIST_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
