import { useSelector } from 'react-redux'
import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import * as assetCommentActions from '@redux/modules/assetComment'

const createAssetComment = (assetCommentParam, dispatch, requestOptions) => {
  const { createAssetComment: createFn } = assetCommentActions

  return dispatch(createFn(assetCommentParam, requestOptions))
 }

const updateAssetComment = (assetCommentParam, dispatch, requestOptions) => {
  const { updateAssetComment: updateFn } = assetCommentActions

  return dispatch(updateFn(assetCommentParam, requestOptions))
}

const deleteAssetComment = (assetCommentParam, dispatch, requestOptions) => {
  const { deleteAssetComment: deleteFn } = assetCommentActions

  return dispatch(deleteFn(assetCommentParam, requestOptions))
}

const useAssetComment = (initAssetComment = {}) => {
  const { entity: assetComment } = useLatestEntity(initAssetComment, 'assetComments')

  const dispatch = useThunkDispatch()

  const { creating, updating, deleting } = useSelector(reduxState => reduxState.assetComments)

  return {
    assetComment,
    callbacks: {
      createAssetComment: (assetCommentParam, requestOptions) => createAssetComment(assetCommentParam, dispatch, requestOptions),
      updateAssetComment: (assetCommentParam, requestOptions) => updateAssetComment(assetCommentParam, dispatch, requestOptions),
      deleteAssetComment: (assetCommentParam, requestOptions) => deleteAssetComment(assetCommentParam, dispatch, requestOptions),
    },
    creating,
    deleting,
    updating,
  }
}

export default useAssetComment
