import { isValidEmail } from '@functions/validations'

const state = {
  id: null,
  name: '',
  refreshToken: '',
  accessToken: '',
  channelId: '',
  emailAddress: '',
}

export const requiredFields = [
  { key: 'name' },
  { key: 'refreshToken' },
  { key: 'accessToken' },
  { key: 'channelId' },
  { key: 'emailAddress', validation: isValidEmail, invalidMessage: 'Invalid Email format' },
]

export default state
