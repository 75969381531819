const hasClass = function (elem, className){
  return new RegExp(` ${ className } `).test(` ${ elem.className } `)
}

const addClass = function (elem, className){
  if (!hasClass(elem, className)){
    elem.className += ` ${ className}`
  }
}

const removeClass = function (elem, className){
  let newClass = ` ${ elem.className.replace(/[\t\r\n]/g, ' ') } `
  if (hasClass(elem, className)){
    while (newClass.indexOf(` ${ className } `) >= 0){
      newClass = newClass.replace(` ${ className } `, ' ')
    }
    elem.className = newClass.replace(/^\s+|\s+$/g, '')
  }
}

export {
  hasClass,
  addClass,
  removeClass,
}
