import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, ListItem, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/pro-light-svg-icons'

const AvailableResources = (props) => {
  const { hidden } = props

  if (hidden) return null

  return (
    <DashboardModule title="Available Resources">
      <ListItem
        boxProps={{
          borderBottom: '1px solid',
          padding: 'large',
          lineHeight: '1.3',
        }}
        flexDirection="row"
        style={{ cursor: 'pointer' }}
      >
        <Box flexShrink="0" alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto" paddingRight="large">
          <Button
            size="medium"
            buttonStyle="ghostEdit"
          >
            Download
          </Button>
        </Box>
        <Box flexDirection="column" paddingRight="large">
          <Text fontSize="small">Hong Kong Azure - Photo Delivery</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">
            photojobs/2020-09-16/56435345_123Addressstreet/Hires
          </Text>
        </Box>
        <Box flexDirection="column" paddingRight="large">
          <Text fontSize="small">28 Files</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">
            12:00 PM, Oct 14 2020
          </Text>
        </Box>
        <FontAwesomeIcon icon={faCog} />
      </ListItem>
    </DashboardModule>
  )
}

AvailableResources.propTypes = {
  hidden: PropTypes.bool,
}

export default AvailableResources
