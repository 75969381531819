import { useSelector } from 'react-redux'
import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import * as serviceJobTagActions from '@redux/modules/serviceJobTag'

const createServiceJobTag = (serviceJobTagParam, dispatch, requestOptions) => {
  const { createServiceJobTag: createFn } = serviceJobTagActions

  return dispatch(createFn(serviceJobTagParam, requestOptions))
 }

const updateServiceJobTag = (serviceJobTagParam, dispatch, requestOptions) => {
  const { updateServiceJobTag: updateFn } = serviceJobTagActions

  return dispatch(updateFn(serviceJobTagParam, requestOptions))
}

const deleteServiceJobTag = (serviceJobTagParams, dispatch, requestOptions) => {
  const { deleteServiceJobTag: deleteFn } = serviceJobTagActions
  return dispatch(deleteFn(serviceJobTagParams, requestOptions))
}

const useServiceJobTags = (initServiceJobTag = {}) => {
  const { entity: serviceJobTag } = useLatestEntity(initServiceJobTag, 'serviceJobTags')

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobTags } = entities

  const dispatch = useThunkDispatch()

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.serviceJobTags)

  return {
    callbacks: {
      createServiceJobTag: (serviceJobTagParam, requestOptions) => createServiceJobTag(serviceJobTagParam, dispatch, requestOptions),
      deleteServiceJobTag: (serviceJobTagParams, requestOptions) => deleteServiceJobTag(serviceJobTagParams, dispatch, requestOptions),
      updateServiceJobTag: (serviceJobTagParam, requestOptions) => updateServiceJobTag(serviceJobTagParam, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    serviceJobTag,
    serviceJobTags,
    updating,
  }
}

export default useServiceJobTags
