import { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import PageContext from '@contexts/pageContext'
import defaultState, { requiredFields } from '@models/serviceCategory'
import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import * as serviceCategoryACtions from '@redux/modules/serviceCategory'

const updateServiceCategory = (serviceCategoryParams, dispatch) => {
  const { updateServiceCategory: updateFn } = serviceCategoryACtions
  return dispatch(updateFn(serviceCategoryParams, {}))
}

const launchCapacityModal = (capacity, type, showCapacityModal) => new Promise((resolve, reject) => {
  if (showCapacityModal){
    const payload = {
      capacity,
      type,
    }

    showCapacityModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showCapacityModal not defined in PageContext callbacks'))
})

export function useServiceCategoryForm(serviceCategory = {}){
  const serviceCategoryForm = useForm(defaultState, { entity: { ...defaultState, ...serviceCategory }, requiredFields, validateOn: 'change' }, [serviceCategory.id])
  const initialValue = Object.keys(serviceCategory).length === 0 ? defaultState : cloneDeep(serviceCategoryForm.entityState)
  return {
    ...serviceCategoryForm,
    initialValue,
  }
}

const useServiceCategory = (initServiceCategory = {}) => {
  const { entity: serviceCategory } = useLatestEntity(initServiceCategory, 'serviceCategories')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showCapacityModal } = callbacks || {}

  return {
    callbacks: {
      launchCapacityModal: (capacity, type) => launchCapacityModal(capacity, type, showCapacityModal),
      updateServiceCategory: serviceCategoryParams => updateServiceCategory(serviceCategoryParams, dispatch),
    },
    serviceCategory,
  }
}

export default useServiceCategory
