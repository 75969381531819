import { useContext } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState, { requiredFields } from '@models/Client/client'

import PageContext from '@contexts/pageContext'

import * as clientActions from '@redux/modules/clientV3'

const createClient = (clientParams, dispatch, requestOptions) => {
  const { createClientV3: createFn } = clientActions
  return dispatch(createFn(clientParams, requestOptions))
}

const updateClient = (clientParams, dispatch, requestOptions) => {
  const { updateClientV3: updateFn } = clientActions
  return dispatch(updateFn(clientParams, requestOptions))
}

const deleteClient = (client, dispatch) => {
  const { deleteClientV3: deleteFn } = clientActions
  return dispatch(deleteFn(client))
}

const editClient = (client, dispatch, showEditClientModal) => new Promise((resolve, reject) => {
  if (showEditClientModal){
    const payload = {
      callbacks: {
        deleteClient: () => deleteClient(client, dispatch),
        updateClient: clientParams => updateClient(clientParams, dispatch),
      },
      client,
    }

    showEditClientModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditClientModal not defined in PageContext callbacks'))
})

export function useClientForm(client = {}){
  const clientForm = useForm(defaultState, { entity: { ...defaultState, ...client }, requiredFields, validateOn: 'change' }, [client.id, client.cache_key])
  const initialValue = Object.keys(client).length === 0 ? defaultState : cloneDeep(clientForm.entityState)
  return {
    ...clientForm,
    initialValue,
  }
}

const useClientV3 = (initClient = {}) => {
  const { entity: clientV3 } = useLatestEntity(initClient, 'clientsV3')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showEditClientModal } = callbacks || {}

  const { creating, deleting, loading, updating } = useSelector(reduxState => reduxState.clientsV3)

  return {
    clientV3,
    callbacks: {
      editClient: () => editClient(clientV3, dispatch, showEditClientModal),
      updateClient: (clientParams, requestOptions) => updateClient(clientParams, dispatch, requestOptions),
      createClient: (clientParams, requestOptions) => createClient(clientParams, dispatch, requestOptions),
      deleteClient: clientParams => deleteClient(clientParams, dispatch),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useClientV3
