import React from 'react'
import PropTypes from 'prop-types'

import { Box, Image } from '@campaignhub/suit-theme'

import * as assets from './assets'

const Icons = (props) => {
  const { name, size } = props

  return (
    <Box title={name}>
      <Image
        backgroundSize="contain"
        url={assets[name?.replace(/\s+/g, '')]}
        width={size || 16}
        height={size || 16}
      />
    </Box>
  )
}

Icons.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
}

export default Icons
