import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import useReduxAction from '@hooks/useReduxAction'
import useZonesV3 from '@hooks/useZonesV3'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import {
  Box, Columns, DashboardModule, LoadingModule, ModalContext, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import EditZoneModal from '@modals/EditZoneModal'

import blankState from '@components/assets/blankState.svg'
import custom from '@styles/custom.module.scss'
import Tools from './components/Tools'
import Zone from './components/Zone'

const defaultState = {
  defaultCount: 50,
  refresh: false,
  reset: false,
  searchFilter: '',
  showEditZoneModal: false,
}

const deleteZone = (deleteFn, setState) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }
    toast('Zone has been deleted!')
    setState({ showEditZoneModal: false })
  })
}

const updateZoneClients = (entityParams, updateFn) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })
}

const updateZoneProviders = (entityParams, updateFn) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })
}

const updateZone = (zoneParams, updateFn, setState,
  linkedClients, linkedProviders, updateZoneClientsFn, updateZoneProvidersFn) => {
  updateFn(zoneParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    const clients = []
    const providers = []

    linkedClients.map(client => clients.push(client.id))
    linkedProviders.map(provider => providers.push(provider.id))

    const zoneDetails = {
      id: zoneParams.id,
      linkedClients: clients,
      linkedProviders: providers,
    }

    updateZoneClients(zoneDetails, updateZoneClientsFn)
    updateZoneProviders(zoneDetails, updateZoneProvidersFn)

    setState({ showEditZoneModal: false })
    toast('Zone has been updated!')
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    EditZoneModal: {
      closeModal: () => setState({ showEditZoneModal: false }),
      deleteZone: deleteFn => deleteZone(deleteFn, setState),
      updateZone: (zoneParams, updateFn, linkedClients, linkedProviders,
        updateZoneClientsFn, updateZoneProvidersFn) => updateZone(zoneParams, updateFn, setState,
          linkedClients, linkedProviders, updateZoneClientsFn, updateZoneProvidersFn),
    },
  }

  return componentCallbacks[component] || {}
}

const RefreshZones = (props) => {
  const { continuationToken, setState, count } = props

  const options = {
    size: count,
    orderBy: '{ \'name\': \'asc\' }',
    ...continuationToken?.length && { continuationToken },
  }

  useReduxAction('zonesV3', 'loadZonesV3', options, [continuationToken], {
    dispatchAction: (action, requestOptions) => action(requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loading } = entityReducer

      return !loading && continuationToken !== null && continuationToken !== undefined
    },
  })

  setState({ refresh: false })
  return null
}

const ZoneList = () => {
  const [state, setState] = useSetState(defaultState)
  const { defaultCount, refresh, reset, searchFilter, showEditZoneModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const entityReducer = useSelector(reduxState => reduxState.zonesV3)
  const { result: continuationToken, loading } = entityReducer

  const { filteredZonesV3, hasfilteredZonesV3 } = useZonesV3({
    searchFilter,
    reset,
  })

  const searchFilterString = `"name":"%${searchFilter}%"`
  const urlComponent = (`{${searchFilter.length > 0 ? `${searchFilterString},` : ''}}`).replace(/\s+/g, '')

  const options = new URLSearchParams([])
  options.append('size', `${defaultCount}`)
  options.append('orderBy', '{ \'name\': \'asc\'}')

  if (urlComponent !== '{}') options.append('filter', urlComponent)

  useReduxAction('zonesV3', 'loadZonesV3', options, [searchFilter])

  const updateSearchFilter = (val) => {
    setState({ searchFilter: val })
  }

  const pageContext = {
    callbacks: {
      showEditZoneModal: (payload) => {
        setModalData('EditZoneModal', payload)
        setState({ showEditZoneModal: true })
      },
      searchZoneFilter: val => updateSearchFilter(val),
    },
  }

  const loadMoreZones = () => {
    setState({ refresh: true })
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          activeTabBarItemKey="zones"
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          title="Zone Management"
        />
        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
            <Columns.Main>
              <DashboardModule title="Zones">
                <Box flexDirection="column" className={custom.scroll}>
                  <Box flexDirection="column" maxHeight="75vh" overflowY="auto">
                    {hasfilteredZonesV3 && filteredZonesV3.map(zone => (
                      <Zone key={zone.id} zone={zone} />
                    ))}
                    <LoadingModule loading={loading} boxProps={{ border: 0 }} />
                  </Box>
                  {hasfilteredZonesV3 && (
                    <DashboardModule.LoadMoreFooter
                      callbacks={{ loadMore: () => loadMoreZones() }}
                      canLoadMore={continuationToken !== null && continuationToken !== undefined}
                      entityCount={filteredZonesV3?.length}
                    />
                  )}
                  {!loading && !hasfilteredZonesV3 && (
                    <DashboardModule.BlankState imageUrl={blankState}>
                      <DashboardModule.BlankState.Title>No Zones Available</DashboardModule.BlankState.Title>
                      <DashboardModule.BlankState.Paragraph>
                        Try adjusting your filters.
                      </DashboardModule.BlankState.Paragraph>
                    </DashboardModule.BlankState>
                  )}
                  {refresh && (
                    <RefreshZones continuationToken={continuationToken} setState={setState} count={defaultCount} />
                  )}
                </Box>
              </DashboardModule>
            </Columns.Main>
            <Columns.Sidebar>
              <DashboardModule title="Tools">
                <Tools />
              </DashboardModule>
            </Columns.Sidebar>
          </Columns>
        </Box>
        <EditZoneModal
          callbacks={callbacks('EditZoneModal', setState)}
          showModal={showEditZoneModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default ZoneList
