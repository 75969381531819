import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  Box, Image, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import Icons from '@components/Icons'

import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const GridItem = (props) => {
  const {
    badgeColor,
    badgeText,
    callbacks,
    content,
    fileUrl,
    iconName,
    link,
    multipleSelection,
    name,
    selected,
    showDescription,
    showDetails,
  } = props

  const { selectContent } = callbacks || {}

  return (
    <Box
      border={showDetails ? '1px solid' : 0}
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      key={content.id}
    >
      <Image
        backgroundPosition="center center"
        borderRadius={showDetails ? '5px 5px 0 0' : '5px'}
        boxProps={{ backgroundColor: 'hoverLightGrey' }}
        height={140}
        url={content?.file?.previewGeneratedAt ? content.previewUrl : ''}
        width={'100%' || 200}
      >
        <Box
          as={multipleSelection ? null : fileUrl ? 'a' : null}
          height="100%"
          href={fileUrl}
          onClick={() => (multipleSelection ? selectContent() : null)}
          position="relative"
          style={{ cursor: multipleSelection ? 'pointer' : '' }}
          target="_blank"
        >
          {!content?.file?.previewGeneratedAt && (
            <Box
              alignItems="center"
              color="bodyFontColor"
              display="grid"
              height="100%"
              justifyContent="center"
              position="absolute"
              width="100%"
            >
              <Icons name={iconName} size={50} width="100%" />
            </Box>
          )}
          {selected && (
            <Box
              alignItems="center"
              backgroundColor="rgba(0, 0, 0, 0.4)"
              borderRadius={showDetails ? '5px 5px 0 0' : '5px'}
              color="bodyFontColor"
              display="grid"
              justifyContent="center"
              width="100%"
              zIndex={1}
            >
              <Box
                alignItems="center"
                borderRadius={20}
                color="white"
                fontSize="xsmall"
                justifyContent="center"
                width="100%"
              >
                <FontAwesomeIcon icon={faCheckCircle} size="3x" />
              </Box>
            </Box>
          )}
        </Box>
      </Image>
      {showDetails && (
        <Box flexDirection="column">
          <Link to={link} style={{ textDecoration: 'none' }}>
            <ListItem
              boxProps={{ padding: 'large' }}
              flexDirection="column"
              showAngleIcon
            >
              <Text variant="ellipsis" fontSize="medium" fontWeight="600" width={180}>
                {name}
              </Text>
              <Box flexDirection="row" marginTop="medium">
                <Box alignItems="center" justifyContent="flexStart" width="auto" paddingRight="medium">
                  <Icons name={iconName} />
                </Box>
                <StatusBadge color={badgeColor} text={badgeText} />
              </Box>
            </ListItem>
            {showDescription && (
              <ListItem
                boxProps={{
                  borderTop: '1px solid',
                  padding: 'large',
                }}
                disableHover
                flexDirection="column"
              >
                <Text fontSize="small">{content?.description}</Text>
              </ListItem>
            )}
          </Link>
        </Box>
      )}
      {!showDetails && (
        <Text fontSize="small" fontWeight={600} paddingTop="medium">
          {content.fileName}
        </Text>
      )}
    </Box>
  )
}

GridItem.propTypes = {
  badgeColor: PropTypes.string,
  badgeText: PropTypes.string,
  callbacks: PropTypes.object,
  content: PropTypes.object,
  fileUrl: PropTypes.string,
  iconName: PropTypes.string,
  link: PropTypes.string,
  multipleSelection: PropTypes.bool,
  name: PropTypes.string,
  selected: PropTypes.bool,
  showDescription: PropTypes.bool,
  showDetails: PropTypes.bool,
}

export default GridItem
