import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, DashboardModule,
} from '@campaignhub/suit-theme'

const IntegrationDetails = (props) => {
  const { clientForm } = props

  const {
    entityState: {
      jobtrack_reference,
      realhub_reference,
    },
    handlers,
  } = clientForm

return (
  <DashboardModule title="Integration Information" boxProps={{ marginBottom: 'large' }}>
    <Box flexDirection="column" padding="large">
      <Box flexDirection={['column', 'row']} marginBottom="large">
        <FormField
          boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
          label="Jobtrack Reference"
        >
          <input name="jobtrack_reference" type="text" value={jobtrack_reference} {...handlers} />
        </FormField>
        <FormField
          boxProps={{ flex: 1 }}
          label="Realhub Reference"
        >
          <input name="realhub_reference" type="text" value={realhub_reference} {...handlers} />
        </FormField>
      </Box>
    </Box>
  </DashboardModule>
  )
}

IntegrationDetails.propTypes = {
  clientForm: PropTypes.object,
}

export default IntegrationDetails
