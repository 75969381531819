import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, FormField,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import LinkingTags from '@components/LinkingTags'

import mapEntityToWorkbaseReferenceArray from '@functions/mapEntityToWorkbaseReferenceArray'
import mapServiceTypeResults from '@functions/mapServiceTypeResults'

const defaultState = {
  searchServiceTypeFilter: '',
  servicesSelected: [],
}

const ProductionManagerDetail = (props) => {
  const { userForm, customErrors } = props
  const {
    entityState,
    entityState: {
      production_manager,
    },
    setEntityState,
  } = userForm

  const [state, setState] = useSetState(defaultState)
  const {
    searchServiceTypeFilter, servicesSelected,
  } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceCategories, services } = entities

  const toggleService = (service, type) => {
    let newList = []

    if (type === 'add'){
      newList = [...servicesSelected, service.workbaseReference]
    } else {
      newList = servicesSelected.filter(x => x !== service.workbaseReference)
    }

    production_manager.services = newList
    setState({ servicesSelected: newList })
    setEntityState({ production_manager })
  }

  useEffect(() => {
    setState({ servicesSelected: entityState.production_manager?.services_selected || [] })
  }, [])

  return (
    <>
      <DashboardModule title="Production Manager Details">
        <Box flexDirection="column" padding="large">
          <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
            <FormField boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}>
              <LinkingTags
                callbacks={{
                  toggleItem: item => toggleService(item, 'add'),
                  toggleSearch: search => setState({ searchServiceTypeFilter: search }),
                  removeItem: item => toggleService(item),
                }}
                errorMessage={customErrors['production_manager.services']}
                label="Services"
                linkedItems={mapEntityToWorkbaseReferenceArray(services, servicesSelected)}
                searchFilter={searchServiceTypeFilter}
                searchResults={mapServiceTypeResults(serviceCategories, services, servicesSelected)}
              />
            </FormField>
          </Box>
        </Box>
      </DashboardModule>
    </>
  )
}

ProductionManagerDetail.propTypes = {
  userForm: PropTypes.object.isRequired,
  customErrors: PropTypes.array,
}

export default ProductionManagerDetail
