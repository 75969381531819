/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
const mapEntityToWorkbaseReferenceArray = (entity, workbaseReferenceArray) => {
  const results = Object.values(entity).filter((x) => {
    if (workbaseReferenceArray.includes(x.workbaseReference)){
      return { ...x, key: x.workbaseReference }
    }
  })

  return results
}

export default mapEntityToWorkbaseReferenceArray
