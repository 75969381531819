import React, { useEffect, useRef, useContext } from 'react'

import PageContext from '@contexts/pageContext'
import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, ListItem, FormField, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

const defaultState = {
  showFilter: false,
}

const WeekFilter = () => {
  const [state, setState] = useSetState(defaultState)
  const { showFilter } = state

  const { callbacks, selectedWeek, weeks } = useContext(PageContext)
  const { toggleUpdateSelectedWeek } = callbacks

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  return (
    <FormField direction="column" boxProps={{ paddingBottom: 'medium' }}>
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          value={selectedWeek.name}
        >
          <option>{selectedWeek.name}</option>
        </select>
        {showFilter && (
          <Box className={custom.popup}>
            <Box flexDirection="column" maxHeight="300px" overflowY="auto">
              {weeks.map(week => (
                <ListItem
                  key={week.id}
                  boxProps={{ paddingY: 'medium' }}
                  onClick={() => {
                    setState({ showFilter: !showFilter })

                    if (week.id === selectedWeek.id) return

                    toggleUpdateSelectedWeek(week.id)
                  }}
                >
                  <Text fontSize="small">{week.name}</Text>
                </ListItem>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </FormField>
  )
}

export default WeekFilter
