import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  DashboardModule,
  ListItem,
} from '@campaignhub/suit-theme'

import DateFilter from './components/DateFilter'

const ReportsFilter = (props) => {
  const {
    setJobState,
    jobState,
  } = props

  const resetAllFilters = () => {
    setJobState({
      dateFilter: {
        filterEnd: '',
        filterStart: '',
      },
    })
  }

  return (
    <DashboardModule boxProps={{ marginBottom: 'large' }} title="Tools">
      <ListItem
        boxProps={{
          borderBottom: '1px solid',
          padding: 'large',
        }}
        flexDirection="row"
        disableHover
      >
        <Box flexDirection={['column', 'row']}>
          <DateFilter setJobState={setJobState} jobState={jobState} />
          <Box
            alignItems="center"
            marginLeft={[0, 'medium']}
            marginBottom={['small', 0]}
            justifyContent="flexEnd"
            width="auto"
            flexDirection="column"
          >
            <Button
              buttonStyle="secondary"
              size="medium"
              onClick={() => resetAllFilters()}
            >
              Reset All Filters
            </Button>
          </Box>
        </Box>
      </ListItem>
    </DashboardModule>
  )
}

ReportsFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default ReportsFilter
