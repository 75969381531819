import React from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, DashboardModule, ListItem, Toggle,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import AccountManagerFilter from '@components/ToolsFilter/components/AccountManagerFilter'
import ClassificationFilter from '@components/ToolsFilter/components/ClassificationFilter'
import ClientFilter from '@components/ToolsFilter/components/ClientFilter'
import DateFilter from '@components/ToolsFilter/components/DateFilter'
import FilterByLimit from '@components/ToolsFilter/components/FilterByLimit'
import FilterByTags from '@components/ToolsFilter/components/FilterByTags'
import ServiceDateFilter from '@components/ToolsFilter/components/ServiceDateFilter'
import ServiceStatusFilter from '@components/ToolsFilter/components/ServiceStatusFilter'
import ServiceTypeFilter from '@components/ToolsFilter/components/ServiceTypeFilter'
import ServiceUserFilter from '@components/ToolsFilter/components/ServiceUserFilter'
import StudioUserFilter from '@components/ToolsFilter/components/StudioUserFilter'
import ViewFilter from '@components/ToolsFilter/components/ViewFilter'
import DeadlineFilter from '@components/ToolsFilter/components/DeadlineFilter'
import AgencyFilter from '@components/ToolsFilter/components/AgencyFilter'
import UploadedToday from '@components/ToolsFilter/components/CustomFilters/UploadedToday'

const defaultState = {
  expanded: true,
}

const ServiceJobsFilter = (props) => {
  const {
    jobState,
    setJobState,
    deadlineFilterTotal,
    agencyFilterItems,
    customFilterItems,
    showAccountManagerFilter,
    showClassificationFilter,
    showServiceDateFilter,
    showServiceUserFilter,
    showStudioUserFilter,
  } = props

  const { hideArchivedJobs, hideDoneJobs } = jobState

  const [state, setState] = useSetState(defaultState)
  const { expanded } = state

  const resetAllFilters = () => {
    setJobState({
      accountManagerFilter: [],
      classificationFilter: [],
      clientFilter: [],
      dateFilter: {
        filterEnd: '',
        filterStart: '',
      },
      filterLimit: 50,
      filterTags: [],
      serviceFilter: [],
      statusFilter: [],
      userFilter: [],
      serviceUserFilter: [],
      serviceDateFilter: {
        filterEnd: '',
        filterStart: '',
      },
    })
  }

  return (
    <DashboardModule
      boxProps={{ marginBottom: 'large' }}
      title="Filters"
      headerRight={(
        <Box
          onClick={() => setState({ expanded: !expanded })}
          style={{ cursor: 'pointer' }}
          textAlign="end"
          width="auto"
        >
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </Box>
      )}
    >
      <Box flexDirection={['column', 'row']}>
        {expanded && (
          <>
            <Box
              flexDirection="column"
              borderBottom={['1px solid #ececec', 0]}
              borderRight={[0, '1px solid #ececec']}
            >
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <Box marginBottom="medium">
                  <Toggle
                    onClick={() => setJobState({ hideDoneJobs: !hideDoneJobs })}
                    toggleOffColor="bodyFontColor"
                    toggleOffText="Show Approved Jobs"
                    toggleOn={!hideDoneJobs}
                    toggleOnText="Show Approved Jobs"
                  />
                </Box>
                <Box>
                  <Toggle
                    onClick={() => setJobState({ hideArchivedJobs: !hideArchivedJobs })}
                    toggleOffColor="bodyFontColor"
                    toggleOffText="Show Archived Jobs"
                    toggleOn={!hideArchivedJobs}
                    toggleOnText="Show Archived Jobs"
                  />
                </Box>
              </ListItem>
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <DateFilter setJobState={setJobState} jobState={jobState} />
                {showServiceDateFilter && (
                  <ServiceDateFilter setJobState={setJobState} jobState={jobState} />
                )}
              </ListItem>
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <ClientFilter setJobState={setJobState} jobState={jobState} />
                {showStudioUserFilter && (
                  <StudioUserFilter setJobState={setJobState} jobState={jobState} />
                )}
                {showServiceUserFilter && (
                  <ServiceUserFilter setJobState={setJobState} jobState={jobState} />
                )}
                {showClassificationFilter && (
                  <ClassificationFilter setJobState={setJobState} jobState={jobState} />
                )}
                {showAccountManagerFilter && (
                  <AccountManagerFilter setJobState={setJobState} jobState={jobState} />
                )}
              </ListItem>
            </Box>
            <Box flexDirection="column" borderBottom={['1px solid #ececec', 0]} borderRight={[0, '1px solid #ececec']}>
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                  paddingBottom: 'small',
                }}
                flexDirection="column"
                disableHover
              >
                <ServiceTypeFilter setJobState={setJobState} jobState={jobState} />
              </ListItem>
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <DeadlineFilter setJobState={setJobState} jobState={jobState} items={deadlineFilterTotal} />
              </ListItem>
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <AgencyFilter setJobState={setJobState} jobState={jobState} items={agencyFilterItems} />
              </ListItem>
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <UploadedToday setJobState={setJobState} jobState={jobState} items={customFilterItems} />
              </ListItem>
            </Box>
            <Box flexDirection="column">
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <ServiceStatusFilter setJobState={setJobState} jobState={jobState} />
              </ListItem>
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <ViewFilter setJobState={setJobState} jobState={jobState} />
              </ListItem>
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <FilterByLimit setJobState={setJobState} jobState={jobState} />
                <FilterByTags setJobState={setJobState} jobState={jobState} />
              </ListItem>
              <ListItem
                boxProps={{
                  borderBottom: '1px solid',
                  padding: 'large',
                }}
                flexDirection="column"
                disableHover
              >
                <Button
                  buttonStyle="secondary"
                  size="medium"
                  onClick={() => resetAllFilters()}
                >
                  Reset All Filters
                </Button>
              </ListItem>
            </Box>
          </>
        )}
      </Box>
    </DashboardModule>
  )
}

ServiceJobsFilter.propTypes = {
  jobState: PropTypes.object,
  setJobState: PropTypes.func,
  deadlineFilterTotal: PropTypes.array,
  agencyFilterItems: PropTypes.array,
  customFilterItems: PropTypes.array,
  showAccountManagerFilter: PropTypes.bool,
  showClassificationFilter: PropTypes.bool,
  showServiceDateFilter: PropTypes.bool,
  showServiceUserFilter: PropTypes.bool,
  showStudioUserFilter: PropTypes.bool,
}

export default ServiceJobsFilter
