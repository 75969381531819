import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page, Image, StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  page: {
    paddingTop: '20px',
    paddingLeft: '45px',
    paddingRight: '45px',
  },
  text: {
    fontSize: '8px',
    marginTop: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  view: {
    width: '100%',
    marginBottom: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

const ImageListPDF = ({ resources }) => (
  <Document>
    <Page style={styles.page}>
      {resources.map(resource => (
        <View wrap={false} style={styles.view}>
          <Image src={resource.file.filePreviewRequests.find(x => x.imageType === 'Preview' && x.generatedAt)?.downloadUrl || resource.previewUrl} />
          <Text style={styles.text}>{resource.file.originalName}</Text>
        </View>
      ))}
    </Page>
  </Document>
  )

ImageListPDF.propTypes = {
  resources: PropTypes.array,
}

export default ImageListPDF
