import { useSelector } from 'react-redux'

import { useForm, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState, { requiredFields } from '@models/emailRequest'

import * as emailRequestActions from '@redux/modules/emailRequest'

const createEmailRequest = (emailRequestParams, dispatch, requestOptions) => {
  const { createEmailRequest: createFn } = emailRequestActions

  return dispatch(createFn(emailRequestParams, requestOptions))
}

export function useEmailRequestForm(emailRequest = {}){
  const emailRequestForm = useForm(defaultState, { entity: emailRequest, requiredFields }, [emailRequest])

  return {
    ...emailRequestForm,
  }
}

const useEmailRequest = () => {
  const dispatch = useThunkDispatch()

  const { creating } = useSelector(reduxState => reduxState.emailRequests)

  return {
    callbacks: {
      createEmailRequest: (emailRequestParams, requestOptions) => createEmailRequest(emailRequestParams, dispatch, requestOptions),
    },
    creating,
  }
}

export default useEmailRequest
