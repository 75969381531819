const classifications = {
  casual: {
     id: 1,
     name: 'Casual',
     description: 'Casual',
  },
  partner: {
    id: 2,
    name: 'Partner',
    description: 'Partner',
  },
  vip: {
    id: 3,
    name: 'VIP',
    description: 'VIP',
  },
}

export default classifications
