import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useServiceJobStatuses = (options = {}) => {
  const { performHttpRequests } = options || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  useReduxAction('serviceJobStatuses', 'loadServiceJobStatuses', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  return {
    serviceJobStatuses,
  }
}

export default useServiceJobStatuses
