import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState, useDebounce } from '@campaignhub/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import useReduxAction from '@hooks/useReduxAction'
import useUsersV3 from '@hooks/useUsersV3'

import {
  Box, FormField, DashboardModule, InputSearch, Tag, Text,
} from '@campaignhub/suit-theme'

const defaultState = {
  accountManagersSelected: [],
  accountManagerSelected: [],
  searchFilter: '',
  listCount: 5,
  reset: false,
}

const mapResults = (filteredUsers, accountManagersSelected) => {
  const results = Object.values(filteredUsers)?.filter(x => !accountManagersSelected.map(y => y.reference).includes(x.reference))

  return {
    groups: [
      {
        key: 'Account Managers',
        title: 'Account Managers',
        items: results.map((result) => {
          const { name, reference } = result

          return {
            ...result,
            key: reference,
            text: name,
          }
        }),
      },
    ],
  }
}

const AccountManagerDetails = (props) => {
  const { clientForm } = props
  const [state, setState] = useSetState(defaultState)

  const { accountManagersSelected, accountManagerSelected, searchFilter, reset, listCount } = state

  const debouncedSearchFilter = useDebounce(searchFilter, 300)

  const {
    entityState,
    entityState: {
      account_managers,
    },
    setEntityState,
  } = clientForm

  useEffect(() => {
    setState({ searchFilter: debouncedSearchFilter })
  }, [debouncedSearchFilter])

  const searchFilterString = `"name":"${searchFilter.replace(/[^a-zA-Z0-9 ]/g, '')}%"`
  const roleString = '"flags*": "Account_Manager"'
  const urlComponent = (`{${searchFilter.length > 0 ? `${searchFilterString},` : ''}
                          ${`${roleString},`}}`).replace(/\s+ /g, '')

  const options = {
    filter: (urlComponent !== '{}') ? urlComponent : '',
    size: -1,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('usersV3', 'loadUsersV3', options, [searchFilter, debouncedSearchFilter])

  const { filteredUsersV3, usersV3 } = useUsersV3({
    searchFilter,
    searchFlags: ['Account_Manager'],
    reset,
    listCount,
  })

  const toggleProvider = (accountManager) => {
    setState({ accountManagerSelected: Object.values(accountManagersSelected).includes(accountManager) ? [] : accountManager })
    setState({
      accountManagersSelected: Object.values(accountManagersSelected).includes(accountManager)
        ? accountManagersSelected.filter(x => x !== accountManager) : [...accountManagersSelected, accountManager],
    })

    if (account_managers.findIndex(x => accountManager.reference?.includes(x)) !== -1
      && Object.values(accountManagersSelected).includes(accountManager)){
      account_managers.splice(account_managers.findIndex(x => accountManager.reference?.includes(x)), 1)
    }
  }

  useEffect(() => {
    if (accountManagersSelected.length > 0 && account_managers.findIndex(x => accountManagerSelected.reference?.includes(x)) === -1 && accountManagerSelected?.reference){
      account_managers.push(accountManagerSelected?.reference)
      setEntityState({ account_managers })
    }
  }, [accountManagersSelected])

  useEffect(() => {
    setState({ accountManagersSelected: [...Object.values(usersV3).filter(x => entityState.account_managers?.includes(x.reference))] })
  }, [entityState.account_managers, usersV3])

  useEffect(() => {
    if (entityState.id === null) entityState.account_managers.splice(0, entityState.account_managers.length)
  }, [])

  return (
    <DashboardModule title="Account Manager" boxProps={{ marginBottom: 'large' }}>
      <Box flexDirection="column" padding="large">
        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Users"
          >
            <Box flexDirection="row" width={['100%', 'calc(50% - 4px)']}>
              <InputSearch
                callbacks={{ selectItem: result => toggleProvider(result) }}
                onChange={e => setState({ searchFilter: e.target.value })}
                placeholder="Search Users"
                value={searchFilter}
                closeOnSelect
                results={mapResults(filteredUsersV3, accountManagersSelected)}
              />
            </Box>
            <Box flexWrap="wrap" gridGap="small" marginTop="medium">
              {accountManagersSelected.sort((a, b) => (a > b ? 1 : -1)).map(user => (
                <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall', onClick: () => toggleProvider(user) }} key={user.id}>
                  <Text color="bodyFontLightColor">
                    {user.name} &nbsp;<FontAwesomeIcon icon={faTimes} />
                  </Text>
                </Tag>
              ))}
            </Box>
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
  )
}

AccountManagerDetails.propTypes = {
  clientForm: PropTypes.object,
}

export default AccountManagerDetails
