import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule,
} from '@campaignhub/suit-theme'

import DuskTime from './components/DuskTime'

const defaultState = {
  renderCount: 0,
}

const DuskTimeDetails = (props) => {
  const { zoneForm, zoneForm: { entityState } } = props

  const [state, setState] = useSetState(defaultState)
  const { renderCount } = state

  useEffect(() => { setState({ renderCount: renderCount + 1 }) }, [entityState.id])

  const addDuskTime = () => {
    entityState.dusk_times.push({})
    setState({ renderCount: renderCount + 1 })
  }

  const removeDuskTime = (duskTime) => {
    entityState.dusk_times = entityState.dusk_times.filter(x => x !== duskTime)
    setState({ renderCount: renderCount + 1 })
  }

  return (
    <DashboardModule
      title="Dusk Times"
      boxProps={{ marginBottom: 'large' }}
    >
      <Box flexDirection="column">
        <Box flexDirection="column">
          {entityState.dusk_times.map(duskTime => (
            <DuskTime
              duskTime={duskTime}
              zoneForm={zoneForm}
              callbacks={{ removeDuskTime: () => removeDuskTime(duskTime) }}
              listSetState={setState}
            />
          ))}
          {!entityState.dusk_times?.length && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>No Dusk Times Set</DashboardModule.BlankState.Title>
              <DashboardModule.BlankState.Paragraph>
                Dusk Time would default to 5PM.
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}
        </Box>
        <Box
          borderTop="1px solid"
          borderColor="lineColor"
          padding="large"
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box marginLeft="auto" width="auto">
            <Button
              buttonStyle="secondaryCreate"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => addDuskTime()}
              size="medium"
              style={{ width: 'auto' }}
            >
              Add DuskTime
            </Button>
          </Box>
        </Box>
      </Box>
    </DashboardModule>
  )
}

DuskTimeDetails.propTypes = {
  zoneForm: PropTypes.object,
}

export default DuskTimeDetails
