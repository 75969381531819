import React from 'react'
import useCurrentUser from '@hooks/useCurrentUser'

import userTypes from '@functions/userTypes'
import BackOfficeRoutes from '@sections/BackOffice/routes'
import DefaultRoutes from '@sections/Default/routes'
import OnSiteRoutes from '@sections/OnSite/routes'
import StudioRoutes from '@sections/Studio/routes'

import Loading from './components/Loading'

const AuthorizationController = () => {
  const { errors, currentUser } = useCurrentUser()

  if (!currentUser && !errors.length){
    return <Loading />
  }

  if (currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'BackOffice').id){
    return <BackOfficeRoutes />
  }

  if (currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio').id){
    return <StudioRoutes />
  }

  if (currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'OnSite').id){
    return <OnSiteRoutes />
  }

  return <DefaultRoutes />
}

export default AuthorizationController
