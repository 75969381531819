import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import userTypes from '@functions/userTypes'

import {
  Box, Grid, ListItem, StatusBadge, Text, DashboardModule,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'

import Icons from '@components/Icons'
import blankState from '@components/assets/blankState.svg'

import { getEntityByName } from '@functions/getEntityByName'
import { getServiceStatusColor } from '@functions/getServiceStatusColor'
import { getDeadline } from '@functions/getDeadline'

const ServiceJobList = (props) => {
  const { serviceJobs, showUsers, showProvider, showQC, showRejection, totalJobCount, setJobState } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, serviceJobStatuses } = entities

  const { currentUser: { timeZone, userRole } } = useCurrentUser()

  if (!serviceJobs.length){
    return (
      <DashboardModule.BlankState imageUrl={blankState}>
        <DashboardModule.BlankState.Title>No Jobs Available</DashboardModule.BlankState.Title>
        <DashboardModule.BlankState.Paragraph>
          Try adjusting your filters.
        </DashboardModule.BlankState.Paragraph>
      </DashboardModule.BlankState>
    )
  }

  return (
    <>
      <Box flexDirection="column" maxHeight="82vh" overflowY="auto">
        {serviceJobs.map((serviceJob) => {
        const now = DateTime.fromISO(DateTime.now(), { zone: timeZone })
        const deadline = DateTime.fromISO(showUsers ? serviceJob.clientDeadline : serviceJob.dateService, { zone: timeZone })
        const proofedAt = DateTime.fromISO(serviceJob.proofedAt, { zone: timeZone })
        const serviceDate = DateTime.fromISO(serviceJob.dateService, { zone: timeZone })
        const pastDeadline = now > deadline
        const proofPastDeadline = proofedAt.invalid ? true : proofedAt > DateTime.fromISO(serviceJob.clientDeadline, { zone: timeZone })

        const currentDate = DateTime.fromISO(DateTime.now(), { zone: timeZone })
        const deadlineHoursDiff = currentDate.diff(deadline, ['years', 'months', 'days', 'hours', 'minutes'])
        const proofedHoursDiff = proofedAt.diff(deadline, ['years', 'months', 'days', 'hours', 'minutes'])

        const providerUsername = serviceJob.details?.find(x => x.entityFieldType.name === 'ServiceUsername')?.value
        const rhContact = serviceJob.details?.find(x => x.entityFieldType.name === 'RealStudiosDetails')?.value
        const workbaseDetails = serviceJob.details?.find(x => x.entityFieldType.name === 'WorkbaseDetails')?.value

        const editors = serviceJob.users.filter(user => user.serviceJobUserRoleId === 1)
        const qc = serviceJob.users.filter(user => user.serviceJobUserRoleId === 2)
        const rejection = serviceJob.users.filter(user => user.serviceJobUserRoleId === 3)

        return (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              gridGap: 'large',
              padding: 'large',
            }}
            flexDirection={['column', 'column', 'row']}
            forceBorderBottom
            justifyContent="center"
            key={serviceJob.id}
            showAngleIcon
          >
            <Link
              key={serviceJob.id}
              to={`/service-jobs/${serviceJob.id}`}
              target="_blank"
              style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
            />
            <Box flexDirection="row" width={showUsers ? ['100%', '100%', '45%'] : ['100%', '80%']}>
              <Box
                alignItems="center"
                color="bodyFontLightColor"
                display="grid"
                justifyContent="center"
                marginRight="large"
                width="auto"
              >
                <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name || 'Photography'} size={23} />
              </Box>
              <Box flexDirection="column" justifyContent="center">
                <Text fontSize="small" marginBottom="medium">
                  {serviceJob.campaign?.name}
                </Text>
                <Box flexDirection="row">
                  <Text fontSize="xsmall" marginBottom="medium" color="bodyFontLightColor">
                    {serviceJob.client?.name}
                    <StatusBadge
                      boxProps={{ marginLeft: 'small' }}
                      ghost
                      color={workbaseDetails ? '#22b8cf' : rhContact ? 'green' : 'orange'}
                      text={workbaseDetails ? 'CH' : rhContact ? 'RH' : 'CT'}
                    />
                  </Text>
                </Box>
                <Box display="block" width="auto">
                  <StatusBadge
                    boxProps={{ marginRight: 'small' }}
                    color="#282B42"
                    ghost
                    text={serviceJob.service?.name}
                  />
                  <StatusBadge
                    boxProps={{ marginRight: 'small' }}
                    color={getServiceStatusColor(serviceJob.status?.name)}
                    ghost
                    text={serviceJob.status?.description}
                  />
                  {serviceJob.client?.classification === 'VIP' && <StatusBadge color="red" ghost text="VIP" />}
                </Box>
              </Box>
            </Box>
            {showUsers && (
              <Grid
                gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
                gridGap="small"
                width={['100%', '100%', '35%']}
              >
                {showProvider && (
                  <Box flexDirection="column" justifyContent="center">
                    <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">Provider</Text>
                    {!providerUsername || JSON.parse(providerUsername).value.trim() === ''
                      ? <StatusBadge fontSize="small" color="green" text="Available" />
                      : <Text fontSize="small">{JSON.parse(providerUsername).value || ''}</Text>}
                  </Box>
                )}
                <Box flexDirection="column" justifyContent="center">
                  <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">Editor</Text>
                  {!editors.length
                    ? <StatusBadge fontSize="small" color="green" text="Available" />
                    : editors.map(serviceJobUser => (
                      <Text key={serviceJobUser.user.firstName} fontSize="small">{serviceJobUser.user.firstName} {serviceJobUser.user.lastName}</Text>
                  ))}
                </Box>
                {showQC && (
                  <Box flexDirection="column" justifyContent="center">
                    <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">QC</Text>
                    {!qc.length
                      ? <StatusBadge fontSize="small" color="green" text="Available" />
                      : qc.map(serviceJobUser => (
                        <Text key={serviceJobUser.user.firstName} fontSize="small">{serviceJobUser.user.firstName} {serviceJobUser.user.lastName}</Text>
                    ))}
                  </Box>
                )}
                {showRejection && (
                  <Box flexDirection="column" justifyContent="center">
                    <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">Rejection</Text>
                    {!rejection.length
                      ? <StatusBadge fontSize="small" color="green" text="Available" />
                      : rejection.map(serviceJobUser => (
                        <Text key={serviceJobUser.user.firstName} fontSize="small">{serviceJobUser.user.firstName} {serviceJobUser.user.lastName}</Text>
                    ))}
                  </Box>
                  )}
                {(userRole?.userTypeId === userTypes?.find(x => x.name === 'Studio')?.id && !serviceDate.invalid) && (
                  <Box flexDirection="column" justifyContent="center">
                    <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">Service Date</Text>
                    <Text fontSize="small" color="bodyFontColor">
                      {serviceDate.invalid ? '' : serviceDate.toFormat('y-LL-dd')}
                    </Text>
                  </Box>
                )}
              </Grid>
            )}
            <Box flexDirection="column" justifyContent="center" width={['100%', '100%', '20%']} textAlign={['left', 'left', 'right']}>
              <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'}>{deadline.invalid ? '' : deadline.toFormat('y-LL-dd')}</Text>
              <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'}>{deadline.invalid ? '' : deadline.toFormat('hh:mm a ccc')}</Text>
              <Text>
                {((serviceJob.service.onSite && serviceJob.status.id < getEntityByName(serviceJobStatuses, 'Booked')?.id)
                || (!serviceJob.service.onSite && serviceJob.status.id < getEntityByName(serviceJobStatuses, 'Uploaded')?.id))
                && pastDeadline && proofedAt.invalid ? null : (
                  !proofedAt.invalid ? (
                    <StatusBadge
                      boxProps={{ marginTop: 'small' }}
                      fontSize="small"
                      ghost
                      color={proofPastDeadline ? 'red' : 'green'}
                      text={!proofPastDeadline ? 'Submitted' : getDeadline(proofedHoursDiff.toObject(), proofPastDeadline, true)}
                    />
                  ) : (
                    !deadline.invalid && (
                      <StatusBadge
                        boxProps={{ marginTop: 'small' }}
                        fontSize="small"
                        color={deadlineHoursDiff.toObject().days <= -1 && !pastDeadline ? 'green'
                            : deadlineHoursDiff.toObject().days >= 0 && !pastDeadline ? '#E3B418' : 'red'}
                        text={getDeadline(deadlineHoursDiff.toObject(), pastDeadline)}
                      />
                    )
                  )
                )}
              </Text>
              {(userRole?.userTypeId === userTypes?.find(x => x.name === 'OnSite')?.id && serviceJob.service?.onSite) && (
              <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'}>
                {` (${!serviceJob.serviceJobLength ? 0 : serviceJob.serviceJobLength} minutes)`}
              </Text>
                )}
            </Box>
          </ListItem>
        )
      })}
      </Box>
      {serviceJobs.length > 0 && (
        <DashboardModule.LoadMoreFooter
          callbacks={{ loadMore: () => setJobState({ filterLimit: serviceJobs?.length + 50 }) }}
          canLoadMore={serviceJobs?.length < totalJobCount}
          entityCount={serviceJobs?.length}
        />
      )}
    </>
  )
}

ServiceJobList.propTypes = {
  serviceJobs: PropTypes.array.isRequired,
  totalJobCount: PropTypes.number,
  showUsers: PropTypes.bool,
  showProvider: PropTypes.bool,
  showQC: PropTypes.bool,
  showRejection: PropTypes.bool,
  setJobState: PropTypes.func,
}

export default ServiceJobList
