const state = {
  services: [],
  services_selected: [],
}

export const customProductionManagerRequiredFields = [
  { key: 'production_manager.services' },
]

export default state
