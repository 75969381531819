import { useEffect } from 'react'

import useCurrentUser from '@hooks/useCurrentUser'

const useFreshDesk = (options = {}) => {
  const { hideWidget } = options

  const { currentUser } = useCurrentUser()
  const { id } = currentUser

  useEffect(() => {
    if (id){
      const { FreshworksWidget } = window

      // hidden if option
      if (hideWidget) return

      FreshworksWidget('show')
    }
  }, [id])
}

export default useFreshDesk
