/* eslint-disable max-len */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, SidebarModal, Text } from '@campaignhub/suit-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'

import AssetComment from './components/AssetComment'

const AssetClientModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { clientComments: comments, serviceJob } = useContext(PageContext)

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Asset" titleSecondLine="Client" />
      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column" alignItems="center" paddingX="large" width="100%">
          {serviceJob.workbaseReferenceId && serviceJob.service.name === 'Copywriting' && comments?.length && (
            <Box
              flexDirection="column"
              paddingY="large"
              justifyContent="center"
              borderBottom="1px dashed"
              borderColor="lineColor"
              marginBottom="large"
            >
              <Text fontSize="xsmall" color="bodyFontLightColor" paddingBottom="medium" lineHeight="1.3">
                The comment highlighting feature is currently unavailable in CMS. You can access and review these comments efficiently through the content gallery using the provided link.
              </Text>
              <Button
                as="a"
                buttonStyle="secondaryUtility"
                href={serviceJob.contentHouseClientProofingUrl}
                icon={<FontAwesomeIcon icon={faExternalLink} />}
                size="medium"
                target="_blank"
              >
                Content Gallery
              </Button>
            </Box>
          )}
          {comments?.sort((a, b) => (a.id > b.id ? -1 : 1)).map(comment => (
            <AssetComment key={comment.id} comment={comment} />
          ))}
          {!comments?.length && (
            <Box paddingY="large" justifyContent="center">
              <Text fontSize="xsmall" color="bodyFontLightColor">No comments</Text>
            </Box>
          )}
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AssetClientModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AssetClientModal.defaultProps = {
  modalKey: 'AssetClientModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AssetClientModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
