const CHANGE_PARAM = 'contenthouse/navigation/CHANGE_PARAM'

const initialState = {
  showNavigation: false,
}

// Actions
export function changeParam(name, value){
  return {
    type: CHANGE_PARAM,
    name,
    value,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { name, type, value } = action

  switch (type){
    case CHANGE_PARAM:
      return {
        ...state,
        [name]: value,
      }
    default:
      return state
  }
}
