import { useMemo } from 'react'
import { DateTime } from 'luxon'

import useCurrentUser from '@hooks/useCurrentUser'

const useCurrentDate = () => {
  const { currentUser: { timeZone } } = useCurrentUser()

  const now = useMemo(() => DateTime.now().setZone(timeZone), [])
  const today = useMemo(() => now.startOf('day'), [])
  const firstCalendarDay = () => {
    const firstDay = today.weekday >= 5 ? today.set({ weekday: 7 }) : today
    const daysOffset = now > today.set({ hour: 15, minute: 0 }) || today.weekday > 5 ? 2 : 1

    const hardcodedStart = DateTime.fromISO('2023-01-30T00:00:00.000', { zone: timeZone })
    if (now > DateTime.fromISO('2023-01-25T15:00:00.000', { zone: timeZone })
      && firstDay.plus({ days: daysOffset }) < hardcodedStart){
      return hardcodedStart
    }

    return firstDay.plus({ days: daysOffset })
  }
  const defaultOffset = firstCalendarDay().diff(today, ['days']).values.days

  return {
    defaultOffset,
    firstCalendarDay,
    now,
    today,
  }
}

export default useCurrentDate
