import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState, { requiredFields } from '@models/serviceJobUser'

import * as serviceJobUserActions from '@redux/modules/serviceJobUser'

import PageContext from '@contexts/pageContext'

const assignServiceJobUser = (serviceJobUserParams, dispatch, requestOptions) => {
  const { assignServiceJobUser: createFn } = serviceJobUserActions

  return dispatch(createFn(serviceJobUserParams, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      return {
        success,
        data,
        redirectUrl: `/service-jobs/${serviceJobUserParams.serviceJobId}`,
      }
    }

    return response
  })
 }

const updateServiceJobUser = (serviceJobUserParam, dispatch, requestOptions) => {
  const { updateServiceJobUser: updateFn } = serviceJobUserActions

  return dispatch(updateFn(serviceJobUserParam, requestOptions))
}

const deleteServiceJobUser = (serviceJobUserParam, dispatch, requestOptions) => {
  const { deleteServiceJobUser: deleteFn } = serviceJobUserActions

  return dispatch(deleteFn(serviceJobUserParam, requestOptions))
}

const launchChangeServiceJobUserModal = (serviceJobUser, dispatch, showChangeJobAssignmentModal) => new Promise((resolve, reject) => {
  if (showChangeJobAssignmentModal){
    const payload = {
      callbacks: {
        assignServiceJobUser: (serviceJobUserParam, requestOptions) => assignServiceJobUser(serviceJobUserParam, dispatch, requestOptions),
        updateServiceJobUser: (serviceJobUserParam, requestOptions) => updateServiceJobUser(serviceJobUserParam, dispatch, requestOptions),
      },
      serviceJobUser,
    }

    showChangeJobAssignmentModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showChangeJobAssignmentModal not defined in PageContext callbacks'))
})

export function useServiceJobUserForm(serviceJobUser = {}){
  const serviceJobUserForm = useForm(defaultState, { entity: serviceJobUser, requiredFields }, [serviceJobUser.id])

  return {
    ...serviceJobUserForm,
  }
}

const useServiceJobUser = (initServiceJobUser = {}) => {
  const { entity: serviceJobUser } = useLatestEntity(initServiceJobUser, 'serviceJobUsers')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showChangeJobAssignmentModal } = callbacks || {}

  const {
 creating, deleting, loading, updating,
} = useSelector(reduxState => reduxState.serviceJobUsers)

  return {
    callbacks: {
      assignServiceJobUser: (serviceJobUsersParam, requestOptions) => assignServiceJobUser(serviceJobUsersParam, dispatch, requestOptions),
      deleteServiceJobUser: (serviceJobUsersParam, requestOptions) => deleteServiceJobUser(serviceJobUsersParam, dispatch, requestOptions),
      launchChangeServiceJobUserModal: () => launchChangeServiceJobUserModal(serviceJobUser, dispatch, showChangeJobAssignmentModal),
      updateServiceJobUser: (serviceJobUsersParam, requestOptions) => updateServiceJobUser(serviceJobUsersParam, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    serviceJobUser,
    updating,
  }
}

export default useServiceJobUser
