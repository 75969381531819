import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DateTime, Duration } from 'luxon'
import CsvDownloadButton from 'react-json-to-csv'

import useReduxAction from '@hooks/useReduxAction'

import { useSetState } from '@campaignhub/react-hooks'
import { Table, Box, LoadingBubbles, Button } from '@campaignhub/suit-theme'

import ReportsFilter from '@components/ReportsFilter'

const defaultState = {
  dateFilter: {
    filterEnd: '1d',
    filterStart: '0d',
  },
  filteredTimeTracker: [],
  filteredUserTime: [],
}

const ReportList = () => {
  const [state, setState] = useSetState(defaultState)

  const { dateFilter, filteredTimeTracker, filteredUserTime } = state

  const payload = {
    ...dateFilter?.filterStart && { filterStart: dateFilter.filterStart },
    ...dateFilter?.filterEnd && { filterEnd: dateFilter.filterEnd },
  }

  useReduxAction('userTimes', 'loadUserTimes', { payload }, [dateFilter])

  useReduxAction('serviceJobsTimeTrackers', 'getServiceJobUserTimes', { payload }, [dateFilter])

  const entities = useSelector(reduxState => reduxState.entities)
  const { services } = entities

  const { loading: serviceJobsTimeTrackerLoading, result: serviceJobsTimeTrackers } = useSelector(reduxState => reduxState.serviceJobsTimeTrackers)

  const { loading: userTimeLoading, result: userTimes } = useSelector(reduxState => reduxState.userTimes)

  useEffect(() => {
    // Job Time Tracker Report
    if (!serviceJobsTimeTrackers || Object.keys(serviceJobsTimeTrackers)?.length) {
      const groupByTimeTrackerFilter = serviceJobsTimeTrackers?.reduce((filtered, jobTime) => {
        if (!filtered.find(x => x.id === jobTime.id)) {
          filtered.push({
            date: DateTime.fromISO(jobTime.entryDate).toFormat('LLL dd, yyyy'),
            user: jobTime.user?.firstName.concat(' ', jobTime.user?.lastName),
            job: jobTime.serviceJob?.name,
            jobLink: `${window.location.host}/service-jobs/${jobTime.serviceJob?.id}`,
            service: services[jobTime.serviceJob?.serviceId].name,
            timeStarted: DateTime.fromISO(jobTime.jobStart).toFormat('hh:mm a'),
            timeEnded: DateTime.fromISO(jobTime.jobEnd).toFormat('hh:mm a'),
            totalJobTimeSpent: Duration.fromMillis(jobTime.totalClockTime).toFormat('hh:mm:ss'),
          })
        }
        return filtered
      }, [])
      setState({
        filteredTimeTracker: groupByTimeTrackerFilter ? [...groupByTimeTrackerFilter] : [],
      })
    } else {
      setState({
        filteredTimeTracker: [],
      })
    }

    // Shift Tracker Report
    if (!userTimes || Object.keys(userTimes)?.length) {
      const groupByUserTimeFilter = userTimes?.reduce((filtered, userTime) => {
        if (!filtered.find(x => x.id === userTime.id)) {
          filtered.push({
            date: DateTime.fromISO(userTime.entryDate).toFormat('LLL dd, yyyy'),
            user: userTime.user?.firstName.concat(' ', userTime.user?.lastName),
            timeIn: DateTime.fromISO(userTime.clockStart).toFormat('hh:mm a'),
            timeOuT: DateTime.fromISO(userTime.clockEnd).toFormat('hh:mm a'),
            totalUserBreak: Duration.fromMillis(userTime.totalBreakTime).toFormat('hh:mm:ss'),
          })
        }
        return filtered
      }, [])
      setState({
        filteredUserTime: groupByUserTimeFilter ? [...groupByUserTimeFilter] : [],
      })
    } else {
      setState({
        filteredUserTime: [],
      })
    }
  }, [serviceJobsTimeTrackers, userTimes])

  return (
    <Box flexDirection="column" paddingX="large" paddingTop="large" width={['100%', '1500px']}>
      <ReportsFilter setJobState={setState} jobState={state} showFilterByLimit showClassificationFilter />
      <Table
        scroll={{ x: 200 }}
        columns={[
          {
            dataKey: 'reports',
            title: 'Reports',
          },
          {
            dataKey: 'name',
            render: function noRefCheck(name) {
              let data = []
              if (name === 'jobTimeTracker') {
                data = Array.from(filteredTimeTracker)
              } else {
                data = Array.from(filteredUserTime)
              }
              return (
                <Box justifyContent="center" alignItems="center">
                  <Button
                    buttonStyle="primaryCreate"
                    loading={userTimeLoading || serviceJobsTimeTrackerLoading}
                    size="medium"
                    width="auto"
                    marginTop="small"
                    marginBottom="small"
                  >
                    <CsvDownloadButton
                      data={data}
                      filename={name}
                      style={{
                        background: 'transparent',
                        border: '0px none',
                        display: 'inline-block',
                        cursor: 'pointer',
                        color: '#ffffff',
                        fontSize: '13px',
                      }}
                    >
                      Generate
                    </CsvDownloadButton>
                  </Button>
                </Box>
              )
            },
          },
        ]}
        data={
          [
            {
              name: 'jobTimeTracker',
              reports: 'Job Time Tracker',
            },
            {
              name: 'shiftTracker',
              reports: 'Shift Tracker',
            },
          ]
        }
      />
    </Box>
  )
}

export default ReportList
