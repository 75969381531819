import { isPositiveIntegerOrZero, isPositiveNumberOrZero } from '@functions/validations'

const state = {
  id: '',
  name: '',
  threshold: null,
  capacityDuration: null,
  description: '',
  workbaseReference: '',
  services: [],
}

export const requiredFields = [
  { key: 'threshold', validation: isPositiveIntegerOrZero, invalidMessage: '>= 0 only' },
  { key: 'capacityDuration', validation: isPositiveNumberOrZero, invalidMessage: '>= 0 only' },
]

export default state
