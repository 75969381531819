import { useSelector } from 'react-redux'
import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import * as serviceJobNoteActions from '@redux/modules/serviceJobNote'

const createServiceJobNote = (serviceJobNoteParam, dispatch, requestOptions) => {
  const { createServiceJobNote: createFn } = serviceJobNoteActions

  return dispatch(createFn(serviceJobNoteParam, requestOptions))
 }

const updateServiceJobNote = (serviceJobNoteParam, dispatch, requestOptions) => {
  const { updateServiceJobNote: updateFn } = serviceJobNoteActions

  return dispatch(updateFn(serviceJobNoteParam, requestOptions))
}

const deleteServiceJobNote = (serviceJobNoteParams, dispatch, requestOptions) => {
  const { deleteServiceJobNote: deleteFn } = serviceJobNoteActions
  return dispatch(deleteFn(serviceJobNoteParams, requestOptions))
}

const useServiceJobNotes = (initServiceJobNote = {}) => {
  const { entity: serviceJobNote } = useLatestEntity(initServiceJobNote, 'serviceJobNotes')
  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobNotes } = entities

  const dispatch = useThunkDispatch()

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.serviceJobNotes)

  return {
    callbacks: {
      createServiceJobNote: (serviceJobNoteParam, requestOptions) => createServiceJobNote(serviceJobNoteParam, dispatch, requestOptions),
      deleteServiceJobNote: (serviceJobNoteParams, requestOptions) => deleteServiceJobNote(serviceJobNoteParams, dispatch, requestOptions),
      updateServiceJobNote: (serviceJobNoteParam, requestOptions) => updateServiceJobNote(serviceJobNoteParam, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    serviceJobNote,
    serviceJobNotes,
    updating,
  }
}

export default useServiceJobNotes
