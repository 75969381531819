import React from 'react'

import { TopBar } from '@campaignhub/suit-theme'
import Result from '@suiteThemeCustom/TopBar/components/Result'

import useServiceJobSearch from './useServiceJobSearch'

const ServiceJobsSearch = () => {
  const {
    callbacks: {
      serviceJobSearch,
      mapResults,
    },
  } = useServiceJobSearch()

  return (
    <TopBar.Search
      callbacks={{ search: serviceJobSearch }}
      mapResults={data => mapResults(data)}
      renderResult={entity => <Result key={entity.id} result={entity} />}
    />
  )
}

ServiceJobsSearch.propTypes = {}

export default ServiceJobsSearch
