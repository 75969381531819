import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useCurrentUserV3 = () => {
  useReduxAction(
    'currentUserV3',
    'loadCurrentUserV3',
    {},
    [],
    {
      shouldPerformFn: (entityReducer) => {
        const { errors, loaded, loading } = entityReducer
        return !loaded && !loading && !errors.length
      },
    },
  )

  const entityReducer = useSelector(reduxState => reduxState.currentUserV3)
  const {
    loaded,
    loading,
    result: currentUserV3,
  } = entityReducer

  return {
    currentUserV3,
    loaded,
    loading,
  }
}

export default useCurrentUserV3
