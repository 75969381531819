import { isPositiveInteger } from '@functions/validations'

const state = {
  id: null,
  serviceJobId: null,
  user: {
    id: null,
  },
  serviceJobUserRole: {
    id: null,
  },
  userId: 0,
  serviceJobUserRoleId: 0,
}

export const requiredFields = [
  { key: 'userId', validation: isPositiveInteger },
  { key: 'serviceJobUserRoleId', validation: isPositiveInteger },
]

export default state
