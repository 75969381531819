import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/activityLog/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/activityLog/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/activityLog/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loading: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function loadActivityLogs(serviceJob){
  const options = serviceJob.payload

  return (dispatch) => {
    dispatch(fetchRequest())
    const promise = api('/v1/activity-logs', options)
      .then(({ data }) => {
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export default function reducer(state = initialState, action = {}){
  const { errors, type, payload } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    default:
      return state
  }
}
