import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useNotificationTypes = (options = {}) => {
  const { performHttpRequests } = options || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { notificationTypes } = entities

  useReduxAction('notificationTypes', 'loadNotificationTypes', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  return {
    notificationTypes,
  }
}

export default useNotificationTypes
