import cloneDeep from 'lodash/cloneDeep'

const checkUserEmptyAddressField = (entityState) => {
  const obj = cloneDeep(entityState)
  if (obj.provider?.address?.address === ''
      || obj.provider?.address?.suburb === ''
      || obj.provider?.address?.postcode === ''
      || obj.provider?.address?.state === ''){
        obj.provider.address = null
  }

  return obj
}

export default checkUserEmptyAddressField
