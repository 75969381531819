import React from 'react'
import {
  Box, Heading, Image, Link, LoadingBubbles, Text,
} from '@campaignhub/suit-theme'

import LogoMarkUrl from '@components/assets/logo-mark.svg'

const Loading = () => {
  const logout = () => {
    window.location.href = 'logout'
  }

  return (
    <Box
      alignItems="center"
      backgroundColor="whiteGrey"
      height="100vh"
      justifyContent="center"
      padding={['large', 'xxlarge']}
      width="100%"
    >
      <Box alignItems="center" flexDirection="column" justifyContent="center" width={['100%', '400px']}>
        <Image backgroundSize="contain" url={LogoMarkUrl} width={50} height={50} />
        <Heading textProps={{ color: 'bodyFontLightColor', marginTop: 'xlarge' }}>
          Loading Application...
        </Heading>
        <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ marginTop: 32 }} />
        <Box alignItems="center" fontSize="xsmall" justifyContent="center" marginTop="xxlarge">
          <Text color="bodyFontLightColor" marginRight="small">Not Loading?</Text>
          <Box marginLeft="medium" width="auto">
            <Link onClick={logout}>Logout</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Loading
