/* eslint-disable no-bitwise */
import { parseInt } from 'lodash'

export function isPositiveInteger(val){
  if (Number.isNaN(val)){
    return false
  }
  const x = parseInt(val)
  return ((x | 0) === x) && x >= 1
}

export function isPositiveNumberOrZero(val){
  if (Number.isNaN(val)){
    return false
  }

  const x = parseFloat(val)
  return x >= 0
}

export function isValidRating(rating){
  if (Number.isNaN(rating)){
    return false
  }
  const x = parseInt(rating)
  return (isPositiveInteger(rating)) && x >= 1 && x <= 5
}

export function isValidEmail(email){
  const trimEmail = email ? email.trim() : ''
  const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,20}(?:\.[a-z]{2})?)(?:\s)*$/i

  return emailRegex.test(trimEmail)
}

export function isPositiveIntegerOrZero(val){
  if (Number.isNaN(val)){
    return false
  }
  const x = parseInt(val)
  return ((x | 0) === x) && x >= 0
}
