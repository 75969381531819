import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/timetracker/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/timetracker/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/timetracker/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/timetracker/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/timetracker/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/timetracker/CREATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
  isActive: false,
  isPaused: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(active = false, paused = false){
  return {
    type: CREATE_SUCCESS,
    isActive: active,
    isPaused: paused,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function getUserTime(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/v1/users/current/time', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TIMETRACKER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function clockIn(options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v1/users/clock-in', options, config)
    .then(({ data }) => {
      dispatch(createSuccess(true, false))

      return { success: true, result: data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(createFailure(errors))

      return { success: false, result: errors }
    })

    return promise
  }
}

export function clockOut(options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v1/users/clock-out', options, config)
    .then(({ data }) => {
      dispatch(createSuccess(false, true))

      return { success: true, data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(createFailure(errors))

      return { success: false, errors }
    })

    return promise
  }
}

export function startBreak(options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/v1/users/break-in', options, config)
    .then(({ data }) => {
      dispatch(createSuccess(true, true))

      return { success: true, data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(createFailure(errors))

      return { success: false, errors }
    })

    return promise
  }
}

export function endBreak(options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest(true, false))

    const promise = api('/v1/users/break-out', options, config)
    .then(({ data }) => {
      dispatch(createSuccess())

      return { success: true, data }
    })
    .catch((data) => {
      const errors = handleError(data)
      dispatch(createFailure(errors))

      return { success: false, errors }
    })

    return promise
  }
}

export default function reducer(state = initialState, action = {}){
  const { errors, type, payload } = action

  switch (type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    default:
      return state
  }
}
