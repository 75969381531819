const state = {
  tos: [{
    email: '',
    name: '',
  }],
  from: {
    email: 'notification@contenthouse.io',
    name: 'Content House Inc.',
  },
  subject: '',
  body: '',
  contentTypeId: 2,
}

export const requiredFields = [
  { key: 'tos' },
  { key: 'subject' },
  { key: 'body' },
]

export default state
