const state = {
  id: null,
  start: '',
  finish: '',
  reason: '',
}

export const requiredFields = [
  { key: 'start' },
  { key: 'finish' },
]

export default state
