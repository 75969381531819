import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useClientResources = (id) => {
  useReduxAction('clientResources', 'loadClientResources', id, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    },
  })

  const entityReducer = useSelector(reduxState => reduxState.clientResources)
  const {
    errors,
    loaded,
    loading,
    result: clientResources,
  } = entityReducer

  return {
    clientResources,
    errors,
    loaded,
    loading,
  }
}

export default useClientResources
