import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, StatusBadge, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import styles from '@styles/custom.module.scss'

const defaultState = {
  expanded: false,
  agencyStatusState: [],
}

const AgencyFilter = (props) => {
  const { setJobState, jobState, items } = props
  const { customFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { expanded, agencyStatusState } = state

  useEffect(() => {
    if (items){
      setState({
        agencyStatusState: items.map(item => ({
          ...item,
          isChecked: customFilter?.includes(item.id),
        })),
      })
    }
  }, [items, customFilter])

  const updateFilterState = (checked, id) => {
    setState({
      agencyStatusState:
      agencyStatusState.map((data) => {
          const temp = data
          if (id === temp.id){
            temp.isChecked = checked
            if (checked){ setJobState({ customFilter: [...customFilter, id] }) } else {
              setJobState({ customFilter: customFilter.filter(i => i !== temp.id) })
            }
          }
          return temp
        }),
    })
  }

  return (
    <Box flexDirection="column">
      <Box
        flexDirection="row"
        marginBottom={expanded ? 'large' : null}
        onClick={() => setState({ expanded: !expanded })}
        style={{ cursor: 'pointer' }}
      >
        <Box flexDirection="column">
          <Text color="bodyFontLightColor" fontSize="small" fontAlign="center">
            Platform
          </Text>
        </Box>
        <Box color="bodyFontLightColor" marginLeft="medium" marginRight="auto" width="auto">
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </Box>
      </Box>
      {expanded && (
        <Box flexDirection="column" alignItems="center" lineHeight="1.5">
          {agencyStatusState.map(item => (
            <Box key={item.id} flexDirection="column" alignItems="center" lineHeight="1.4" marginBottom="medium">
              <Box flexDirection="row">
                <Box color="bodyFontLightColor" marginLeft="auto" marginRight="medium" width="auto">
                  <input
                    type="checkbox"
                    onChange={e => updateFilterState(e.target.checked, item.id)}
                    checked={item.isChecked}
                    className={styles.checkbox}
                  />
                </Box>
                <Box flexDirection="column">
                  <Text
                    color="bodyFontLightColor"
                    fontSize="small"
                    marginBottom="medium"
                    marginRight="medium"
                  >
                    {item.name}
                  </Text>
                </Box>
                <Box flexDirection="column" width="auto">
                  <StatusBadge color="gray" text={item.count} />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

AgencyFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
  items: PropTypes.array,
}

export default AgencyFilter
