import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useSetState, useDebounce } from '@campaignhub/react-hooks'
import {
  Box, DashboardModule, FormField, LoadingBubbles, StatusBadge,
} from '@campaignhub/suit-theme'

import useClients from '@hooks/useClients'
import useReduxAction from '@hooks/useReduxAction'

import LinkingTags from '@components/LinkingTags'

const defaultState = {
  refresh: '',
  reset: false,
  searchFilter: '',
}

const mapResults = (filteredClients, linkedClients) => {
  const results = Object.values(filteredClients).filter(client => !linkedClients.find(linked => linked.clientId === client.id))

    return {
      groups: [
        {
          key: 'clients',
          title: 'Clients',
          items: results.map((result) => {
            const { name, id } = result

            return {
              ...result,
              key: id,
              text: name,
            }
          }),
        },
    ],
  }
}

const ChannelClientLinking = (props) => {
  const { callbacks, linkedClients, channelForm } = props
  const {
    toggleLinkClientYoutubeChannel,
    toggleRemoveClientYoutubeChannel,
  } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { reset, searchFilter } = state

  const {
    entityState: {
      id,
    },
  } = channelForm

  const debouncedSearchFilter = useDebounce(searchFilter, 300)

  const searchFilterString = `"name":"${searchFilter.replace(/[^a-zA-Z0-9 ]/g, '')}%"`
  const urlComponent = (`{${searchFilter.length > 0 ? `${searchFilterString},` : ''}}`).replace(/\s+ /g, '')

  const options = {
    filter: (urlComponent !== '{}') ? urlComponent : '',
    size: 50,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('clients', 'loadClients', options, [searchFilter, debouncedSearchFilter])

  const { clientYoutubeChannels, youtubeChannels } = useSelector(reduxState => reduxState.entities)

  const { filteredClients, loading } = useClients({
    searchFilter,
    reset,
  })

  const {
    creating: clientYoutubeCreating, deleting: clientYoutubeDeleting,
  } = useSelector(reduxState => reduxState.clientYoutubeChannels)

  const toggleClientYoutube = async (clientYoutube, type) => {
    if (type === 'add'){
      const clientYoutubeDetails = {
        clientId: clientYoutube.id,
        youtubeChannelId: id,
        name: clientYoutube.name,
      }

      toggleLinkClientYoutubeChannel(clientYoutubeDetails)
    } else {
      toggleRemoveClientYoutubeChannel(clientYoutube)
    }
  }

  return (
    <DashboardModule
      title="Channel Client Linking"
      headerRight={(
        <Box display="block" width="auto" textAlign="end" height={20}>
          {clientYoutubeCreating || clientYoutubeDeleting ? <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ flexShrink: 0 }} />
          : <StatusBadge color="#43ac6a" ghost text="Updated" />}
        </Box>
      )}
    >
      <Box flexDirection="column" padding="large">
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
          <FormField boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}>
            <LinkingTags
              callbacks={{
                toggleItem: item => toggleClientYoutube(item, 'add'),
                toggleSearch: search => setState({ searchFilter: search }),
                removeItem: item => toggleClientYoutube(item),
              }}
              disabled={clientYoutubeCreating || clientYoutubeDeleting}
              label="Clients"
              linkedItems={linkedClients}
              loading={loading}
              secondLabel={`(${linkedClients.length})`}
              searchFilter={searchFilter}
              searchResults={mapResults(filteredClients, linkedClients, clientYoutubeChannels, youtubeChannels)}
            />
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
  )
}

ChannelClientLinking.propTypes = {
  callbacks: PropTypes.object,
  linkedClients: PropTypes.array,
  channelForm: PropTypes.object,
}

export default ChannelClientLinking
