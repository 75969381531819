import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import swal from 'sweetalert2'

import { Box, Checkbox, ListItem } from '@campaignhub/suit-theme'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import useAssetComment from '@hooks/useAssetComment'
import useCurrentUser from '@hooks/useCurrentUser'

import Comment from '@suiteThemeCustom/Comment'
import custom from '@styles/custom.module.scss'

const defaultState = {
  showOptions: false,
}

const CopyComment = (props) => {
  const { comment } = props

  const [state, setState] = useSetState(defaultState)
  const { showOptions } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { userRoles } = entities

  const { currentUser: { timeZone }, currentUser } = useCurrentUser()

  const assetCommentPayload = useAssetComment()
  const {
    callbacks: {
      updateAssetComment: updateFn,
      deleteAssetComment: deleteFn,
    },
  } = assetCommentPayload

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showOptions })

  useEffect(() => {
    if (isClickedOutside && showOptions){
      setState({ showOptions: !showOptions })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  const resolveComment = () => {
    const updatedComment = {
      id: comment.id,
      comment: comment.comment,
      status: comment.status === null ? 'Resolved' : null,
    }

    return updateFn((updatedComment)).then(({ success, errors }) => {
      if (!success){
        toast.warning(errors[0])
      }

      return success
    })
  }

  const deleteComment = () => {
    swal.fire({
      title: 'Delete Comment',
      html: 'This action will delete the comment.'
      + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
    }).then(({ value }) => {
      if (value){
        deleteFn(comment).then(({ success, errors }) => {
          if (!success){
            toast.warning(errors[0])
          }
        })
      }
    })
  }

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px solid',
        marginBottom: 'medium',
      }}
      disableHover
      flexDirection="row"
      forceBottomBorder
      key={comment.id}
    >
      <Box flexDirection="row" marginBottom="medium" className={custom.root} ref={contentEl}>
        <Box flexDirection="column" width="auto" marginTop="small" onClick={() => resolveComment()}>
          <Checkbox checked={comment.status === 'Resolved'} onClick={() => resolveComment()} />
        </Box>
        <Comment
          callbacks={{ deleteCallback: () => deleteComment() }}
          boxProps={{ marginBottom: 'medium', borderBottom: 0, paddingBottom: 0 }}
          commentBody={comment.comment}
          commentTime={DateTime.fromISO(comment.createdAt, { zone: timeZone }).toFormat('dd LLLL y, hh:mm a')}
          fullName={`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
          hideImage
          isDeletable={currentUser.id === comment.createdBy?.id}
          subHeading={userRoles[comment.createdBy.userRoleId].description}
        />
      </Box>
    </ListItem>
  )
}

CopyComment.propTypes = {
  comment: PropTypes.object,
}

export default CopyComment
