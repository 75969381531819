import React, { useEffect, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'

import PageContext from '@contexts/pageContext'
import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, FormField, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

const defaultState = {
  showFilter: false,
}

const CategoryFilter = () => {
  const [state, setState] = useSetState(defaultState)
  const { showFilter } = state

  const { callbacks: { toggleUpdateSelectedCategories }, selectedCategories } = useContext(PageContext)

  const { serviceCategories } = useSelector(reduxState => reduxState.entities)

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  return (
    <FormField direction="column" boxProps={{ paddingBottom: 'medium' }}>
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          value="Categories"
        >
          <option>Categories</option>
        </select>

        {showFilter && (
          <Box className={custom.popup}>
            <Box flexDirection="column" maxHeight="280px" overflowY="auto">
              {Object.values(serviceCategories).sort((a, b) => (a.description > b.description ? 1 : -1)).map(serviceCategory => (
                <Box flexDirection="row" paddingTop="small" paddingBottom="medium" alignItems="center" key={serviceCategory.id}>
                  <input
                    className={custom.checkbox}
                    onChange={e => toggleUpdateSelectedCategories(e.target.checked, serviceCategory.id)}
                    type="checkbox"
                    checked={selectedCategories.find(x => x.id === serviceCategory.id)}
                  />
                  <Text fontSize="small" marginLeft="medium">{serviceCategory.description}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </FormField>
  )
}

export default CategoryFilter
