import React, { useContext } from 'react'
import ImageMarker from 'react-image-marker'

import { Box, LoadingBubbles, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import useUsers from '@hooks/useUsers'

import CustomMarker from './components/CustomMarker'
import styles from './styles.module.scss'

const AssetMarker = () => {
  const {
    assignedUserRole,
    currentUser,
    markerSetState,
    markers,
    selectedAsset,
    showAllComments,
    showMarkers,
  } = useContext(PageContext)

  const { users } = useUsers()

  const premiumFile = selectedAsset?.file?.filePreviewRequests?.find(x => x.imageType === 'Premium')
  const previewFile = selectedAsset?.file?.filePreviewRequests?.find(x => x.imageType === 'Preview')

  return (
    <Box alignItems="center" height="94vh" flexDirection="column" justifyContent="center" lineHeight="1.5">
      <Box position="absolute">
        {premiumFile?.generatedAt || previewFile?.generatedAt ? (
          <ImageMarker
            markers={showMarkers ? (showAllComments ? markers : markers.filter(x => x.status === null || !x.status || x.status === 'ChangeRequest')) : []}
            markerComponent={markerProps => CustomMarker({
              markerProps, markers, markerSetState,
            })}
            onAddMarker={(marker) => {
              if (['ProductionManager'].includes(users[currentUser?.id]?.userRole.name) || assignedUserRole?.find(x => ['QualityControl', 'Specialist'].includes(x.serviceJobUserRole?.name))){
                markerSetState({ markers: [...markers, marker] })
              }
            }}
            src={premiumFile?.generatedAt ? selectedAsset?.premiumUrl : selectedAsset?.previewUrl}
            extraClass={styles.markerImg}
          />
        ) : (
          <Box flexDirection="column" alignItems="center" justifyContent="center" height="90vh">
            {premiumFile?.failedAt && previewFile?.failedAt ? (
              <Text fontSize="small" color="bodyFontLightColor">
                No preview generated
              </Text>
             ) : (
               <>
                 <Text fontSize="small" color="bodyFontLightColor" marginBottom="small">
                   Preview still generating
                 </Text>
                 <Text fontSize="small" color="bodyFontLightColor">
                   Please refresh in a few seconds
                 </Text>
                 <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ marginTop: '14px' }} />
               </>
             )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default AssetMarker
