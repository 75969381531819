import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useZoneV3 from '@hooks/useZoneV3'

const Zone = (props) => {
  const { zone } = props

  const { callbacks: { editZone } } = useZoneV3(zone)

  const { currentUser: { timeZone } } = useCurrentUser()

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="row"
      justifyContent="center"
      onClick={editZone}
      showAngleIcon
      key={zone.id}
    >
      <Box flexDirection="column">
        <Text fontSize="small" marginBottom="small">
          {zone.name}
        </Text>
      </Box>
      <Box flexDirection="column" textAlign="right">
        <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">
          Last Modified:
        </Text>
        <Text fontSize="xsmall">
          {DateTime.fromISO(zone.lastModified, { zone: timeZone }).toFormat('LLLL dd, y hh:mm a')}
        </Text>
      </Box>
    </ListItem>
  )
}

Zone.propTypes = {
  zone: PropTypes.object,
}

export default Zone
