import * as activityLogs from './activityLog'
import * as activityLogTypes from './activityLogType'
import * as assetCommentTypes from './assetCommentType'
import * as assetComments from './assetComment'
import * as assets from './asset'
import * as blockedServiceJobs from './blockedServiceJob'
import * as blockouts from './blockout'
import * as capacities from './capacity'
import * as clientResources from './clientResource'
import * as clientYoutubeChannels from './clientYoutubeChannel'
import * as clients from './client'
import * as clientsV3 from './clientV3'
import * as currentUser from './currentUser'
import * as currentUserV3 from './currentUserV3'
import * as emailReplacements from './emailReplacement'
import * as emailRequests from './emailRequest'
import * as employmentTypesV3 from './employmentTypeV3'
import * as entities from '../entities'
import * as filters from './filter'
import * as navigation from './navigation'
import * as notificationTypes from './notificationType'
import * as notifications from './notification'
import * as providerFlagsV3 from './providerFlagV3'
import * as resourceTypes from './resourceType'
import * as resources from './resource'
import * as resourcesCompressRequests from './resourcesCompressRequest'
import * as serviceCategories from './serviceCategory'
import * as serviceGroups from './serviceGroup'
import * as serviceJobChecklistItems from './serviceJobChecklistItem'
import * as serviceJobDetails from './serviceJobDetail'
import * as serviceJobFilter from './serviceJobFilter'
import * as serviceJobNotes from './serviceJobNote'
import * as serviceJobPushRaw from './serviceJobPushRaw'
import * as serviceJobSetAvailable from './serviceJobSetAvailable'
import * as serviceJobStatuses from './serviceJobStatus'
import * as serviceJobTags from './serviceJobTag'
import * as serviceJobUserRoles from './serviceJobUserRole'
import * as serviceJobUsers from './serviceJobUser'
import * as serviceJobs from './serviceJob'
import * as serviceJobsSearch from './serviceJobSearch'
import * as serviceJobsTimeTrackers from './serviceJobTimetracker'
import * as serviceTypesV3 from './serviceTypeV3'
import * as slots from './slot'
import * as statesV3 from './stateV3'
import * as statistics from './statistic'
import * as timetrackers from './timetracker'
import * as userFlagsV3 from './userFlagV3'
import * as userRoles from './userRole'
import * as userRolesV3 from './userRoleV3'
import * as userTimes from './userTime'
import * as users from './user'
import * as usersV3 from './userV3'
import * as wordReplacements from './wordReplacement'
import * as workflows from './workflow'
import * as youtubeChannels from './youtubeChannel'
import * as zoneFlagsV3 from './zoneFlagV3'
import * as zones from './zone'
import * as zonesV3 from './zoneV3'
import * as fileServiceContainers from './fileServiceContainer'

const modules = {
  activityLogs,
  activityLogTypes,
  assetCommentTypes,
  assetComments,
  assets,
  blockedServiceJobs,
  blockouts,
  capacities,
  clientResources,
  clientYoutubeChannels,
  clients,
  clientsV3,
  currentUser,
  currentUserV3,
  emailReplacements,
  emailRequests,
  employmentTypesV3,
  entities,
  filters,
  navigation,
  notificationTypes,
  notifications,
  providerFlagsV3,
  resourceTypes,
  resources,
  resourcesCompressRequests,
  serviceCategories,
  serviceGroups,
  serviceJobChecklistItems,
  serviceJobDetails,
  serviceJobFilter,
  serviceJobNotes,
  serviceJobPushRaw,
  serviceJobSetAvailable,
  serviceJobStatuses,
  serviceJobTags,
  serviceJobUserRoles,
  serviceJobUsers,
  serviceJobs,
  serviceJobsSearch,
  serviceJobsTimeTrackers,
  serviceTypesV3,
  slots,
  statesV3,
  statistics,
  timetrackers,
  userFlagsV3,
  userRoles,
  userRolesV3,
  userTimes,
  users,
  usersV3,
  wordReplacements,
  workflows,
  youtubeChannels,
  zoneFlagsV3,
  zones,
  zonesV3,
  fileServiceContainers,
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
