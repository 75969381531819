import { useContext } from 'react'
import { useSelector } from 'react-redux'
import useReduxAction from '@hooks/useReduxAction'
import PageContext from '@contexts/pageContext'
import useEmailReplacement from '@hooks/useEmailReplacement'

const createOrEditEmailReplacements = (emailReplacements, showEmailReplacementsModal, createFn, deleteFn, updateFn) => new Promise((resolve, reject) => {
  if (showEmailReplacementsModal){
    const payload = {
      callbacks: {
        createEmailReplacement: emailReplacementParam => createFn(emailReplacementParam),
        deleteEmailReplacement: emailReplacementParam => deleteFn(emailReplacementParam),
        updateEmailReplacement: emailReplacementParam => updateFn(emailReplacementParam),
      },
      emailReplacements,
    }

    showEmailReplacementsModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEmailReplacementsModal not defined in PageContext callbacks'))
})

const useEmailReplacements = (options = {}) => {
  const { performHttpRequests } = options || {}
  useReduxAction('emailReplacements', 'loadEmailReplacements', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { callbacks: {
    createEmailReplacement: createFn,
    deleteEmailReplacement: deleteFn,
    updateEmailReplacement: updateFn,
  } } = useEmailReplacement()

  const { callbacks } = useContext(PageContext)
  const { showEmailReplacementsModal } = callbacks || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { emailReplacements } = entities

  const { updating, loading } = useSelector(reduxState => reduxState.emailReplacements)

  return {
    callbacks: {
      createOrEditEmailReplacements: () => createOrEditEmailReplacements(emailReplacements, showEmailReplacementsModal, createFn, deleteFn, updateFn),
    },
    loading,
    updating,
    emailReplacements,
  }
}

export default useEmailReplacements
