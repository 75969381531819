import React, { useEffect } from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, DashboardModule, ListItem, Text,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'

import IconProfile from '@components/IconProfile'

const defaultState = {
  serviceUser: '',
}

const AppointmentDetails = (props) => {
  const { dateService, details } = props

  const [state, setState] = useSetState(defaultState)
  const { serviceUser } = state

  const { currentUser: { timeZone } } = useCurrentUser()

  useEffect(() => {
    setState({ serviceUser: '' })
    if (details?.length){
      setState({
        serviceUser: details.find(x => x.entityFieldType.name === 'ServiceUsername')?.value || '',
      })
    }
  }, [details])

  return (
    <DashboardModule title="Appointment Details">
      <Box flexDirection="column" lineHeight="1.3">
        {dateService && (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
              alignItems: 'center',
            }}
            flexDirection="row"
            disableHover
          >
            <Box
              variant="white"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              padding="medium"
              width="auto"
            >
              <Text fontSize="large" fontWeight="bold">
                {DateTime.fromISO(dateService, { zone: timeZone }).toFormat('dd')}
              </Text>
              <Text color="bodyFontLightColor" fontSize="xsmall">
                {DateTime.fromISO(dateService, { zone: timeZone }).toFormat('ccc').toUpperCase()}
              </Text>
            </Box>
            <Box
              flexDirection="column"
              paddingLeft="large"
            >
              <Text fontSize="small">
                {DateTime.fromISO(dateService, { zone: timeZone }).toFormat('EEEE, dd MMMM')}
              </Text>
              <Text color="bodyFontLightColor" fontSize="xsmall">
                {DateTime.fromISO(dateService, { zone: timeZone }).toFormat('t')}
              </Text>
            </Box>
          </ListItem>
        )}
        {serviceUser && JSON.parse(serviceUser).value.trim() !== '' && (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Text fontSize="xsmall" marginBottom="large">
              Service User
            </Text>
            <IconProfile
              name={JSON.parse(serviceUser).value}
            // contactNo="0412 737 5082"
            // email="miketyson@contenthouse.com"
            />
          </ListItem>
        )}
      </Box>
    </DashboardModule>
  )
}

AppointmentDetails.propTypes = {
  dateService: PropTypes.string,
  details: PropTypes.array,
}

export default AppointmentDetails
