import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Box, SidebarModal, ListItem, Line } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'
import { getEntityByName } from '@functions/getEntityByName'

import Checklist from '@components/Checklist'
import AssetCommentGroup from './components/AssetCommentGroup'

const AssetQCModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { groupedAssetComments, serviceJob, serviceJobUserRoles } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobChecklistItems } = entities

  const filteredChecklist = Object.values(serviceJobChecklistItems).filter(i => serviceJob.postProductionChecklistItems.includes(i.id))

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Asset" titleSecondLine="QC" />
      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column" alignItems="center" width="100%">
          <Box flexDirection="column" padding="large">
            <ListItem
              boxProps={{
                  borderBottom: '1px dashed',
                  paddingY: 'large',
                  paddingBottom: filteredChecklist?.length > 0 ? 0 : 'large',
                }}
              flexDirection="column"
              disableHover
              style={{ cursor: 'pointer' }}
            >
              <Checklist postProductionChecklist={filteredChecklist?.filter(x => x.serviceJobUserRoleId
                !== getEntityByName(serviceJobUserRoles, 'Specialist')?.id) || []}
              />
            </ListItem>
          </Box>
          <Line margin={0} />
          <AssetCommentGroup groupName="Unresolved" comments={groupedAssetComments?.null || []} />
          <AssetCommentGroup groupName="Revised" comments={groupedAssetComments?.Revised || []} />
          <AssetCommentGroup groupName="Resolved" comments={groupedAssetComments?.Resolved || []} />
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AssetQCModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AssetQCModal.defaultProps = {
  modalKey: 'AssetQCModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AssetQCModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
