import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import PageContext from '@contexts/pageContext'

import { Box, Button, DashboardModule, FormField } from '@campaignhub/suit-theme'

import useServiceJobDetail from '@hooks/useServiceJobDetail'

const assignServiceJobDetailDusk = (serviceJobDetail, createFn) => {
  createFn(serviceJobDetail).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const DuskPositional = (props) => {
  const { serviceJobDetail, serviceJobId, editable } = props

  const {
    callbacks: {
      assignServiceJobDetailDusk: createFn,
    },
    creating,
  } = useServiceJobDetail()

  const { callbacks: { toggleUpdateDuskDetail }, duskContent, duskTotalImage } = useContext(PageContext)

  useEffect(() => {
    if (serviceJobDetail){
      toggleUpdateDuskDetail(JSON.parse(serviceJobDetail)?.content, JSON.parse(serviceJobDetail)?.totalImages)
    }
  }, [serviceJobDetail])

  const saveDuskDetail = () => {
    const duskPayload = {
      serviceJobId,
      fieldName: 'DuskPositional',
      value: `{ "Content": "${ duskContent.replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t') }", "TotalImages" : "${ duskTotalImage || 0 }" }`,
    }

    assignServiceJobDetailDusk(duskPayload, createFn)
  }

  return (
    <DashboardModule title="Dusk Positional">
      <Box flexDirection="column" padding="large">
        <Box
          flexDirection={['column', 'row']}
          marginBottom={[0, 'large']}
        >
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Dusk Instruction"
          >
            <textarea
              name="duskContent"
              onChange={e => toggleUpdateDuskDetail(e.target.value, duskTotalImage)}
              placeholder="Type your note here..."
              value={duskContent}
              style={{ height: 80, resize: 'none', width: '100%' }}
              readOnly={!editable}
            />
          </FormField>
        </Box>
        <Box
          flexDirection={['column', 'row']}
          marginBottom={[0, 'small']}
          style={{ fontSize: '13px', height: 80, resize: 'none', width: '50%' }}
        >
          <FormField
            boxProps={{ flex: 1 }}
            label="Total Dusk Positional Images"
          >
            <input
              name="totalImages"
              onChange={e => toggleUpdateDuskDetail(duskContent, e.target.value)}
              type="number"
              value={duskTotalImage}
              readOnly={!editable}
            />
          </FormField>
        </Box>
        {editable && (
          <Button
            buttonStyle="primaryCreate"
            loading={creating}
            onClick={() => saveDuskDetail()}
            size="medium"
            marginLeft="auto"
            marginTop="small"
            width="auto"
          >
            {(!serviceJobDetail || JSON.parse(serviceJobDetail)?.content.trim() === '' || JSON.parse(serviceJobDetail)?.totalImages <= 0)
            ? 'Save' : 'Update' }
          </Button>
        )}
      </Box>
    </DashboardModule>
  )
}

DuskPositional.propTypes = {
  serviceJobDetail: PropTypes.object,
  serviceJobId: PropTypes.number,
  editable: PropTypes.bool,
}

export default DuskPositional
