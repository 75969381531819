import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'

import { useSetState } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'

import blankState from '@components/assets/blankState.svg'

import useReduxAction from '@hooks/useReduxAction'
import useCurrentUser from '@hooks/useCurrentUser'

import { getServiceStatusColor } from '@functions/getServiceStatusColor'

import { DashboardModule, ListItem, Box, Text, Grid, StatusBadge, LoadingModule } from '@campaignhub/suit-theme'

const defaultState = {
  filteredActivityLog: [],
}

const ActivityLogs = (props) => {
  const { hidden, serviceJob, workflowUpdated } = props
  const [state, setState] = useSetState(defaultState)
  const { filteredActivityLog } = state

  const { currentUser: { timeZone } } = useCurrentUser()

  const payload = {
    serviceJobs: serviceJob.id,
    includes: 'createdBy',
  }

  useReduxAction('activityLogs', 'loadActivityLogs', { payload }, [serviceJob, workflowUpdated])

  const { loading, result: activityLogs } = useSelector(reduxState => reduxState.activityLogs)
  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses, activityLogTypes } = entities

  useEffect(() => {
    if (!activityLogs || Object.keys(activityLogs)?.length) {
      const groupByActivityLogFilter = activityLogs?.reduce((filtered, activityLog) => {
        if (!filtered.find(x => x.id === activityLog.id)) {
          filtered.push({ ...activityLog })
        }
        return sortArrayBy(filtered, 'desc', 'id')
      }, [])
      setState({
        filteredActivityLog: groupByActivityLogFilter ? [...groupByActivityLogFilter] : [],
      })
    } else {
      setState({
        filteredActivityLog: [],
      })
    }
  }, [activityLogs])

  if (!filteredActivityLog.length) {
    return (
      <DashboardModule.BlankState imageUrl={blankState}>
        <DashboardModule.BlankState.Title>Activity Log is empty</DashboardModule.BlankState.Title>
      </DashboardModule.BlankState>
    )
  }

  if (hidden) return null

  return (
    <DashboardModule title="Activity Logs">
      <LoadingModule loading={loading} />
      {!loading && (
        <Box flexDirection="column" maxHeight="82vh" overflowY="auto">
          {Object.values(filteredActivityLog).map(activityLog => (
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                gridGap: 'large',
                padding: 'large',
              }}
              flexDirection={['column', 'column', 'row']}
              forceBorderBottom
              justifyContent="center"
              key={activityLog.id}
              disableHover
            >
              <Box flexDirection="row" width={['100%', '20%']}>
                <Box flexDirection="column" justifyContent="center">
                  <Text fontSize="small" marginBottom="medium">
                    {activityLogTypes[activityLog.activityLogTypeId]?.description}
                  </Text>
                </Box>
              </Box>
              <Grid
                gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                gridGap="small"
                width={['100%', '100%', '75%']}
              >
                <Box flexDirection="column" justifyContent="center">
                  <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">Description</Text>
                  <Text fontSize="small" variant="ellipsis">{activityLog.description ? activityLog.description : '-'}</Text>
                </Box>
                <Box flexDirection="column" justifyContent="center">
                  {activityLog.serviceJobStatusId > 0 && (
                    <>
                      <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">Status</Text>
                      <StatusBadge
                        boxProps={{ marginRight: 'small' }}
                        color={getServiceStatusColor(serviceJobStatuses[activityLog.serviceJobStatusId]?.name)}
                        ghost
                        text={serviceJobStatuses[activityLog.serviceJobStatusId]?.description}
                      />
                    </>
                  )}
                </Box>
                <Box flexDirection="column" justifyContent="center">
                  <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">User</Text>
                  <Text fontSize="small">{activityLog.createdBy?.firstName} {activityLog.createdBy?.lastName}</Text>
                </Box>
              </Grid>
              <Box flexDirection="column" justifyContent="center" width={['100%', '100%', '20%']} textAlign={['left', 'left', 'right']}>
                <Text fontSize="small">{DateTime.fromISO(activityLog.createdAt, { zone: timeZone }).toFormat('dd LLLL y, hh:mm a')}</Text>
              </Box>
            </ListItem>
          ))}
        </Box>
      )}
    </DashboardModule>
  )
}

ActivityLogs.propTypes = {
  hidden: PropTypes.bool,
  serviceJob: PropTypes.object,
  workflowUpdated: PropTypes.bool,
}

export default ActivityLogs
