import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'
import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as setAvailableActions from '@redux/modules/serviceJobSetAvailable'

const getServiceJobSetAvailable = (id, dispatch) => {
  const { getServiceJobSetAvailable: loadFn } = setAvailableActions

  return dispatch(loadFn(id, []))
}

const useServiceJobSetAvailable = (id) => {
  useReduxAction('serviceJobSetAvailable', 'getServiceJobSetAvailable', id, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    },
  })

  const entityReducer = useSelector(reduxState => reduxState.serviceJobSetAvailable)
  const {
    errors,
    loaded,
    loading,
    result: serviceJobSetAvailable,
  } = entityReducer

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      getServiceJobSetAvailable: serviceJobId => getServiceJobSetAvailable(serviceJobId, dispatch),
    },
    serviceJobSetAvailable,
    errors,
    loaded,
    loading,
  }
}

export default useServiceJobSetAvailable
