const mapServiceTypeResults = (serviceCategories, services, linkedServiceTypes) => {
  const results = Object.values(serviceCategories).filter(category => category.id !== -1 && category.services?.length > 0)

  return {
    groups: results.map((result) => {
      const { description, workbaseReference, services: categoryServices } = result

      return {
        key: workbaseReference,
        title: description,
        items: categoryServices?.map((service) => {
          const serviceDetails = Object.values(services).find(x => x.id === service)

          if (!linkedServiceTypes.find(linked => linked === serviceDetails.workbaseReference)){
            return {
              ...serviceDetails,
              key: serviceDetails.workbaseReference,
              text: serviceDetails.name,
            }
          }
          return serviceDetails
        }),
      }
    }),
  }
}

export default mapServiceTypeResults
