import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, FormField, Tag, Text,
} from '@campaignhub/suit-theme'

import useFlags from '@hooks/useFlags'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons'

const GeneralDetail = (props) => {
  const { callbacks, userForm } = props
  const {
    entityState,
    entityState: {
      email,
      employment,
      flags,
      jobtrack_username,
      name,
      username,
    },
    errors,
    handlers,
    setEntityState,
  } = userForm

  const { setSelectedRole } = callbacks

  const { result: employmentTypesV3 } = useSelector(reduxState => reduxState.employmentTypesV3)
  const { result: userFlagsV3 } = useSelector(reduxState => reduxState.userFlagsV3)
  const { result: userRolesV3 } = useSelector(reduxState => reduxState.userRolesV3)

  const userRoleChange = (event) => {
    setEntityState({ roles: (event.target.value !== '' && event.target.value !== null) ? [event.target.value] : undefined })
    setSelectedRole(event.target.value)
  }

  useEffect(() => {
    const role = entityState.roles !== undefined ? entityState.roles[0] : ''
    setSelectedRole(role)
  }, [entityState.roles])

  const { callbacks: { editFlags } } = useFlags()

  return (
    <DashboardModule title="General Information">
      <Box flexDirection="column" padding="large">
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Name"
            errorMessage={errors.name}
          >
            <input name="name" type="text" value={name} {...handlers} />
          </FormField>
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
            label="Username"
            errorMessage={errors.username}
          >
            <input name="username" type="text" value={username} {...handlers} />
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Email"
            errorMessage={errors.email}
          >
            <input name="email" type="text" value={email} {...handlers} />
          </FormField>
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
            label="Jobtrack Username"
          >
            <input name="jobtrack_username" type="text" value={jobtrack_username} {...handlers} />
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
            label="Employment"
            errorMessage={errors.employment}
          >
            <select
              data-validate-field-on="change"
              value={employment}
              name="employment"
              {...handlers}
            >
              <option value="" disabled>Please Select...</option>
              {Object.values(employmentTypesV3).map(employmentType => (
                <option value={employmentType.value} key={employmentType.value}>
                  {employmentType.name}
                </option>
              ))}
            </select>
          </FormField>
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
            label="Roles"
            errorMessage={errors.roles}
          >
            <select
              data-validate-field-on="change"
              value={entityState.roles[0] || ''}
              onChange={event => userRoleChange(event)}
            >
              <option value="" disabled>Please Select...</option>
              {Object.values(userRolesV3).map(role => (
                <option value={role.value} key={role.value}>{role.name}</option>
              ))}
            </select>
          </FormField>
        </Box>
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
          <FormField
            boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
            label={(
              <Box flexDirection="row" alignItems="center">
                <Text
                  color="bodyFontLightColor"
                  fontSize="xsmall"
                  letterSpacing="1px"
                  marginRight="small"
                >
                  Flags
                </Text>
                <Tag
                  boxProps={{
                    backgroundColor: '#43ac6a!important',
                    fontSize: 'xsmall',
                    onClick: () => { editFlags('user') },
                    width: 'fit-content',
                  }}
                >
                  <Text color="white" fontSize={9} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Text>
                </Tag>
              </Box>
            )}
          >
            <Box flexWrap="wrap" gridGap="small">
              {flags.map(flag => (
                <Tag
                  key={flag}
                  boxProps={{
                    width: 'fit-content',
                    fontSize: 'xsmall',
                    onClick: () => {
                      setEntityState({ flags: flags.filter(x => x !== flag) })
                    },
                  }}
                >
                  <Text color="bodyFontLightColor" style={{ cursor: 'pointer' }} title={userFlagsV3.find(x => x.value === flag)?.description}>
                    {userFlagsV3.find(x => x.value === flag)?.name} &nbsp;<FontAwesomeIcon icon={faTimes} />
                  </Text>
                </Tag>
              ))}
            </Box>
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
    )
 }
 GeneralDetail.propTypes = {
  callbacks: PropTypes.object,
  userForm: PropTypes.object,
  customErrors: PropTypes.array,
}

  export default GeneralDetail
