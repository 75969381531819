import { useSelector } from "react-redux"

import useReduxAction from '@hooks/useReduxAction'

const useServiceJobUserRoles = () => {
  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobUserRoles } = entities

  useReduxAction('serviceJobUserRoles', 'loadServiceJobUserRoles', {}, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    }
  })

  return {
    serviceJobUserRoles,
  }
}

export default useServiceJobUserRoles