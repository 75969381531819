import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, FormField, ListItem, Text } from '@campaignhub/suit-theme'

import { useServiceCategoryForm } from '@hooks/useServiceCategory'

const ServiceCategoryItem = (props) => {
  const { serviceCategory, callbacks } = props
  const { insertError, removeError } = callbacks

  const serviceCategoryForm = useServiceCategoryForm(serviceCategory)

  const {
    entityState,
    entityState: {
      threshold,
      capacityDuration,
      description,
    },
    handlers,
    errors,
  } = serviceCategoryForm

  useEffect(() => {
    serviceCategory.threshold = entityState.threshold
    serviceCategory.capacityDuration = entityState.capacityDuration
  }, [entityState])

  useEffect(() => {
    if (Object.keys(errors).length > 0){
      insertError(entityState.id)
    } else {
      removeError(entityState.id)
    }
  }, [errors])

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="column"
    >
      <Box flexDirection={['column', 'row']}>
        <Box flexDirection="column" alignSelf="center" flex={1} marginBottom={['large', 0]}>
          <Text fontSize="small" color="bodyFontLightColor">{description}</Text>
        </Box>
        <FormField
          boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
          label="Threshold"
          errorMessage={errors.threshold}
        >
          <input name="threshold" type="number" value={threshold} {...handlers} />
        </FormField>
        <FormField
          boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
          label="Duration (in hours)"
          errorMessage={errors.capacityDuration}
        >
          <input name="capacityDuration" type="number" value={capacityDuration} {...handlers} />
        </FormField>
      </Box>
    </ListItem>
  )
}

ServiceCategoryItem.propTypes = {
  serviceCategory: PropTypes.object.isRequired,
  callbacks: PropTypes.object,
}

export default ServiceCategoryItem
