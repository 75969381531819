import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import {
  Box, Image, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import Icons from '@components/Icons'
import { getServiceStatusColor } from '@functions/getServiceStatusColor'

const AssetDetails = () => {
  const { callbacks, selectedAsset, serviceJob } = useContext(PageContext)
  const { showAssetUploadVersionModal } = callbacks || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, serviceJobStatuses } = entities

  const latestVersion = selectedAsset?.histories?.reduce((x, y) => ((x.version > y.version) ? x : y))

  return (
    <ListItem
      boxProps={{ padding: 'large' }}
      onClick={() => (serviceJobStatuses[serviceJob.serviceJobStatusId]?.name !== 'SentToClient' && serviceGroups[serviceJob?.serviceGroupId]?.name !== 'Copywriting'
        ? showAssetUploadVersionModal() : null)}
      showAngleIcon={serviceJobStatuses[serviceJob.serviceJobStatusId]?.name !== 'SentToClient' && serviceGroups[serviceJob?.serviceGroupId]?.name !== 'Copywriting'}
      disableHover={serviceJobStatuses[serviceJob.serviceJobStatusId]?.name === 'SentToClient' || serviceGroups[serviceJob?.serviceGroupId]?.name === 'Copywriting'}
    >
      <Box flexDirection="column">
        <Box flexDirection="row">
          <Box width={60} marginRight="medium">
            <Image
              borderRadius={5}
              height={45}
              marginRight="medium"
              url={selectedAsset?.file?.previewGeneratedAt ? selectedAsset.previewUrl : ''}
              width={60}
            >
              {!selectedAsset?.file?.previewGeneratedAt && (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  display="grid"
                  height="100%"
                  justifyContent="center"
                  position="absolute"
                  width="100%"
                >
                  <Icons name={serviceGroups[serviceJob?.serviceGroupId]?.name || 'Photography'} size={25} width="100%" />
                </Box>
              )}
            </Image>
          </Box>

          <Box justifyContent="center" flexDirection="column" width="calc(100% - 75px)">
            <Text color="bodyFontLightColor" fontSize="small" marginBottom="small" variant="ellipsis">
              {serviceGroups[serviceJob?.serviceGroupId]?.name === 'Copywriting'
                ? serviceJob?.name
                : `Version ${selectedAsset?.version}  ${(latestVersion?.version === selectedAsset?.version) ? '(Current)' : ''}`}
            </Text>
            <StatusBadge
              color={getServiceStatusColor(serviceJobStatuses[selectedAsset?.serviceJobStatusId]?.name)}
              ghost
              text={serviceJobStatuses[selectedAsset?.serviceJobStatusId]?.description}
            />
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

export default AssetDetails
