export function customColor(colorKey, fallbackColor = '#6B818C'){
  const colors = {
    // employment
    full_time: '#6FAA01',
    contract: '#FFA14A',
    locked_in_contract: '#67ACBC',

    // classifications
    casual: '#EDB230',
    partner: '#1C7293',
    vip: '#FE482C',

    // roles
    back_office: '#6A66A3',
    customer_service: '#7F2982',
    editor: '#2771CC',
    finance: '#3C855C',
    operation: '#FC8C41',
    production_manager: '#0D5C63',
    provider: '#FE482C',
    qc: '#FFC107',
  }

  return colors[colorKey] || fallbackColor
}

export default customColor
