import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'

const watchEntityKeys = ['youtubeChannels']

const useYoutubeChannels = (options = {}) => {
  const {
    selectedVideoServiceType,
  } = options

  const {
    updatedEntities: { youtubeChannels: youtubeChannelsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { youtubeChannels } = useSelector(reduxState => reduxState.entities)

  const filteredYoutubeChannels = useMemo(() => {
    const filtered = Object.values(youtubeChannels).filter(channel => (
      selectedVideoServiceType.length ? selectedVideoServiceType.includes(channel.videoServiceTypeId) : channel.videoServiceTypeId
    ))

    return sortArrayBy(filtered, 'asc', 'name')
  }, [youtubeChannelsUpdatedAt, options])

  const hasfilteredYoutubeChannels = !!filteredYoutubeChannels.length

  const entityReducer = useSelector(reduxState => reduxState.youtubeChannels)
  const { loading } = entityReducer

  return {
    filteredYoutubeChannels,
    hasfilteredYoutubeChannels,
    loading,
    youtubeChannels,
  }
}

export default useYoutubeChannels
