import { useThunkDispatch, useLatestEntity } from '@campaignhub/react-hooks'
import * as timeTrackerActions from '@redux/modules/timetracker'

const clockIn = (dispatch) => {
  const { clockIn: clockInFn } = timeTrackerActions
  return dispatch(clockInFn())
}

const clockOut = (dispatch) => {
  const { clockOut: clockOutFn } = timeTrackerActions
  return dispatch(clockOutFn())
}

const startBreak = (dispatch) => {
  const { startBreak: startBreakFn } = timeTrackerActions
  return dispatch(startBreakFn())
}

const endBreak = (dispatch) => {
  const { endBreak: endBreakFn } = timeTrackerActions
  return dispatch(endBreakFn())
}

const getUserTime = (options, dispatch) => {
  const { getUserTime: getUserTimeFn } = timeTrackerActions
  return dispatch(getUserTimeFn(options))
}

const useTimeTracker = (initTimetracker = {}) => {
  const { entity: timetracker } = useLatestEntity(initTimetracker, 'timetrackers')

  const dispatch = useThunkDispatch()

  return {
    timetracker,
    callbacks: {
      clockIn: () => clockIn(dispatch),
      clockOut: () => clockOut(dispatch),
      startBreak: () => startBreak(dispatch),
      endBreak: () => endBreak(dispatch),
      getUserTime: options => getUserTime(options, dispatch),
    },
  }
}

export default useTimeTracker
