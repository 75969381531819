import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState from '@models/slot'

import * as slotActions from '@redux/modules/slot'

const filterSlotParams = (slotParams) => {
  let filteredParam = Object.entries(slotParams)
  const filteredSlots = filteredParam.filter(x => x[1] !== undefined)

  const updatedSlots = Object.fromEntries(filteredSlots)
  filteredParam = updatedSlots

  return filteredParam
}

const updateSlots = (slotsParams, dispatch, requestOptions) => {
  const { updateSlots: updateFn } = slotActions

  return dispatch(updateFn(filterSlotParams(slotsParams)), requestOptions)
}

export function useSlotForm(slot = {}){
  const slotForm = useForm(defaultState, { entity: { ...defaultState, ...slot } }, [slot])
  const initialValue = Object.keys(slot).length === 0 ? defaultState : cloneDeep(slotForm.entityState)

  const { loaded, loading } = useSelector(reduxState => reduxState.slots)

  return {
    ...slotForm,
    initialValue,
    loaded,
    loading,
  }
}

const useSlot = (initSlot = {}) => {
  const { entity: slot } = useLatestEntity(initSlot, 'slots')

  const dispatch = useThunkDispatch()

  return {
    slot,
    callbacks: {
      updateSlots: (slotsParams, requestOptions) => updateSlots(slotsParams, dispatch, requestOptions),
    },
  }
}

export default useSlot
