import {
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = ({ toggleNavigate }) => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          icon: faTachometer,
          key: 'dashboard',
          onClick: () => toggleNavigate('/'),
          title: 'Dashboard',
          visible: true,
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
