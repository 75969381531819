import { useThunkDispatch } from '@campaignhub/react-hooks'
import { useSelector } from 'react-redux'

import * as serviceJobChecklistItemActions from '@redux/modules/serviceJobChecklistItem'

const updateServiceJobChecklistItem = (checklistDetail, dispatch, requestOptions) => {
  const { updateServiceJobChecklistItem: updateFn } = serviceJobChecklistItemActions

  return dispatch(updateFn(checklistDetail, requestOptions))
}

const useServiceJobChecklistItem = () => {
  const dispatch = useThunkDispatch()

  const { updating } = useSelector(reduxState => reduxState.serviceJobChecklistItems)

  return {
    callbacks: {
      updateServiceJobChecklistItem: (checklistDetail, requestOptions) => updateServiceJobChecklistItem(checklistDetail, dispatch, requestOptions),
    },
    updating,
  }
}

export default useServiceJobChecklistItem
