import api from '@functions/api'

import { handleError } from '../utils'

const CREATE_REQUEST = 'contenthouse/serviceJobPushRaw/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/serviceJobPushRaw/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/serviceJobPushRaw/CREATE_FAILURE'

// Initial State
const initialState = {
    errors: [],
    loaded: false,
    loading: false,
    creating: false,
  }

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function postServiceJobPushRaw(serviceJobId, options){
  const config = {
    method: 'POST',
    data: options,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/v2/service-jobs/${serviceJobId}/push-raw`, options, config)
      .then(() => {
        dispatch(createSuccess())
        return { success: true, data: {} }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export default function reducer(state = initialState, action = {}){
  const { additionalInfo, type, errors, loadedForKeys, loadedIds, results } = action

  switch (type){
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
