import { DateTime } from 'luxon'
import { minuteToTimeString } from '@functions/minuteToTimeString'

import Ghost from './ghost'
import Range from './range'
import Base from './base'
import Emitter from './emitter'
import { addClass } from './utils'

class Bar extends Base {
  constructor(options = {}){
    super()

    this.options = {
      allowRemove: true,
      allowAdd: true,
      allowChange: true,
      maxRanges: Infinity,
      ghostLabel: () => '+',
      label: value => `${value[0].toString() }-${ value[1].toString()}`,
      valueParse: value => value,
      valueFormat: value => value,
      ...options,
    }

    this.el = document.createElement('div')

    this.el.className = 'multirangeslider-bar'

    if (this.options.allowChange === false){
      addClass(this.el, 'multirangeslider-allowChangeFalse')
    }

    this.el.addEventListener('mousemove', event => this.mousemove(event))
    this.el.addEventListener('mouseleave', event => this.mouseleave(event))
    this.el.addEventListener('mouseup', event => this.mouseup(event))
    this.el.addEventListener('mousedown', event => this.mousedown(event))

    this.el.addEventListener('touchmove', event => this.mousemove(event))
    this.el.addEventListener('touchcancel', event => this.mouseleave(event))
    this.el.addEventListener('touchend', event => this.mouseup(event))
    this.el.addEventListener('touchstart', event => this.mousedown(event))

    this.el.ondragstart = () => false

    this.rangeIdCount = 0

    this.rangeList = []

    this.emitter = new Emitter()
  }

  getRangeId(){
    // Just return some unique number
    this.rangeIdCount += 1
    return this.rangeIdCount
  }

  proxyRangeEvent(eventName, range){
    range.emitter.addListener(eventName, () => {
      this.emitter.emit(eventName, {
        data: this.data(),
        range: range.data(),
      })
    })
  }

  addNewRange(options){
    const range = new Range(options)

    this.el.appendChild(range.el)

    this.rangeList.push(range)

    const rangeId = range.id

    const eventNames1 = ['remove', 'changing', 'change', 'click']
    eventNames1.forEach(eventName => this.proxyRangeEvent(eventName, range))

    range.emitter.addListener('remove', () => {
      this.remove(rangeId)
    })

    const eventNames2 = ['change', 'addRange']
    eventNames2.forEach((eventName) => {
      this.emitter.emit(eventName, {
        data: this.data(),
        range: range.data(),
      })
    })

    return range
  }

  addRange(value, options){
    if (this.rangeList.length >= this.options.maxRanges){
      return false
    }

    options = {
      id: this.getRangeId(),
      value,
      allowChange: this.options.allowChange,
      ...options,
      bar: this,
    }

    return this.addNewRange(options)
  }

  addRanges(){
    if (this.options.values.length > 0){
      this.options.values.map((x) => {
        const startTime = DateTime.fromISO(x.start)
        const start = (startTime.hour * 60) + startTime.minute

        const endTime = DateTime.fromISO(x.end)
        const end = (endTime.hour * 60) + endTime.minute

        const options = {
          id: this.getRangeId(),
          allowChange: this.options.allowChange,
          value: [start, end],
          bar: this,
        }

        return this.addNewRange(options)
      })
    }
  }

  remove(rangeId){
    const range = this.rangeList.find(x => x.id === rangeId)

    if (range){
      range.removeEvents()

      this.el.removeChild(range.el)

      this.rangeList = this.rangeList.filter(x => x.id !== rangeId)

      return true
    }

      return false
  }

  removeGhost(){
    if (this.ghost){
      this.ghost.removeEvents()
      this.el.removeChild(this.ghost.el)
      delete this.ghost
    }
  }

  mousedown(){}

  mouseup(){
    if (this.ghost){
      this.addRange([this.ghost.left, this.ghost.right])
    }
  }

  mouseleave(){
    this.removeGhost()
  }

  getInsideRange(cursor){
    for (const range of this.rangeList){
      if (range.left < cursor && cursor < range.right){
        return range
      }
    }

    return false
  }

  isOverRange(left, right){
    for (const range of this.rangeList){
      if (left <= range.left && range.right <= right){
        return true
      }
    }

    return false
  }

  getNewGhostValue(cursor){
    if (this.getInsideRange(cursor)){
      return null
    }

    cursor = this.roundUserValue(cursor)

    const h = this.options.minWidth / (this.options.step)
    const dLeft = Math.floor(h / 2) * this.options.step
    const dRight = Math.floor((h + 1) / 2) * this.options.step

    let left = cursor - dLeft
    let right = cursor + dRight

    if (this.options.max < right){
      right = this.options.max

      if (right - left < this.options.minWidth){
        left = this.options.max - this.options.minWidth
      }
    }

    if (left < this.options.min){
      left = this.options.min

      if (right - left < this.options.minWidth){
        right = this.options.min + this.options.minWidth
      }
    }

    const rangeLeft = this.getInsideRange(left)

    if (rangeLeft){
      left = rangeLeft.getValue()[1]

      right = left + this.options.minWidth
    }

    const rangeRight = this.getInsideRange(right)

    if (rangeRight){
      right = rangeRight.getValue()[0]

      left = right - this.options.minWidth
    }

    if (this.getInsideRange(left) || this.getInsideRange(right)){
      return null
    }

    if (left < this.options.min){
      return null
    }

    if (this.options.max < right){
      return null
    }

    return [left, right]
  }

  mousemove(event){
    if (this.ghost){
      return
    }

    if (this.options.allowAdd === false){
      return
    }

    if (this.rangeList.length >= this.options.maxRanges){
      return
    }

    if (this.rangeList.filter(x => x.pressed).length > 0){
      return
    }

    const cursor = this.getCursor(event)

    const newGhostValue = this.getNewGhostValue(cursor)

    if (newGhostValue == null){
      return
    }

    this.ghost = new Ghost({ bar: this })

    this.el.appendChild(this.ghost.el)

    this.ghost.setValue(newGhostValue)
  }

  getValue(){
    const result = []

    for (const range of this.rangeList){
      const value = range.getValue()

      result.push(value)
    }

    return result
  }

  data(){
    return this.rangeList.map((x) => {
      const start = x.data().val[0] * 60
      const end = x.data().val[1] * 60

      return {
        start: minuteToTimeString(start),
        end: minuteToTimeString(end),
      }
    })
  }

  render(){
    for (const range of this.rangeList){
      range.render()
    }
  }
}

export default Bar
