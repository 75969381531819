import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import Comment from '@suiteThemeCustom/Comment'
import PageContext from '@contexts/pageContext'
import useAssetComment from '@hooks/useAssetComment'

import styles from './styles.module.scss'

const AssetComment = (props) => {
  const { comment } = props
  const {
    assetVideo, assignedUserRole, currentUser, users,
  } = useContext(PageContext)

  const assetCommentPayload = useAssetComment()
  const {
    callbacks: {
      deleteAssetComment: deleteFn,
      updateAssetComment: updateFn,
    },
  } = assetCommentPayload

  const updateComment = () => {
    if (!assignedUserRole.length && !['ProductionManager'].includes(currentUser.userRole.name)) return null
    if (comment.status === 'Resolved' && !(assignedUserRole?.find(x => ['QualityControl', 'Specialist'].includes(x.serviceJobUserRole?.name))
      || ['ProductionManager'].includes(currentUser.userRole.name))) return null

    const updateToStatus = () => {
      switch (comment.status){
        case null:
          return !(assignedUserRole?.find(x => ['QualityControl', 'Specialist'].includes(x.serviceJobUserRole?.name))
            || ['ProductionManager'].includes(currentUser.userRole.name)) ? 'Revised' : 'Resolved'
        case 'Revised':
          return !(assignedUserRole?.find(x => ['QualityControl', 'Specialist'].includes(x.serviceJobUserRole?.name))
            || ['ProductionManager'].includes(currentUser.userRole.name)) ? null : 'Resolved'
        case 'Resolved':
          return null
        default: return null
      }
    }

    const updatedComment = {
      id: comment.id,
      comment: comment.comment,
      status: updateToStatus(),
    }

    return updateFn((updatedComment)).then(({ success, errors }) => {
      if (!success){
        toast.warning(errors[0])
      }

      return success
    })
  }

  const iconClassName = () => {
    if (['ProductionManager'].includes(currentUser.userRole.name) || assignedUserRole.length){
      const iconClass = `${(!(assignedUserRole?.find(x => ['QualityControl', 'Specialist'].includes(x.serviceJobUserRole?.name))
        || ['ProductionManager'].includes(currentUser.userRole.name)) ? 'retoucher' : 'qc') + (comment.status === null ? 'Unresolved' : comment.status) }Icon`

      return styles[iconClass]
    }

    return styles[`default${(comment.status === null ? 'Unresolved' : comment.status)}Icon`]
  }

  const deleteComment = () => {
    swal.fire({
      title: 'Delete Comment',
      html: 'This action will delete the comment.'
      + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
    }).then(({ value }) => {
      if (value){
        deleteFn(comment).then(({ success, errors }) => {
          if (!success){
            toast.warning(errors[0])
          }
        })
      }
    })
  }

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        paddingY: 'large',
        borderBottom: '1px dashed',
      }}
      flexDirection="row"
      disableHover
      key={comment.id}

    >
      <Box paddingBottom="xsmall" marginBottom="xsmall">
        <Box className={styles.root} flexDirection="column" width="auto">
          <Text
            borderRadius={20}
            className={iconClassName()}
            color="white"
            fontSize="small"
            fontWeight={500}
            style={{ cursor: 'pointer' }}
            textAlign="center"
            lineHeight="34px"
            marginRight="large"
            onClick={() => updateComment()}
            width="34px"
          >
            {comment.commentIndex}
          </Text>
        </Box>
        <Comment
          assetVideo={assetVideo || null}
          boxProps={{ marginBottom: 0, borderBottom: 0, paddingBottom: 0 }}
          callbacks={{ deleteCallback: () => deleteComment() }}
          commentBody={comment.comment}
          commentTime={DateTime.fromISO(comment.createdAt, { zone: currentUser.timeZone }).toFormat('dd LLLL y, hh:mm a')}
          fullName={`${users[comment.createdById]?.firstName} ${users[comment.createdById]?.lastName}`}
          hideImage
          isDeletable={comment.timestamp >= 0 && (comment.createdById === currentUser.id || ['ProductionManager'].includes(currentUser.userRole.name))}
          timestamp={comment.timestamp >= 0 ? comment.timestamp : null}
        />
      </Box>
    </ListItem>
  )
}

AssetComment.propTypes = {
  comment: PropTypes.object,
}

export default AssetComment
