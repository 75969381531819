import React from 'react'
import PropTypes from 'prop-types'

import {
  Button, FormField, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

const ResourcePrompts = (props) => {
  const { callbacks, uploadState } = props

  const { allowResourceUpload, updateUploadCount } = callbacks
  const { allowUpload, doneUpload, filesToUploadCount, recentlyFailed, recentlyUploaded } = uploadState

  return (
    <>
      {!doneUpload && !(recentlyUploaded.length > 0 || recentlyFailed.length > 0) && (
        <SidebarNoticeBox boxProps={{ marginBottom: 'medium' }}>
          <SidebarNoticeBox.Title>Number of Resources</SidebarNoticeBox.Title>

          <SidebarNoticeBox.Paragraph>
            How many files will you be uploading?
          </SidebarNoticeBox.Paragraph>
          <FormField direction="column" marginTop="medium">
            <input
              type="number"
              onChange={e => updateUploadCount(e.target.value)}
              value={filesToUploadCount > 0 ? filesToUploadCount : ''}
              disabled={allowUpload}
            />
          </FormField>
          <Button
            buttonStyle="primaryCreate"
            size="medium"
            marginTop="medium"
            onClick={() => {
              if (parseInt(filesToUploadCount, 10) > 0){
                allowResourceUpload()
              }
            }}
            disabled={allowUpload}
          >
            Okay
          </Button>
        </SidebarNoticeBox>
      )}

      {(recentlyUploaded.length > 0 || recentlyFailed.length > 0) && (
        <>
          <SidebarNoticeBox boxProps={{ marginBottom: 'medium' }}>
            <SidebarNoticeBox.Title>Resources</SidebarNoticeBox.Title>

            <SidebarNoticeBox.Paragraph>
              {recentlyUploaded.length} out of {filesToUploadCount} images uploaded.
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>

          {recentlyUploaded.length !== parseInt(filesToUploadCount, 10) && (
            <SidebarNoticeBox
              boxProps={{
                marginBottom: 'medium',
                backgroundColor: 'lightcyan!important',
                borderColor: 'rgb(125, 208, 215, 0.5)!important',
              }}
            >
              <SidebarNoticeBox.Title>Resources</SidebarNoticeBox.Title>

              <SidebarNoticeBox.Paragraph>
                Your resource QTY does not match the total number of uploaded resources. Please note that you can always upload or delete resources from your end.
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}
        </>
      )}
    </>
  )
}

ResourcePrompts.propTypes = {
  callbacks: PropTypes.object.isRequired,
  uploadState: PropTypes.object,
}

export default ResourcePrompts
