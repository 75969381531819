import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, DashboardModule, FormField, Text,
} from '@campaignhub/suit-theme'

import useServiceJobNotes from '@hooks/useServiceJobNotes'

const createServiceJobNote = (serviceJobNote, createFn) => {
  createFn(serviceJobNote).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const updateServiceJobNote = (serviceJobNote, updateFn) => {
  updateFn(serviceJobNote).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const deleteServiceJobNote = (serviceJobNote, deleteFn) => {
  deleteFn(serviceJobNote).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const defaultState = { noteContent: '' }

const ServiceJobNote = (props) => {
  const { dashboardModule, editable, serviceJobId, serviceJobNoteTypeId, title } = props

  const [state, setState] = useSetState(defaultState)
  const { noteContent } = state

  const {
    callbacks: {
      createServiceJobNote: createFn,
      updateServiceJobNote: updateFn,
      deleteServiceJobNote: deleteFn,
    },
    creating,
    deleting,
    updating,
    serviceJobNotes,
  } = useServiceJobNotes()

  const serviceJobNote = Object.values(serviceJobNotes).find(x => x.serviceJobNoteTypeId === serviceJobNoteTypeId && x.serviceJobId === serviceJobId)

  useEffect(() => {
    setState({ noteContent: serviceJobNote?.content })
  }, [serviceJobNote])

  const saveNote = () => {
    const notePayload = {
      content: noteContent,
      serviceJobId,
      serviceJobNoteTypeId,
    }

    if (noteContent?.trim() === '' || !noteContent){
      if (!serviceJobNote) return
      notePayload.id = serviceJobNote.id
      deleteServiceJobNote(notePayload, deleteFn)
      return
    }
    if (!serviceJobNote){
      createServiceJobNote(notePayload, createFn)
    } else {
      notePayload.id = serviceJobNote.id
      updateServiceJobNote(notePayload, updateFn)
    }
  }

  const noteComponent = (
    <Box flexDirection="column" padding={dashboardModule ? 'large' : 0} lineHeight="1.3">
      {!dashboardModule && title && (
        <Text fontSize="small" paddingBottom="small">
          {title}
        </Text>
      )}
      {editable ? (
        <FormField>
          <textarea
            name="noteContent"
            onChange={e => setState({ noteContent: e.target.value })}
            placeholder="Type your note here..."
            value={noteContent}
            style={{ height: 80, resize: 'none', width: '100%' }}
          />
          <Button
            buttonStyle="primaryCreate"
            loading={creating || deleting || updating}
            onClick={() => saveNote(noteContent)}
            size="medium"
            marginLeft="auto"
            marginTop="medium"
            width="auto"
          >
            {serviceJobNote?.content ? 'Update' : 'Save'}
          </Button>
        </FormField>
      ) : (
        <Text fontSize="xsmall" color="bodyFontLightColor">
          {noteContent || 'No Available Notes'}
        </Text>
      )}
    </Box>
  )

  if (dashboardModule){
    return (
      <DashboardModule title={title || 'Notes'}>
        {noteComponent}
      </DashboardModule>
    )
  }

  return noteComponent
}

ServiceJobNote.propTypes = {
  dashboardModule: PropTypes.bool,
  editable: PropTypes.bool,
  serviceJobId: PropTypes.number,
  serviceJobNoteTypeId: PropTypes.number,
  title: PropTypes.string,
}

export default ServiceJobNote
