import React from 'react'

import { faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Columns,
  DashboardModule,
  ModuleBlankState,
  PageHeader,
  ModalContext,
} from '@campaignhub/suit-theme'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'

import CreateOrEditWordReplacementsModal from '@modals/CreateOrEditWordReplacementsModal'
import CreateOrEditEmailReplacementsModal from '@modals/CreateOrEditEmailReplacementsModal'
import EditServiceCategoriesModal from '@modals/EditServiceCategoriesModal'

import TasksImageUrl from '@components/assets/tasks.svg'

import Tools from './components/Tools'

const defaultState = {
  showEditServiceCategoriesModal: false,
  showWordReplacementsModal: false,
  showEmailReplacementsModal: false,
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditWordReplacementsModal: {
      closeModal: () => setState({ showWordReplacementsModal: false }),
    },
    EditServiceCategoriesModal: {
      closeModal: () => setState({ showEditServiceCategoriesModal: false }),
    },
    CreateOrEditEmailReplacementsModal: {
      closeModal: () => setState({ showEmailReplacementsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const Overview = () => {
  const [state, setState] = useSetState(defaultState)
  const { showEditServiceCategoriesModal, showWordReplacementsModal, showEmailReplacementsModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    callbacks: {
      showEditServiceCategoriesModal: (payload) => {
        setModalData('EditServiceCategoriesModal', payload)
        setState({ showEditServiceCategoriesModal: true })
      },
      showWordReplacementsModal: (payload) => {
        setModalData('CreateOrEditWordReplacementsModal', payload)
        setState({ showWordReplacementsModal: true })
      },
      showEmailReplacementsModal: (payload) => {
        setModalData('CreateOrEditEmailReplacementsModal', payload)
        setState({ showEmailReplacementsModal: true })
      },
    },
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          activeTabBarItemKey="dashboard"
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          tabBarItems={[
            {
              href: '/',
              icon: faHome,
              key: 'dashboard',
              title: 'Dashboard',
            },
          ]}
          title="Admin Dashboard"
        />

        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns
            boxProps={{ marginTop: 'large' }}
            flexDirection={['column', 'column', 'row']}
          >
            <Columns.Main>
              <DashboardModule
                headerRight={(
                  <DashboardModule.Link href="#/">
                    Module Link
                  </DashboardModule.Link>
                )}
                title="Module"
              >
                <ModuleBlankState imageUrl={TasksImageUrl}>
                  <ModuleBlankState.Title>
                    No Tasks to Display
                  </ModuleBlankState.Title>

                  <ModuleBlankState.Paragraph>
                    Tasks are created when you assign a workflow to a campaign.
                    Workflow Tasks can be located in the campaign menu.
                  </ModuleBlankState.Paragraph>
                </ModuleBlankState>
              </DashboardModule>

              <DashboardModule
                headerRight={(
                  <DashboardModule.Link href="#/">
                    Module Link
                  </DashboardModule.Link>
                )}
                title="Module"
              >
                <ModuleBlankState imageUrl={TasksImageUrl}>
                  <ModuleBlankState.Title>
                    No Tasks to Display
                  </ModuleBlankState.Title>

                  <ModuleBlankState.Paragraph>
                    Tasks are created when you assign a workflow to a campaign.
                    Workflow Tasks can be located in the campaign menu.
                  </ModuleBlankState.Paragraph>
                </ModuleBlankState>
              </DashboardModule>
            </Columns.Main>

            <Columns.Sidebar>
              <Tools />
            </Columns.Sidebar>
          </Columns>
        </Box>
        <CreateOrEditWordReplacementsModal
          callbacks={callbacks('CreateOrEditWordReplacementsModal', setState)}
          showModal={showWordReplacementsModal}
        />
        <EditServiceCategoriesModal
          callbacks={callbacks('EditServiceCategoriesModal', setState)}
          showModal={showEditServiceCategoriesModal}
        />
        <CreateOrEditEmailReplacementsModal
          callbacks={callbacks('CreateOrEditEmailReplacementsModal', setState)}
          showModal={showEmailReplacementsModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
    )
}

export default Overview
