import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

// ToDo: CH-733 - check on backend
// import useReduxAction from '@hooks/useReduxAction'

import {
  Box, DashboardModule,
} from '@campaignhub/suit-theme'

import Summary from './components/Summary'

const Statistics = (props) => {
  const { hidden } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { statistics } = entities

  // useReduxAction('statistics', 'loadStatistics', {})

  if (hidden) return null

  return (
    <DashboardModule title="Statistics">
      <Box flexDirection="column">
        {Object.values(statistics).map(summary => (
          <Summary key={summary.id} summary={summary} />
        ))}
      </Box>
    </DashboardModule>
  )
}

Statistics.propTypes = {
  hidden: PropTypes.bool,
}

export default Statistics
