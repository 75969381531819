import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, InputSearch, LoadingBubbles, Tag, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

const LinkingTags = (props) => {
  const {
    callbacks, disabled, errorMessage, fullWidth, itemsDirection, label, linkedItems, loading,
    secondLabel, searchFilter, searchResults,
  } = props

  const {
    toggleItem, toggleSearch, removeItem,
  } = callbacks || {}

  return (
    <Box flexDirection="column">
      <FormField
        boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
        label={`${label} ${secondLabel || ''}`}
        errorMessage={errorMessage}
      >
        <Box flexDirection="row" width={fullWidth ? '100%' : ['100%', 'calc(50% - 4px)']}>
          <InputSearch
            callbacks={{ selectItem: result => toggleItem(result) }}
            onChange={e => toggleSearch(e.target.value)}
            placeholder={`Search ${label}`}
            showOnFocus
            value={searchFilter}
            closeOnSelect
            results={searchResults}
            disabled={disabled}
          />
        </Box>
        <Box flexDirection={itemsDirection || 'row'} flexWrap="wrap" gridGap="small" marginTop="medium">
          {linkedItems.length > 0 && linkedItems.sort((a, b) => (a.name > b.name ? 1 : -1)).map(x => (
            <Tag
              key={x.id}
              boxProps={{
                width: 'fit-content',
                fontSize: 'xsmall',
                onClick: () => (disabled ? null : removeItem(x)),
              }}
            >
              <Text color="bodyFontLightColor" style={{ cursor: disabled ? 'wait' : 'pointer' }}>
                {x.name} &nbsp;<FontAwesomeIcon icon={faTimes} />
              </Text>
            </Tag>
          ))}
          {!linkedItems.length && !loading && (
            <Text color="bodyFontLightColor" fontSize="xsmall">
              No linked {label.toLowerCase()}
            </Text>
          )}
          {loading && <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ flexShrink: 0 }} />}
        </Box>
      </FormField>
    </Box>
  )
}

LinkingTags.propTypes = {
  callbacks: PropTypes.object,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  itemsDirection: PropTypes.string,
  label: PropTypes.string.isRequired,
  linkedItems: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  secondLabel: PropTypes.string,
  searchFilter: PropTypes.string.isRequired,
  searchResults: PropTypes.object,
}

export default LinkingTags
