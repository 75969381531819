import React from 'react'

import {
  Box, Button, DashboardModule,
} from '@campaignhub/suit-theme'

import useBlockout from '@hooks/useBlockout' // CHANGEMOTO

const Tools = () => {
  const { callbacks: { createOrEditCurrentBlockout } } = useBlockout()

  return (
    <DashboardModule title="Actions">
      <Box flexDirection="column" padding="large">
        <Button
          buttonStyle="secondary"
          onClick={() => createOrEditCurrentBlockout()}
          size="medium"
        >
          Add Blockout
        </Button>
      </Box>
    </DashboardModule>
  )
}

export default Tools
