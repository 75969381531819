import { useContext } from 'react'
import { useSelector } from 'react-redux'
import useReduxAction from '@hooks/useReduxAction'
import PageContext from '@contexts/pageContext'
import useServiceCategory from '@hooks/useServiceCategory'

const editServiceCategories = (serviceCategories, updating, showEditServiceCategoriesModal, updateFn) => new Promise((resolve, reject) => {
  if (showEditServiceCategoriesModal){
    const payload = {
      callbacks: {
        updateServiceCategory: serviceCategoryParam => updateFn(serviceCategoryParam),
      },
      serviceCategories,
      updating,
    }

    showEditServiceCategoriesModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditServiceCategoriesModal not defined in PageContext callbacks'))
})

const useServiceCategories = (options = {}) => {
  const { performHttpRequests } = options || {}
  useReduxAction('serviceCategories', 'loadServiceCategories', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { callbacks: { updateServiceCategory: updateFn } } = useServiceCategory()

  const { callbacks } = useContext(PageContext)
  const { showEditServiceCategoriesModal } = callbacks || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceCategories } = entities

  const { updating, loading } = useSelector(reduxState => reduxState.serviceCategories)
  return {
    callbacks: {
      editServiceCategories: () => editServiceCategories(serviceCategories, updating, showEditServiceCategoriesModal, updateFn),
    },
    serviceCategories,
    loading,
  }
}

export default useServiceCategories
