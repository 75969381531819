import React, { useEffect, useRef, useContext } from 'react'
import PageContext from '@contexts/pageContext'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, FormField, Text } from '@campaignhub/suit-theme'
import videoServiceTypes from '@functions/videoServiceTypes'

import custom from '@styles/custom.module.scss'

const defaultState = {
  showFilter: false,
}

const VideoServiceTypeFilter = () => {
  const [state, setState] = useSetState(defaultState)
  const { showFilter } = state

  const { callbacks: { toggleSelectedVideoServiceType }, selectedVideoServiceType } = useContext(PageContext)

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  return (
    <FormField direction="column" boxProps={{ paddingBottom: 'medium' }}>
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          value="Video Service Types"
        >
          <option>Video Service Types</option>
        </select>

        {showFilter && (
          <Box className={custom.popup}>
            <Box flexDirection="column" maxHeight="280px" overflowY="auto">
              {videoServiceTypes.map(x => (
                <Box flexDirection="row" paddingTop="small" paddingBottom="medium" alignItems="center">
                  <input
                    className={custom.checkbox}
                    onChange={e => toggleSelectedVideoServiceType(e.target.checked, x.id)}
                    type="checkbox"
                    checked={selectedVideoServiceType.includes(x.id)}
                  />
                  <Text fontSize="small" marginLeft="medium">{x.name}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </FormField>
  )
}

export default VideoServiceTypeFilter
