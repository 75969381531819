import React from 'react'

/*
 * Use this context to provide data to a specific page
 * Use PageContext.Provider in the page
 * Generally we use it to supply page specific params e.g. callbacks or props
 * that need to be available through deeply nested components
 */

const data = {}

const PageContext = React.createContext(data)

export default PageContext
