import React from 'react'
import ReactDOM from 'react-dom'
import { IdleTimerProvider } from 'react-idle-timer'
import { ToastContainer } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import 'react-toastify/dist/ReactToastify.css'
import '@styles/toast.scss'

import './index.css'
import App from './App'

import * as serviceWorker from './serviceWorker'

const CloseButton = ({ closeToast }) => (
  <a onClick={closeToast}>
    <FontAwesomeIcon icon={faTimes} />
  </a>
)

ReactDOM.render(
  <React.StrictMode>
    <IdleTimerProvider
      timeout={60000}
      onIdle={() => console.log('idle')}
      onActive={() => console.log('active')}
    >
      <App />
    </IdleTimerProvider>

    <ToastContainer
      autoClose={5000}
      closeButton={<CloseButton />}
      hideProgressBar
      icon={false}
    />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
