import React from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom'

import ErrorPage from '@screens/ErrorPage'

import Default from '.'
import Dashboard from './packs/Dashboard'
import Overview from './packs/Dashboard/screens/Overview'

const DefaultRoutes = () => {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Overview />} />
      </Route>
    </Route>,
  ))

  return (
    <Default>
      <RouterProvider router={router} />
    </Default>
  )
}

export default DefaultRoutes
